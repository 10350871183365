export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const Errors = {
    mandatory: 'This field is mandatory',
    notification: 'Either threshhold or risk level is required'
}

export const MAX_TEXTFIELD_CHARS = 4000;
export const RISK_LEVEL_3_LABEL = "Risk Level 3";
export const TOOLTIP_SECURITY_CONCEPT_REGION = "Please ensure to upload a security concept before submitting the event to the next level";
export const DELAY_SEARCH = 300;
export const DELAY_LOADING = 650;
export const ROWS_PER_PAGE = 10;

export const PAGE_NUMBER_KEY = 'pageNumber';
