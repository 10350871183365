import React from 'react';
import { DeclarationInterface, ValuableAssetItem } from '../../declarationNewEdit/interfaces';
import { UiSectionTitle } from '../../../shared/components/ui/UiSectionTitle';
import NumberFormat from 'react-number-format';
import ConditionalChildren from '../../../shared/components/ConditionalReadOnlyField';
import { FIELD_IDS } from '../../../admin/components/FieldsMgmt/Fields.type';
import UiTextareaField from "../../../shared/components/fields/UiTextareaField";
import {MAX_TEXTFIELD_CHARS} from "../../../shared/constants";

type Insurance = {
    isFromInsuranceTeam: boolean
}

export const ValuablesManagement = (props: DeclarationInterface & Insurance) => (
    <React.Fragment>
        <section className="section">
            <ConditionalChildren fieldId={FIELD_IDS.valuablesAssets.list} >
                {!props.isFromInsuranceTeam &&
                    <table>
                        <thead>
                        <tr>
                            <th>Quantity</th>
                            <th>Type</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.valuableAssets?.valuableAssetsList?.map((v: ValuableAssetItem, i: number) => (
                            <tr key={"valuable-" + i}>
                                <td>{v.quantity}</td>
                                <td>{v.type}</td>
                                <td><NumberFormat value={v.amount} displayType={'text'} thousandSeparator="'" prefix={'€'} /></td>
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td></td>
                            <td>
                                <strong>Total</strong>
                            </td>
                            <td><strong><NumberFormat value={props.valuableAssets?.totalEstimated} displayType={'text'} thousandSeparator="'" prefix={'€'} /></strong></td>
                        </tr>
                        </tfoot>
                    </table>
                }
            </ConditionalChildren>
            <ConditionalChildren fieldId={FIELD_IDS.valuablesAssets.valuableAssetsInformation}>
                <div style={{marginTop:"1.5rem"}}>
                    <UiTextareaField
                        value={props.valuableAssets?.valuableAssetsInformation || ''}
                        label="Valuables Assets Information for broker"
                        disabled={true}
                        placeholder="Valuables Assets Information to send to broker…"
                        inputProps={{maxLength: MAX_TEXTFIELD_CHARS}}
                    />
                </div>
            </ConditionalChildren>
        </section>
        <section className="section" >
            <UiSectionTitle>Routing and transfer</UiSectionTitle>
            <table>
                <tbody>
                    <ConditionalChildren fieldId={FIELD_IDS.routingAndTransfer.routingAndDeparture}>
                        <tr>
                            <td>routing and departure done by</td>
                            <td>{props.routingAndTransfer?.routingAndDeparture}</td>
                        </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.routingAndTransfer.from}>
                    <tr>
                        <td>from</td>
                        <td className="address">{props.routingAndTransfer?.from}</td>
                    </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.routingAndTransfer.to}>
                    <tr>
                        <td>to</td>
                        <td className="address">{props.routingAndTransfer?.to}</td>
                    </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.routingAndTransfer.transportOrganizer}>
                    <tr>
                        <td>transport organizer</td>
                        <td>{props.routingAndTransfer?.transportOrganizer}</td>
                    </tr>
                    </ConditionalChildren>
                </tbody>
            </table>
            <ConditionalChildren fieldId={FIELD_IDS.routingAndTransfer.routingAndTransferInformation}>
                <div style={{marginTop: "1.5rem"}}>
                    <UiTextareaField
                        value={props.routingAndTransfer?.routingAndTransferInformation || ''}
                        label="Routing and transfer information for broker"
                        disabled={true}
                        placeholder="Information about routing and transfer to send to broker..."
                        inputProps={{maxLength: MAX_TEXTFIELD_CHARS}}
                    />
                </div>
            </ConditionalChildren>
        </section>
        <UiSectionTitle>Movements and storages</UiSectionTitle>
            <table>
                <tbody>
                <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.movementOfValuables}>
                    <tr>
                        <td>Movement of Pieces inside the event venue </td>
                        <td className={(props.movementsAndStorages?.movementOfValuables ? 'yes' : 'no')}>{props.movementsAndStorages?.movementOfValuables ? 'yes' : 'no'}</td>
                    </tr>
                </ConditionalChildren>
                </tbody>
            </table>
            <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.movementDescription}>
                <div style={{marginTop: "1.5rem"}}>
                    <UiTextareaField
                        value={props.movementsAndStorages?.movementDescription || ''}
                        label="Description of movement of valuables for brokers"
                        disabled={true}
                        placeholder="Description of movement of valuables for brokers"
                        inputProps={{maxLength: MAX_TEXTFIELD_CHARS}}
                    />
                </div>
            </ConditionalChildren>
            <table>
                <tbody>
                    <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.movementOfValuablesOutside}>
                        <tr>
                            <td>Movement of valuables outside of the event venue</td>
                            <td  className={(props.movementsAndStorages?.isMovValuableOutsideEventVenue ? 'yes' : 'no')}>{props.movementsAndStorages?.isMovValuableOutsideEventVenue ? 'yes' : 'no'}</td>
                        </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.movValOutsideLocationPlace}>
                        <tr>
                            <td>Location place</td>
                            <td className={(props.movementsAndStorages?.movValOutsideLocationPlace ? 'yes' : 'no')}>{props.movementsAndStorages?.movValOutsideLocationPlace}</td>
                        </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.movementOfValuablesOutsideDescription}>
                        <tr>
                            <td>Movement of valuables outside of the event venue description</td>
                            <td>{props.movementsAndStorages?.movementValuableDescription}</td>
                        </tr>
                    </ConditionalChildren>
                </tbody>
            </table>
            <table>
                <tbody>
                <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.tempExternalStorageBeforeEvent}>
                    <tr>
                        <td>Temporary external storage before event</td>
                        <td className={(props.movementsAndStorages?.tempExternalStorageBeforeEvent ? 'yes' : 'no')}>{props.movementsAndStorages?.tempExternalStorageBeforeEvent ? 'yes': 'no'}</td>
                    </tr>
                </ConditionalChildren>
                <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.locationPlaceBeforeEvent}>
                    <tr>
                        <td>Location place</td>
                        <td  className={(props.movementsAndStorages?.locationPlaceBeforeEvent ? 'yes' : 'no')}>{props.movementsAndStorages?.locationPlaceBeforeEvent}</td>
                    </tr>
                </ConditionalChildren>
                <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.tempExternalStorageAfterEvent}>
                    <tr>
                        <td>Temporary external storage after event</td>
                        <td className={(props.movementsAndStorages?.tempExternalStorageAfterEvent ? 'yes' : 'no')}>{props.movementsAndStorages?.locationPlaceAfterEvent ? 'yes': 'no'}</td>
                    </tr>
                </ConditionalChildren>
                <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.locationPlaceAfterEvent}>
                    <tr>
                        <td>Location place</td>
                        <td  className={(props.movementsAndStorages?.locationPlaceAfterEvent ? 'yes' : 'no')}>{props.movementsAndStorages?.locationPlaceAfterEvent}</td>
                    </tr>
                </ConditionalChildren>
                </tbody>
            </table>
            <div>
                <h4><strong>How are pieces stored during after-hours</strong></h4>
                <table>
                    <tbody>
                    <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.dedicatedSafeRoom}>
                        <tr>
                            <td>Dedicated safe room in the event venue</td>
                            <td  className={(props.movementsAndStorages?.isDedicatedSafeRoom ? 'yes' : 'no')}>{props.movementsAndStorages?.isDedicatedSafeRoom ? 'yes' : 'no'}</td>
                        </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.externalSecuredStorage}>
                        <tr>
                            <td>External secured storage outside of the event venue</td>
                            <td  className={(props.movementsAndStorages?.isExternalSecuredStorage ? 'yes' : 'no')}>{props.movementsAndStorages?.isExternalSecuredStorage ? 'yes' : 'no'}</td>
                        </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.nearbyBoutique}>
                        <tr>
                            <td>Nearby boutique</td>
                            <td  className={(props.movementsAndStorages?.isNearbyBoutique ? 'yes' : 'no')}>{props.movementsAndStorages?.isNearbyBoutique ? 'yes' : 'no'}</td>
                        </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.piecesRemainInShowcases}>
                        <tr>
                            <td>Pieces remain in showcases (exhibition events)</td>
                            <td  className={(props.movementsAndStorages?.isPiecesRemainInShowcases ? 'yes' : 'no')}>{props.movementsAndStorages?.isPiecesRemainInShowcases ? 'yes' : 'no'}</td>
                        </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.other}>
                        <tr>
                            <td>Other</td>
                            <td  className={(props.movementsAndStorages?.isOther ? 'yes' : 'no')}>{props.movementsAndStorages?.isOther ? 'yes' : 'no'}</td>
                        </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.movementAndStorage.otherDescription}>
                        <tr>
                            <td>Other description</td>
                            <td>{props.movementsAndStorages?.otherDescription}</td>
                        </tr>
                    </ConditionalChildren>

                    </tbody>
                </table>
            </div>
    </React.Fragment>
)