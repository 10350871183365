import React, { Component, ErrorInfo } from 'react';

import { LoggingServie } from '../services/LoggingService';

class AppErrorBoundary extends Component {
    state = {
      error: null, errorInfo: null, hasError: null
    }

    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null, hasError: null };
      }
  
    static getDerivedStateFromError(error: Error | null, info: ErrorInfo) {
        // Update state so next render shows fallback UI.
        return { error: error, hasError: true, errorInfo: info};
    }

    componentDidCatch(error: Error | null, info: ErrorInfo) {
        this.setState({
            error: error,
            errorInfo: info.componentStack,    
            hasError: true,
        })
        if(error !== null){
          LoggingServie.LogError(error?.message, info.componentStack);
        }
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        // TODO: redirect to 404 page and try to change status 
        return (
            <h1>
                <p>Something broke !!! :-(</p>
            </h1>
        );
      }
      return this.props.children; 
    }
  }

  export default AppErrorBoundary;