import React from 'react';
import { ILevelOfMyEvent } from '../../interface'


const LevelOfMyEvent = (props: ILevelOfMyEvent) => {

   const [firstLigne, ...autresLignes] = props.arrayValues;

   return (
      <div>
         <p>Events' risk levels are defined by different factors : country, total estimated values, certain types of assets.</p>
         <p>For all level 3 evets, a declaration form <strong>MUST be filled in</strong> and a security concept file <strong>MUST be attached.</strong></p>
         <p><strong>Risk level 3 must be chosen if fashion shows / heritage pieces are chosen.</strong></p>
         <p>You'll find below the risk level matrix to help you compute the appropriate risk level of your event.</p>
         <div className="box-content__table">
            <table className="table__level">
               <thead>
                  <tr>
                     {firstLigne.map((value, index) => <th key={index}>{value}</th>)}
                  </tr>
               </thead>
               <tbody>
                  {
                     autresLignes.map((ligne, indexLigne) => {
                        const [firstCol, ...restCol] = ligne
                        return (
                            <tr key={indexLigne}>
                                <td>{firstCol}</td>
                                {restCol.map((coloneValue, indexCol) => (
                                    <td key={indexCol}>
                                        {coloneValue && coloneValue === true && (
                                            <span className={"circle circle-" + indexCol}></span>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                     })
                  }
               </tbody>
            </table>
         </div>
      </div>
   );
}

export default LevelOfMyEvent