import React from "react";

class UiLogo extends React.Component<any> {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 410 39.6"
                width={250}
                height="auto"
            >
                <defs>
                    <style>
                        {`
                            .cls-1 {
                                fill: #004165;
                            }
                            .cls-1, .cls-2 {
                                stroke-width: 0;
                            }
                            .cls-2 {
                                fill: #9dbcb0;
                            }
                        `}
                    </style>
                </defs>
                <g>
                    <path
                        className="cls-1"
                        d="M12.8,22.3c-3.7-3.7-3.7-9.8,0-13.6s9.8-3.7,13.6,0l4.2-4.2c-6.1-6.1-15.9-6.1-21.9,0s-6.1,15.9,0,21.9l4.2-4.2Z"
                    />
                    <path
                        className="cls-2"
                        d="M4.2,30.9c3.7,3.7,9.8,3.7,13.6,0s3.7-9.8,0-13.6l4.2-4.2c6.1,6.1,6.1,15.9,0,21.9s-15.9,6.1-21.9,0l4.2-4.2Z"
                    />
                </g>
                <g>
                    <path className="cls-1" d="M61.4,34.3v5.3h-19.6V13h19.6v5.3h-13.4v5.9h13.4v5.4h-13.4v4.7h13.4Z" />
                    <path className="cls-1" d="M86.5,13h6.7l-13.1,26.6h-4.8l-13.1-26.6h6.7l8.7,17.8,8.8-17.8Z" />
                    <path className="cls-1" d="M113.6,34.3v5.3h-19.6V13h19.6v5.3h-13.4v5.9h13.4v5.4h-13.4v4.7h13.4Z" />
                    <path className="cls-1" d="M143.3,13v26.6h-5.3l-13.8-16.4v16.4h-6.2V13h5.3l13.8,16.4V13h6.2Z" />
                    <path className="cls-1" d="M168.3,13v5.3h-8.2v21.3h-6.2v-21.3h-8.2v-5.3h22.6Z" />
                    <path
                        className="cls-1"
                        d="M177,39.6h-6.3V13h6.4c1.3,0,2.5.2,3.7.5,1.2.3,2.3.8,3.3,1.3,1,.6,2,1.3,2.8,2.1.9.8,1.6,1.7,2.2,2.7.6,1,1.1,2,1.4,3.2.3,1.1.5,2.3.5,3.5s-.2,2.4-.5,3.5-.8,2.2-1.4,3.2-1.3,1.9-2.2,2.7c-.9.8-1.8,1.5-2.8,2.1-1,.6-2.2,1-3.4,1.4-1.2.3-2.4.5-3.7.5ZM177,14.2h-5.1v24.2h5.1c1.2,0,2.3-.1,3.4-.4,1.1-.3,2.1-.7,3.1-1.2.9-.5,1.8-1.2,2.6-1.9.8-.7,1.4-1.6,2-2.5s1-1.9,1.3-2.9c.3-1,.5-2.1.5-3.2s-.2-2.2-.5-3.2c-.3-1-.7-2-1.3-2.9s-1.2-1.7-2-2.4c-.8-.7-1.6-1.4-2.6-1.9-.9-.5-2-.9-3.1-1.2-1.1-.3-2.2-.4-3.4-.4Z"
                    />
                    <path
                        className="cls-1"
                        d="M210.6,27.6h-15.2v10.8h15.2v1.2h-16.4V13h16.4v1.2h-15.2v12.2h15.2v1.2Z"
                    />
                    <path
                        className="cls-1"
                        d="M227,39.6c-1.3,0-2.5-.2-3.7-.5-1.2-.3-2.3-.8-3.3-1.4-1-.6-1.9-1.3-2.8-2.1-.8-.8-1.5-1.7-2.1-2.7s-1-2-1.4-3.2c-.3-1.1-.5-2.3-.5-3.5s.2-2.4.5-3.5c.3-1.1.8-2.2,1.4-3.2s1.3-1.9,2.1-2.7c.8-.8,1.8-1.5,2.8-2.1,1-.6,2.1-1,3.3-1.3s2.4-.5,3.7-.5,3.1.2,4.6.7c1.5.5,2.8,1.1,4,2v1.5c-1.2-.9-2.5-1.7-3.9-2.2-1.5-.5-3-.8-4.7-.8s-2.3.1-3.4.4c-1.1.3-2.1.7-3,1.2-.9.5-1.8,1.1-2.5,1.9-.8.7-1.4,1.5-1.9,2.4-.5.9-1,1.9-1.2,2.9-.3,1-.4,2.1-.4,3.2s.1,2.2.4,3.2c.3,1,.7,2,1.2,2.9.5.9,1.2,1.7,1.9,2.5.8.7,1.6,1.4,2.5,1.9.9.5,1.9.9,3,1.2s2.2.4,3.4.4c1.7,0,3.2-.3,4.7-.8,1.5-.5,2.8-1.3,3.9-2.2v1.5c-1.2.8-2.6,1.5-4,2-1.5.5-3,.7-4.6.7Z"
                    />
                    <path className="cls-1" d="M240.7,13h1.2v25.4h15.3v1.2h-16.5V13Z" />
                    <path
                        className="cls-1"
                        d="M259.6,39.6h-1.3l11-26.6h1.5l11,26.6h-1.3l-4.4-10.7h-12.1l-4.4,10.7ZM264.5,27.7h11l-5.5-13.3-5.5,13.3Z"
                    />
                    <path
                        className="cls-1"
                        d="M293.1,27.5l7.6,12.1h-1.4l-8.3-13.2h.8c1.1,0,2.1-.2,3.1-.5.9-.3,1.8-.8,2.5-1.4.7-.6,1.2-1.2,1.6-2s.6-1.5.6-2.3-.2-1.6-.6-2.3-1-1.4-1.7-1.9c-.7-.6-1.5-1-2.5-1.3-.9-.3-2-.5-3-.5h-6.1v25.4h-1.2V13h7.3c1.3,0,2.4.2,3.5.6,1.1.4,2.1.9,2.9,1.6.8.7,1.5,1.5,1.9,2.3.5.9.7,1.8.7,2.8s-.2,1.7-.6,2.5c-.4.8-.9,1.5-1.6,2.1-.7.6-1.5,1.2-2.4,1.6-.9.4-1.9.8-3.1.9Z"
                    />
                    <path
                        className="cls-1"
                        d="M303.9,39.6h-1.3l11-26.6h1.5l11,26.6h-1.3l-4.4-10.7h-12.1l-4.4,10.7ZM308.8,27.7h11l-5.5-13.3-5.5,13.3Z"
                    />
                    <path className="cls-1" d="M335.1,14.2h-8.2v-1.2h17.7v1.2h-8.2v25.4h-1.2V14.2Z" />
                    <path className="cls-1" d="M348.6,39.6V13h1.2v26.6h-1.2Z" />
                    <path
                        className="cls-1"
                        d="M367.6,39.6c-1.3,0-2.5-.2-3.7-.5-1.2-.3-2.3-.8-3.3-1.3-1-.6-2-1.3-2.8-2.1-.8-.8-1.6-1.7-2.2-2.7s-1.1-2-1.4-3.2c-.3-1.1-.5-2.3-.5-3.5s.2-2.4.5-3.5c.3-1.1.8-2.2,1.4-3.2.6-1,1.3-1.9,2.2-2.7s1.8-1.5,2.8-2.1c1-.6,2.1-1,3.3-1.4,1.2-.3,2.4-.5,3.7-.5s2.5.2,3.7.5c1.2.3,2.3.8,3.3,1.4,1,.6,2,1.3,2.8,2.1s1.6,1.7,2.2,2.7c.6,1,1.1,2,1.4,3.2.3,1.1.5,2.3.5,3.5s-.2,2.4-.5,3.5c-.3,1.1-.8,2.2-1.4,3.2-.6,1-1.3,1.9-2.2,2.7-.8.8-1.8,1.5-2.8,2.1-1,.6-2.1,1-3.3,1.3-1.2.3-2.4.5-3.7.5ZM367.6,14.2c-1.2,0-2.3.1-3.4.4-1.1.3-2.1.7-3,1.2-.9.5-1.8,1.2-2.6,1.9-.8.7-1.4,1.6-2,2.4-.6.9-1,1.9-1.3,2.9-.3,1-.5,2.1-.5,3.2s.2,2.2.5,3.2c.3,1,.7,2,1.3,2.9.6.9,1.2,1.7,2,2.4.8.7,1.6,1.4,2.6,1.9.9.5,2,.9,3,1.2,1.1.3,2.2.4,3.4.4s2.3-.1,3.4-.4c1.1-.3,2.1-.7,3-1.2.9-.5,1.8-1.2,2.6-1.9.8-.7,1.4-1.5,2-2.4.6-.9,1-1.9,1.3-2.9.3-1,.5-2.1.5-3.2s-.2-2.2-.5-3.2c-.3-1-.7-2-1.3-2.9-.6-.9-1.2-1.7-2-2.4-.8-.7-1.6-1.4-2.6-1.9-.9-.5-2-.9-3-1.2-1.1-.3-2.2-.4-3.4-.4Z"
                    />
                    <path className="cls-1" d="M386.7,13l17.6,24.9V13h1.2v26.6h-1.5l-17.6-24.9v24.9h-1.2V13h1.5Z" />
                </g>
            </svg>
        );
    }
}

export default UiLogo;
