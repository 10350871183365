import { RiskTagsProps } from '../shared/models/interfaces'
import { EventFlow } from "../shared/services/flowRefService";

export interface IPropsDashboard {
   dashBoarditems: IPropsDashboardItem[];
   upcomingEvents: number;
   currentMonthClosedEvents: number;
}

export interface IPropsDashboardItem extends IPropsDashboardBase {
   showExclamationPoint: boolean;
   riskTags: RiskTagsProps
   eventFlowToClose?: EventFlow[]
}

export interface IPropsDashboardBase {
   id?: number;
   itemContent?: string;
   status?: EventStatus;
   eventNameId?: string;
   isSnoozed?: boolean | null;
   eventName?: string | null;
   leadbrandName?: string | null;
   countriesContent?: string | null;
   createdDate?: string | null;
   fromDate?: string | null;
   toDate?: string | null;
   locationName?: string | null;
   estimatedTotalValue?: number | null;
   countryLevelRiskContent?: string | null;
   riskLevelContent?: string | null;
   idEventFlow? : number | null;
   idTask: number | null;
   idFlow : number | null ;
   idNextTask: number | null;
   idSnoozeReminder?: number;
   taskLabel: string | null;
   hasConceptAccessDoc?: boolean;
   hasReportAssessment?: boolean;
   fetch?: any;
   multiBrandEvent?: boolean;
   maxEstimatedValue?: number;
   minEstimatedValue?: number;
   reportedDate?: string;
   statusModifiedDate?: string;
}

export interface IModalFilter {
   riskAssessment: boolean,
   securityConcept: boolean,
   eventToWatch: boolean,
   isSummary: boolean,
   status: [],
   from: string,
   to: string,
   levelRisk: [],
   country: [],
   riskTags: {
      heritage: boolean,
      public: boolean,
      strategic: boolean,
      vip: boolean
   },
   leadBrandName: [],
   minEstimatedValue: number | undefined,
   maxEstimatedValue: number | undefined,
   reportedDateFrom: string,
   reportedDateTo: string,
   isNotClosed: boolean,
   isNotCancelled: boolean,
   kpiFilter: string
}

export enum RiskTagsEnum {
   isStrategic = "Open displays",
   isHeritage = "Heritage",
   isMulti = "Multi"
}

export enum EventStatus {
   new = "New",
   draft = "Draft",
   pendinghq = "Pending HQ",
   pendingregion = "Pending Region",
   pendingbroker = "Pending Broker",
   pendingrisk = "Pending Risk",
   cancelled = "Cancelled",
   closed = "Closed",
   pendinglocal = "Pending local",
   pendingunderw = "Pending underw.",
   pendingriska = "Pending Risk A.",
   escalated = "Escalated",
   pendingMaison = "Pending Maison"
}

export interface BigValendarItem {
   start?: Date;
   end?: Date;
   title?: string;
   resource?: Resource;
}

interface Resource {
   id?: number;
   risks?: RiskTagsProps;
   country?: string;
   maison?: string;
   amount?: string;
   status?: EventStatus | undefined;
   riskLevelContent?: string,
   item?: any
}