import React, { FC } from "react";
import { roleAllowedToSeeTable } from "../../../shared/config/securityConcept";
import { useAuthContext } from "../../../shared/contexts/AuthContext";
import './SecurityConcept.scss';
import { SecurityConceptTable, SecurityConceptTableProps } from "./SecurityConceptTable";

export type SecurityConceptBoxProps = SecurityConceptTableProps

export const SecurityConceptBox: FC<SecurityConceptBoxProps> = ({event,reloadFiles,securityConcepts,loadConcepts,securityConceptsLoading, onReject}) => {
    const {role} = useAuthContext();

    if(!role || !roleAllowedToSeeTable.includes(role)) return <></>

    return <SecurityConceptTable 
        event={event}
        reloadFiles={reloadFiles}
        securityConcepts={securityConcepts}
        loadConcepts={loadConcepts}
        securityConceptsLoading={securityConceptsLoading}
        onReject={onReject}
    />
}