import { AxiosRequestConfig } from "axios";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { defaultFieldFormValues, FieldFormValues } from "../../admin/components/FieldsMgmt/Fields.type";
import { NotificationFormValue } from "../../admin/components/NotificationsMgmt/NotificationForm";
import { ReminderFormValue } from "../../admin/components/NotificationsMgmt/ReminderForm";
import { TestTemplate } from "../../admin/components/NotificationsMgmt/TemplateForm";
import {  serverUrlBase, urlAddUser, urlDeleteUser, urlGetPagedUsers, urlUpdateUser } from "../config/GlobalAppConfig";
import CustomAxios, { ApiCallingMethods } from "../helpers/AxiosHelper";
import {
    BaseAttributeDto,
    CountryDto, FilteredAdminPagedRequest,
    ICreateAttribute,
    ICreateTemplate,
    IThreshold,
    MaisonDto,
    NotificationDto,
    NotificationDtoBack, PagedRequest,
    ReminderDto,
    ReminderDtoBack,
    TemplateDto,
    UserDtoBack
} from "../models/interfaces";
import { BaseService } from "./base.service";

export class AdministrationService {
    
    static getAllMaisons(): Observable<MaisonDto[]> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${serverUrlBase}/api/Administration/Attributes/Maisons`,
            data: null,
        };
        return BaseService.doRequest(options);
    }

    static addMaison(maison: ICreateAttribute): Observable<MaisonDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/Administration/Attributes/Maisons/Add`,
            data: maison,
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    }

    static updateMaison(maison: MaisonDto): Observable<MaisonDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: `${serverUrlBase}/api/Administration/Attributes/Maisons/Update`,
            data: maison,
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    }

    static deleteMaison(maison: MaisonDto): Observable<MaisonDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.delete,
            url: `${serverUrlBase}/api/Administration/Attributes/Maisons/Delete/${maison.id}`,
            data: null,
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    }

    static getAllCountries(): Observable<CountryDto[]> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${serverUrlBase}/api/Administration/Attributes/Countries`,
            data: null,
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    }

    static addCountry(country: ICreateAttribute): Observable<MaisonDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/Administration/Attributes/Countries/Add`,
            data: {
                id: 0,
                ...country,
                displayOrder: 0,
            },
        };
        return BaseService.doRequest(options);
    }

    static updateCountry(country: CountryDto): Observable<CountryDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: `${serverUrlBase}/api/Administration/Attributes/Countries/Update`,
            data: country,
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    }

    static deleteCountry(country: BaseAttributeDto): Observable<MaisonDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.delete,
            url: `${serverUrlBase}/api/Administration/Attributes/Countries/Delete/${country.id}`,
            data: null,
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    }

    static getThreshold(): Observable<IThreshold> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${serverUrlBase}/api/Administration/Thresholds`,
            data: null,
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    }

    static updateThreshold(threshold: IThreshold): Observable<IThreshold> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: `${serverUrlBase}/api/Administration/Thresholds/Update?thresholdValue=${threshold.thresholdValue}`,
            data: null,
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    }

    static getTemplates(): Observable<TemplateDto[]> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${serverUrlBase}/api/Administration/Notifications/Templates`,
            data: null,
        };
        return BaseService.doRequest(options);
    }

    static addTemplate(template: ICreateTemplate): Observable<TemplateDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/Administration/Notifications/Templates/Add`,
            data: template,
        };
        return BaseService.doRequest(options);
    }

    static updateTemplate(template: TemplateDto): Observable<TemplateDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: `${serverUrlBase}/api/Administration/Notifications/Templates/Update`,
            data: template,
        };
        return BaseService.doRequest(options);
    }

    static deleteTemplate(template: TemplateDto): Observable<TemplateDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.delete,
            url: `${serverUrlBase}/api/Administration/Notifications/Templates/Delete/${template.id}`,
            data: null,
        };
        return BaseService.doRequest(options);
    }

    static getNotifications(): Observable<NotificationDto[]> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${serverUrlBase}/api/Administration/Notifications/Notifications`,
            data: null,
        };
        return BaseService.doRequest(options).pipe(
            map((res) => {
                return res.map((notification: any) : NotificationDto => ({
                    id: notification.id,
                    name: "",
                    template: {
                        id: notification.templateId,
                        name: notification.templateName,
                    },
                    recipients: notification.notificationRoleRecipients.map((recipient: any) => ({
                        id: recipient.idNotificationRoleGroupUser,
                        name: recipient.name,
                    })),
                    thresholdRecipients: notification.thresholdAlertRecipients.map((recipient: any) => ({
                        id: recipient.idThresholdRoleGroupUser,
                        name: recipient.name,
                    })),
                    active: notification.isActive,
                    nextAction: {
                        id: notification.nextActionId,
                        itemContent: notification.nextAction,
                    },
                    threshold: notification.threshold,
                    idRiskLevel: notification.idRiskLevel,
                    isShownDeleteButton: notification.isShownDeleteButton
                }));
            })
        );
    }

    static addNotification(notification: NotificationFormValue): Observable<NotificationDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/Administration/Notifications/Notifications/Add`,
            data: {
                id: 0,
                templateId: notification.template?.value,
                nextActionId: notification.nextAction?.value,
                notificationRoleRecipients: notification.roles?.map((recipient) => ({
                    id: 0,
                    idNotificationRoleGroupUser: recipient.value
                })),
                thresholdAlertRecipients: notification.thresholdRecipients.map((recipient) => ({
                    id: recipient.value,
                    idThresholdRoleGroupUser : recipient.value
                })),

                threshold: notification.threshold,
                isActive: notification.active,
                idRiskLevel: notification.riskLevels?.value
            },
        };
        return BaseService.doRequest(options);
    }

    static updateNotification(notification: NotificationDtoBack & { id: number }): Observable<NotificationDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: `${serverUrlBase}/api/Administration/Notifications/Notifications/Update`,
            data: notification
        };
        return BaseService.doRequest(options);
    }

    static deleteNotification(notification: NotificationDto): Observable<NotificationDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.delete,
            url: `${serverUrlBase}/api/Administration/Notifications/Notifications/Delete/${notification.id}`,
            data: null,
        };
        return BaseService.doRequest(options);
    }

    static testTemplateNotification(testTemplate: TestTemplate): Observable<boolean> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/Email/TestTemplate`,
            data: testTemplate,
        };
        return BaseService.doRequest(options);
    }

    static getReminders(): Observable<ReminderDto[]> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${serverUrlBase}/api/Administration/Notifications/Reminders`,
            data: null,
        };
        return BaseService.doRequest(options).pipe(
            map((res) => {
                return res.map((reminder: any) : ReminderDto=> ({
                    id: reminder.id,
                    name: "",
                    template: {
                        id: reminder.templateId,
                        name: reminder.templateName,
                    },
                    recipients: reminder.reminderRoleRecipients.map((recipient: any) => ({
                        id: recipient.id,
                        idReminderRoleGroupUser : recipient.idReminderRoleGroupUser,
                        name: recipient.name,
                    })),
                    active: reminder.isActive,
                    nextAction: {
                        id: reminder.nextActionId,
                        itemContent: reminder.nextActionName,
                    },
                    frequency: reminder.frequency,
                }));
            })
        );
    }

    static addReminder(reminder: ReminderFormValue): Observable<ReminderDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/Administration/Notifications/Reminders/Add`,
            data: {
                id: 0,
                templateId: reminder.template?.value,
                nextActionId: reminder.nextAction?.value,
                reminderRoleRecipients: reminder.recipients?.map((recipient) => ({
                    idReminder: 0,
                    idReminderRoleGroupUser: recipient.value,
                })),
                frequency: reminder.frequency,
                isActive: reminder.active,
            },
        };
        return BaseService.doRequest(options);
    }
    static updateReminder(reminder: ReminderDtoBack): Observable<ReminderDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: `${serverUrlBase}/api/Administration/Notifications/Reminders/Update`,
            data: reminder,
        };
        return BaseService.doRequest(options);
    }

    static deleteReminder(reminder: ReminderDto): Observable<ReminderDto> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.delete,
            url: `${serverUrlBase}/api/Administration/Notifications/Reminders/Delete/${reminder.id}`,
            data: null,
        };
        return BaseService.doRequest(options);
    }

    static getFields(idGroupUser: number): Observable<FieldFormValues> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${serverUrlBase}/api/Administration/Fields/${idGroupUser}`,
            data: null,
        };
        return BaseService.doRequest(options).pipe(
            map((fields: string) => {
                const data = JSON.parse(fields.replace(/(^'|'$)/g, "").replace(/(^"|"$)/g, ""));
                // data should be an object, so length should be undefined
                return data.length ? defaultFieldFormValues : data;
            })
        );
    }

    static updateFields(idGroupUser: number, fields: FieldFormValues) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: `${serverUrlBase}/api/Administration/Fields/${idGroupUser}/Update`,
            data: '"' + JSON.stringify(fields) + '"',
            headers: { "content-type": "application/json" },
        };
        return BaseService.doRequest(options).pipe(
            map((fields) => {
                return JSON.parse(fields);
            })
        );
    }

    static getAllUsers(pageSize : number, pageNumber: number){
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlGetPagedUsers,
            data: {pageSize: pageSize, pageNumber: pageNumber},
            headers: { "content-type": "application/json" }
        }
        return CustomAxios.request(options).pipe(map(source => source.data));
    }

    static getPagedUsers(data: FilteredAdminPagedRequest){
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlGetPagedUsers,
            data,
            headers: { "content-type": "application/json" }
        }
        return CustomAxios.request(options).pipe(map(source => source.data));
    }

    static addUser(data: UserDtoBack){
        const options : AxiosRequestConfig = {
            method:  ApiCallingMethods.post,
            url: `${urlAddUser}`,
            data,
            headers: { "content-type": "application/json" }
        }
        return BaseService.doRequest(options)
    }

    static updateUser(data: UserDtoBack){
        const options : AxiosRequestConfig = {
            method:  ApiCallingMethods.put,
            url: `${urlUpdateUser}`,
            data,
            headers: { "content-type": "application/json" }
        }
        return BaseService.doRequest(options)
    }

    static deleteUser(id: number){
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.delete,
            url: `${urlDeleteUser}/${id}`,
        }
        return BaseService.doRequest(options)
    }
}
