import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';

import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerField = ({...props}: any) =>{
    const { setFieldValue } = useFormikContext(); 
    //console.log(props);
    // console.log(props.children);
    const [field] = useField(props.field);

    function handleDayChange(day_: any){
        //console.log(day_);
        setFieldValue(field.name, day_, true);
    }

    return(
        // <DayPickerInput key={field.name} {...field} {...props}
        // <DayPickerInput key={field.name} {...props}
        //     //selected={(field.value && new Date(field.value)) || null} 
        //     onChange={
        //         val => {
        //             console.log('-----------------------');
        //             console.log(val);
        //             console.log(field.name);
        //             console.log(field.value);
        //             console.log('-----------------------');
        //             setFieldValue(field.name, field.value, true);
        //         }
        //     } 
        //     value={field.value}
        // />
        <DayPickerInput format="LL" placeholder={props.placeholder} onDayChange={handleDayChange} dayPickerProps={{

        }} />
    );
};

export const Example = ({...props}: any) => {

      let inputStartDate: Date = props.startDate;
      let useMinDate: boolean = props.useMinDate;
      let dateFormat: any = props.dateFormat;
      let locale: string = props.locale;
      const [field] = useField(props.field);

      if(locale){
        registerLocale("currentLocation", locale); // register it with the name you want
      }
      
        const { setFieldValue } = useFormikContext(); 
        //const [field] = useField(props.field);

        const [startDate, setStartDate] = useState(new Date(inputStartDate));
        //const [endDate, setEndDate] = useState(new Date("2014/02/10"));

        const handleDayChangeFrom = (date_: any) => {
            console.log(date_);
            console.log(field.name);
            setFieldValue(field.name, date_, false);
            setStartDate(date_);
            if(props.customAction){
              props.customAction(date_, setFieldValue);
            }
        }

        // function handleDayChangeTo(date_: any){
        //     //console.log(day_);
        //     setFieldValue("to", date_, true);
        //     //setEndDate(date_);
        // }

        return (
          <>
          <DatePicker
            selected={startDate}
            //onChange={date => setStartDate(date)}
            onChange={handleDayChangeFrom}
            // selectsStart
            startDate={inputStartDate ? inputStartDate : null}
            minDate={ useMinDate ? inputStartDate : null}
            //endDate={endDate}
            dateFormat={dateFormat ? dateFormat : null}
            locale="currentLocation"
            value={props.value}
            
          />
        </>
          // <>
          //   <label>From</label>
          //   <DatePicker
          //     selected={startDate}
          //     //onChange={date => setStartDate(date)}
          //     onChange={handleDayChangeFrom}
          //     selectsStart
          //     startDate={startDate}
          //     endDate={endDate}
          //   />
          //   <label>To</label>
          //   <DatePicker
          //     selected={endDate}
          //     //onChange={date => setEndDate(date)}
          //     onChange={handleDayChangeTo}
          //     selectsEnd
          //     startDate={startDate}
          //     endDate={endDate}
          //     minDate={startDate}
          //   />
          // </>
        );
      };

//export default DatePickerField;