import * as Yup from 'yup';

export const InitEventInformation = {
  eventName: 'Event name',
  eventType: '',
  from: '',
  to: '',
  locationName: '',
  locationType: '',
  EventAddress: '',
}

export const SchemaEventInformation = Yup.object().shape({
    eventName: Yup.string().required("*"),
    eventType: Yup.string().required("*"),
    from: Yup.date(),
    to: Yup.date(),
    locationName: Yup.string(),
    locationType: Yup.string(),
    EventAddress: Yup.string(),
  })