import { ReactText } from "react";
import { NotificationManager } from 'react-notifications';
import { FieldsVisibilityFormStructure } from "../../admin/components/FieldsMgmt/Fields.type";
import { EventStatus, IPropsDashboardItem } from "../../dashboard/interface";
import { dashboard_maxDaysLeftToTriggerWarning, notificationDisplayTime, notificationMessage, oneDay, truncateMaxCharNb, truncateText } from "../config/GlobalAppConfig";
import { emailRegex } from "../constants";
import { LocationNamingRsult, NotificationType, Task } from '../models/enums';
import { Download, IFieldListItem } from "../models/interfaces";
import LocalStorageTokenService from "./SessionStorageTokenService";

// function mergeSchemas(...schemas: Array<Yup.ObjectSchema<object>>) {
export function MergeSchemas(...schemas: any[]) {
    const [first, ...rest] = schemas;
    
    const merged = rest.reduce(
        (mergedSchemas, schema) => mergedSchemas.concat(schema),
        first
    );
    return merged;
}



export const ArrayUtils = {
    getUniqueBySingleKey(arr: any[], index: any) {

        const unique = arr
             .map(e => e[index])
      
             // store the keys of the unique objects
             .map((e, i, final) => final.indexOf(e) === i && i)
      
             // eliminate the dead keys & store unique objects
            .filter((e: any) => arr[e]).map((e: any) => arr[e]);      
      
         return unique;
      },
}

export const TruncateUtils = {
    truncateText(textValue: string) {
        if(textValue !== undefined && textValue.length > truncateMaxCharNb){
            return textValue.slice(0, truncateMaxCharNb) + truncateText;
        }
        else{
            return textValue;
        }
    }
}

// export function ObjectUtils<T>(value: T){
export const ObjectUtils = {
    getInterfacePropertyValue: (objData: any, key: any) => {
        const indexKey = Object.keys(objData).indexOf(key);
        return Object.entries(objData)[indexKey][1];
    },
    getInterfaceProperties: (objInterface: any) => {
        let propsName: string[] = [];
        Object.keys(objInterface).forEach(
            (key)  => propsName.push(key)
        );
        return propsName;
    }, 
}

export const numberScaleFormat = (amount: number): ReactText => {
    let divider : number = 1;
    let symbol : string = '';
 
    amount = Math.abs(amount);
 
    // Billions
    if (amount >= 1.0e+9){
        divider = 1.0e+9;
        symbol = 'B';
    }
    // Millions
    else if (amount >= 1.0e+6){
        divider = 1.0e+6;
        symbol = 'M';
    }
    // Thousands
    else if (amount >= 1.0e+3){
        divider = 1.0e+3;
        symbol = 'K';
    }
 
    const result: number = Number((amount / divider).toFixed(1)); 
    
    return result.toString().concat(symbol);
}


export const sortArrayObject = (list: any, ascending: boolean, prop: string) => {
    if (list.length) {
        if (!isNaN(list[0][prop])) {
            (ascending) ? list.sort((a: any, b: any) => a[prop]-b[prop]) : list.sort((a: any, b: any) => b[prop]-a[prop]);
        }
        else {
            (ascending) ?
                    list.sort((a: any, b: any) => a[prop]>b[prop] ? -1 : a[prop]<b[prop] ? 1 : 0) :
                    list.sort((a: any, b: any) => a[prop]>b[prop] ? 1 : a[prop]<b[prop] ? -1 : 0);
        }
    }
}

export const getEnumKeyByEnumValue = (myEnum: any, enumValue: any) => {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
 }
 
 export const getEnumValueByEnumKey = (myEnum: any, enumKey: any) => {
    return myEnum[enumKey];
 }

 export const getMapKeyByEnumValue = (myMap: Map<any, any>, enumValue: any) => {
    let keys : string = '';
    myMap.forEach((value: any, key: any) => {
        if(value === enumValue){
            keys = key;
        }
      })
    return keys.length > 0 ? keys : null;
 }

 export const formatBytes = (bytes: number) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}

export const isDateInRange = (startDate: Date, endDate: Date, dateToCheck: Date): boolean => {
    return isDateSuperiorOrEqualTo(startDate, dateToCheck) && isDateLowerOrEqualTo(endDate,dateToCheck)
}

export const getDateDifferenceInDays = (referenceDate: Date, dateToCompare: Date) => {
    const diffTime = dateToCompare.getTime() - referenceDate.getTime();
    return (Math.round(diffTime / (1000 * 3600 * 24)) + 1)
}

export const isDateSuperiorOrEqualTo = (referenceDate: Date, dateToCompare: Date) =>{
     //Avoid error in date comparison because of hours.
    referenceDate.setHours(0,0,0,0)
    dateToCompare.setHours(0,0,0,0)
    return (dateToCompare >= referenceDate)
}

export const isSameMonth = (referenceDate: Date, dateToCompare: Date) =>{
    return (dateToCompare.getMonth() === referenceDate.getMonth() && dateToCompare.getFullYear() === referenceDate.getFullYear())
}

export const isDateLowerOrEqualTo = (referenceDate: Date, dateToCompare: Date) =>{
     //Avoid error in date comparison because of hours.
    referenceDate.setHours(0,0,0,0)
    dateToCompare.setHours(0,0,0,0)
    return (dateToCompare <= referenceDate)
}

type Field = 'username' | 'role'

export const getUsernameOrRole = (field: Field): string => {
    if(field === 'username'){
        let userid: string | null = LocalStorageTokenService.getUserNameToken();
        return userid ? userid : '';
    }
    else{
        let userRole: string | null = LocalStorageTokenService.getUserRoleToken();
        return userRole ? userRole : '';
    }
}

// export const getUsers = ():User[] => {
//     const users: User[] = require(`./users.json`);
//     return users;
// }

export const createNotification = (type : NotificationType, idTask: number, customTitle : string = '', customMessage : string = '') => {
    const {titleFormatted ,messageFormatted} = (customTitle && customMessage) ?
        {titleFormatted :customTitle ,messageFormatted : customMessage} : formatMessage(type,idTask,customTitle,customMessage)
    switch (type) {
    case NotificationType.info:
        NotificationManager.info(messageFormatted,titleFormatted,notificationDisplayTime.info);
        break;
    case NotificationType.success:
        NotificationManager.success(messageFormatted,titleFormatted,notificationDisplayTime.succes);
        break;
    case NotificationType.warning:
        NotificationManager.warning(messageFormatted,titleFormatted,notificationDisplayTime.warning);
        break;
    case NotificationType.error:
        NotificationManager.error(messageFormatted,titleFormatted,notificationDisplayTime.error);
        break;
    }
};

export const baseCreateNotification = (type : NotificationType, customTitle : string = '', customMessage : string = '') => {
    switch (type) {
    case NotificationType.info:
        NotificationManager.info(customMessage, customTitle);
        break;
    case NotificationType.success:
        NotificationManager.success(customMessage, customTitle);
        break;
    case NotificationType.warning:
        NotificationManager.warning(customMessage, customTitle);
        break;
    case NotificationType.error:
        NotificationManager.error(customMessage, customTitle);
        break;
    }
};

export const formatMessage = ( type : NotificationType, idTask: number, customTitle : string = '', customMessage : string = '') => {
    let titleFormatted = '';
    let messageFormatted = '';
    if(type === NotificationType.success){
        switch(idTask){
            case Task.Send_to_security:
                titleFormatted = notificationMessage.Send_to_security.title;
                messageFormatted = notificationMessage.Send_to_security.message;
                break;
            case Task.Save_for_later:
                titleFormatted = notificationMessage.Save_for_later.title;
                messageFormatted = notificationMessage.Save_for_later.message;
                break;
            case Task.Send_to_HQ:
                titleFormatted = notificationMessage.Send_to_HQ.title;
                messageFormatted = notificationMessage.Send_to_HQ.message;
                break;
            case Task.Create_event:
                titleFormatted = notificationMessage.Create_event.title;
                messageFormatted = notificationMessage.Create_event.message;
                break;
            case Task.Need_more_details:
                titleFormatted = notificationMessage.Need_more_details.title;
                messageFormatted = notificationMessage.Need_more_details.message;
                break;
            case Task.Send_to_Broker:
                titleFormatted = notificationMessage.Send_to_Broker.title;
                messageFormatted = notificationMessage.Send_to_Broker.message;
                break;
            case Task.Escalate_event:
                titleFormatted = notificationMessage.Escalate_event.title;
                messageFormatted = notificationMessage.Escalate_event.message;
                break;
            case Task.Assign_to_me:
                titleFormatted = notificationMessage.Assign_to_me.title;
                messageFormatted = notificationMessage.Assign_to_me.message;
                break;
            case Task.Confirm_cover_is_available:
                titleFormatted = notificationMessage.Confirm_cover_is_available.title;
                messageFormatted = notificationMessage.Confirm_cover_is_available.message;
                break;
            case Task.Ask_for_Underwritter:
                titleFormatted = notificationMessage.Ask_for_Underwritter.title;
                messageFormatted = notificationMessage.Ask_for_Underwritter.message;
                break;
            case Task.Confirm_no_coverage:
                titleFormatted = notificationMessage.Confirm_no_coverage.title;
                messageFormatted = notificationMessage.Confirm_no_coverage.message;
                break;
            case Task.Update_Event:
                titleFormatted = notificationMessage.Update_Event.title;
                messageFormatted = notificationMessage.Update_Event.message;
                break;
            case Task.Reopen_Event:
                titleFormatted = notificationMessage.Reopen_Event.title;
                messageFormatted = notificationMessage.Reopen_Event.message;
                break;
            case Task.Ask_for_Risk_Assessment:
                titleFormatted = notificationMessage.Ask_for_Risk_Assessment.title;
                messageFormatted = notificationMessage.Ask_for_Risk_Assessment.message;
                break;
            case Task.Notify_the_Underwriter:
                titleFormatted = notificationMessage.Notify_the_Underwriter.title;
                messageFormatted = notificationMessage.Notify_the_Underwriter.message;
                break;
            case Task.Cancel_event:
                titleFormatted = notificationMessage.Cancel_event.title;
                messageFormatted = notificationMessage.Cancel_event.message;
                break;
            case Task.Close:
                titleFormatted = notificationMessage.Close.title;
                messageFormatted = notificationMessage.Close.message;
                break;
            case Task.___:
                titleFormatted = notificationMessage.___.title;
                messageFormatted = notificationMessage.___.message;
                break;
            case Task.Ask_central_insurance:
                titleFormatted = notificationMessage.Ask_central_insurance.title;
                messageFormatted = notificationMessage.Ask_central_insurance.message;
                break;
            case Task.Approve:
                titleFormatted = notificationMessage.Approve.title;
                messageFormatted = notificationMessage.Approve.message;
                break;
            case Task.Refuse:
                titleFormatted = notificationMessage.Refuse.title;
                messageFormatted = notificationMessage.Refuse.message;
                break;
            case Task.Approve_subject_to_conditions:
                titleFormatted = notificationMessage.Approve_subject_to_conditions.title;
                messageFormatted = notificationMessage.Approve_subject_to_conditions.message;
                break;
            case Task.Send_to_Region:
                titleFormatted = notificationMessage.Send_to_region.title;
                messageFormatted = notificationMessage.Send_to_region.message;
                break;
            case Task.More_details_local:
                titleFormatted = notificationMessage.More_details_local.title;
                messageFormatted = notificationMessage.More_details_local.message;
                break;   
            case Task.More_details_region:
                titleFormatted = notificationMessage.More_details_region.title;
                messageFormatted = notificationMessage.More_details_region.message;
                break;
            case Task.More_details_maison:
                titleFormatted = notificationMessage.More_details_maison.title;
                messageFormatted = notificationMessage.More_details_maison.message;
                break;
            case Task.Recall_event:
                titleFormatted = notificationMessage.Recall_event.title;
                messageFormatted = notificationMessage.Recall_event.message;
                break;
        }
    }else if(type === NotificationType.error){
        titleFormatted = notificationMessage.ErrorGenericMessage.title;
        messageFormatted = notificationMessage.ErrorGenericMessage.message;
    }
    return {titleFormatted, messageFormatted}
}

export const downloadFile = (object: Download, filename: string): void => {
    let mime = object.headers['content-type'];
    const url = window.URL.createObjectURL(
                        new Blob([mime === 'application/json' ? JSON.stringify(object.data) : object.data],
                                {type: mime}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export const isToShowExclamationPoint = (props: IPropsDashboardItem): boolean => {
    const today: any = new Date();
    const evDate: any = props.fromDate ? new Date(props.fromDate) : today;
    // if hours available in date reset them
    today.setHours(0, 0, 0, 0);
    evDate.setHours(0, 0, 0, 0);

    if(props.fromDate && evDate >= today) {
        const diffDays = Math.round(Math.abs((today - evDate) / oneDay));
    
        if(
            (props.itemContent === EventStatus.draft ||
            props.itemContent === EventStatus.pendinghq ||
            props.itemContent === EventStatus.pendinglocal) &&
            diffDays < dashboard_maxDaysLeftToTriggerWarning
            ) {
            return true;
        }
    }
    return false;
}

export const StringFormat = (str: string, ...args: string[]) =>
  str.replace(/{(\d+)}/g, (match, index) => args[index] || '');

 export const ScrollToTopHelper = (val: number = 0) => {
     window.scrollTo(0, val);
};

export const getLocationForUpload = (declarationId?: number) : string => {
    const location = window.location.pathname;
    if(location.includes('/event/') && declarationId !== undefined){
        return getEnumKeyByEnumValue(LocationNamingRsult, LocationNamingRsult.declaration) ?? "";
    }
    else{
        return getEnumKeyByEnumValue(LocationNamingRsult, LocationNamingRsult.event) ?? "";
    }
}

export const getLocationSite = () : LocationNamingRsult => {
    const location = window.location.pathname;
    if(location.includes('/dashboard/event/readonly/')){
        return LocationNamingRsult.event_readonly;
    }
    else if(location.includes('/dashboard/event/')){
        return LocationNamingRsult.event;
    }
    else if(location.includes('/dashboard/')){
        return LocationNamingRsult.dashboard;
    }
    else{
        return LocationNamingRsult.none;
    }
}

export const InitFieldsForRole = (role: string): IFieldListItem => {
    return {
        role,
        declarationFields: FieldsVisibilityFormStructure
    };
}

export const InitFieldsForRoles = (roles: string[]): IFieldListItem[] => {
    const rolesFieldList:IFieldListItem[] = [];
    for(let i = 0; i < roles.length; i++) {
        rolesFieldList.push(InitFieldsForRole(roles[i]));
    }
    return rolesFieldList;
}

export const validateEmail = (email: string) => {
    return email.match(emailRegex);
};