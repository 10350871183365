
/**
 * All Fields ID
 * WARNING: Value need to be unique
 */
export const FIELD_IDS = {
    eventDescription: {
        globalDescription: "edGlobalDescription",
        recurrentEvent: "edRecurrentEvent",
        exhibitionSurface: "edExhibitionSurface",
        specificEvents: "edSpecificEvents",
        specificShows: "edSpecificShows",
    },
    valuablesAssets: {
        list: "vaList",
        valuableAssetsInformation: "vaInformation"
    },
    routingAndTransfer: {
        routingAndDeparture: "rtRoutingAndDeparture",
        from: "rtFrom",
        to: "rtTo",
        transportOrganizer: "rtTransportOrganizer",
        complyWithDirectives: "rtComplyWithDirectives",
        routingAndTransferInformation: "rtRoutingAndTransferInformation"
    },
    movementAndStorage: {
        movementOfValuables: "msMovementOfValuables",
        movementDescription: "msMovementDescription",
        movementOfValuablesOutside: "msMovementOfValuablesOutside",
        movValOutsideLocationPlace: "msValOutsideLocationPlace",
        movementOfValuablesOutsideDescription: "msMovementOfValuablesOutsideDescription",
        tempExternalStorageBeforeEvent: "msTempExternalStorageBeforeEvent",
        locationPlaceBeforeEvent: "msLocationPlaceBeforeEvent",
        tempExternalStorageAfterEvent: "msTempExternalStorageAfterEvent",
        locationPlaceAfterEvent: "msLocationPlaceAfterEvent",
        dedicatedSafeRoom: "msDedicatedSafeRoom",
        externalSecuredStorage: "msExternalSecuredStorage",
        nearbyBoutique: "msNearbyBoutique",
        piecesRemainInShowcases: "msPiecesRemainInShowcases",
        other: "msOther",
        otherDescription: "msOtherDescription",
    },
    securityStaff: {
        securityDonyBy: "sstSecurityDonyBy",
        eventPhaseList: "sstEventPhaseList",
        hasSecurityService: "sstHasSecurityService",
        securityServiceDesc: "sstSecurityServiceDesc",
        securityStaffDeployment: "sstSecurityStaffDeployment",
    },
    securitySurveillance: {
        securitySystemDevicesList: "ssuSecuritySystemDevicesList",
        hasSecurity: "ssuHasSecurity",
        securityServiceDesc: "ssuSecurityServiceDesc",
        technicalSurveillanceSystemInformation: "ssuTechnicalSurveillanceSystemInformation"
    },
    securityConcept: {
        author: "scAuthor",
        validator: "scValidator",
    },
} as const;

type NestedValues<T> = T extends {
    [k in keyof T]: {
      [k: string]: infer OptionValue
    }
  } ? OptionValue : never
  
export type FieldId = NestedValues<typeof FIELD_IDS>

export type FieldFormValues = {[key:string]:boolean}

export const defaultFieldFormValues = Object.keys(FIELD_IDS).reduce((sectionAcc: FieldFormValues, sectionKey)=>{
    const fieldIds : FieldFormValues = Object.keys(FIELD_IDS[sectionKey]).reduce((fieldAcc, fieldKey)=>{
        fieldAcc[FIELD_IDS[sectionKey][fieldKey]] = true;
        return fieldAcc;
    }, sectionAcc)
    return fieldIds;
}, {} )


type Field = {
    id: FieldId,
    name: string,
    dependsOn?: string,
    mandatory: boolean
}
type SubSection = {
    name: string;
    fields: Field[]
}
type Section =  {
    section: string
    fields?: Field[]
    subSections?: SubSection[]
}


export const FieldsVisibilityFormStructure : Section[] = [
    {
        section: "Event Description",
        fields: [
            {
                name: "Global Description",
                mandatory: false,
                id: FIELD_IDS.eventDescription.globalDescription,
            },
            {
                name: "Recurrent Event",
                mandatory: false,
                id: FIELD_IDS.eventDescription.recurrentEvent,
            },
            {
                name: "Exhibition surface area",
                mandatory: false,
                id: FIELD_IDS.eventDescription.exhibitionSurface,
            },
            {
                name: "Are there specific events or shows during the show?",
                mandatory: false,
                id: FIELD_IDS.eventDescription.specificEvents,
            },
            {
                name: "Are there specific shows related to all brands?",
                mandatory: false,
                id: FIELD_IDS.eventDescription.specificShows,
            },
        ],
    },
    {
        section: "Valuables Management",
        subSections: [
            {
                name: "List of valuable assets",
                fields: [
                    {
                        name: "List",
                        mandatory: false,
                        id: FIELD_IDS.valuablesAssets.list,
                    },
                    {
                        name: "Valuable Assets Information to send to broker",
                        mandatory:false,
                        id: FIELD_IDS.valuablesAssets.valuableAssetsInformation
                    },
                ],
            },
            {
                name: "Routing and transfer",
                fields: [
                    {
                        name: "Routing and departure done by",
                        mandatory: false,
                        id: FIELD_IDS.routingAndTransfer.routingAndDeparture,
                    },
                    {
                        name: "From",
                        mandatory: false,
                        id: FIELD_IDS.routingAndTransfer.from,
                    },
                    {
                        name: "To",
                        mandatory: false,
                        id: FIELD_IDS.routingAndTransfer.to,
                    },
                    {
                        name: "Transport organizer",
                        mandatory: false,
                        id: FIELD_IDS.routingAndTransfer.transportOrganizer,
                    },
                    {
                        name: "Comply with directives",
                        mandatory: false,
                        id: FIELD_IDS.routingAndTransfer.complyWithDirectives,
                    },
                    {
                        name: "Routing and transfer information for broker",
                        mandatory: false,
                        id: FIELD_IDS.routingAndTransfer.routingAndTransferInformation,
                    },
                ],
            },
            {
                name: "Movements and storages",
                fields: [
                    {
                        name: "Movement of pieces inside the event venue",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.movementOfValuables,
                    },
                    {
                        name: "Movement Description",
                        dependsOn: "Movement of valuables during the exhibition",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.movementDescription,
                    },
                    {
                        name: "Movement of pieces outside of the event venue",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.movementOfValuablesOutside,
                    },
                    {
                        name: "Location of pieces outside the event venue",
                        dependsOn: "Movement of valuables outside of the event venue",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.movValOutsideLocationPlace,
                    },
                    {
                        name: "Movement of valuables outside of the event venue description",
                        dependsOn: "Movement of valuables outside of the event venue",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.movementOfValuablesOutsideDescription,
                    },
                    {
                        name: "Temporary external storage before event",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.tempExternalStorageBeforeEvent,
                    },
                    {
                        name: "Location Place",
                        dependsOn: "Temporary external storage before event",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.locationPlaceBeforeEvent,
                    },
                    {
                        name: "Temporary external storage after event",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.tempExternalStorageAfterEvent,
                    },
                    {
                        name: "Location Place",
                        dependsOn: "Temporary external storage after event",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.locationPlaceAfterEvent,
                    },
                    {
                        name: "Dedicated safe room in the event venue",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.dedicatedSafeRoom,
                    },
                    {
                        name: "External secured storage outside of the event venue",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.externalSecuredStorage,
                    },
                    {
                        name: "Nearby boutique",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.nearbyBoutique,
                    },
                    {
                        name: "Pieces remain in showcases (exhibition events)",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.piecesRemainInShowcases,
                    },
                    {
                        name: "Other (please specify)",
                        mandatory: false,
                        id: FIELD_IDS.movementAndStorage.other,
                    },
                    {
                        name: "Other description",
                        mandatory: false,
                        dependsOn: "Other(please specify)",
                        id: FIELD_IDS.movementAndStorage.otherDescription,
                    },
                ],
            },
        ],
    },
    {
        section: "Security & Surveillance System",
        subSections: [
            {
                name: "Security concept",
                fields: [
                    {
                        name: "Author(s)",
                        mandatory: false,
                        id: FIELD_IDS.securityConcept.author,
                    },
                    {
                        name: "Validator(s)",
                        mandatory: false,
                        id: FIELD_IDS.securityConcept.validator,
                    },
                ],
            },
            {
                name: "Security Staff deployment",
                fields: [
                    {
                        name: "Security done by",
                        mandatory: false,
                        id: FIELD_IDS.securityStaff.securityDonyBy,
                    },
                    {
                        name: "Event phase list",
                        mandatory: false,
                        id: FIELD_IDS.securityStaff.eventPhaseList,
                    },
                    {
                        name: "Does the site have a security service providing staff",
                        mandatory: false,
                        id: FIELD_IDS.securityStaff.hasSecurityService,
                    },
                    {
                        name: "Description of the security service",
                        dependsOn: "Does the site have a security service providing staff",
                        mandatory: false,
                        id: FIELD_IDS.securityStaff.securityServiceDesc,
                    },
                    {
                        name: "Security Staff deployment to send to broker",
                        mandatory: false,
                        id: FIELD_IDS.securityStaff.securityStaffDeployment,
                    },
                ],
            },
            {
                name: "Technical Surveillance System",
                fields: [
                    {
                        name: "Security system device list",
                        mandatory: false,
                        id: FIELD_IDS.securitySurveillance.securitySystemDevicesList,
                    },
                    {
                        name: "Does the site have a security service providing technical surveillance",
                        mandatory: false,
                        id: FIELD_IDS.securitySurveillance.hasSecurity,
                    },
                    {
                        name: "Description of the security service",
                        dependsOn: "Does the site have a security service providing technical surveillance",
                        mandatory: false,
                        id: FIELD_IDS.securitySurveillance.securityServiceDesc,
                    },
                    {
                        name: "Technical Surveillance System information to send to broker",
                        mandatory: false,
                        id: FIELD_IDS.securitySurveillance.technicalSurveillanceSystemInformation,
                    },
                ],
            },
        ],
    },
];
