import React from "react";

export const UiBoldNoShift: React.FC = ({ children }) => {
    return (
        <span className="bold-noshift">
            <span className="bold-noshift__element">{ children }</span>
            <span className="bold-noshift__sizer" aria-hidden="true">{ children }</span>
        </span>
    );
};
