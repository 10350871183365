import React from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { Button, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
export const UiPagination = (props:any) => {
  const { items } = usePagination({
...props
  });

  return (
    <nav className="ui-pagination">
      <ul>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = <span className="ui-pagination__ellipsis">...</span>;
          } else if (type === 'page') {
            children = (
              <Button 
                {...item}
                className={selected ? "ui-pagination__btn ui-pagination__btn--current" :"ui-pagination__btn"}
                variant={selected ? 'outlined' : 'text'}
                color={selected ? 'secondary' : 'default'} 
                size="small">
                {page}
              </Button>
            );
          } else {
            children = (
              <IconButton size="small" className="ui-pagination__btn ui-pagination__btn--arrow" {...item}>
                {type === 'previous' ? <ArrowBackIcon /> : <ArrowForwardIcon />}
              </IconButton>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
}