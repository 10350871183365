import WebViewer from "@pdftron/pdfjs-express-viewer";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import { Subscription } from "rxjs";
import { useAuthContext } from "../../contexts/AuthContext";
import { UserRoles } from "../../models/enums";
import { SecurityConceptService } from "../../services/mock.securityConcept.service";
import { CustomFile } from "../upload/interfaces";

export type PdfViewerContentProps = {
    file: CustomFile;
};
export const PdfViewerContent = ({ file }: PdfViewerContentProps) => {
    const viewer = useRef(null);
    const pdfSubscription = useRef<Subscription>();
    const instance = useRef<any>();
    const {role} = useAuthContext();

    const pdfLicenceKey = useMemo(() => process.env.REACT_APP_PDF_JS_LICENCE_KEY, []);

    const loadPdf = useCallback(() => {
        // now you can access APIs through the WebViewer instance
        const { UI } = instance.current;
        pdfSubscription.current?.unsubscribe();
        pdfSubscription.current = SecurityConceptService.preview(file).subscribe((response) => {
            UI.loadDocument(response.data, {extension: "pdf", filename: "security-concept.pdf"});
        });
    }, [file, instance]);
    const setViewerSettings = useCallback( () => {
        if(instance.current){
            const { UI, Feature, Tools } = instance.current;
            // Disable features: https://pdfjs.express/api/UI.html#.Feature__anchor
            if(![UserRoles.hqsecurity, UserRoles.director, UserRoles.areasec, UserRoles.localsec].includes(role as UserRoles)){
                UI.disableFeatures([Feature.Download]);
            }
            UI.disableFeatures([Feature.Print]);
            UI.disableFeatures([Feature.TextSelection]);
            UI.disableFeatures([Feature.Copy]);
            UI.disableElements(["selectToolButton"]);
            UI.disableElements(["menuButton"]);
            UI.disableTools([
                Tools.ToolNames.STICKY, 
                Tools.ToolNames.FREETEXT,
                Tools.ToolNames.FREEHAND,
                Tools.ToolNames.FREEHAND_HIGHLIGHT,
                Tools.ToolNames.HIGHLIGHT,
                Tools.ToolNames.HIGHLIGHT2,
                Tools.ToolNames.PAN,
            ])
        }
    }, [role, instance])

    useEffect(() => {
        if (!instance.current) {
            WebViewer(
                {
                    path: "/webviewer/lib", // Lib location in public folder
                    licenseKey: pdfLicenceKey,
                },
                viewer.current
            ).then((viewerInstance: any) => {
                instance.current = viewerInstance;
                setViewerSettings()
                loadPdf();
            });
        } else {
            loadPdf();
        }
        
        return () => {
            if (pdfSubscription.current) {
                pdfSubscription.current.unsubscribe();
            }
        };
    }, [loadPdf, setViewerSettings]);

    return (
        <div className="pdf-viewer-content" ref={viewer}></div>
    );
};
