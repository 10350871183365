import {IUiTableHead, IStateOrder} from '../../../shared/components/ui/UiTable/types';
import {
    Actions,
    status,
    risks,
    C,
    RA,
    dateString,
    amountFormat,
    countryFormat,
    nameOfEventFormat,
    riskLevelFormat,
    exclamationPoint,
    Snooze,
    createdDate, CloseEvent
} from "./UiListViewCells";
import { INewEvent } from '../../../event/interface';
import {IPropsDashboardItem} from '../../interface'

export const sortStatus = (firstItem: IPropsDashboardItem, secondtItem:IPropsDashboardItem, order: IStateOrder) => {
    // This two "if" manage status when value is null.
    // It force Item with status equal to null to go down in Dashboard
    // if firstItem is null, it must go down and secondItem must go up. For this behaviour we must return 1
    if(!firstItem.status){
        return 1
    }
    // if secondItem is null it must not go up and FistItem must keep his place. For this behaviour we must return -1
    if(!secondtItem.status){
        return -1
    }
    if(order==='asc'){
        return firstItem.status.localeCompare(secondtItem.status)
    }
    return secondtItem.status.localeCompare(firstItem.status)
}

export const sortAmount = (a: INewEvent, b:INewEvent, order: IStateOrder) => {
    if(order === 'asc'){
        return a.estimatedTotalValue - b.estimatedTotalValue
    }
    return  b.estimatedTotalValue - a.estimatedTotalValue 
}

export const sortCountry = (firstItem: IPropsDashboardItem, secondtItem:IPropsDashboardItem, order: IStateOrder) => {

    // This two "if" manage countriesContent when value is null.
    // It force Item with countriesContent equal to null to go down in Dashboard
    // if firstItem is null, it must go down and secondItem must go up. For this behaviour we must return 1
    if(!firstItem.countriesContent){
        return 1
    }
    // if secondItem is null it must not go up and FistItem must keep his place. For this behaviour we must return -1
    if(!secondtItem.countriesContent){
        return -1
    }

    if(order==='asc'){
        return firstItem.countriesContent.localeCompare(secondtItem.countriesContent)
    }
    return secondtItem.countriesContent.localeCompare(firstItem.countriesContent)
}

export const sortDate = (firstItem: IPropsDashboardItem, secondtItem:IPropsDashboardItem, order: IStateOrder) => {

    // This two "if" manage fromDate when value is null.
    // It force Item with fromDate equal to null to go down in Dashboard
    // if firstItem is null, it must go down and secondItem must go up. For this behaviour we must return 1
    if(!firstItem.fromDate){
        return 1
    }
    // if secondItem is null it must not go up and FistItem must keep his place. For this behaviour we must return -1
    if(!secondtItem.fromDate){
        return -1
    }

    if(order==='asc'){
        return firstItem.fromDate.localeCompare(secondtItem.fromDate)
    }
    return secondtItem.fromDate.localeCompare(firstItem.fromDate)
}

export const mutiMaisonBrandOrOrMainMaisonBrand = (item: IPropsDashboardItem): string => {
    if (item.multiBrandEvent) {
        return "Multi-Maison";
    }
    return item.leadbrandName || "None";
}

export const tableHeads : IUiTableHead[] = [
    {name: "warning", label: "", cell: exclamationPoint, cssProperties: {width: "20px"}},
    {
        name: "status",
        label: 'Status',
        customSort: sortStatus,
        sortable: true,
        cell: status,
        cssProperties: {width: "130px"}
    },
    {
        name: "reportDate",
        label: 'Report Date',
        accessor: "createdDate",
        cell: createdDate,
        sortable: true,
        cssProperties: {width: "98px"}
    },
    {
        name: "date",
        label: 'Start Date',
        accessor: "fromDate",
        cell: dateString,
        sortable: true,
        cssProperties: {width: "98px"}
    },
    {name: "name", label: 'Name of event', accessor: 'eventName', cell: nameOfEventFormat, sortable: true, cssProperties: {width: "100px"}},
    {
        name: "country",
        label: 'Country',
        customSort: sortCountry,
        accessor: 'countriesContent',
        cell: countryFormat,
        sortable: true,
        cssProperties: {width: "100px"}
    },
    {name: "maison", label: 'Maison', accessor: mutiMaisonBrandOrOrMainMaisonBrand, sortable: true, cssProperties: {width: "90px"}},
    {
        name: "level",
        label: 'Risk Level',
        accessor: 'riskLevelContent',
        cell: riskLevelFormat,
        sortable: true,
        cssProperties: {width: "95px"}
    },
    {
        name: "amount",
        label: 'Amount',
        customSort: sortAmount,
        cell: amountFormat,
        sortable: true,
        cssProperties: {width: "80px"}
    },
    {name: "risks", label: 'Risks', cell: risks, cssProperties: {width: "110px"}},
    {name: "c", label: 'C', size: 'small', cell: C, cssProperties: {width: "30px"}},
    {name: "r", label: 'RA', size: 'small', cell: RA, cssProperties: {width: "30px"}},
    {name: "snooze", label: '', size: 'small', cell: Snooze, cssProperties: {width: "50px"}},
    {name: "actions", label: 'Actions available', cell: Actions, cssProperties: {width: "120px"}},
    {name: "close", label: 'Close', cell: CloseEvent, cssProperties: {width: "120px"}},
]

