import { z } from "zod";
import axios, { AxiosRequestConfig } from "axios";
import CustomAxios, { ApiCallingMethods } from "../../helpers/AxiosHelper";
import {
    urlMassImportServiceImportEvents,
    urlMassImportServiceGetImportedFiles,
    urlMassImportServiceAddDuplicateEvents,
} from "../../config/GlobalAppConfig";

/** Enum representing possible custom statuses returned by the API */
export enum EApiResponseCustomStatus {
    FileImportedWithSuccess = "FileImportedWithSuccess",
    ErrorsInFile = "ErrorsInFile",
    DuplicateData = "DuplicateData",
}

/** Enum representing possible alert statuses for the UI */
export enum EAlertStatus {
    Success = "success",
    Error = "error",
    Duplicate = "duplicate",
    Unknown = "unknown",
}

const zEAlertStatusSchema = z.nativeEnum(EAlertStatus);

const zApiResponseStatusCodeSchema = z.enum(["200", "409", "420", "500"]);
const zApiResponseCustomStatusSchema = z.nativeEnum(EApiResponseCustomStatus);

const zEventErrorSchema = z.object({
    excelRowNumber: z.number(),
    customErrorMessage: z.string(),
});

const zDuplicateEventSchema = z.object({
    excelRowNumber: z.number(),
    groupId: z.number().nullable(),
    isDuplicateInDatabase: z.boolean(),
    leadMaison: z.string(),
    country: z.string(),
    eventName: z.string(),
    eventType: z.string(),
    fromDate: z.string(),
    toDate: z.string(),
    eventAddress: z.string(),
    estimatedTotalValue: z.number(),
    importedFileId: z.number().nullable(),
    isImportedEvent: z.boolean().nullable(),
});

const zApiResponseSchema = z.object({
    message: z.string().nullable(),
    didError: z.boolean(),
    errorMessage: z.string().nullable(),
    model: z
        .object({
            customStatus: zApiResponseCustomStatusSchema,
            customStatusCode: zApiResponseStatusCodeSchema,
            importedFileId: z.number().nullable(),
            totalImportedEvents: z.number(),
            totalDuplicateEvents: z.number(),
            totalEventsWithError: z.number(),
            eventsWithError: z.array(zEventErrorSchema).nullable(),
            duplicateEvents: z.array(zDuplicateEventSchema).nullable(),
        })
        .nullable(),
});

const zMassImportResponseSchema = z.object({
    status: zEAlertStatusSchema,
    statusCode: zApiResponseStatusCodeSchema,
    message: z.string(),
    importedFileId: z.number().nullable(),
    newEventsCount: z.number(),
    errorsCount: z.number(),
    duplicatesCount: z.number(),
    eventsWithError: z.array(zEventErrorSchema),
    duplicateEvents: z.array(zDuplicateEventSchema),
});

const zImportedFileSchema = z.object({
    id: z.number(),
    fileName: z.string(),
    importDate: z.string(),
});

const zGetAllImportedFilesResponseSchema = z.array(zImportedFileSchema);

/** Type representing possible API response status codes */
export type TApiResponseStatusCode = z.infer<typeof zApiResponseStatusCodeSchema>;

/** Type representing an error encountered during event import */
export type TEventError = z.infer<typeof zEventErrorSchema>;

/**
 * Type representing a duplicate event detected during import
 * This includes all relevant details about the event
 */
export type TDuplicateEvent = z.infer<typeof zDuplicateEventSchema>;

/**
 * Type representing the full API response structure
 * This includes metadata about the import process and any errors or duplicates found
 */
export type TApiResponse = z.infer<typeof zApiResponseSchema>;

/**
 * Type representing the processed mass import response
 * This is the structure that will be used by the UI to display import results
 */
export type TMassImportResponse = z.infer<typeof zMassImportResponseSchema>;

/**
 * Type representing an imported file's metadata
 * This is used when listing previously imported files
 */
export type TImportedFile = z.infer<typeof zImportedFileSchema>;

export class MassImportService {
    /**
     * Imports events from an Excel file.
     * @param {File} file - The Excel file containing event data to import.
     * @returns {Promise<TMassImportResponse>} A promise that resolves to the import response.
     */
    static async importEvents(file: File): Promise<TMassImportResponse> {
        const excelFile = new FormData();
        excelFile.append("file", file);

        return this.makeRequest({
            method: ApiCallingMethods.post,
            url: urlMassImportServiceImportEvents,
            data: excelFile,
            headers: { "Content-Type": "multipart/form-data" },
        });
    }

    /**
     * Retrieves all previously imported files.
     * @returns {Promise<TImportedFile[]>} A promise that resolves to an array of imported file metadata.
     */
    static async getAllImportedFiles(): Promise<TImportedFile[]> {
        const response = await this.makeRequest({
            method: ApiCallingMethods.get,
            url: urlMassImportServiceGetImportedFiles,
        });

        if (response.status === EAlertStatus.Success && response.duplicateEvents.length > 0) {
            return zGetAllImportedFilesResponseSchema.parse(response.duplicateEvents);
        }

        throw new Error("Failed to retrieve imported files");
    }

    /**
     * Adds duplicate events to the database.
     * @param {TDuplicateEvent[]} duplicates - The duplicate events to add.
     * @returns {Promise<TMassImportResponse>} A promise that resolves to a standardized response.
     */
    static async addDuplicateEvents(duplicates: TDuplicateEvent[]): Promise<TMassImportResponse> {
        return this.makeRequest({
            method: ApiCallingMethods.post,
            url: urlMassImportServiceAddDuplicateEvents,
            data: duplicates,
        });
    }

    /**
     * Makes a request to the API and processes the response.
     * @param {AxiosRequestConfig} config - The Axios request configuration.
     * @returns {Promise<TMassImportResponse>} A promise that resolves to a standardized response.
     */
    private static async makeRequest(config: AxiosRequestConfig): Promise<TMassImportResponse> {
        try {
            const response = await CustomAxios.requestAsPromise(config);
            return this.processApiResponse(response.data);
        } catch (error) {
            console.error("Error during API request:", error);
            if (axios.isAxiosError(error) && error.response) {
                // Use error response data if available
                return this.processApiResponse(error.response.data);
            }
            // Fallback for non-Axios errors or errors without response
            return this.createErrorResponse("Network error or server unavailable");
        }
    }

    /**
     * Processes the API response and standardizes it.
     * @param {unknown} data - The raw API response data.
     * @returns {TMassImportResponse} A standardized API response.
     */
    private static processApiResponse(data: unknown): TMassImportResponse {
        const parseResult = zApiResponseSchema.safeParse(data);

        if (!parseResult.success) {
            return this.createErrorResponse("Invalid API response format");
        }

        const apiResponse = parseResult.data;

        if (apiResponse.didError) {
            return this.createErrorResponse(
                apiResponse.errorMessage || "An unknown error occurred",
                apiResponse.model?.customStatusCode
            );
        }

        if (!apiResponse.model) {
            return this.createErrorResponse("Missing response data");
        }

        return this.createSuccessResponse(apiResponse.model);
    }

    /**
     * Creates a standardized success response.
     * @param {NonNullable<TApiResponse["model"]>} model - The API response model.
     * @returns {TMassImportResponse} A standardized success response.
     */
    private static createSuccessResponse(model: NonNullable<TApiResponse["model"]>): TMassImportResponse {
        const status = this.determineAlertStatus(model.customStatus);
        const message = this.createSuccessMessage(model);

        return zMassImportResponseSchema.parse({
            status,
            statusCode: model.customStatusCode,
            message,
            importedFileId: model.importedFileId,
            newEventsCount: model.totalImportedEvents,
            errorsCount: model.totalEventsWithError,
            duplicatesCount: model.totalDuplicateEvents,
            eventsWithError: model.eventsWithError || [],
            duplicateEvents: model.duplicateEvents || [],
        });
    }

    /**
     * Creates a standardized error response.
     * @param {string} message - The error message.
     * @param {TApiResponseStatusCode} [statusCode="500"] - The status code of the error.
     * @returns {TMassImportResponse} A standardized error response.
     */
    private static createErrorResponse(
        message: string,
        statusCode: TApiResponseStatusCode = "500"
    ): TMassImportResponse {
        return {
            status: EAlertStatus.Error,
            statusCode: statusCode,
            message: message,
            importedFileId: null,
            newEventsCount: 0,
            errorsCount: 0,
            duplicatesCount: 0,
            eventsWithError: [],
            duplicateEvents: [],
        };
    }

    /**
     * Determines the appropriate alert status based on the API custom status.
     * @param {EApiResponseCustomStatus} customStatus - The custom status from the API.
     * @returns {EAlertStatus} The corresponding alert status.
     */
    private static determineAlertStatus(customStatus: EApiResponseCustomStatus): EAlertStatus {
        switch (customStatus) {
            case EApiResponseCustomStatus.FileImportedWithSuccess:
                return EAlertStatus.Success;
            case EApiResponseCustomStatus.ErrorsInFile:
                return EAlertStatus.Error;
            case EApiResponseCustomStatus.DuplicateData:
                return EAlertStatus.Duplicate;
            default:
                return EAlertStatus.Unknown;
        }
    }

    /**
     * Creates a human-readable success message based on the API response model.
     * @param {NonNullable<TApiResponse["model"]>} model - The API response model.
     * @returns {string} A human-readable success message.
     */
    private static createSuccessMessage(model: NonNullable<TApiResponse["model"]>): string {
        switch (model.customStatus) {
            case EApiResponseCustomStatus.FileImportedWithSuccess:
                return `File imported successfully. ${model.totalImportedEvents} event(s) successfully imported.`;
            case EApiResponseCustomStatus.ErrorsInFile:
                return `Errors found in the file. ${model.totalEventsWithError} error(s) found.`;
            case EApiResponseCustomStatus.DuplicateData:
                return `Duplicate data found in the file. ${model.totalDuplicateEvents} duplicate(s) found.`;
            default:
                return "Unknown status.";
        }
    }

    /**
     * Ensures that the status code is valid and supported by the application.
     * @param {number | undefined} status - The status code to validate.
     * @returns {TApiResponseStatusCode} A valid status code.
     */
    private static getValidStatusCode(status: number | undefined): TApiResponseStatusCode {
        if (status === 200 || status === 409 || status === 420 || status === 500) {
            return status.toString() as TApiResponseStatusCode;
        }
        return "500";
    }
}
