import React from "react";
import { Formik, Field, FormikState } from "formik";
import EventDescription from "./EventDescription";
import {
  DeclarationInterface,
  ValuableAssetItem,
  EventPhaseItem,
  SecuritySystemDevicesItem,
} from "./interfaces";
import {
  declarationSchema,
  eventDescriptionSchema,
  mergeProps,
} from "./validation";
import { Option } from "../../shared/components/SelectField";
import ValuablesManagement from "./valuablesManagement/ValuablesManagement";
import SecuritySurveillanceSystem from "./securitySurveillanceSystem/SecuritySurveillanceSystem";
import { DeclarationService } from "../../shared/services/declaration/declarationService";
import Upload from "../../shared/components/upload/Upload";
import {
  fileTypesAccepted,
  MAX_FILE_SIZE,
} from "../../shared/config/uploadConfig";
import { UiAutocompleteField } from "../../shared/components/fields/UiAutocompleteField";
import { UiSwitchField } from "../../shared/components/fields/UiSwitchField";
import { Grid, Container } from "@material-ui/core";
import { UiBox } from "../../shared/components/ui/UiBox";
import {NextActions} from "../../event/components/nextActions/nextActions";
import "./DeclarationNewEdit.module.scss";
import {
  urlRedirectionToEventReadOnly,
  partialUrlUpload,
  partialUrlProgress,
  partialUrlDownload,
} from "../../shared/config/GlobalAppConfig";
import { UiScrollToTopButton } from "../../shared/components/ui/UiScrollToTopButton";
import {
  ReferencesService,
  Reference,
} from "../../shared/services/referencesService";
import { Maison, NotificationType } from "../../shared/models/enums";
import { combineLatest, Subscription } from "rxjs";
import { CustomFile } from "../../shared/components/upload/interfaces";
import { UploadService } from "../../shared/services/uploadService";
import "react-notifications/lib/notifications.css";
import LoadingContext from "../../routes/LoadingContext";
import LocalStorageTokenService from "../../shared/helpers/SessionStorageTokenService";
import { getMappingRoles } from "../../shared/models/records";
import { LoggingServie } from "../../shared/services/LoggingService";
import { take } from "rxjs/internal/operators/take";
import { ConditionnalSection } from "../../shared/components/ConditionnalSection";
import { baseCreateNotification } from "../../shared/helpers/HelpersFunc";

const users: Option[] = [];

let valuableAssetsList: ValuableAssetItem[] = require("./valuablesManagement/valuablesAssets.json");
let eventPhasesList: EventPhaseItem[] = require("./securitySurveillanceSystem/securityAgents.json");
let technicalSurveillanceList: SecuritySystemDevicesItem[] = require("./securitySurveillanceSystem/technicalSurveillanceSystem.json");

interface InitialValues {
  isReadOnly?: boolean;
  showSections: boolean[];
  title: string;
  selectedBrandName: Option | {};
  selectedAuthors: Option[];
  selectedValidators: Option[];
  upload: number; // TO DO
  declaration: DeclarationInterface;
  files: CustomFile[];
}

export interface NextActionBehavior {
  comment: string;
  idTask: number;
  idEventFlow: number;
  nextAction?: number;
  actionDesc: string;
  companyName?: string;
}

class DeclarationNewEdit extends React.PureComponent<any, any> {
  childRef: any = React.createRef();
  static contextType = LoadingContext;

  constructor(props: any) {
    super(props);
    this.state = {
      initialValues: Object.assign({}, this.initialValues),
      isReadyToRender: false,
      event: props.props.event,
      brandsMaisonList: [],
      lockCall: false, // To avoid double call (componentDidMount and useEffect)
      authors: [],
      validators: [],
      subscriptions: []
    };
  }

  componentWillUnmount() {
    this.state.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  initialValues: InitialValues = {
    upload: 1, // TO DO
    isReadOnly: false,
    showSections: [true, true, true],
    selectedBrandName: {},
    selectedAuthors: [],
    selectedValidators: [],
    declaration: {
      globalConcept: false,
      brandsName: [],
      globalDescription: '',
      recurrentEvent: false,
      exhibitionSurface: 0,
      specificEvents: '',
      specificShows: '',
      valuableAssets: {
        valuableAssetsList: valuableAssetsList,
        totalEstimated: 0
      },
      routingAndTransfer: {
        routingAndDeparture: '',
        from: '',
        to: '',
        transportOrganizer: ''
      },
      securityStaffDeployment: {
        security: '',
        eventPhases: {
          eventPhasesList: eventPhasesList,
          total: 0,
          totalGuardingCostEstimated: 0
        }
      },
      technicalSurveillanceSystem: {
        securitySystemDevicesList: technicalSurveillanceList
      },
      movementsAndStorages: {
        locationPlaceBeforeEvent: '',
        locationPlaceAfterEvent: '',
        movValOutsideLocationPlace: '',
        movementDescription: '',
        movementOfValuables: false,
        tempExternalStorageBeforeEvent: false,
        tempExternalStorageAfterEvent: false,
        isDedicatedSafeRoom: false,
        isExternalSecuredStorage: false,
        isNearbyBoutique: false,
        isPiecesRemainInShowcases: false,
        isOther: false,
        otherDescription: '',
        movementValuableDescription: '',
        isMovValuableOutsideEventVenue: false,
      }
    },
    title: 'New Declaration',
    files: []
  };

  declarationSchema = mergeProps(declarationSchema, eventDescriptionSchema);

  updateStateValues = (initialValues: InitialValues, fromDidMount: boolean) => {
    this.setState({
      initialValues: initialValues
    });

    if (fromDidMount) {
      this.setState({
        isReadyToRender: true
      });
    }

  };

  submit = (values: any, actions: any) => {
    // Get the saved authors and validators with their ids that were not removed
    // this is done because the id is lost when choosing another user
    values.declaration.securityConcept.authors = values.declaration.securityConcept?.authors.map((a: any) => {
        const author = values.selectedAuthors.filter(
          (pa: any) => pa.itemContent === a.itemContent
        )[0];
        return author ? author : a;
      }
    );

    values.declaration.securityConcept.validators = values.declaration.securityConcept?.validators.map(
      (a: any) => {
        const validator = values.selectedValidators.filter(
          (pa: any) => pa.itemContent === a.itemContent
        )[0];
        return validator ? validator : a;
      }
    );

    DeclarationService.update(
      values.declaration,
      this.state.event.id,
      valuableAssetsList,
      eventPhasesList,
      technicalSurveillanceList,
      users
    ).pipe(take(1)).subscribe(
      (res: any) => {
        this.setState({
          initialValues: {
            title: this.state.initialValues.title,
            declaration: res,
          },
        });
        baseCreateNotification(NotificationType.success, 'Saved', 'Event successfully saved');
      },
      (error: any) => {
        const extraRes = `(${error?.response} - ${error?.response?.data} - ${error?.response?.data?.errors})`;
        LoggingServie.LogError(error, extraRes);
      }
    );
    actions.setSubmitting(false);
  };

  componentDidMount() {
    const valuableAssets = ReferencesService.GetValuableAssetsList();
    const eventPhases = ReferencesService.GetEventPhaseList();
    const securitySystemDevice = ReferencesService.GetSecuritySystemDeviceList();
    const maisonBrands = ReferencesService.getAllMaisons();

    const combineSubscriptiom = combineLatest([
      valuableAssets,
      eventPhases,
      securitySystemDevice,
      maisonBrands
    ]).subscribe(
      ([
        valuableAssetsListResp,
        eventPhasesListResp,
        securitySystemDevicesListResp,
        brandsListResp,
      ]) => {
        valuableAssetsList = valuableAssetsListResp;
        eventPhasesList = eventPhasesListResp;
        technicalSurveillanceList = securitySystemDevicesListResp;
        this.setState({ brandsMaisonList: brandsListResp });
        let subscriptions = this.updateInitialValues(true);
        this.setState({
          subscriptions: this.state.subscriptions.concat(subscriptions)
        })
      }
    );

    this.setState({
      subscriptions: [combineSubscriptiom]
  });
  }

  updateInitialValues = (fromDidMount: boolean = false): Subscription[] => {
    let subscriptions: Subscription[] = [];
    if (!this.state.lockCall) {
      let tempInitialValues: any = {};
      if (
        this.props.props &&
        this.props.props.declaration
      ) {
        const res1 = DeclarationService.getById(
            Number(this.props.props.declaration.id),
            valuableAssetsList,
            eventPhasesList,
            technicalSurveillanceList,
            this.state.authors
        );
        const res2 = UploadService.getAllDocuments(
            Number(getMappingRoles(LocalStorageTokenService.getUserRoleToken())),
            Number(this.props.props.declaration.idEvent),
            this.props.props.event.eventDeclarations.length > 1 ? Number(this.props.props.declaration.id) : null
        );
        const res = combineLatest([res1, res2]).subscribe(([declaration, file]) => {
          const maisonObj = this.state?.brandsMaisonList.filter(
            (d: Reference) => declaration.brandsName.indexOf(d.itemContent) > -1
          )[0];
          tempInitialValues.showSections = [true, true, true];
          tempInitialValues.title = maisonObj
            ? maisonObj.itemContent
            : "Global";
          tempInitialValues.selectedBrandName = maisonObj
            ? maisonObj
            : { id: Maison.global, itemContent: "Global" };
          tempInitialValues.selectedAuthors = declaration.securityConcept?.authors.slice();
          tempInitialValues.selectedValidators = declaration.securityConcept?.validators.slice();
          tempInitialValues.declaration = declaration;
          tempInitialValues.nextActionBehavior = {};
          tempInitialValues.nextActionBehavior.comment = "";
          tempInitialValues.nextActionBehavior.create = false;
          tempInitialValues.nextActionBehavior.nextAction = -1;
          if (!tempInitialValues.declaration.valuableAssets.totalEstimated) {
            tempInitialValues.declaration.valuableAssets.totalEstimated = this.props.props.location?.state.estimatedTotalValue;
          }
          if (!tempInitialValues.files) tempInitialValues.files = [];

          this.updateStateValues(
            tempInitialValues as InitialValues,
            fromDidMount
          );
          this.setState({
            lockCall: false,
          });

          const newTempInitialValues = Object.assign({}, tempInitialValues);
          newTempInitialValues.files = file;

            if (
              newTempInitialValues.declaration &&
              newTempInitialValues.declaration.id
            ) {
              this.updateStateValues(
                newTempInitialValues as InitialValues,
                fromDidMount
            );
          }

          subscriptions[0].unsubscribe();
        }, error => {
          console.log(error);
        });
        subscriptions.push(res)
      } else {
        this.updateStateValues(this.initialValues, fromDidMount);
      }

      this.setState({
        lockCall: true,
      });
    }
    return subscriptions;
  };
  
  render() {
    return (
      this.state.isReadyToRender && (
        <Formik
          onSubmit={this.submit}
          initialValues={this.state.initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={this.declarationSchema}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            setSubmitting,
            errors,
          }) => {
            React.useEffect(() => {

              let isMounted = true;
              let subscriptions: Subscription[];
              let timeout: NodeJS.Timeout;
              if(isMounted) {
                subscriptions = this.updateInitialValues();
                timeout = setTimeout(() => {
                  resetForm(
                    this.state.initialValues as FormikState<InitialValues>
                  );
                }, 100);
                this.context.stopLoading();
              }

              return () => {
                clearTimeout(timeout);
                subscriptions.forEach(subscription => {
                  subscription.unsubscribe();
                })
                isMounted = false;
              }
            }, [resetForm]);
            return (
              <div className="readonly">
                <form
                  onSubmit={handleSubmit}
                  className="bg-white border p-5 d-flex flex-column"
                >
                  <Container maxWidth="xl" className="content declaration">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={7} className="readonly__left">
                        <h1>
                          {this.state.initialValues.title}
                          {this.state.initialValues.title !== "New Declaration"
                            ? " - declaration"
                            : ""}
                        </h1>
                        {this.state.initialValues.title ===
                          "New Declaration" && (
                          <UiBox
                            title="Searching Brand Name"
                            name="declaration__search"
                          >
                            <div className="inline-form">
                              <Field
                                name="globalConcept"
                                label="Global Concept declaration"
                                component={UiSwitchField}
                                disabled={values.isReadOnly}
                                column="half"
                              />
                              <Field
                                name={"selectedBrandName"}
                                label="selected Brand Name"
                                component={UiAutocompleteField}
                                multiple={false}
                                options={this.state.brandsMaisonList}
                                disabled={values.isReadOnly}
                              />
                            </div>
                          </UiBox>
                        )}
                        <ConditionnalSection
                            component={EventDescription}
                            section={'eventDescription'}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                           >
                        </ConditionnalSection>
                        <ConditionnalSection
                            component={ValuablesManagement}
                            section={'valuablesManagement'}
                            name="declaration.valuableAssets"
                            values={values}
                            errors={errors}
                        >
                        </ConditionnalSection>
                        <ConditionnalSection
                            component={SecuritySurveillanceSystem}
                            section={'security'}
                            name="declaration.securitySurveillanceSystem"
                        >
                        </ConditionnalSection>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        className="declaration-right readonly__right"
                      >
                        <UiBox title="Next actions" altStyle={true}>
                          <Field
                            isSingleButton={false}
                            enableEditButton={false}
                            enableSaveAndCloseButton={true}
                            handleSubmit={handleSubmit}
                            isDeclaration={true}
                            handleSubmitWithoutFormik={() =>
                              this.submit(values, {
                                setSubmitting: setSubmitting,
                              })
                            }
                            isCancelled={this.props.isCancelled}
                            component={NextActions}
                          />
                        </UiBox>
                        <Field
                          name="upload"
                          component={Upload}
                          title="Manage documents"
                          fileTypesAccepted={fileTypesAccepted}
                          maxFileSize={MAX_FILE_SIZE()}
                          urlUpload={partialUrlUpload}
                          urlProgress={partialUrlProgress}
                          urlDownload={partialUrlDownload}
                          eventId={this.state.event?.id}
                          declarationId={
                            this.state.initialValues.declaration.id
                          }
                          files={this.state.initialValues.files}
                          reloadFiles={this.updateInitialValues}
                          progressActivated={true}
                        />
                      </Grid>
                    </Grid>
                    <UiScrollToTopButton {...this.props}></UiScrollToTopButton>
                  </Container>
                </form>
              </div>
            );
          }}
        </Formik>
      )
    );
  }
}

export default DeclarationNewEdit;
