import React from 'react';
import { useHistory } from "react-router-dom";
import { Event } from 'react-big-calendar';
import moment from 'moment';
import { numberScaleFormat, getEnumValueByEnumKey, createNotification, baseCreateNotification } from '../../../shared/helpers/HelpersFunc';
import { RiskTagsEnum } from '../../interface';
import UiStatus from '../../../shared/components/ui/UiStatus';
import ErrorIcon from '@material-ui/icons/Error';
import UiTags from '../../../shared/components/ui/UiTags';
import './CalendarEventDetail.scss';
import {notificationMessage, urlRedirectionToEventReadOnly} from '../../../shared/config/GlobalAppConfig';
import { C, RA} from '../listview-paggination/UiListViewCells';
import { Formik, Field } from 'formik';
import {NextActions} from '../../../event/components/nextActions/nextActions';
import { FlowRefService } from '../../../shared/services/flowRefService';
import LoadingContext from '../../../routes/LoadingContext';
import {NotificationType} from '../../../shared/models/enums'

interface Props extends Event {
    event: Event,
    onClickBtn?: () => void;
}

export const CalendarEventDetail = (props: Props) => {
    const ctx = React.useContext(LoadingContext);
    const history = useHistory();
    const [event, setEvent] = React.useState(props.event);

    const redirectToEvent = (eventId: number) => {
        history.push(urlRedirectionToEventReadOnly + eventId);
    }

    let risksArr: string[] = [];
    if(event && event.resource && event.resource.risks) {
        Object.entries(event.resource.risks).forEach(([key, value]) =>
         {
            if(value) risksArr.push(key);
        });
    }

    React.useEffect(()=> {
        if(props.event !== event) {
            setEvent(props.event);
        }
    }, [props.event, event])

    const submit = (action: any) => {
        if(event.resource.id && action.nextActionBehavior) {
            ctx.startLoading();
            
            FlowRefService.changeEventStatus(event.resource.id, action.nextActionBehavior).subscribe(() => {
                if(props.onClickBtn) props.onClickBtn();
                createNotification(NotificationType.success ,action.nextActionBehavior.idTask);
            },(error) => {
                ctx.stopLoading();
                baseCreateNotification(NotificationType.error,notificationMessage.ErrorGenericMessage.title,error.data.errorMessage)
            })
         }
    }

    return (
        <Formik
            initialValues={{}}
            onSubmit={submit}>
            {({
                values,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => {
                return (
                    <React.Fragment>
                        {event && event.resource && (
                            <div className="calendar-details" onClick={() => redirectToEvent(event.resource.id)}>
                                <div className="calendar-details__header">
                                    <label>
                                        {event.resource.showExclamationPoint && (
                                            <ErrorIcon/>
                                        )}
                                        <UiStatus label={event.resource.status} name={event.resource?.status.toLowerCase()} />
                                    </label>
                                    {moment(event.start).format('DD.MM.yyyy')}
                                </div>
                                <div className="calendar-details__infos">
                                    <div className="calendar-details__block">
                                        <div className="calendar-details__title">{event.title}</div>
                                        <div className="calendar-details__location">
                                            Maison: {event.resource.maison}<br />
                                            Country: {event.resource.country}
                                        </div>
                                    </div>
                                    
                                    <div className="calendar-details__block">
                                        Risk level: {event.resource.riskLevelContent}<br />
                                        Estimated amount: {event.resource.amount && numberScaleFormat(event.resource.amount)}€<br />
                                        <div className="calendar-details__risks">
                                            {
                                                risksArr.map((el: any, index: number) => (
                                                        <UiTags key={el} label={getEnumValueByEnumKey(RiskTagsEnum, el)}></UiTags>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="calendar-details__block">
                                        <div className="calendar-details__checked">
                                            <div >
                                                <div className="calendar-details__checked__sym">{C(event.resource.item)}</div>
                                                <div className="calendar-details__checked__data"> Concept </div>
                                            </div>
                                            <div >
                                                <div className="calendar-details__checked__sym">{RA(event.resource.item)} </div>
                                                <div className="calendar-details__checked__data"> Risk assessment </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Field
                                        isSingleButton={true}
                                        singleButtonValue={event.resource.item}
                                        handleSubmit={handleSubmit}
                                        eventId={event.resource.id}
                                        component={NextActions}
                                    />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )
            }}
        </Formik>
)};