import { AxiosRequestConfig } from "axios";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { EventPhaseItem, SecuritySystemDevicesItem, ValuableAssetItem } from "../../declaration/declarationNewEdit/interfaces";
import { UiRadioFieldOption } from '../components/fields/UiRadioField';
import {
    serverUrlBase,
    urlPartialGetActivityType,
    urlPartialGetCountries,
    urlPartialGetCountryLevelRisk,
    urlPartialGetEventPhaseList,
    urlPartialGetEventType,
    urlPartialGetLocationType,
    urlPartialGetMaisonBrandsWithUserFilter,
    urlPartialGetNumberOfParticipantsList,
    urlPartialGetRiskLevel,
    urlPartialGetRoles,
    urlPartialGetSecuritySystemDeviceList,
    urlPartialGetUserRole,
    urlPartialGetUsers,
    urlPartialGetUsersWithIds,
    urlPartialGetValuableAssetsList
} from "../config/GlobalAppConfig";
import CustomAxios, { ApiCallingMethods } from "../helpers/AxiosHelper";
import {UserGroup, UserRoles} from '../models/enums';
import { KeyValuePair, MaisonDto } from '../models/interfaces';
import {insuranceRoles} from "../config/securityConcept";
import LocalStorageTokenService from "../helpers/SessionStorageTokenService";
import {of} from "rxjs";

export interface Reference {
    itemKey: string,
    itemContent: string,
    displayOrder: number,
    isVisible: boolean,
    id: number,
    createdBy: string,
    createdDate: string,
    modifiedBy: string,
    modifiedDate: string,
    label?: string
}

export interface AllReferences {
    rolesList?: Reference[],
    brandsMaisonList?: Reference[],
    countriesList?: Reference[],
    eventTypeList?: Reference[],
    locationTypeList?: Reference[],
    countryLevelRisk?: Reference[],
    riskLevel?: Reference[]
}

export interface Role {
    id: string,
    displayName: string,
    targetEmail: Array<string>,
    applicationRole: string;
    appId: number;
}


export class ReferencesService {
    static getListUsers(searchValue: string): Observable<string[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetUsers}?searchValue=${searchValue}`,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static getListUsersWithIds(searchValue: string): Observable<KeyValuePair[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetUsersWithIds}?searchValue=${searchValue}`,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static getListUserRole(id: string): Observable<any> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetUserRole}?searchValue=${id}`,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static getAllRoles(groupUser?: UserGroup): Observable<Role[]> {
        const groupQuery = groupUser ? `?targetUserRole=${groupUser}` : ''
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetRoles}${groupQuery}`,
            data: null
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model.reduce((acc:Role[], item: Role) => {
            if(!acc.find(r=>r.appId === item.appId)){
                acc.push(item)
            }
            return acc;
        }, [])));    }

    static getAllMaisons(): Observable<Reference[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${serverUrlBase}/api/Administration/Attributes/Maisons`,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => {
            // TODO Remove this adpater and fix maison display everywhere according MaisonDto
            return res.data.model.map((maison:MaisonDto)=>({
                createdBy: maison.createdBy,
                createdDate: maison.createdDate,
                displayOrder: 0,
                id: maison.id,
                isDeleted: maison.isDeleted,
                isVisible: maison.isActive,
                itemContent: maison.name,
                itemContent2: maison.alternateName,
                itemtKey: maison.code,
                label: maison.name,
                modifiedBy: maison.modifiedBy,
                modifiedDate: maison.modifiedDate,
            }))
        }));
    }

    static getAllMaisonsWithUserFilter(): Observable<Reference[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetMaisonBrandsWithUserFilter,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static getAllCountries(): Observable<Reference[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetCountries,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static GetAllEventType(): Observable<Reference[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetEventType,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static GetAllLocationType(): Observable<Reference[]> {

        if (insuranceRoles.includes(LocalStorageTokenService.getUserRoleToken() as UserRoles)) {
            return of([]);
        }

        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetLocationType,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static GetAllActivityType(): Observable<Reference[]> {

        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetActivityType,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static GetAllCountryLevelRisk(): Observable<Reference[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetCountryLevelRisk,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static GetAllRiskLevel(): Observable<Reference[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetRiskLevel,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static GetValuableAssetsList(): Observable<ValuableAssetItem[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetValuableAssetsList,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => {
            const list: Reference[] = res.data.model;
            const valuableAssetsList: ValuableAssetItem[] = []

            list.forEach((listItem: Reference) => {
                valuableAssetsList.push({
                    quantity: 0,
                    type: listItem.itemContent.toLowerCase().indexOf('other') > -1 ? '' : listItem.itemContent,
                    amount: 0,
                    id: listItem.id
                })
            })

            return valuableAssetsList;
        }));
    }

    static GetEventPhaseList(): Observable<EventPhaseItem[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetEventPhaseList,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => {
            const list: Reference[] = res.data.model;
            const eventPhasesList: EventPhaseItem[] = []

            list.forEach((listItem: Reference) => {
                eventPhasesList.push({
                    eventPhase: listItem.itemContent,
                    numberOfSecurityAgents: 0,
                    guardingCost: 0,
                    id: listItem.id
                })
            })
            return eventPhasesList;
        }));
    }

    static GetSecuritySystemDeviceList(): Observable<SecuritySystemDevicesItem[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetSecuritySystemDeviceList,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => {
            const list: Reference[] = res.data.model;
            const securitySystemDeviceList: SecuritySystemDevicesItem[] = []

            list.forEach((listItem: Reference) => {
                securitySystemDeviceList.push({
                    quantity: 0,
                    securitySystemDevice: listItem.itemContent.toLowerCase().indexOf('other') > -1 ? '' : listItem.itemContent,
                    installationCompany: '',
                    cost: 0,
                    id: listItem.id
                })
            })
            return securitySystemDeviceList;
        }));
    }

    static GetAllNumberOfParticipants(): Observable<UiRadioFieldOption[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetNumberOfParticipantsList,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model.map(
            (ref: Reference): UiRadioFieldOption => ({ label: ref.itemContent, value: ref.id.toString() })
        )));
    }

    static GetAllReferences(): AllReferences {
        const referencesData: AllReferences = {};

        const brandsStr = sessionStorage.getItem('brandsMaisonList')
        if (brandsStr) {
            referencesData.brandsMaisonList = JSON.parse(brandsStr);
        }
        else {
            ReferencesService.getAllMaisons().subscribe({
                next: (resp: any) => {
                    referencesData.brandsMaisonList = resp;
                    sessionStorage.setItem('brandsMaisonList', JSON.stringify(resp));
                },
                error: err => console.error(err),
            })
        }

        ReferencesService.getAllCountries().subscribe({
            next: (resp: any) => {
                referencesData.countriesList = resp
            },
            error: err => console.error(err),
        })
        ReferencesService.GetAllEventType().subscribe({
            next: (resp: any) => {
                referencesData.eventTypeList = resp;
            },
            error: err => console.error(err),
        })
        ReferencesService.GetAllLocationType().subscribe({
            next: (resp: any) => {
                referencesData.locationTypeList = resp;
            },
            error: err => console.error(err),
        })
        ReferencesService.GetAllCountryLevelRisk().subscribe({
            next: (resp: any) => {
                referencesData.countryLevelRisk = resp;
            },
            error: err => console.error(err),
        })

        ReferencesService.GetAllRiskLevel().subscribe({
            next: (resp: any) => {
                referencesData.riskLevel = resp;
            },
            error: err => console.error(err),
        })

        return referencesData;
    }
}