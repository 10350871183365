import cLocal from "date-fns/locale/fr";
export const serverUrlBase = process.env.REACT_APP_PUBLIC_URL;

export const urlPartialLogs: string = serverUrlBase + "/api/Logs/SaveLog";

export const urlPartialGetUsers: string = serverUrlBase + "/api/AppRefData/GetListUsers";
export const urlPartialGetUsersWithIds: string = serverUrlBase + "/api/AppRefData/GetListUsersWithIds";
export const urlPartialGetUserRole: string = serverUrlBase + "/api/AppRefData/GetRole";
export const urlPartialGetRoles: string = serverUrlBase + "/api/AppRefData/GetListApplicationRole";
export const urlPartialGetMaisonBrands: string = serverUrlBase + "/api/AppRefData/GetListMaisonBrands";
export const urlPartialGetMaisonBrandsWithUserFilter: string =
    serverUrlBase + "/api/AppRefData/GetListMaisonBrandsWithUserFilter";

export const urlPartialGetCountries: string = serverUrlBase + "/api/AppRefData/GetListCountries";

export const appCurrentLocation = cLocal;
export const dashboardRowPerPage = 10;
export const adminRowsPerPage = 10;
export const minLettersToTriggerSearch = 3;
export const urlPartialGetEventType: string = serverUrlBase + "/api/AppRefData/GetListEventType";
export const urlPartialGetLocationType: string = serverUrlBase + "/api/AppRefData/GetListLocationType";
export const urlPartialGetActivityType: string = serverUrlBase + "/api/AppRefData/GetActivityTypes";
export const urlPartialGetCountryLevelRisk: string = serverUrlBase + "/api/AppRefData/GetListCountryRiskLevel";
export const urlPartialGetRiskLevel: string = serverUrlBase + "/api/AppRefData/GetListRiskLevel";
export const urlPartialGetEventStatus: string = serverUrlBase + "/api/Flow/GetFlow";
export const urlPartialGetValuableAssetsList: string = serverUrlBase + "/api/AppRefData/GetValuableAssetsList";
export const urlPartialGetEventPhaseList: string = serverUrlBase + "/api/AppRefData/GetEventPhaseList";
export const urlPartialGetSecuritySystemDeviceList: string =
    serverUrlBase + "/api/AppRefData/GetSecuritySystemDeviceList";
export const urlPartialGetUploadedFilesList: string = serverUrlBase + "/api/FilesHandler/UploadedFiles";
export const urlPartialGetFlows: string = serverUrlBase + "/api/Flow/GetFlows";
export const urlPartialGetTasks: string = serverUrlBase + "/api/Flow/GetTasks";
export const urlPartialChangeEventStatus: string = serverUrlBase + "/api/Flow/ChangeEventStatus";
export const urlPartialGetNumberOfParticipantsList: string =
    serverUrlBase + "/api/AppRefData/GetNumberOfParticipantsList";

export const urlPartialGetDashboard: string = serverUrlBase + "/api/Dashboard/GetDashboard";
export const urlPartialGetEventDeclarationExportData: string =
    serverUrlBase + "/api/Dashboard/GetEventDeclarationExportData";

export const urlPartialGetDeclaration: string = serverUrlBase + "/api/Declarations/GetDeclaration";
export const urlPartialUpdateDeclaration: string = serverUrlBase + "/api/Declarations/UpdateDeclaration";

export const urlToSendEmail: string = serverUrlBase + "/api/Email/SendEmailInformation";
export const urlToSaveEmailEventHistory: string = serverUrlBase + "/api/Email/SaveEmailEventHistory";
export const urlGetEvent: string = serverUrlBase + "/api/Events/GetEvent";
export const urlAddEvent: string = serverUrlBase + "/api/Events/AddEvent";
export const urlUpdateEvent: string = serverUrlBase + "/api/Events/UpdateEvent";
export const urlGetEventStatusList: string = serverUrlBase + "/api/Events/GetEventStatusList";
export const urlGetEventHistoryStatus: string = serverUrlBase + "/api/Events/GetEventHistoryStatus";
export const urlRecallEvent: string = serverUrlBase + "/api/Events/RecallEvent";

export const partialUrlUpload = serverUrlBase + "/api/filesHandler/Upload";
export const partialUrlProgress = serverUrlBase + "/api/filesHandler/Progress";
export const partialUrlDownload = serverUrlBase + "/api/filesHandler/Download";

export const urlGetPagedUsers = serverUrlBase + "/api/Administration/Users/Paged";
export const urlAddUser = serverUrlBase + "/api/Administration/Users/Add";
export const urlUpdateUser = serverUrlBase + "/api/Administration/Users/Update";
export const urlDeleteUser = serverUrlBase + "/api/Administration/Users/Delete";

export const partialUrlGetDocumentRightsByRole = serverUrlBase + "/api/Documents/GetDocumentRightsByRole";

export const urlGetSecurityConceptsByEvent = "http://localhost:3100/api/SecurityConceptsByEvent";
export const urlGetSecurityConceptsById = "http://localhost:3100/api/SecurityConcept";

// Used to add to react history
export const urlRedirectionToEventReadOnly: string = "/dashboard/event/readonly/";

export const dashboard_maxDaysLeftToTriggerWarning: number = 10;
export const oneDay: number = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

export const reactAppAdPrefix = process.env.REACT_APP_AD_PREFIX;

export const truncateMaxCharNb = 36;
export const truncateText = "...";
//Notification display time in ms
export const notificationDisplayTime = {
    info: 5000,
    succes: 5000,
    warning: 5000,
    error: 5000,
};
// Notification message
export const notificationMessage = {
    Send_to_security: { title: "Event sent", message: "Event successfully sent to security" },
    Save_for_later: { title: "Event saved", message: "Event successfully saved" },
    Send_to_HQ: { title: "Event sent", message: "Event successfully sent to HQ" },
    Create_event: { title: "Event", message: "Event successfully Created" },
    Need_more_details: { title: "Info", message: "Need more details" },
    Send_to_Broker: { title: "Message sent", message: "Event successfully sent to Broker" },
    Escalate_event: { title: "Info", message: "Escalate event" },
    Assign_to_me: { title: "Message sent", message: "Assign to me" },
    Confirm_cover_is_available: { title: "Message sent", message: "Confirm cover is available" },
    Ask_for_Underwritter: { title: "Message sent", message: "Ask for Underwriter" },
    Confirm_no_coverage: { title: "Message sent", message: "Confirm no coverage" },
    Update_Event: { title: "Message sent", message: "Update Event" },
    Reopen_Event: { title: "Message sent", message: "Reopen Event" },
    Ask_for_Risk_Assessment: { title: "Message sent", message: "Ask for Risk Assessment" },
    Notify_the_Underwriter: { title: "Message sent", message: "Notify the Underwriter" },
    Cancel_event: { title: "Event", message: "Event successfully canceled" },
    Close: { title: "Event Close", message: "Event successfully Close" },
    ___: { title: "Info", message: "___" },
    Ask_central_insurance: { title: "Message sent", message: "Ask central insurance" },
    Send_to_region: { title: "Event sent", message: "Event successfully sent to region security" },
    Approve: { title: "Event Approve", message: "Event successfully Approve" },
    Refuse: { title: "Event Refuse", message: "Event successfully Refuse" },
    Approve_subject_to_conditions: { title: "Message sent", message: "Approve subject to conditions" },
    Send_to_HQ_No_Valid: { title: "Event sent to HQ", message: "Unable to send to HQ, event not valid" },
    Error_Upload_Files: { title: "Upload files", message: "Something went wrong during the upload" },
    Error_Download_Files: { title: "Download file", message: "Something went wrong during the download" },
    Error_Download_FileNotFound: {
        title: "Download file",
        message: "File not found, please contact technical support",
    },
    ErrorGenericMessage: { title: "Error", message: "An error occurred, please contact your admin" },
    More_details_local: { title: "Info", message: "Need more details local" },
    More_details_region: { title: "Info", message: "Need more details region" },
    More_details_maison: { title: "Info", message: "Need more details maison" },
    Recall_event: { title: "Event recall", message: "Event successfully recalled" },
    Send_to_region_invalid: { title: "Event update cancelled", message: "Unable to send to Region, event not valid" },
    Reject_security_concept: {
        title: "Security concept rejected",
        message: "Security concept rejected, event rollbacked to previous status",
    },
};

export const thresholdRefreshIntervalMs = 60000 * 5;
export const onTypeSearchWaitTime = 500;

export const urlMassImportServiceImportEvents = `${serverUrlBase}/api/Dashboard/ImportEvents`;
export const urlMassImportServiceGetImportedFiles = `${serverUrlBase}/api/Dashboard/GetAllImportedFiles`;
export const urlMassImportServiceAddDuplicateEvents = `${serverUrlBase}/api/Events/AddDuplicateEvents`;
