import React, { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import ErrorPage from "../../Error/ErrorPage";
import { AllowedAdministrationRoles } from '../config/administrationConfig';
import { useAuthContext } from '../contexts/AuthContext';
import { UserRoles } from '../models/enums';
import {insuranceRoles} from "../config/securityConcept";

const PrivateRoute = ({ component: Component, render: Render, auth, path, ...rest }: any) => {
    const { isAuth, isError, role } = useAuthContext();
    const history = useHistory();
    useEffect(() => {
        if (path === "/admin" && (!role || !AllowedAdministrationRoles.includes(role))) {
            history.push("/dashboard");
        }
        if (role === UserRoles.admin && path !== "/admin") {
            history.push("/admin");
        }

        if (insuranceRoles.includes(role as UserRoles) && path === "/dashboard/event/new") {
            history.push("/dashboard");
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    return isAuth ? (
        <Route exact path={path} {...rest} render={Render} component={Component} />
    ) : isError ? (
        <Route component={ErrorPage} />
    ) : (
        <Redirect to="/" />
    );
};

export default PrivateRoute;
