import { toNumber } from "lodash";
import { FileTypesAccepted } from "../models/interfaces";

// TODO: to be deleted - used only to test locally
// export const serverUrlLocal = 'http://localhost:8000/upload';

export const securityConceptFileTypesAccepted = {
    word_97: 'doc',
    word: 'docx',
    power_point_97: 'ppt',
    power_point: 'pptx',
    power_point_show_97: 'pps',
    power_point_show: 'ppsx',
    text: 'rtf',
};

export const fileTypesAccepted: FileTypesAccepted = {
    document: {
        pdf: 'pdf',
        ...securityConceptFileTypesAccepted
    },
    image: {
        image_1: 'png',
        image_2: 'jpg',
        image_3: 'jpeg',
    },
    video: {
    }
};

export const MAX_FILE_SIZE = () => {

    let val: number = toNumber(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE);
    if(toNumber(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE) === undefined
        || isNaN(val)
        || toNumber(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE) === null) {
            // this is the default value for tests
            return 2;
    }
    else{
        return toNumber(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE);
    }
};