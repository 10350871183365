import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

export const CalendarEventItem = ({ event } : any)  => {
    // TODO: implement logic for warning icon
    const warning = event.resource.showExclamationPoint;
    // TODO: Get statut name dynamic
    const cssClass = "calendar-event-item calendar-event-item--"+event.resource.status.toLowerCase();
    return (
      <span className={cssClass}>
          {warning && <ErrorIcon  fontSize="small" />}
          <span className="calendar-event-item__title">
            {event.title}
          </span>
      </span>
    )
  }