import * as Yup from "yup";
import { Field, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useRef } from "react";
import { UiAmountField } from "../../../shared/components/fields";
import { Grid } from "@material-ui/core";
import UiBox from "../../../shared/components/ui/UiBox";
import { Button } from "@material-ui/core";
import { useRefDataContext } from "../../../shared/hooks/refDataHook";
import { useLoadingContext } from "../../../routes/LoadingContext";
import { Subscription } from "rxjs";
import { AdministrationService } from "../../../shared/services/administration.service";

type IThresoldFormValue = {
    threshold: number;
};

const validationSchema = Yup.object().shape<IThresoldFormValue>({
    threshold: Yup.number().min(1).required(),
});

export const ThresholdMgmt = () => {

    const {threshold} = useRefDataContext()
    const {startLoading, stopLoading} = useLoadingContext();
    const updateSubscription = useRef<Subscription>()
    const initialValues = {
        threshold: threshold.value.thresholdValue,
    };
    const onConfirm = useCallback((data: any) => {
        startLoading();
        updateSubscription.current = AdministrationService.updateThreshold({...threshold.value, thresholdValue: data.threshold}).subscribe((result=>{
            threshold.load();
            stopLoading();

        }))
    }, [startLoading, stopLoading, threshold]);

    useEffect(()=>{
        return () => updateSubscription.current?.unsubscribe()
    }, [updateSubscription])

    return (
        <div className="threshold-mgmt">
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={onConfirm}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={true}
                touched
            >
                {(formikProps: FormikProps<IThresoldFormValue>) => {
                    return (
                        <form onSubmit={formikProps.handleSubmit}>
                            <UiBox title="Threshold">
                                <Grid spacing={3} alignItems="center" container>
                                    <Grid item>
                                        <Field id="threshold-input" component={UiAmountField} label="Threshold" name="threshold" />
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            id="validate-threshold"
                                            onClick={() => formikProps.handleSubmit()}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Validate Threshold
                                        </Button>
                                    </Grid>
                                </Grid>
                            </UiBox>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};
