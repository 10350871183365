import { Field, GenericFieldHTMLAttributes } from 'formik';
import React, { ComponentProps } from 'react';
import { FieldId } from '../../admin/components/FieldsMgmt/Fields.type';
import { useAuthContext } from '../contexts/AuthContext';


type ConditionnalFieldProps<Component extends React.FC<any>> = {
    component: Component,
} & GenericFieldHTMLAttributes & ComponentProps<Component>

export const ConditionnalField= <T extends  React.FC<any>>({...props} : ConditionnalFieldProps<T> & {fieldId: FieldId}) => {
    const {fieldsVisibility} = useAuthContext();
    const {fieldId, ...rest} = props as unknown as any; 
    if(fieldsVisibility && fieldsVisibility[fieldId] !== false){
        return <Field {...rest} />
    }else{
        return <></>
    }

}
