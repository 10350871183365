import { AxiosRequestConfig } from "axios";
import { saveAs } from "file-saver";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { notificationMessage, partialUrlDownload, serverUrlBase } from "../config/GlobalAppConfig";
import CustomAxios, { ApiCallingMethods } from "../helpers/AxiosHelper";
import { ISecurityConcept } from "../models/interfaces";
import { getMappingRoles } from "../models/records";
import LocalStorageTokenService from "../helpers/SessionStorageTokenService";
import { CustomFile } from "../components/upload/interfaces";
import { baseCreateNotification } from "../helpers/HelpersFunc";
import { NotificationType } from "../models/enums";

const notificationHandler = (resp : any, type : NotificationType) => {
    if(type === NotificationType.error) baseCreateNotification(type,notificationMessage.ErrorGenericMessage.title,resp.response.data.errorMessage)
}
    

export const SecurityConceptService = {
    securityConceptBus: new Subject(),

    getSecurityConcepts: function (eventId: number): Observable<ISecurityConcept[]> {
        const idrole = getMappingRoles(LocalStorageTokenService.getUserRoleToken());

        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${serverUrlBase}/api/Documents/GetSecurityConcept/${idrole}/${eventId}`,
            data: null,
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    },

    validate: function (securityConceptId: number, comments: string = ""): Observable<ISecurityConcept> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/FilesHandler/SecurityConcept/Validate/${securityConceptId}`,
            data: comments  || "",
            headers: {
                'content-type': 'application/json',
            }
        };
        return CustomAxios.request(options,notificationHandler).pipe(map((res) => res.data.model));
    },

    accept: function (securityConceptId: number, comments: string = ""): Observable<ISecurityConcept> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/FilesHandler/SecurityConcept/Accept/${securityConceptId}`,
            data: comments || "",
            headers: {
                'content-type': 'application/json',
            }
        };
        return CustomAxios.request(options,notificationHandler).pipe(map((res) => res.data.model));
    },

    reject: function (securityConceptId: number, comment: string, idEvent: number): Observable<ISecurityConcept> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/FilesHandler/SecurityConcept/Reject/${securityConceptId}`,
            data: {comment, idEvent},
            headers: {
                'content-type': 'application/json',
            }
        };
        return CustomAxios.request(options,notificationHandler).pipe(map((res) => res.data.model));
    },

    setAsCurrent: function (securityConcept: ISecurityConcept): Observable<ISecurityConcept> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/FilesHandler/SetFileAsCurrentVersion`,
            params: {
                fileId: securityConcept.id,
                fileAppVersion: securityConcept.version,
                idEvent: securityConcept.idEvent,
                idDeclaration: securityConcept.idDeclaration,
            },
        };
        return CustomAxios.request(options).pipe(map((res) => res.data.model));
    },
    download: function (securityConcept: ISecurityConcept): Observable<boolean> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: partialUrlDownload,
            responseType: "blob",
            data: {
                fileId: securityConcept.id,
                fileVersion: securityConcept.version
            }
        };
        return CustomAxios.request(options).pipe(
            map((res) => {
                saveAs(res.data, `${securityConcept.filename}.${securityConcept.extension}`);
                return true;
            })
        );
    },
    preview: function (securityConcept: CustomFile) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/FilesHandler/PreviewFile`,
            responseType: "blob",
            data: {
                filename: securityConcept.filenameInBe,
            }
        };
        return CustomAxios.request(options)
    },
    review(securityConceptId: number, comments: string) {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/FilesHandler/SecurityConcept/Review/${securityConceptId}`,
            data: comments || "",
            headers: {
                'content-type': 'application/json',
            }
        };
        return CustomAxios.request(options,notificationHandler).pipe(map((res) => res.data.model));
    }
};
