import React, { Fragment, useState } from 'react';
//import { withFormik, FormikProps, FormikErrors, Form, Formik, Field, ErrorMessage } from 'formik';
import { Field, ErrorMessage } from 'formik';
import { addDays } from "date-fns";


import { appCurrentLocation } from '../../config/GlobalAppConfig'; 

// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import 'react-day-picker/lib/style.css';
import { Example } from '../../components/DatePickerField';

// import { SchemaPreRequisites, InitPreRequisites } from './PreRequisitesSchema';
import { SchemaEventInformation } from './EventInformationSchema';

  // Use <MyForm /> wherevs
  const EventInformation = (props: {title: string, formProp: Array<any>}) => (
    <div>
      <h1>{props.title}</h1>
      <InnerForm formProp={props.formProp} />
    </div>
  );


  const InnerForm = (props: {formProp: Array<any>}) => {
    let touched = props.formProp[0];
    let errors = props.formProp[1]
    let dateTo = new Date();
    //let useFormikCtx = useFormikContext();


    const [startDateTo, setStartDateTo] = useState<Date>(new Date());
    // function setCustomDateTo(dateFrom: Date){
    const setCustomDateTo = (dateFrom: Date, setFieldValue_:any) =>{
      //const { setFieldValue } = useFormikCtx; 
      dateTo = addDays(dateFrom, 1);
      setStartDateTo(new Date(dateTo));
      setFieldValue_("to", dateTo, false);
    }

    return (
      <Fragment>
        <div>
          <label>Event Name</label>
          <Field type="text" name="eventName" placeholder="text" validation={SchemaEventInformation.fields.eventName} />
          {touched.eventName && errors.eventName && <p>{errors.eventName}</p>}
        </div>
        <div>
          <label>Event Type</label>
          <Field as="select" name="eventType" validation={SchemaEventInformation.fields.eventType}>
              <option value="red">e1</option>
              <option value="green">e2</option>
              <option value="blue">e3</option>
          </Field>
          <ErrorMessage name="eventType" />
        </div>
        <div>
          <label>From</label>
          {/* <Field type="text" name="from" placeholder="text" validation={SchemaEventInformation.fields.from} /> */}
          {/* <DatePickerField name="from" placeholder="text" validation={SchemaEventInformation.fields.from} /> */}
          {/* <Field key="from" name="from" placeholder="From" nameFrom="From" nameTo="to" validation={SchemaEventInformation.fields.from} component={DatePickerField} /> */}
          {/* {touched.from && errors.from && <p>{errors.from}</p>} */}
        </div>
        <div>
          <label>To</label>
          {/* <Field type="date" name="to" placeholder="text" validation={SchemaEventInformation.fields.to} /> */}
          {/* <Field type="text" key="to" name="to" placeholder="To" validation={SchemaEventInformation.fields.to} component={DatePickerField} /> */}
          {/* {touched.to && errors.to && <p>{errors.to}</p>} */}
        </div>
        <div>
          <label>Location Name</label>
          <Field type="text" name="locationName" placeholder="text" validation={SchemaEventInformation.fields.locationName} />
          {touched.locationName && errors.locationName && <p>{errors.locationName}</p>}
        </div>
        <div>
          <label>Location Type</label>
          <Field type="text" name="locationType" placeholder="text" validation={SchemaEventInformation.fields.locationType} />
          {touched.locationType && errors.locationType && <p>{errors.locationType}</p>}
        </div>
        <div>
          <label>Event address</label>
          <Field type="text" name="EventAddress" placeholder="text" validation={SchemaEventInformation.fields.EventAddress} />
          {touched.EventAddress && errors.EventAddress && <p>{errors.EventAddress}</p>}
        </div>
        <div>
          <label>From</label>
          <Field name="from" startDate={new Date()} useMinDate={true} dateFormat="dd/MM/yyyy" locale={appCurrentLocation} customAction={setCustomDateTo} component={Example} />
          {touched.from && errors.from && <p>{errors.from}</p>}
        </div>
        <div>
          <label>To</label>
          <Field name="to" value={startDateTo} startDate={startDateTo} useMinDate={true} dateFormat="dd/MM/yyyy" locale={appCurrentLocation} component={Example} />
          {touched.to && errors.to && <p>{errors.to}</p>}
        </div>
      </Fragment>
    )
  };


export default EventInformation;