import React from "react";
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import NewEventService from '../services/NewEvent.service';
import { DeclarationService } from "../services/declaration/declarationService";
import { TruncateUtils } from "../helpers/HelpersFunc";

export class AppBreadcrumbs extends React.Component<any, any> {
    routesList = {
        // '/': 'Home',
        '/dashboard': 'List of Events',
        '/dashboard/event': '',
        '/dashboard/event/new': 'New Event',
        '/dashboard/event/:mode': '',
        '/dashboard/event/:mode/:id': () => this.state.eventName,
        '/dashboard/event/:mode/:id/:idd': () => this.state.declarationName,
        '/dashboard/event/:mode/:id/:idd/readonly': '',
        '/declaration/': '',
        '/declaration/new': 'New Declaration',
        '/admin': 'Administration'
    }

    constructor(props: any) {
        super(props);

        this.state = {
            routesList: this.routesList,
            eventName: '',
            declarationName: ''
        }
    }

    componentDidMount() {
        NewEventService.eventData.subscribe(value => this.setState({eventName: TruncateUtils.truncateText(value?.eventName)}));
        DeclarationService.declarationData.subscribe(value => this.setState({declarationName: value?.declarationName}));
    }

    render() {
        return (
            <Breadcrumbs 
                mappedRoutes={this.state.routesList}
                WrapperComponent={(props: any) => {
                        return <ol className="breadcrumb">{props.children}</ol>
                    }
                }
                ActiveLinkComponent={(props: any) =>
                    <li className="breadcrumb-item active" >{props.children}</li>
                }
                LinkComponent={(props: any) =>
                    <li className="breadcrumb-item">{props.children}<ArrowRightIcon/>
                    </li>
                }
            />
        );
    }
}