import Skeleton from "@material-ui/lab/Skeleton";
import React, { FC } from "react";
export type UiLoadingRowsProps = {
    rowCount: number;
    columnCount: number;
};
export const UiLoadingRows: FC<UiLoadingRowsProps> = ({ rowCount, columnCount }) => {
    return (
        <>
            {new Array(rowCount).fill("").map((_, index) => (
                <tr key={`skeleton-concept-${index}`}>
                    {new Array(columnCount).fill("").map((_, tdIndex) => (
                        <td key={`skeleton-td-${index}-${tdIndex}`}>
                            <Skeleton variant="text" />
                        </td>
                    ))}
                </tr>
            ))}
        </>
    );
};
