import { Button } from "@material-ui/core";
import { PropTypes } from "@material-ui/core";
import React, { FC, ReactChild, ReactChildren, ReactNode, useCallback, useContext, useState } from "react";
import { UiModal } from "../components/ui/UiModal/UiModal";
import "./ConfirmationModal.scss";
type ActionButton = {
    id: string;
    label: string;
    color?: PropTypes.Color;
    left?: boolean;
    variant?: "text" | "outlined" | "contained";
};
type ConfirmArgs = {
    title: string;
    description?: string | ReactChild | ReactChildren | ReactNode;
    cancelText: string;
    confirmText: string;
    confirmColor: PropTypes.Color;
    cancelColor: PropTypes.Color;
    hideCancel: boolean;
    hideConfirm: boolean;
    actionButtons: ActionButton[];
};

const confirmDefaultArgs: ConfirmArgs = {
    title: "",
    description: "",
    cancelText: "Cancel",
    confirmText: "Confirm",
    confirmColor: "primary",
    cancelColor: "primary",
    hideCancel: false,
    hideConfirm: false,
    actionButtons: [],
};
type ConfirmModalResolveType = boolean | string;
export type IConfirmationContext = {
    confirm(args: Partial<ConfirmArgs>): Promise<ConfirmModalResolveType>;
};

const defaultState: IConfirmationContext = {
    confirm: async () => {
        throw new Error("ConfirmationContext must be inside a Provider");
    },
};

export const ConfirmationContext = React.createContext<IConfirmationContext>(defaultState);
type ConfirmResolve = (value: ConfirmModalResolveType) => void;
const noop = () => {};

export const ConfirmationContextProvider: FC = ({ children }) => {
    const [confirmModal, setConfirmModal] = useState<ConfirmArgs>(confirmDefaultArgs);

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmResolver, setConfirmResolver] = useState<{ resolve: ConfirmResolve }>({ resolve: noop });
    const confirm = (args: Partial<ConfirmArgs>): Promise<ConfirmModalResolveType> => {
        return new Promise((resolve) => {
            setConfirmModal({ ...confirmDefaultArgs, ...args });
            setConfirmResolver({ resolve });
            setConfirmModalOpen(true);
        });
    };
    const handleCancel = () => {
        setConfirmModalOpen(false);
        confirmResolver.resolve(false);
        setConfirmModal(confirmDefaultArgs);
    };

    const handleConfirm = useCallback(() => {
        setConfirmModalOpen(false);
        confirmResolver.resolve(true);
        setConfirmModal(confirmDefaultArgs);
    }, [confirmResolver]);

    const handleAction = useCallback(
        (b: ActionButton) => {
            setConfirmModalOpen(false);
            confirmResolver.resolve(b.id);
            setConfirmModal(confirmDefaultArgs);
        },
        [confirmResolver]
    );

    const ActionBtn = (b: ActionButton) => {
        return (
            <Button
                id={`confirmation-modal-action--${b.id}`}
                variant={b.variant || "contained"}
                color={b.color || "primary"}
                onClick={() => handleAction(b)}
            >
                {b.label}
            </Button>
        );
    };
    return (
        <ConfirmationContext.Provider
            value={{
                confirm,
            }}
        >
            {children}
            <UiModal
                id="delete-modal"
                className="confirmation-modal"
                title={confirmModal.title}
                open={confirmModalOpen}
                onClose={handleCancel}
            >
                <>
                    {!!confirmModal.description && <div className="row" id="delete-modal-description">{confirmModal.description}</div>}
                    <div className="ui-modal__footer confirmation-modal-footer ui-modal__footer--buttons">
                        <div className="confirmation-modal-footer__left">
                            {!confirmModal.hideCancel && (
                                <Button
                                    id="confirmation-modal-cancel"
                                    onClick={handleCancel}
                                    variant="outlined"
                                    color={confirmModal.cancelColor}
                                >
                                    {confirmModal.cancelText || "Cancel"}
                                </Button>
                            )}

                            {confirmModal.actionButtons
                                .filter((b) => b.left)
                                .map((b, index) => (
                                    <ActionBtn key={`confirm-${index}-${b.id}`} {...b} />
                                ))}
                        </div>
                        <div className="confirmation-modal-footer__right">
                            {confirmModal.actionButtons
                                .filter((b) => !b.left)
                                .map((b, index) => (
                                    <ActionBtn key={`confirm-${index}-${b.id}`} {...b} {...b} />
                                ))}
                            {!confirmModal.hideConfirm && (
                                <Button
                                    id="confirmation-modal-confirm"
                                    onClick={() => handleConfirm()}
                                    variant="contained"
                                    color={confirmModal.confirmColor}
                                >
                                    {confirmModal.confirmText || "Confirm"}
                                </Button>
                            )}
                        </div>
                    </div>
                </>
            </UiModal>
        </ConfirmationContext.Provider>
    );
};

export const useConfirmation = () => {
    const ctx = useContext(ConfirmationContext);
    return ctx.confirm;
};
