import { Field } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { UiAutocompleteField, UiSwitchField } from '../../../shared/components/fields';
import { sortArrayObject } from '../../../shared/helpers/HelpersFunc';
import { Reference } from '../../../shared/services/referencesService';
import { EventMaisonBrand, IPreRequisities } from '../../interface';


export const preRequisitesInitialValues: IPreRequisities = {
   leadbrandName: '',
   country: null,
   multiBrandEvent: false,
   eventMaisonBrands: [],
   selectedNewBrandsMaison: [],
   selectedConcernedMaison: [],
   selectedCountry: null,
   selectedLeadBrandName: null,
}

export const preRequisitesSchema = Yup.object().shape<IPreRequisities>({
   leadbrandName: Yup.string().required('Lead Maison is required').min(3, 'Too short'),
   selectedLeadBrandName: Yup.object().shape<any>({
      itemContent: Yup.string().required()
   }).nullable().required("Lead Maison is required"),
   country: Yup.number(),
   selectedCountry: Yup.object().shape<any>({
      itemContent: Yup.string().required()
   }).nullable().required("Country is required"),
   multiBrandEvent: Yup.boolean(),
   eventMaisonBrands: Yup.array()
})

export const preRequisitesConcernedMaisonSchema = Yup.object().shape({
   selectedConcernedMaison: Yup.array().of(Yup.object().shape<any>({
      itemContent: Yup.string().required()
   })).nullable().required("Concerned Maison is required")
})

const PreRequisites = ({ field, form, ...props }: any) => {
   //Do not remove /*...*/ this will break array destructuring order.
   const [leadbrandName, country, multiBrandEvent, eventMaisonBrands, /*selectedNewBrandsMaison*/, selectedConcernedMaison, selectedCountry, selectedLeadBrandName] = Object.keys(preRequisitesInitialValues)
   let { brandsList, brandsListWithUserFilter, countriesList, onBrandsListChange } = props;
   let [currentLeadBrand, setCurrentLeadBrand] = useState(null);

   const customOnChangemultibrandevent = (event: any, propsfield: any) => {
      if (!event.target.checked) {
         // ici je prefere appeler le comportement par default plutot que de faire un setFieldValue
         //de la checkbox car il y a peut etre d'autre chose qui sont aussi executées dans le onChange par default du field
         propsfield.onChange(event)
         form.setFieldValue(selectedConcernedMaison, [], false)
         form.setFieldValue(eventMaisonBrands, [], false)
      }
      else {
         // ici je prefere appeler le comportement par default plutot que de faire un setFieldValue
         //de la checkbox car il y a peut etre d'autre chose qui sont aussi executer dans le onChange par default du field
         propsfield.onChange(event)
      }
   }

   const customOnSelectBrand = (values: Reference[], eventId: number) => {
      const savedInDb = form.values.eventMaisonBrands?.filter((t: any) => t.id);

      const newArr = values.map((mb) => {
         const mbFound = form.values.eventMaisonBrands?.filter((t: any) => t.idMaisonBrands === mb.id)[0];

         if (mbFound) {
            mbFound.isDeleted = false;
            return mbFound;
         }
         else {
            const newEventMaisonBrand: EventMaisonBrand = {
               idEvent: eventId,
               idMaisonBrands: mb.id
            }

            return newEventMaisonBrand;
         }
      });

      // Get all maisons saved in the backend but they were removed in front
      const toConcat: any = [];
      if (savedInDb) {
         savedInDb.forEach((s: any) => {
            let flag = true;
            newArr.forEach((n: any) => {
               if (s.id === n.id) {
                  flag = false;
               }
            })
            if (flag) {
               s.isDeleted = true;
               toConcat.push(s)
            }
         })
      }


      form.setFieldValue(eventMaisonBrands, newArr.concat(toConcat));
   }

   const customOnSelectLeadBrand = (values: Reference, eventId: number) => {
      //restore list with the removed maison brand from the list
      if (currentLeadBrand != null) {
         brandsList.push(currentLeadBrand);
      }

      if (values) {
         brandsListWithUserFilter.forEach((i: any, pos: number) => {
            if (i.itemContent === values.itemContent) {
               setCurrentLeadBrand(i);
            }
         });
      }
      else {
         setCurrentLeadBrand(null);
      }

      let newBrandsList;
      if (values) {
         newBrandsList = brandsList.filter((b: any) => b.itemContent !== values.itemContent);

         sortArrayObject(newBrandsList, false, 'itemContent');
         onBrandsListChange(newBrandsList);
      }

      sortArrayObject(brandsListWithUserFilter, false, 'itemContent');

      form.setFieldValue(leadbrandName, values?.itemContent)
   }

   const customOnSelectCountry = (value: Reference) => {
      form.setFieldValue(country, value ? value.id : null);
   }

   const prevListRef: any = React.useRef();
   const prevCMaisonRef: any = React.useRef();

   React.useEffect(() => {
      if (prevListRef.current && prevListRef.current.length !== props.brandsList.length) {
         prevListRef.current.forEach((p: any) => {
            let flag = false;
            flag = props.brandsList.some((r: any) => r.itemContent === p.itemContent);
            if (!flag) setCurrentLeadBrand(p);
         })
      }

      if (prevCMaisonRef.current && prevCMaisonRef.current.length !== form.values.selectedConcernedMaison.length && form.values.selectedConcernedMaison.length < 1) {
         const res = form.values.eventMaisonBrands.map((t: any) => { t.isDeleted = true; return t; })
         form.setFieldValue('eventMaisonBrands', res);
      }

      prevListRef.current = props.brandsListWithUserFilter;
      prevCMaisonRef.current = form.values.selectedConcernedMaison;
   }, [form, props.brandsListWithUserFilter, props.countriesList, props.brandsList, form.values.selectedConcernedMaison])

   return (
      <div className="inline-form">
         <Field
            id="selectAutocompleteLeadMaison"
            name={selectedLeadBrandName}
            label="Lead Maison (*)"
            component={UiAutocompleteField}
            options={brandsListWithUserFilter}
            multiple={false}
            onCustomSelect={(e: any, eventId: number) => customOnSelectLeadBrand(e, eventId)}
         />
         <Field
            id="selectAutocompleteCountry"
            name={selectedCountry}
            label="Country (*)"
            component={UiAutocompleteField}
            options={countriesList}
            multiple={false}
            onCustomSelect={(e: any) => customOnSelectCountry(e)}

         />

         {form.values.multiBrandEvent === true &&
            <Field
               name={selectedConcernedMaison}
               label="Participating Maisons (*)"
               component={UiAutocompleteField}
               options={brandsList}
               multiple={true}
               onCustomSelect={(e: any, eventId: number) => customOnSelectBrand(e, eventId)}
            />
         }
      </div>
   )
}

export default PreRequisites;