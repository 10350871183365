import { IPreRequisites } from '../mock/PreRequisites/PreRequisitesSchema';


export const mockServie = {
    getAll,
    getById,
    create,
    update,
    delete: _delete
};

function getAll(){
    return null;
}

function getById(id: number){
    let mockObj1: IPreRequisites = {} as any;

    mockObj1 = {
        leadBrandName: 'Tata 123 ' + new Date(),
        country: 'c2',
        multiBrandEvent: true,
        brandsConcerned: [],
    };
    return mockObj1;
}

function create(params: IPreRequisites){
    return null;
}

function update(id: number, params: IPreRequisites){
    return null;
}

function _delete(id: number){
    
}