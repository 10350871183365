import React from "react";
import { FieldProps, FormikProps } from "formik";
import { Option } from "../../../shared/components/SelectField";
import { RichemontTable } from "../valuablesManagement/components";
import { SecuritySystemDevicesItem, EventPhaseItem } from "../interfaces";
import { UiAutocompleteField, UiTextField, UiSwitchField, UiTextareaField } from "../../../shared/components/fields";
import { UiSectionTitle } from "../../../shared/components/ui/UiSectionTitle";
import { Button } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Subscription } from "rxjs";
import { ReferencesService } from "../../../shared/services/referencesService";
import { ConditionnalField } from "../../../shared/components/ConditionnalField";
import { FIELD_IDS } from "../../../admin/components/FieldsMgmt/Fields.type";
import { MAX_TEXTFIELD_CHARS } from "../../../shared/constants";

interface Props extends FieldProps, FormikProps<any> {
    fieldVisibility: any;
}

interface State {
    listsize: number;
    subscriptions: Subscription[];
    authors: Option[];
    validators: Option[];
    authorsInputValue: string;
    validatorsInputValue: string;
    displaySurveillanceSubSection: boolean;
    displaysecurityStaffSubSection: boolean;
    displaySecurityConceptSubSection: boolean;
}

type Params = "authors" | "validators";

let timeout$: NodeJS.Timeout | null = null;
const eventPhasesList: EventPhaseItem[] = require("./securityAgents.json");
const originalList = require("./technicalSurveillanceSystem.json");

class SecuritySurveillanceSystem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            listsize:
                originalList.length > this.props.form.values.declaration.technicalSurveillanceSystem.securitySystemDevicesList
                    ? originalList.length
                    : this.props.form.values.declaration.technicalSurveillanceSystem.securitySystemDevicesList,
            subscriptions: [],
            authors: [],
            validators: [],
            authorsInputValue: "",
            validatorsInputValue: "",
            displaySurveillanceSubSection: false,
            displaysecurityStaffSubSection: false,
            displaySecurityConceptSubSection: false,
        };
    }

    componentDidMount() {
        const fieldsVisibility = this.props.fieldVisibility;
        const tempAuthors = this.props.form.values.declaration.securityConcept?.authors
            ? this.props.form.values.declaration.securityConcept.authors.map((item: any): Option => ({ itemContent: item.idAuthor, value: item.idAuthor }))
            : [];
        const tempValidators = this.props.form.values.declaration.securityConcept?.validators
            ? this.props.form.values.declaration.securityConcept.validators.map((item: any): Option => ({ itemContent: item.idValidator, value: item.idValidator }))
            : [];

        this.setState({
            authors: tempAuthors,
            validators: tempValidators,
        });
        Object.entries(FIELD_IDS.securityConcept).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                this.setState({
                    displaySecurityConceptSubSection: true
                })
            }
        });
        Object.entries(FIELD_IDS.securityStaff).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                this.setState({
                    displaysecurityStaffSubSection: true
                })
            }
        });
        Object.entries(FIELD_IDS.securitySurveillance).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                this.setState({
                    displaySurveillanceSubSection: true
                })
            }
        });
    }

    addNewDevice = (props: any) => {
        const newItem: SecuritySystemDevicesItem = {
            quantity: 0,
            installationCompany: "",
            securitySystemDevice: "",
        };

        const newList: SecuritySystemDevicesItem[] = this.props.form.values.declaration.technicalSurveillanceSystem.securitySystemDevicesList.slice();
        newList.push(newItem);

        props.form.setFieldValue("declaration.technicalSurveillanceSystem.securitySystemDevicesList", newList);
    };

    handleInputChange = (searchValue: string, param: Params) => {
        if (param === "authors") {
            this.setState({
                authorsInputValue: searchValue,
            });
        } else {
            this.setState({
                validatorsInputValue: searchValue,
            });
        }
        if (searchValue.length > 2) {
            if (timeout$) clearTimeout(timeout$);

            const localtimeout$ = setTimeout(() => {
                ReferencesService.getListUsers(searchValue).subscribe((usersList: string[]) => {
                    if (param === "authors") {
                        this.setState({
                            authors: this.props.form.values.declaration.securityConcept.authors.concat(
                                usersList.reduce((filtered: Option[], username: string) => {
                                    if (!this.props.form.values.declaration.securityConcept.authors.some((author: Option) => author.value === username)) filtered.push({ value: username, itemContent: username });

                                    return filtered;
                                }, [])
                            ),
                        });
                    } else {
                        this.setState({
                            validators: this.props.form.values.declaration.securityConcept.validators.concat(
                                usersList.reduce((filtered: Option[], username: string) => {
                                    if (!this.props.form.values.declaration.securityConcept.validators.some((author: Option) => author.value === username)) filtered.push({ value: username, itemContent: username });

                                    return filtered;
                                }, [])
                            ),
                        });
                    }
                });
            }, 500);

            timeout$ = localtimeout$;
        }
    };

    handleAuthorsInputChange = (searchValue: string) => {
        this.handleInputChange(searchValue, "authors");
    };

    handleValidatorsInputChange = (searchValue: string) => {
        this.handleInputChange(searchValue, "validators");
    }

    render() {
        return (
            <div className="security">
                {this.state.displaySecurityConceptSubSection && <section className="section">
                    <UiSectionTitle>
                        <h4>Security Concept</h4>
                    </UiSectionTitle>
                    <ConditionnalField
                        fieldId={FIELD_IDS.securityConcept.author}
                        label="Author(s)"
                        name={"declaration.securityConcept.authors"}
                        component={UiAutocompleteField}
                        onInputChange={this.handleAuthorsInputChange}
                        multiple={true}
                        inputValue={this.state.authorsInputValue}
                        options={this.state.authors.map((x) => ({ ...x, label: "" }))}
                        disabled={this.props.form.values.isReadOnly}
                    />
                    <ConditionnalField
                        fieldId={FIELD_IDS.securityConcept.validator}
                        label="Validator(s)"
                        name={"declaration.securityConcept.validators"}
                        component={UiAutocompleteField}
                        onInputChange={this.handleValidatorsInputChange}
                        multiple={true}
                        inputValue={this.state.validatorsInputValue}
                        options={this.state.validators.map((x) => ({ ...x, label: "" }))}
                        disabled={this.props.form.values.isReadOnly}
                    />
                </section>}
                {this.state.displaysecurityStaffSubSection && <section className="section">
                    <UiSectionTitle>
                        <h4>Security Staff deployment</h4>
                    </UiSectionTitle>
                    <ConditionnalField
                        fieldId={FIELD_IDS.securityStaff.securityDonyBy}
                        name={"declaration.securityStaffDeployment.security"}
                        label="Security done By"
                        component={UiTextField}
                        readOnly={this.props.form.values.isReadOnly}
                    />
                    <ConditionnalField
                        fieldId={FIELD_IDS.securityStaff.eventPhaseList}
                        name="declaration.securityStaffDeployment"
                        list={this.props.form.values.declaration.securityStaffDeployment.eventPhases.eventPhasesList}
                        listname="declaration.securityStaffDeployment.eventPhases.eventPhasesList"
                        lastrowname="eventPhases.total"
                        lastrownamesecondfield="eventPhases.totalGuardingCostEstimated"
                        lastrowlabel="Total"
                        otherfieldexists={false}
                        footer={null}
                        originallistsize={eventPhasesList.length}
                        showlastrow={true}
                        showlastrowsecondfield={true}
                        types={["view", "number", "amount", "amount", "number"]}
                        isreadonly={this.props.form.values.isReadOnly}
                        columnnumbertocalc={1}
                        columnnumbertocalc2={2}
                        columnstoignore={["id", "idEventPhase"]}
                        component={RichemontTable}
                    />

                    <ConditionnalField
                        fieldId={FIELD_IDS.securityStaff.hasSecurityService}
                        name={"declaration.securityStaffDeployment.hasSecurityService"}
                        label="Does the site have a security service providing staff ?"
                        disabled={this.props.form.values.isReadOnly}
                        uncheckWarning={false}
                        fieldToChange={"declaration.securityStaffDeployment.securityServiceDesc"}
                        component={UiSwitchField}
                        inline={true}
                    />

                    {this.props.form.values.declaration.securityStaffDeployment && this.props.form.values.declaration.securityStaffDeployment.hasSecurityService && (
                        <ConditionnalField
                            fieldId={FIELD_IDS.securityStaff.securityServiceDesc}
                            name="declaration.securityStaffDeployment.securityServiceDesc"
                            label="Description of the security service"
                            disabled={this.props.form.values.isReadOnly}
                            component={UiTextareaField}
                        />
                    )}

                    <ConditionnalField
                        fieldId={FIELD_IDS.securityStaff.securityStaffDeployment}
                        name="declaration.securityStaffDeployment.securityStaffDeployment"
                        label="Security Staff deployment for broker"
                        component={UiTextareaField}
                        disabled={this.props.form.values.isReadOnly}
                        placeholder="Security Staff deployment to send to broker…"
                        inputProps={{ maxLength: MAX_TEXTFIELD_CHARS }}
                    />
                </section>}
                {this.state.displaySurveillanceSubSection && <div>
                    <UiSectionTitle>
                        <h4>Technical Surveillance System</h4>
                    </UiSectionTitle>
                    <ConditionnalField
                        fieldId={FIELD_IDS.securitySurveillance.securitySystemDevicesList}
                        name="declaration.technicalSurveillanceSystem"
                        list={this.props.form.values.declaration.technicalSurveillanceSystem.securitySystemDevicesList}
                        listname="declaration.technicalSurveillanceSystem.securitySystemDevicesList"
                        showlastrow={false}
                        types={["number", "view", "text", "amount"]}
                        otherfieldexists={true}
                        originallistsize={originalList.length}
                        isreadonly={this.props.form.values.isReadOnly}
                        columnstoignore={["id", "idSecuritySystemDevice"]}
                        component={RichemontTable}
                        footer={
                            <Button onClick={() => this.addNewDevice(this.props)} variant="contained" color="primary"
                                    size="small" startIcon={<AddCircleOutlineIcon />}>
                                Add new device
                            </Button>
                        }
                    />

                    <ConditionnalField
                        fieldId={FIELD_IDS.securitySurveillance.hasSecurity}
                        name={"declaration.technicalSurveillanceSystem.hasSecurityService"}
                        label="Does the site have a security service providing technical surveillance ?"
                        disabled={this.props.form.values.isReadOnly}
                        uncheckWarning={false}
                        fieldToChange={"declaration.technicalSurveillanceSystem.securityServiceDesc"}
                        component={UiSwitchField}
                        inline={true}
                    />
                    {this.props.form.values.declaration.technicalSurveillanceSystem && this.props.form.values.declaration.technicalSurveillanceSystem.hasSecurityService && (
                        <ConditionnalField
                            fieldId={FIELD_IDS.securitySurveillance.securityServiceDesc}
                            name="declaration.technicalSurveillanceSystem.securityServiceDesc"
                            label="Description of the security service"
                            disabled={this.props.form.values.isReadOnly}
                            component={UiTextareaField}
                        />
                    )}

                    <ConditionnalField
                        fieldId={FIELD_IDS.securitySurveillance.technicalSurveillanceSystemInformation}
                        name="declaration.technicalSurveillanceSystem.technicalSurveillanceSystemInformation"
                        label="Technical Surveillance System information for broker"
                        component={UiTextareaField}
                        disabled={this.props.form.values.isReadOnly}
                        placeholder="Technical Surveillance System information to send to broker…"
                        inputProps={{ maxLength: MAX_TEXTFIELD_CHARS }}
                    />
                </div>}
            </div>
        );
    }
}

export default SecuritySurveillanceSystem;
