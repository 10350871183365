import { StringFormat } from '../helpers/HelpersFunc'

export const ValidatorNextActionComments = {
    NextActionCommentMustMandatory
};

let nextActionCommRules: string[] = [
    '50,5,32' // 50, Central Insurance, Pending C.I., Need more details
    , '53,22,32' // 53,Underwriter,Pending Underw.,Approve subject to conditions
];

function NextActionCommentMustMandatory(idEventFlow: number, idTask: number, idNextAction: number){
    let concatVars = StringFormat("{0},{1},{2}", idEventFlow.toString(), idTask.toString(), idNextAction?.toString());
    return nextActionCommRules.includes(concatVars);
}
