export enum UserRoles {
    localeventmanager = "localeventmanager",
    localsec = "localsec",
    maisonsec = "maisonsec", //TODO: to be validated on back the role name (NEW ROLE to be created)
    admin = "admin", //TODO: to be validated on back the role name (NEW ROLE to be created) - this will only have access to the admin page
    areasec = "areasec",
    broker = "broker",
    centralinsurance = "centralinsurance",
    hqsecurity = "hqsecurity",
    riskassessor1 = "riskassessor-1",
    riskassessor2 = "riskassessor-2",
    riskassessor3 = "riskassessor-3",
    riskassessor4 = "riskassessor-4",
    riskassessor5 = "riskassessor-5",
    underwriter = "underwriter",
    director = "director",
    dashboard = "dashboard"
}

export enum UserRolesToDisplay {
    localeventmanager = "Local Event Manager",
    localsec = "Local Security Manager",
    maisonsec = "Maison Security Manager", //TODO: to be validated
    areasec = "Area Security Manager",
    hqsecurity = "HQ Security",
    broker = "Broker",
    centralinsurance = "Central Insurance",
    underwriter = "Underwriter",
    riskassessor = "Risk Assessor",
}

export enum UserGroup {
    administrator = 0,
    local_event_manager = 1,
    local_security_manager = 2,
    group_security_manager = 3,
    risk_assessor = 4,
    broker = 5,
    underwriter = 6,
    central_insurance = 7,
    region_security_manager = 8,
    maison_security_manager = 9,
    director = 11,
    dashboard = 12
}

export enum Maison {
    global = 1
}

export enum SecurityDevice {
    other = 6
}

export enum ValuableAsset {
    other = 7
}

export enum RiskLevel {
    zero = 1
}

export enum DocAccessType {
    "No Type" = 0,
    "Richemont only" = 1,
    "Security concept" = 2,
    "Visible to all" = 3,
    "Report assessment" = 4,
    "Underwriter" = 5,
}

export enum Flow {
    "None" = 0
    , "Draft" = 1
    , "Pending HQ" = 2
    , "Pending Local Sec" = 3
    , "Pending Broker" = 4
    , "Pending Underw." = 5
    , "Pending Risk A." = 6
    , "Escalated" = 7
    , "Closed" = 8
    , "Accepted" = 9
    , "Cancelled" = 10
    , "Pending C.I." = 11
    , "Pending Region" = 12
    , "Pending local event" = 13
}

export enum Action {
    Main_Action = 1,
    Save_Action = 2,
    Send_to_Broker = 3,
    Edit_Event = 4,
    Secondary_action = 5,
    Save_and_close = 6,
    Ask_for_Risk_Assessment = 7,
    Ask_for_Underwritter = 8,
    Send_to_Region = 9,
    Recall_Event = 10,
    Cancel = 11
}

export enum TaskMappingToAction {
    "Send to security" = Action.Main_Action,
    "Save for later" = Action.Save_Action,
    "Send to region" = Action.Main_Action,
    "Send to HQ" = Action.Main_Action,
    "Need more details" = Action.Secondary_action,
    "More details local" = Action.Secondary_action,
    "More details region" = Action.Secondary_action,
    "More details maison" = Action.Secondary_action, 
    "Send to Broker" = Action.Send_to_Broker,
    "Escalate event" = Action.Secondary_action,
    "Confirm cover is available" = Action.Main_Action,
    "Ask for Underwriter" = Action.Ask_for_Underwritter,
    "Confirm no coverage" = Action.Secondary_action,
    "Update Event" = Action.Main_Action,
    "Reopen Event" = Action.Main_Action,
    "Recall Event" = Action.Recall_Event,
    "Ask for Risk Assessment" = Action.Ask_for_Risk_Assessment,
    "Appoint Risk Assessor" = Action.Ask_for_Risk_Assessment,
    "Notify the Underwriter" = Action.Main_Action,
    "Close" = Action.Secondary_action,
    "Ask for insurance" = Action.Secondary_action,
    "Approve" = Action.Main_Action,
    "Refuse" = Action.Secondary_action,
    "Approve subject to conditions" = Action.Secondary_action,
    "Ask central insurance" = Action.Secondary_action,
    "Save" = Action.Save_and_close,
    "Cancel event" = Action.Secondary_action,
    "Edit summary" = Action.Edit_Event,
    "Assign to me" = -1,
    "Create event",
    "Cancel" = Action.Cancel,
}

export enum ActivityType {
    Pieces_presentations_on_displays = 1,
    Boutique_activation = 2,
    Exhibition = 3,
    Private_viewings = 4,
    Gala_dinner = 5,
    Model_show = 6,
    Other = 7,
    OtherDescription = 0
}

export enum Task {
    Send_to_security = 1,
    Save_for_later = 2,
    Send_to_HQ = 3,
    Create_event = 4,
    Need_more_details = 5,
    Send_to_Broker = 6,
    Escalate_event = 7,
    Assign_to_me = 8,
    Confirm_cover_is_available = 9,
    Ask_for_Underwritter = 10,
    Confirm_no_coverage = 11,
    Update_Event = 12,
    Reopen_Event = 13,
    Ask_for_Risk_Assessment = 14,
    Notify_the_Underwriter = 15,
    Cancel_event = 16,
    Close = 17,
    ___ = 18,
    Ask_central_insurance = 19,
    Approve = 20,
    Refuse = 21,
    Approve_subject_to_conditions = 22,
    Send_to_Region = 23,
    More_details_local = 24,
    More_details_region = 25,
    More_details_maison = 26,
    Recall_event = 27
}

export type StatusType = 'new' | 'draft' | 'pendinghq' | 'pendingbroker' | 'pendingrisk' | 'cancelled' | 'closed' | 'accepted' | 'escalated' | 'pendingci' | 'pendingregion';

export enum NotificationType {
    info = 1,
    success = 2,
    warning = 3,
    error = 4
}

export enum LocationNamingRsult {
    declaration = "declaration",
    event = "event",
    event_readonly = "event/readonly",
    dashboard = "dashboard",
    none = "none"
 }

 
export enum DocumentActions {
    Upload = "upload",
    Download = "download",
    Delete = "delete"
}

export enum LocationType {
    Richemont_boutique = 1,
    Richemont_Location = 2,
    Non_richemont_location = 3
}