import ReactQuill, {Range} from "react-quill";
import {useRef, useState} from "react";
import React from "react";
import 'react-quill/dist/quill.snow.css';
import {getFieldData} from "../fields/UiFieldHelpers";
import FormControl from "@material-ui/core/FormControl";
import UiFieldWrapper from "../fields/UiFieldWrapper";
import UiBaseField from "../fields/UiBaseField";
import "./RichTextEditor.scss"
import {FormHelperText} from "@material-ui/core";

type RichTextEditorProps = UiBaseField & {
    onFocus?: any,
    onBlur?: any,
    label: string,
    onSelection?: (selection: Range) => void
}
export const RichTextEditor = (props: RichTextEditorProps) => {

    const [selectionQuill, setSelectionQuill] = useState<Range>();
    const {error, name, value, onChange} = getFieldData(props);
    const quillRef = useRef<ReactQuill>(null);

    const handleQuillFocus = () => {
        if (props.onFocus) {
            const target = {
                selectionStart: selectionQuill?.index,
                selectionEnd: ((selectionQuill?.index || 0) + (selectionQuill?.length || 0)),
                text: () => value,
                focus: () => quillRef.current?.focus(),
                insertText: (index: number, text: string) => quillRef.current?.editor?.insertText(index, text),
                name: name
            };
            props.onFocus({target: target});
        }
    }
    const handleChangeSelection = (selection: Range) => {
        setSelectionQuill(selection);
        if (props.onSelection && selection !== null) {
            props.onSelection(selection);
        }
    }


    return (
        <UiFieldWrapper name="ui-rich-text-editor" {...props}>

            <div className="ui-field__label label">
                {props.label}
            </div>
            <span className="ui-rich-text-editor-container">
                <FormControl fullWidth variant="outlined">
                    <ReactQuill
                        theme="snow"
                        id="quillNotificationTemplateEditor"
                        ref={quillRef}
                        onBlur={props.onBlur}
                        onFocus={() => handleQuillFocus()}
                        onChangeSelection={(selection) => handleChangeSelection(selection)}
                        value={value}
                        onChange={onChange(name)}
                    />
                    {error.hasError && (
                        <FormHelperText error={error.hasError}>{error.errorMessage}</FormHelperText>
                    )}
                </FormControl>
            </span>
        </UiFieldWrapper>
    );
}