import { Box, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { AdministrationContextProvider } from './AdministrationContext';
import { AttributsMgmt } from './components/AttributsMgmt/AttributsMgmt';
import { FieldsMgmt } from './components/FieldsMgmt/FieldsMgmt';
import { NotificationsMgmt } from './components/NotificationsMgmt/NotificationsMgmt';
import { ThresholdMgmt } from './components/ThresholdMgmt/ThresholdMgmt';
import { UsersMgmt } from './components/UserMgmt/UserMgmt';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel (props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: 3, margin: 20 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
      id: `admin-tab-${index}`,
      'aria-controls': `admin-tabpanel-${index}`,
    };
  }

export const Administration = function() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    };
  
    return (
        <AdministrationContextProvider>
          <Box style={{ width: '100%' }} id="administration-nav">
            <Box style={{ borderBottom: 1, borderBottomStyle: "solid", borderBottomColor: "lightgray" }}>
              <Tabs value={value} onChange={handleChange} aria-label="admin tabs">
                <Tab label="Users" {...a11yProps(0)} />
                <Tab label="Attributes" {...a11yProps(1)} />
                <Tab label="Notifications" {...a11yProps(2)} />
                <Tab label="Fields" {...a11yProps(3)} />
                <Tab label="Threshold" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <UsersMgmt />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AttributsMgmt />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <NotificationsMgmt />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <FieldsMgmt />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ThresholdMgmt />
            </TabPanel>
          </Box>
        </AdministrationContextProvider>
    );
  }