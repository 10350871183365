import { AxiosRequestConfig } from "axios";
import { catchError, map } from "rxjs/operators";
import { baseCreateNotification } from "../helpers/HelpersFunc";
import { NotificationType } from "../models/enums";

import CustomAxios from "../helpers/AxiosHelper";
import { throwError } from "rxjs";

export class BaseService {
    static doRequest(options: AxiosRequestConfig) {
        return CustomAxios.request(options).pipe(
            map((res) => {
                return res.data.model;
            }),
            catchError((e) => {
                if (e && e.data && e.data.errorMessage) {
                    baseCreateNotification(NotificationType.error, "Error", e.data.errorMessage);
                }
                return throwError(e);
            })
        );
    }
}