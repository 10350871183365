import React, { ReactNode } from 'react'
import { FieldId } from '../../admin/components/FieldsMgmt/Fields.type'
import { useAuthContext } from '../contexts/AuthContext'

interface ConditionalChildrenProps {
    fieldId: FieldId
    children : ReactNode
}

const ConditionalChildren : React.FunctionComponent<ConditionalChildrenProps> = (props :ConditionalChildrenProps) => {
    const {fieldId} = {...props}
    const {fieldsVisibility} = useAuthContext();

    if(fieldsVisibility[fieldId]){
        return <>{props.children}</>
    }else{
        return <></>
    }
}

export default ConditionalChildren;