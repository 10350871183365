import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Popper, Grow, ClickAwayListener, MenuList, MenuItem, Paper } from '@material-ui/core';
import './UiSplitButton.scss'

type PropsUiSplitButton = {
    actions: UiSplitButtonAction[]
    onClick?(event: any, actionName: any): void
    onChange?(event: any, actionName: string): void
    default: number
    disabled?: boolean
}

type UiSplitButtonAction = {
    name: string
    label: string
}

export const UiSplitButton = (props: PropsUiSplitButton) => {

    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex ] = useState<number>(props.default)

    const handleClick = (event: any) => {
        if(props.onClick){
            props.onClick(event, props.actions[selectedIndex].name);
        }
    }
    const handleToggle = (event:any) =>{
        setOpen(!open);
    }

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
      ) => {
        setSelectedIndex(index);
        setOpen(false);
        if(props.onChange){
            props.onChange(event, props.actions[index].name );
        }
      };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
          return;
        }

        setOpen(false);
    };



    return (
        <div onClick={(e : any) => e.stopPropagation()}>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                className="ui-splitbutton"
                disableElevation={true}
                disabled={props.disabled}
            >
                <Button
                    className="ui-splitbutton__action"
                    onClick={handleClick}
                    disabled={props.disabled}
                >
                    {props.actions[selectedIndex].label}
                </Button>
                {  props.actions.length > 1 &&
                    <Button
                        className="ui-splitbutton__icon"
                        size="small"
                        onClick={handleToggle}
                        disabled={props.disabled}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                }
            </ButtonGroup>
            <Popper className="ui-splitbutton__popper" open={open} anchorEl={anchorRef.current} role={undefined} transition >
            {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
            >
                <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                    {props.actions.map((action, index) => (
                        <MenuItem
                        key={ index }
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        >
                        {action.label}
                        </MenuItem>
                    ))}
                    </MenuList>
                </ClickAwayListener>
                </Paper>
            </Grow>
            )}
        </Popper>
       </div>
    )
}

export default UiSplitButton
