import React, { ComponentProps } from "react";
import { Field, GenericFieldHTMLAttributes } from "formik";
import { useAuthContext } from "../contexts/AuthContext";
import { FIELD_IDS } from "../../admin/components/FieldsMgmt/Fields.type";
import UiCollapsable from "./ui/UiCollapsable";
import EventDescription from "../../declaration/declarationNewEdit/EventDescription";


type ConditionnalFieldProps<Component extends React.FC<any>> = {
    component: Component,
} & GenericFieldHTMLAttributes & ComponentProps<Component>

export const ConditionnalSection= <T extends  React.FC<any>>({...props} : ConditionnalFieldProps<T> & {section: string}) => {
    const {fieldsVisibility} = useAuthContext();
    const {section, ...rest} = props as unknown as any;
    let display = false;
    if (section === 'eventDescription') {
        Object.entries(FIELD_IDS.eventDescription).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                display = true;
            }
        });
        if(display){
            return (
                <UiCollapsable title="Event Description">
                    <EventDescription
                        {...rest}
                    />
                </UiCollapsable>)
        }else{
            return <></>
        }

    } else if (section === 'valuablesManagement') {
        Object.entries(FIELD_IDS.valuablesAssets).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                display = true;
            }
        })
        Object.entries(FIELD_IDS.routingAndTransfer).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                display = true;
            }
        })
        Object.entries(FIELD_IDS.movementAndStorage).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                display = true;
            }
        })

        if(display){
            return (
                <UiCollapsable title="Valuables Management">
                    <Field
                        {...rest}
                        fieldVisibility={fieldsVisibility}
                    />
                </UiCollapsable>)
        }else{
            return <></>
        }
    } else {
        Object.entries(FIELD_IDS.securityConcept).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                display = true;
            }
        })
        Object.entries(FIELD_IDS.securitySurveillance).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                display = true;
            }
        })
        Object.entries(FIELD_IDS.securityStaff).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                display = true;
            }
        })
        if(display){
            return (
                <UiCollapsable title="Security &amp; Surveillance System">
                    <Field
                        {...rest}
                        fieldVisibility={fieldsVisibility}
                    />
                </UiCollapsable>)
        }else{
            return <></>
        }
    }
}