import { Button, Tooltip } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { UiBoldNoShift } from "../../../shared/components/ui/UiBoldNoShift";
import UiBox from "../../../shared/components/ui/UiBox";
import { UiLoadingRows } from "../../../shared/components/ui/UiLoadingTable/UiLoadingRows";
import { ISecurityConcept } from "../../../shared/models/interfaces";
import { SecurityConceptService } from "../../../shared/services/mock.securityConcept.service";
import { INewEvent } from "../../interface";
import { SecurityConceptModal } from "./SecurityConceptModal";

export type SecurityConceptTableProps = {
    event: INewEvent;
    reloadFiles : () => void;
    securityConcepts : ISecurityConcept[];
    loadConcepts : () => void;
    securityConceptsLoading: boolean;
    onReject: () => void
};

export const SecurityConceptTable: FC<SecurityConceptTableProps>=({event,reloadFiles,securityConcepts,loadConcepts,securityConceptsLoading, onReject }) => {
    const [currentItem, setCurrentItem] = useState<ISecurityConcept>();

    const headers = ["Maison", "Version", "Status", "Author", "Validator", "Reviewer", "Actions"];
    const eventCreatedBy = event.createdBy || "";

    const securityConceptsMaster: ISecurityConcept[] = useMemo(() => {
        //return securityConcepts.filter((s) => s.master)
        const items: ISecurityConcept[] = [];
        securityConcepts
            .sort((a, b) => b.version - a.version)
            .forEach((i) => {
                if (!items.find((it) => it.maison.id === i.maison.id)) {
                    items.push(i);
                }
            })
        return items;
    }, [securityConcepts]);

    const openModal = useCallback((item: ISecurityConcept) => {
        setCurrentItem(item);
    }, []);

    const currentItems = useMemo(() => {
        if (!currentItem) return [];
        return securityConcepts.filter((s) => s.maison.id === currentItem.maison.id);
    }, [currentItem, securityConcepts]);


    useEffect(() => {
        const subscription = SecurityConceptService.securityConceptBus.subscribe((data) => {
            loadConcepts();
        });
        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadConcepts]);

    useEffect(() => {
        loadConcepts();
    }, [event]);

    const handleModalClose = useCallback(() => {
        setCurrentItem(undefined);
    }, []);

    return (
        <UiBox title="Security Concept" id="security-concept-table" >
            <div className="overflowing">
                <table className="security-concept-table">
                    <thead>
                    <tr>
                        {headers.map((header) => (
                            <th key={`header-${header}`}>{header}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {securityConceptsLoading ?
                        <UiLoadingRows rowCount={3} columnCount={headers.length}/> :
                        securityConceptsMaster.map((item, index) => (
                            <tr key={`${item.id}-${event.id}`} onClick={() => openModal(item)}>
                                <td>
                                    <UiBoldNoShift>{item.maison.maison}</UiBoldNoShift>
                                </td>
                                <td>
                                    <UiBoldNoShift>{item.version}</UiBoldNoShift>
                                </td>
                                <td>
                                    <UiBoldNoShift>{item.status}</UiBoldNoShift>
                                </td>
                                <td>
                                    <UiBoldNoShift>{item.author?.name}</UiBoldNoShift>
                                </td>
                                <td>
                                    <UiBoldNoShift>{item.validator?.name ?
                                        <Tooltip title={item.validator.name}><Check/></Tooltip> : ""}</UiBoldNoShift>
                                </td>
                                <td>
                                    <UiBoldNoShift>{item.reviewer?.name ?
                                        <Tooltip title={item.reviewer.name}><Check/></Tooltip> : ""} </UiBoldNoShift>
                                </td>
                                <td>
                                    <Button className={`security-concept-edit--${index + 1}`}
                                            onClick={() => openModal(item)}>
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <SecurityConceptModal onUpdate={loadConcepts} onClose={handleModalClose} items={currentItems}  reloadFiles={reloadFiles} eventCreatedBy={eventCreatedBy}  onReject={onReject}/>
        </UiBox>
    );
};
