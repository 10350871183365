import { Button } from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import React, { ReactNode } from "react";
import makeAnimated from "react-select/animated";
import { IPropsUiModalBase, UiModal } from "./UiModal";
import Select from 'react-select';
import { ReferencesService, Role } from "../../../services/referencesService";
import { SelectItem } from "../../../models/interfaces";
import { UserGroup } from "../../../models/enums";

type IPropsUiModal = IPropsUiModalBase & {
    send?: (value: string) => void
    onClose?: () => void
    role: UserGroup
}

interface IModalRiskAssessor {
    selectedRole: any;
    role: string;
}

const animatedComponents = makeAnimated();

export const UiModalRiskAssessorOrUnderwritter = (props: IPropsUiModal) => {
    const InitialValues: IModalRiskAssessor = {
        selectedRole: '',
        role: props.role === UserGroup.underwriter ? 'Underwriter' : 'Risk Assessor'
    }

    const [roles, setRoles] = React.useState<SelectItem[]>([]);
    
    const submit = (values : any) => {
    }

    const onClose = (func: () => void) => {
        if(props.onClose) {
            func();
            props.onClose();
        }
    }

    const send = (value: string) => {
        if(props.send) {
            props.send(value);
        }
    }
    
    const footerButtons: ReactNode[] = [];

    React.useEffect(() => {
        const res = ReferencesService.getAllRoles(props.role).subscribe((roles: Role[]) => {

            if (!roles?.length) {
                return;
            }

            const roletemp: SelectItem[] = roles[0].targetEmail.map(user => {
                return {
                    value: user,
                    label: user
                };
            });
            setRoles(roletemp);
        });
        return () => {
            res.unsubscribe();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <UiModal
            title={`Fill the informations to send to ${InitialValues.role}`} 
            open={props.open}
            onClose={props.onClose}
            footerButtons={footerButtons}
        >
            <Formik
                initialValues={InitialValues}
                onSubmit={submit}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleReset,
                    setFieldValue
                }: FormikProps<any>) => {

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <Select
                                    id={'userid'}
                                    name={'userid'}
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    components={animatedComponents}
                                    options={roles}
                                    value={values.selectedRole ?? ''}
                                    onChange={(value:any) => {
                                        setFieldValue('selectedRole', value);
                                    }}
                                />
                            </div>
                            <div className="ui-modal__footer ui-modal__footer--buttons">
                                <Button onClick={() => onClose(handleReset)} variant="outlined" color="primary">Cancel</Button>
                                <Button onClick={() => send(values.selectedRole.label)} variant="contained" color="primary" disabled={values.selectedRole === ''}>Ask for {InitialValues.role}</Button>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </UiModal>
    );
}

export default UiModalRiskAssessorOrUnderwritter;