import React, { ReactNode, ReactChild, ReactChildren } from "react"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type IPropsUiCollapsable = {
    name? : string,
    children: ReactChild | ReactChildren,
    title: string | ReactNode
    number? : number,
}
export const UiCollapsable = (props: IPropsUiCollapsable) => {
    const cssClass = ['ui-collapsable']
    if(props.name){
        cssClass.push('ui-collapsable--'+props.name)
    }
    return(
        <Accordion defaultExpanded={true} className={cssClass.join(' ')} elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <h3>{props.title}</h3>
            </AccordionSummary>
            <AccordionDetails>
                <div className="box-fullwidth">
                {props.children}
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

export default UiCollapsable;