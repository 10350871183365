import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

export enum EAlertSeverity {
    Success = "success",
    Error = "error",
    Warning = "warning",
}

export interface IStatusAlertProps {
    severity: EAlertSeverity;
    title: string;
    message: string;
}

const StatusAlert = ({ severity, title, message }: IStatusAlertProps) => {
    console.log("StatusAlert Props:", { severity, title, message });

    return (
        <Alert severity={severity}>
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    );
};

export default StatusAlert;
