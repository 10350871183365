import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import ScrollToTop from './shared/components/scrollToTop/ScrollToTop'

import { ThemeWrap } from "./uiTheme";

import './index.css';
import './assets/scss/styles.scss';
import App from './App';
import {history} from "./routes/RouterHistory";

ReactDOM.render(
  // <React.StrictMode>
  <Router history={history}>
    <ThemeWrap>
      <ScrollToTop />
      <App />
    </ThemeWrap>
  </Router>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
