import React, { useEffect, useState, useCallback } from "react";
import { TProcessResponseData } from "./ImportResponseData.config";
import { EAlertStatus, TDuplicateEvent } from "../../../shared/services/massImportLVL1/massImportService";
import { Box, Checkbox, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";

interface IProcessedDataDisplayProps {
    data: TProcessResponseData | null;
    onDuplicateSelection: (selectedEvents: TDuplicateEvent[]) => void;
}
interface IGroupedDuplicates {
    [key: string]: {
        events: TDuplicateEvent[];
        selected: number | null;
    };
}

const ImportedDataDisplay: React.FC<IProcessedDataDisplayProps> = ({ data, onDuplicateSelection }) => {
    const [groupedDuplicates, setGroupedDuplicates] = useState<IGroupedDuplicates>({});

    useEffect(() => {
        if (data?.status === EAlertStatus.Duplicate) {
            const grouped = data.duplicateEvents.reduce((acc, event) => {
                const key = event.groupId ?? "ungrouped";
                if (!acc[key]) {
                    acc[key] = { events: [], selected: null };
                }
                acc[key].events.push(event);
                return acc;
            }, {} as IGroupedDuplicates);
            setGroupedDuplicates(grouped);
        }
    }, [data]);

    const handleCheckboxChange = useCallback(
        (groupKey: string, excelRowNumber: number) => {
            setGroupedDuplicates((prev) => {
                const updatedGroup = {
                    ...prev[groupKey],
                    selected: prev[groupKey].selected === excelRowNumber ? null : excelRowNumber,
                };
                const newGroupedDuplicates = {
                    ...prev,
                    [groupKey]: updatedGroup,
                };

                // Calculate selected events here
                const selectedEvents = Object.values(newGroupedDuplicates)
                    .map((group) => group.events.find((event) => event.excelRowNumber === group.selected))
                    .filter((event): event is TDuplicateEvent => event !== undefined);

                // Call onDuplicateSelection with the new selection
                onDuplicateSelection(selectedEvents);

                return newGroupedDuplicates;
            });
        },
        [onDuplicateSelection]
    );

    const formatDate = useCallback((dateString: string) => {
        const date = new Date(dateString);
        return format(date, "dd MMM yyyy");
    }, []);

    if (!data) return null;

    const containerModifiers =
        data.status === EAlertStatus.Error ? "processed-data--error" : "processed-data--duplicate";

    return (
        <section className="modal-import-lvl1__processed-data">
            <div className={`processed-data ${containerModifiers}`}>
                {data.status === EAlertStatus.Error && (
                    <>
                        <h4>{data.errorsCount} errors have been spotted</h4>
                        <ul>
                            {data.eventsWithError.map((error, index) => (
                                <li key={index}>
                                    Line {error.excelRowNumber}: {error.customErrorMessage}
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {data.status === EAlertStatus.Duplicate && (
                    <>
                        <Typography variant="body1" style={{ marginBottom: "16px" }}>
                            An event is considered as duplicate if at least 3 of the following values are identical to
                            another existing event or another line in the import file : Country, Maison, Start Date, End
                            Date, Values (Cost in Euro).
                        </Typography>
                        {Object.entries(groupedDuplicates).map(([groupKey, group], groupIndex) => (
                            <Box key={groupKey} className="duplicate_group">
                                {group.events.map((event) => (
                                    <Box
                                        key={event.excelRowNumber}
                                        p={1}
                                        display="flex"
                                        alignItems="center"
                                        className={`duplicate_group__item ${
                                            group.selected === event.excelRowNumber ? "duplicate_group__selected" : ""
                                        }`}
                                    >
                                        <Checkbox
                                            color="primary"
                                            checked={group.selected === event.excelRowNumber}
                                            onChange={() => handleCheckboxChange(groupKey, event.excelRowNumber)}
                                        />
                                        <Box ml={2}>
                                            <Typography variant="body2">Line {event.excelRowNumber}</Typography>
                                            <Typography variant="body1">
                                                {event.eventName}, {formatDate(event.fromDate)} -{" "}
                                                {formatDate(event.toDate)}
                                            </Typography>
                                            <Typography variant="body2">
                                                {event.leadMaison}, {event.country}, {event.eventType}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                                {groupIndex < Object.keys(groupedDuplicates).length - 1 && <Divider />}
                            </Box>
                        ))}
                    </>
                )}
            </div>
        </section>
    );
};

export default ImportedDataDisplay;
