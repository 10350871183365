import { AuthResponse, Configuration, UserAgentApplication } from 'msal';
import SessionStorageTokenService, { SessionStorageToken } from '../../helpers/SessionStorageTokenService';
import { AuthAppResponse, loginRequestParam, msalConfig, tokenRequestParam } from './authentificationConfig';

class AuthenficationCustom {

   private MSAL: UserAgentApplication;
   private signInResponse : AuthAppResponse;

   constructor(configuration: Configuration) {
      this.MSAL = new UserAgentApplication(configuration);
      this.signInResponse = { isError : false, errorMsg : null, response : null, token: null };
   }

   storeToken = (response: AuthAppResponse) : SessionStorageToken => {
      let tokenObj: SessionStorageToken = { 
         AccessToken : (response.response?.idToken.rawIdToken as string), 
         RefreshToken : null,
         UserName:  (response.response?.idToken.claims.userName as string),
         UserFullName: (response.response?.idToken.claims.name as string),
         UserRoles: (response.response?.idToken.claims.roles as string),
       };
      return tokenObj
   }

   createResponse = (response: AuthResponse|null = null, isError: boolean = false, errorMsg: string|null = null) => {
      this.signInResponse.isError = isError;
      this.signInResponse.errorMsg = errorMsg;
      this.signInResponse.response = response;
      if(isError === false){
         this.signInResponse.token = this.storeToken(this.signInResponse);
      }
      return this.signInResponse;
   }

   signIn2 = async (params: any) : Promise<void> => {
      await this.signIn();
   }

   signIn = async () : Promise<void> => {
      this.MSAL.loginRedirect(loginRequestParam);
   }

   refreshToken = async () : Promise<AuthAppResponse> => {
      tokenRequestParam.forceRefresh = true;
      return this.MSAL.acquireTokenSilent(tokenRequestParam)
      .then((serviceResponse) => {
         return this.createResponse(serviceResponse);
      }).catch((error) => {
         return this.createResponse(null, true, error);
      });
   }

   logOut() {
      this.MSAL.logout();
      SessionStorageTokenService.clearToken();
      SessionStorageTokenService.clearFilters();
   }

   getAccount() {
      return this.MSAL.getAccount()
   }
}

const AuthenficationService = new AuthenficationCustom(msalConfig)

export default AuthenficationService;