import { EAlertStatus, TMassImportResponse } from "../../../shared/services/massImportLVL1/massImportService";

export type TProcessResponseData = TMassImportResponse;

/**
 * Process the API response for the mass import and returns a structured response.
 * @param response The raw response data from the API.
 * @returns Structured import response.
 */
export function processResponseData(response: TMassImportResponse | null): TProcessResponseData {
    if (!response) {
        return {
            status: EAlertStatus.Unknown,
            statusCode: "500",
            message: "An internal server error occurred.",
            importedFileId: null,
            newEventsCount: 0,
            errorsCount: 0,
            duplicatesCount: 0,
            eventsWithError: [],
            duplicateEvents: [],
        };
    }

    return response;
}
