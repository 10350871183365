import React, { useEffect } from 'react';
import {UiBaseField} from './UiBaseField';
import {UiFieldWrapper} from './UiFieldWrapper';
import { RadioGroup, FormControlLabel, Radio, InputLabel, FormHelperText } from '@material-ui/core';
import {getFieldData} from './UiFieldHelpers';
export type UiRadioFieldOption = {
    value: string,
    label: string
}
type IPropsUiRadioField = UiBaseField & {
    options : Array<UiRadioFieldOption>
}
export const UiRadioField = (props: IPropsUiRadioField) => {
    const {field} = props;
    let {error, name, value} = getFieldData(props);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected((event.target as HTMLInputElement).value);
        props.onChange ? props.onChange(event, field) : field.onChange(event)
    };
    const [selected, setSelected] = React.useState(value);
    useEffect(() => setSelected(value), [value]);

    return (
        <React.Fragment>
            <UiFieldWrapper name="ui-radio-field" {...props}>
                <InputLabel error={error.hasError} className="ui-field__label">
                    {props.label}
                </InputLabel>
                <RadioGroup 
                    row 
                    name={name}
                    value={selected ? selected.toString() : null} 
                    onChange={handleChange}
                    >
                    {props.options.map((option, index)=>(
                        <FormControlLabel 
                            value={option.value.toString()} 
                            control={<Radio />} 
                            label={option.label} 
                            labelPlacement="end"  
                            key={field.name + '-' +index}
                        />
                    ))}
                </RadioGroup>
            </UiFieldWrapper>
            {error.hasError && (
                <FormHelperText style={{margin: "-10px 20px 0px"}} error={error.hasError}>{ error.errorMessage }</FormHelperText>
            )}
        </React.Fragment>
    )
}