import React, { useEffect } from "react"
import {getComparator} from './UiTableHelper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableSortLabel } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {UiTableRowSkeleton} from './UiTableRowSkeleton';
import {IPropsUiTable, IStateOrderBy, IStateOrder} from './types'
import {UiTableCell} from './UiTableCell'
import {UiPagination} from './UiTablePagination'
import {PagedRequest, PagedResponse} from "../../../models/interfaces";
import {IAdministrationTableHead} from "../../../../admin/components/CommonMgmt/AdministrationTable";

export const UiTableWithPaginationBack = (props: IPropsUiTable & PagedResponse<any>) => {
    const [order, setOrder] = React.useState<IStateOrder>();
    const [orderBy, setOrderBy] = React.useState<IStateOrderBy>();
    const [orderByColmunName, setOrderByColmunName] = React.useState<string>();
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(props.rowPerPages);
    const [page, setPage] = React.useState<number>(1);

    const {model, feedLists, tableHeads, onRowSelect, pageSize, pageCount, itemsCount, pageNumber} = props;

    useEffect(() => {
        setRowsPerPage(props.rowPerPages);
        setPage(1)
    }, [props.rowPerPages]);

    const pageRequest = (): PagedRequest => {
        return {
            pageSize: pageSize,
            pageNumber: page,
            orderColumn: orderByColmunName,
            sortOrder: order
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


    const toggleOrder = () => {
        const newOrder = order === 'asc' ? 'desc' : 'asc';
        setOrder(newOrder);

        return newOrder;
    }

    const sort = (headIndex: number, headName:string) => {

        let newOrder = 'asc';
        if (headIndex === orderBy) {
            setOrderBy(headIndex)
            newOrder = toggleOrder();
        } else {
            setOrder('asc')
            setOrderBy(headIndex)
        }
        setOrderByColmunName(headName);

        if (props.sortColumnHandler) {
            props.sortColumnHandler({headIndex: headIndex, order: newOrder});
        }
    }

    const createSortHandler = (headIndex:number, headName:string) => (event: React.MouseEvent<unknown>) => {
        sort(headIndex, headName);
    }

    useEffect(() => {
        if (props.sort) {
            setOrder(props.sort.order);
            setOrderBy(props.sort.headIndex);
        }

    }, [props.sort]);

    useEffect(() => {
        setPage(pageNumber);
    }, [pageNumber]);

    useEffect(() => {

        if(props.handlePageChange){
            props.handlePageChange(pageRequest());
        }

    }, [page, orderBy, order]);

    useEffect(() => {
        if(props.sorted && props.sortColumn !== undefined) {
            sort(props.sortColumn, (tableHeads[props.sortColumn] as IAdministrationTableHead).name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    const loadingClass = props.loading ? 'ui-table--loading' : 'ui-table--loaded';
    return(
        <>
        <Table size="small" className={`ui-table fixed ${props.className} ${loadingClass}`}>
            <TableHead>
                <TableRow>
                    {tableHeads.map((head, index)=>(
                        <TableCell
                            style={head.cssProperties}
                            key={'headDashboard-'+index}
                            sortDirection={orderBy === index ? order : false}
                            className={'ui-tableheadcell ui-tableheadcell--'+head.name}
                        >
                            {head.sortable ?
                                <TableSortLabel
                                    onClick={createSortHandler(index, head.name)}
                                    active={orderBy === index}
                                    direction={orderBy === index ? order : 'asc'}
                                    IconComponent={ArrowDropDownIcon}
                                >
                                    {head.label}
                                </TableSortLabel>
                                :
                                <>{head.label}</>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {!props.loading ? 
                    model?.length ?
                        model.map((item:any, i:number) =>
                            <TableRow className={`ui-tablerow ${props.rowClasses ? props.rowClasses(item) : ""}`} key={'tablerow-'+i} onClick={() => onRowSelect ? onRowSelect(item) : null}>
                                {tableHeads.map((head, j:number) => <UiTableCell name="" key={'tablecell'+i+'-'+j} index={i} {...item} {...feedLists} onChange={props.onItemChange} head={head}></UiTableCell> )}
                            </TableRow>
                        )
                    :
                    <TableRow>
                        <TableCell colSpan={tableHeads.length} align="center">No item found</TableCell>
                    </TableRow>

                : <UiTableRowSkeleton nbRow={rowsPerPage} nbCell={tableHeads.length} />}

            </TableBody>
        </Table>
        <div className="ui-table__footer">
           <div><div></div></div>
            <div>
                <div className="ui-table__action">
                {props.action}
                </div>
            </div>
            <div>
                <div>
                    {Math.ceil(itemsCount / pageSize) > 1 && !props.loading && (
                        <UiPagination
                            page={page}
                            onChange={handleChangePage}
                            count={!props.loading ? pageCount  : 0}
                        />
                    )}
                </div>
            </div>

         </div>

    </>
    )
}