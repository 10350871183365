import { AxiosRequestConfig } from "axios";
import { Observable } from "rxjs";
import { serverUrlBase } from "../config/GlobalAppConfig";
import { ApiCallingMethods } from "../helpers/AxiosHelper";
import { BaseService } from "./base.service";

export class SnoozeService {
    static createSnooze(idEvent: number): Observable<any> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${serverUrlBase}/api/Events/SnoozeReminder/Add`,
            data: {
                "userEmail": "",
                "idEvent": idEvent,
                "duration": 99,
                "isActive": true
              },
        };
        return BaseService.doRequest(options);
    }
    static deleteSnooze(idEvent: number): Observable<any> {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.delete,
            url: `${serverUrlBase}/api/Events/SnoozeReminder/Delete/${idEvent}`,
            data: null
        };
        return BaseService.doRequest(options);
    }

}