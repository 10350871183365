import { StatusItemServer } from "../../event/interface";
import { Flow, UserRoles } from "../models/enums";
import { getEnumKeyByEnumValue } from "./HelpersFunc";
import { IAuthContext, useAuthContext } from "../contexts/AuthContext";

export function GetEventStatusLabel(statusItem: StatusItemServer[]) : string | undefined {
    const totalEventStatus = statusItem.length;
    return statusItem[totalEventStatus-1].status?.flow.itemContent;
}

export function GetIsEditFromEventStatus(statusItem: StatusItemServer[]) : boolean {
    let res: boolean = true;
    const resStatus: string | undefined = GetEventStatusLabel(statusItem);
    const  compVal: string | null = getEnumKeyByEnumValue(Flow, Flow.Cancelled);

    if(resStatus !== undefined && compVal !== null && resStatus.toLowerCase() === compVal.toLowerCase()){
        res = false;
    }
    return res;
}

export function CanEditClosedEvent( authContext: IAuthContext, statusItem?: StatusItemServer[]) : boolean {
    let res: boolean = false;
    if (!statusItem) {
        return res;
    }
    const resStatus: string | undefined = GetEventStatusLabel(statusItem);
    const compVal: string | null = getEnumKeyByEnumValue(Flow, Flow.Closed);

    if(resStatus !== undefined && compVal !== null && resStatus.toLowerCase() === compVal.toLowerCase() && authContext.role && authContext.role === UserRoles.hqsecurity){
        res = true;
    }
    return res;
}