import { TMassImportResponse } from "../../../shared/services/massImportLVL1/massImportService";
import { EAlertSeverity } from "./StatusAlert";
import type { IStatusAlertProps } from "./StatusAlert";

/**
 * Generates alert properties to be used in the UI based on the mass import response.
 * @param response The response from the mass import.
 * @returns Alert properties for UI display.
 */
export function getAlertProps(response: TMassImportResponse | null): IStatusAlertProps | null {
    if (!response) return null;

    const { message, status } = response;

    // Determine the severity of the alert based on the status
    const severity = (() => {
        switch (status) {
            case "success":
                return EAlertSeverity.Success;
            case "error":
                return EAlertSeverity.Error;
            case "duplicate":
                return EAlertSeverity.Warning;
            default:
                return EAlertSeverity.Error;
        }
    })();

    // Set an appropriate title based on the status
    const title = (() => {
        switch (status) {
            case "success":
                return "File successfully uploaded";
            case "error":
                return "Errors detected in file";
            case "duplicate":
                return "Duplicate entries found";
            default:
                return "An unknown error occurred";
        }
    })();

    return {
        severity,
        title,
        message: message || "An unknown error occurred.",
    };
}
