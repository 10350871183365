import React from 'react';
import LoadingContext from '../routes/LoadingContext';
import {HTTP_STATUS_UNAUTHORIZED} from "../shared/helpers/AxiosHelper";
import {history} from "../routes/RouterHistory";
import "./ErrorPage.scss"

function ErrorPage() {
    const ctx = React.useContext(LoadingContext);

    React.useEffect(() => {
        ctx.stopLoading();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTitle = () => {
        const state = history.location.state as any;
        const status = state?.detail as number || -1;

        switch (status) {
            case HTTP_STATUS_UNAUTHORIZED:
                return "Access Denied";
            default:
                return "Something gone wrong"
        }
    }

    return (
        <div className="error-container">
            <h1 className="error-title">{getTitle()}</h1>
            <h4>Please contact an administrator</h4>
        </div>
    )
}

export default ErrorPage;