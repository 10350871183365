import React, { MouseEvent, useRef, useState } from "react";
import { UiModal, IPropsUiModalBase, EUiModalReason } from "../../../shared/components/ui/UiModal/UiModal";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import "./ModalImportLVL1.scss";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import StatusAlert from "./StatusAlert";
import { getAlertProps } from "./statusAlert.config";
import FooterModalImportLVL1 from "./FooterModalImportLVL1";
import {
    EAlertStatus,
    TDuplicateEvent,
    TMassImportResponse,
} from "../../../shared/services/massImportLVL1/massImportService";
import { processResponseData, TProcessResponseData } from "./ImportResponseData.config";
import ImportedDataDisplay from "./ImportedDataDisplay";

interface IModalImportLVL1Props extends IPropsUiModalBase {
    onImport: (file: File) => Promise<TMassImportResponse>;
    onDuplicateSelection: (selectedEvents: TDuplicateEvent[]) => Promise<TMassImportResponse>;
}

const ModalImportLVL1: React.FC<IModalImportLVL1Props> = ({
    onImport,
    onDuplicateSelection,
    onClose,
    open,
    ...rest
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [importResponse, setImportResponse] = useState<TMassImportResponse | null>(null);
    const [processedData, setProcessedData] = useState<TProcessResponseData | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImport = async (file: File) => {
        setIsLoading(true);
        setError(undefined);

        try {
            const response = await onImport(file);
            console.log("Import Response:", response);
            setImportResponse(response);
            const processed = processResponseData(response);
            setProcessedData(processed);
        } catch (error) {
            console.error("Error during import:", error);
            setError("An error occurred during import. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDuplicateSubmit = async (selectedEvents: TDuplicateEvent[]) => {
        setIsLoading(true);
        setError(undefined);

        try {
            const response = await onDuplicateSelection(selectedEvents);
            console.log("Duplicate events added:", response);
            if (response.status === EAlertStatus.Success) {
                onClose?.({}, EUiModalReason.ApplyFilter);
            } else {
                setError(`Import completed with status: ${response.status}. ${response.message}`);
            }
        } catch (error) {
            console.error("Error adding duplicate events:", error);
            setError("An error occurred while adding duplicate events. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = (event: {} | MouseEvent<HTMLElement> | KeyboardEvent, reason: EUiModalReason) => {
        // Using optional chaining for a more concise check
        onClose?.(event, reason);

        if (reason === EUiModalReason.TryAgainBtnClickj || reason === EUiModalReason.Import) {
            setImportResponse(null);
            setProcessedData(null);
            setError(undefined);
        }
    };

    const alertProps = getAlertProps(importResponse);
    const footerstatusResponse = importResponse?.status ?? null;

    return (
        <UiModal
            title="Import LV1 events only"
            open={open}
            onClose={handleClose}
            footerButtons={[
                <FooterModalImportLVL1
                    statusResponse={footerstatusResponse}
                    onClose={handleClose}
                    setImportResponse={setImportResponse}
                    setResponseData={setProcessedData}
                />,
            ]}
            className="modal-import-lvl1"
            {...rest}
        >
            {alertProps ? <StatusAlert {...alertProps} /> : null}

            {importResponse === null ? (
                <>
                    <section>
                        <p>
                            This feature enables to import a batch of LV1 events. To use it, upload an Excel file.
                            Please download and use the provided template.
                        </p>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<GetAppIcon />}
                            className="modal-import-lvl1__download-button"
                            href="/documents/template_events_import.xlsx"
                        >
                            Download Template (.xls)
                        </Button>
                    </section>
                    <DragAndDrop
                        onFileDrop={handleImport}
                        accept=".xls,.xlsx"
                        externalLoading={isLoading}
                        externalError={error}
                        onLoadingChange={setIsLoading}
                        onErrorChange={setError}
                        inputRef={fileInputRef}
                    />
                </>
            ) : (
                <ImportedDataDisplay data={processedData} onDuplicateSelection={handleDuplicateSubmit} />
            )}
        </UiModal>
    );
};

export default ModalImportLVL1;
