
import React from 'react';

class Ui extends React.Component<any> {
    render() {
        return (
            <div>
                <h1>UI ONLY</h1>
            </div>
        )
    }
}

export default Ui;