import { Button, PropTypes } from "@material-ui/core";
import { FormikErrors } from "formik";
import React, { FC, useState } from "react";
import { ModalSize, UiModal } from "../../../shared/components/ui/UiModal/UiModal";
import { Accessor } from "../../../shared/components/ui/UiTable/types";
import { accessValue } from "../../../shared/components/ui/UiTable/UiTableHelper";
import { useConfirmation } from '../../../shared/contexts/ConfirmationContext';
export type ActionBtnItem<T=any> = {
    id?: string;
    label: string;
    action: (item: T) => any,
    isSubmit?: boolean,
    onValidateForm?(): Promise<FormikErrors<any>>;
    onConfirm?(): void,
    color?: PropTypes.Color,
    variant?: 'text' | 'outlined' | 'contained';
}
export type ActionBtnProps<T=any> = ActionBtnItem<T> & {
    item: T;
}
export type AddOrEditModalProps<T = any> = {
    onConfirm?(): void;
    onValidateForm?(): Promise<FormikErrors<any>>;
    onDelete(item: T): void;
    onCancel(): void;
    title: string;
    open: boolean;
    item?: T,
    loading?: boolean,
    itemLabelProp?: Accessor<T>
    actionsBtns?: ActionBtnItem<T>[],
    size?: ModalSize,
    disableSubmit?: boolean
};

const ActionBtn  = ({label, action, onValidateForm, isSubmit, color="primary", variant="contained", id, item}: ActionBtnProps) => {
    const [loading,setLoading] = useState(false);
    const handleAction = async () => {
        setLoading(true);
        await action(item);
        setLoading(false);
    }

    const handleValidateForm = async() => {
        if(onValidateForm) {
            const resp = await onValidateForm();
            if(!Object.keys(resp).length) {
                handleAction();
            }
        }
    }
    return (
        <>
            {
                isSubmit
                ? <Button id={id} type="submit" variant={variant} color={color} disabled={loading} onClick={handleValidateForm}>{label}</Button>
                : <Button id={id} variant={variant} color={color} disabled={loading} onClick={handleAction}>{label}</Button>
            }
        </>
    )
}
export const AddOrEditModal: FC<AddOrEditModalProps> = ({ size, onConfirm, onValidateForm, onDelete, onCancel, open, title, children,loading, item, itemLabelProp,actionsBtns=[],disableSubmit=false, }) => {
    const confirm = useConfirmation()
    const modalTitle = !item ? `Add ${title}` : `Edit ${title}`;
    const handleDelete = async () => {
        const label = itemLabelProp ? ` ${accessValue(item, itemLabelProp)}` :  '';
        const ok = await confirm({title: `Delete ${title}`, description: `Do you really want to delete${label}?`})
        if(ok){
            onDelete(item)
        }
    }
    return (
        <UiModal open={open} title={modalTitle} onClose={onCancel} size={size}>
            {children}
            {!!item && <div className="ui-modal__footer ui-modal__footer--buttons">
                <Button id="admin-modal-cancel" disabled={!!loading} onClick={onCancel} variant="outlined" color="primary">Cancel</Button>

                {onDelete && item.isShownDeleteButton &&
                    <Button id="admin-modal-delete" disabled={!!loading} onClick={handleDelete} variant="contained"
                            color="secondary">Delete</Button>
                }

                { actionsBtns.map((btn, index) => <ActionBtn key={`action-btn-${index}`} {...btn} onValidateForm={onValidateForm} item={item} /> )}
                <Button id="admin-modal-save" disabled={!!loading || disableSubmit} onClick={onConfirm} variant="contained" color="primary">Save</Button>
            </div>}
            {!item && <div className="ui-modal__footer ui-modal__footer--buttons">
                <Button id="admin-modal-cancel"  disabled={!!loading} onClick={onCancel} variant="outlined" color="primary">Cancel</Button>
                { actionsBtns.map((btn, index) => <ActionBtn  key={`action-btn-${index}`} {...btn} onValidateForm={onValidateForm} item={item} /> )}
                <Button id="admin-modal-add" disabled={!!loading || disableSubmit} onClick={onConfirm} variant="contained" color="primary">Add</Button>
            </div>}
        </UiModal>
    );
};
