import { map } from 'rxjs/operators';
import { Option } from '../../components/SelectField';
import { urlPartialGetDeclaration, urlPartialUpdateDeclaration } from '../../config/GlobalAppConfig';
import { DeclarationInterface, ValuableAssetItem, EventPhaseItem, SecuritySystemDevicesItem } from '../../../declaration/declarationNewEdit/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeclarationFromServer } from './interfaces';
import { mappingDeclarationFromlocalToServer, mappingDeclarationFromServerToLocal } from './declarationMapping';
import { AxiosRequestConfig } from 'axios';
import CustomAxios, { ApiCallingMethods } from '../../helpers/AxiosHelper';

export class DeclarationService {
    static declarationData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    static getAllDeclarationFormsFromEvent(id: string) {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${process.env.PUBLIC_URL}/mockDB/declarationForm.json`,
            data: null
        };
        return CustomAxios.request(options).pipe(map((r: any) => r.data.filter((d: any) => d.eventId === id)[0]));
    }

    static getAll() {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${process.env.PUBLIC_URL}/mockDB/declarations.json`,
            data: null
        };
        return CustomAxios.request(options);
    }

    static update(declarationToSend: DeclarationInterface, eventId: number, valuableAssetsList: ValuableAssetItem[], eventPhasesList: EventPhaseItem[], technicalSurveillanceList: SecuritySystemDevicesItem[], users: Option[]) {
        const declarationToServer: DeclarationFromServer = mappingDeclarationFromlocalToServer(declarationToSend, eventId);
        
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: urlPartialUpdateDeclaration,
            data: declarationToServer
        };
        return CustomAxios.request(options).pipe(map((r: any) => {
            const declaration: DeclarationInterface = mappingDeclarationFromServerToLocal(r.data.model, valuableAssetsList, eventPhasesList, technicalSurveillanceList, users);
            declarationToSend.valuableAssets = declaration.valuableAssets;
            declarationToSend.securityStaffDeployment = declaration.securityStaffDeployment;
            declarationToSend.technicalSurveillanceSystem = declaration.technicalSurveillanceSystem;
            return declarationToSend;
        }));      
    }

    static getById(id: number, valuableAssetsList: ValuableAssetItem[], eventPhasesList: EventPhaseItem[], technicalSurveillanceList: SecuritySystemDevicesItem[], users: Option[]): Observable<DeclarationInterface> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: `${urlPartialGetDeclaration}?idDeclaration=${id}`,
            data: null
        };
        return CustomAxios.request(options).pipe(map((r: any) => {
            const declaration: DeclarationInterface = mappingDeclarationFromServerToLocal(r.data.model, valuableAssetsList, eventPhasesList, technicalSurveillanceList, users);
            return declaration;
        }))
    }
}