import { FormikHelpers } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Observable, Subscription } from 'rxjs';
import { BaseAttributeDto, CountryDto, MaisonDto, LoadableItems } from '../../../shared/models/interfaces';
import { AdministrationService } from '../../../shared/services/administration.service';
import { useAdministrationContext } from '../../AdministrationContext';
import { AdministrationTable, IAdministrationTableHead } from '../CommonMgmt/AdministrationTable';
import { AddOrEditAttribute } from './AddOrEditAttribute';


const IsVisible = (props: MaisonDto | CountryDto) => {
    return props.isActive ? 'Yes' : 'No';
}

const maisonTableHeads : IAdministrationTableHead<MaisonDto>[] = [
    {
        name: 'name',
        label: 'Maison',
        sortable: true,
        accessor: 'name',
        search: (item, search) => item.name.toLowerCase().includes(search.toLowerCase()),
    },
    { name: 'code', label: 'Code', sortable: true, accessor: 'code'},
    { name: 'isVisible', label: 'Active', sortable: true, cell: IsVisible},
]

const countryTableHeads : IAdministrationTableHead<CountryDto>[] = [
    {
        name: 'name',
        label: 'Country',
        sortable: true,
        accessor: 'name',
        search: (item, search) => item.name.toLowerCase().includes(search.toLowerCase()),
    },
    { name: 'code', label: 'Code', sortable: true, accessor: 'code'},
    { name: 'isActive', label: 'Active', sortable: true, cell: IsVisible},
]

export const AttributsMgmt = () => {
    const {maison, country} = useAdministrationContext();
    const actionSubscription = useRef<Subscription>();
    
    const checkDuplication = (items: BaseAttributeDto[], data:BaseAttributeDto, formikHelpers: FormikHelpers<BaseAttributeDto>, item?:BaseAttributeDto ) => {
        let hasError = false;
        if(items.some(t=>t.name?.toLowerCase()===data.name?.toLowerCase() && (!item || data.name !== item.name))){
            hasError = true
            formikHelpers.setFieldError("name", "This name already exists")    
        }
        if(items.some(t=>t.code===data.code && (!item || data.code !== item.code))){
            hasError = true
            formikHelpers.setFieldError("code", "This code already exists")    
        }
        return hasError;
    }

    const doCrudAction = (action: Observable<any>, item: LoadableItems<any>) => {
        return new Promise(resolve=>{
            if(actionSubscription.current){
                actionSubscription.current.unsubscribe();
            }
            actionSubscription.current = action.subscribe(()=>{
                item.load()
                resolve(true)
            }, ()=>{
                resolve(false)
            })
        })
    }

    const handleCreateMaison = (data: BaseAttributeDto, formikHelpers:FormikHelpers<BaseAttributeDto>) => {
            if(checkDuplication(maison.items, data, formikHelpers)){
                return false;
            }
            return doCrudAction(AdministrationService.addMaison(data), maison);
    };
    
    const handleDeleteMaison = (data: MaisonDto) => {
        return doCrudAction(AdministrationService.deleteMaison(data), maison);
    };
    
    const handleUpdateMaison = async (data: BaseAttributeDto, item: MaisonDto, formikHelpers: FormikHelpers<BaseAttributeDto>) => {
        if (checkDuplication(maison.items, data, formikHelpers, item)) {
            return false;
        }
        return doCrudAction(
            AdministrationService.updateMaison({
                ...item,
                ...data,
            }),
            maison
        );
    };
    
    const handleCreateCountry =  (data: BaseAttributeDto, formikHelpers:FormikHelpers<BaseAttributeDto>) => {
        if(checkDuplication(country.items, data, formikHelpers)){
            return false;
        }
        return doCrudAction(AdministrationService.addCountry(data), country)
    };
    
    const handleDeleteCountry = async (data: BaseAttributeDto) => {
        return doCrudAction(AdministrationService.deleteCountry(data), country);
    };
    const handleUpdateCountry = async (data: BaseAttributeDto, item: CountryDto, formikHelpers:FormikHelpers<BaseAttributeDto>) => {
        if(checkDuplication(country.items, data, formikHelpers, item)){
            return false;
        }
        return doCrudAction(
            AdministrationService.updateCountry({
                ...item,
                ...data,
            }),
            country
        );
    };

    useEffect(()=>{
        return () => {
            if(actionSubscription.current){
                actionSubscription.current.unsubscribe();
            }
        }
    })

    return (
        <div style={{margin: '0 17%'}}>
            <AdministrationTable
                id="maison-table"
                className="columns-4"
                title="Maison"
                itemLabelProp="name"
                items={maison.items}
                tableHeads={maisonTableHeads}
                form={AddOrEditAttribute}
                loading={maison.isLoading}
                onCreate={handleCreateMaison}
                onDelete={handleDeleteMaison}
                onUpdate={handleUpdateMaison}
            />
            <AdministrationTable
                id="country-table"
                className="columns-4"
                title="Country"
                itemLabelProp="name"
                items={country.items}
                tableHeads={countryTableHeads}
                form={AddOrEditAttribute}
                loading={country.isLoading}
                onCreate={handleCreateCountry}
                onDelete={handleDeleteCountry}
                onUpdate={handleUpdateCountry}
            />
        </div>
    )
} 