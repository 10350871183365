import { AxiosRequestConfig } from "axios";
import { urlPartialLogs } from "../config/GlobalAppConfig";
import CustomAxios, { ApiCallingMethods } from "../helpers/AxiosHelper";

enum Severity
{
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4,
    Debug = 5
}

interface AppLog {
    message: string;
    severity: Severity;
    source: string;
    extraInfo: string;
}


export const LoggingServie = {
    LogError: (error: string, extraInfo: string) => {
        const res: AppLog = {
            message: error.toString(),
            severity: Severity.Error,
            source: 'Front-end',
            extraInfo: extraInfo
        }

        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialLogs,
            data: res
        };
        CustomAxios.request(options).subscribe();
    },
    
    
    LogInformation: (info: any) => {
        const res: AppLog = {
            message: info.toString(),
            severity: Severity.Error,
            source: 'Front-end',
            extraInfo: ''
        }

        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialLogs,
            data: res
        };
        CustomAxios.request(options).subscribe();
    }
};

