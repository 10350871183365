import { urlPartialGetUploadedFilesList, partialUrlGetDocumentRightsByRole } from '../config/GlobalAppConfig';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomFile, DocumentRights } from '../components/upload/interfaces';
import { CommonProperties } from '../models/interfaces';
import { DocAccessType } from '../models/enums';
import { Stream } from 'stream';
import { AxiosRequestConfig } from 'axios';
import CustomAxios, { ApiCallingMethods } from '../helpers/AxiosHelper';

interface EventDeclarationDocuments extends CommonProperties {
    idEvent: number;
    idDeclaration?: number;
    fullpath: string ;
    filesize: number ;
    filename: string ;
    filenameConverted: string;
    extension: string ;
    filetype: string ;
    docType: string ;
}

interface EventDeclarationDocumentsReceived extends EventDeclarationDocuments {
    idMaison: number;
    canBeDeleted: boolean;
}

export interface EventDeclarationDocumentsToSend extends EventDeclarationDocuments {
    fileContent: Stream
}

export class UploadService {
    static getAllDocuments(idGroupUsers : number, idEvent: number, idDeclaration?: number | null): Observable<CustomFile[]> {
        let url = `${urlPartialGetUploadedFilesList}?idEvent=${idEvent}&idGroupUsers=${idGroupUsers}`;
        if(idDeclaration) {
            url += `&idDeclaration=${idDeclaration}`
        }

        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: url,
            data: null
        };
        return CustomAxios.request(options).pipe(map(resp => {
            const fileListFromServer: EventDeclarationDocumentsReceived[] = resp.data;
            const filelist: CustomFile[] = fileListFromServer.map((fileFromServer: EventDeclarationDocumentsReceived) => {
                const file: CustomFile = {
                    docAccessType: DocAccessType[fileFromServer.docType],
                    encrypting: false,
                    encryptionDone: true,
                    uploading: false,
                    name: `${fileFromServer.filename}.${fileFromServer.extension}`,
                    size: fileFromServer.filesize,
                    id: fileFromServer.id,
                    filenameInBe: fileFromServer.filenameConverted,
                    createdBy: fileFromServer.createdBy,
                    modifiedBy: fileFromServer.modifiedBy,
                    createdDate: fileFromServer.createdDate,
                    modifiedDate: fileFromServer.modifiedDate,
                    idDeclaration: fileFromServer.idDeclaration,
                    idMaison: fileFromServer.idMaison,
                    canBeDeleted: fileFromServer.canBeDeleted,
                    // Init manadory values
                    arrayBuffer: () => Promise.resolve(new ArrayBuffer(8)),
                    text: () => Promise.resolve(''),
                    stream: () => new ReadableStream({}),
                    slice: () => new Blob(),
                    type: '',
                    lastModified: 0
                }
                return file;
            })
            return filelist;
        }));
    }

    static getDocumentRightsByRole(idGroupUser : number): Observable<DocumentRights[]>{
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: partialUrlGetDocumentRightsByRole + "/" + idGroupUser,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static update(url: string, file: CustomFile) {
        const pos = Number(file.docAccessType);
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: url + "/" + file.id + "?docType=" + DocAccessType[pos],
            data: null
        };
        return CustomAxios.request(options);
    }

    static delete(url: string, id: number) {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.delete,
            url: url + "/" + id,
            data: null
        };
        return CustomAxios.request(options);
    }

    static download(url: string, filename: string) {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: url,
            data: {filename: filename},
            responseType: 'blob'
        };
        return CustomAxios.request(options);
    }
}