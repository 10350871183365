import { Button } from "@material-ui/core";
import { Field, Formik, FormikProps, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Subscription } from "rxjs";
import * as Yup from "yup";
import { UiTextField } from "../../../shared/components/fields";
import UiMultipleTagInput from "../../../shared/components/fields/UiMultipleTagInput";
import { UiModal } from "../../../shared/components/ui/UiModal/UiModal";
import { TemplatePlaceholder, templatePlaceholders } from "../../../shared/config/administrationConfig";
import { emailRegex, Errors } from "../../../shared/constants";
import { validateEmail } from "../../../shared/helpers/HelpersFunc";
import { AdministrationService } from "../../../shared/services/administration.service";
import { IAddOrEditForm } from "../../types";
import { AddOrEditForm } from "../CommonMgmt/AddOrEditForm";
import { ActionBtnItem } from "../CommonMgmt/AddOrEditModal";
import { FormObserver } from "../CommonMgmt/FormObserver";
import {RichTextEditor} from "../../../shared/components/richeTextEditor/RichTextEditor";
import {Range} from "react-quill";

export interface TemplateFormValue {
    name: string;
    subject: string;
    body: string;
}

export interface TestTemplate {
    recipients: string[],
    subject: string,
    body: string,
    templatePath:string
}

const validationSchema = Yup.object().shape<TemplateFormValue>({
    name: Yup.string().required(Errors.mandatory),
    subject: Yup.string().required(Errors.mandatory),
    body: Yup.string().required(Errors.mandatory),
});

type RecipientFormValues = { recipients: string[] };

const testValidationSchema = Yup.object().shape<RecipientFormValues>({
    recipients: Yup.array().of(Yup.string().trim().matches(emailRegex, 'Email is not in correct format. ')).min(1)
});

export const TemplateForm = (props: IAddOrEditForm<TemplateFormValue>) => {
    const [open, setOpen] = useState(false);
    const [recipientInput, setRecipientInput] = useState('');
    const [recipientInitialValues, setRecipientInitialValues] = useState<RecipientFormValues>({ recipients: [] });
    const searchSubscription = useRef<Subscription>();
    const formInitialValues: TemplateFormValue = props.item || {
        name: "",
        subject: "",
        body: "",
    };
    const [formValue, setFormValue] = useState<TemplateFormValue>(formInitialValues);
    
    // Cleanup function
    useEffect(
        () => () => {
            if (searchSubscription.current) {
                searchSubscription.current.unsubscribe();
            }
        },
        []
    );

    const openTestModal = (_data: any) => {
        if(formValue.name && formValue.body && formValue.subject) {
            recipientInitialValues.recipients = [];
            setRecipientInitialValues(Object.assign({}, recipientInitialValues));
            setOpen(true);
        }
    };

    const handleSubmitTest = (value: RecipientFormValues) => {
       // console.log("handle test", formValue, value.recipients);
        let testTemplate: TestTemplate = {
            recipients: value.recipients,
            subject: formValue.subject,
            body: formValue.body,
            templatePath: ''
        };

        AdministrationService.testTemplateNotification(testTemplate).subscribe((res) =>{
            console.log("result ", res);
        });

        setOpen(false);
    };

    const actionBtns: ActionBtnItem[] = [
        {
            id: "admin-modal-test-template",
            label: "Test template",
            action: openTestModal,
            isSubmit: true
        },
    ]

    const handleInputChange = (emailInput: string) => {
        setRecipientInput(emailInput);
    }

    const handleSend = async(formikProps: FormikProps<RecipientFormValues>) => {
        if(recipientInput.length) {
            const newValues = formikProps.values.recipients.concat([recipientInput]).slice();
            // await here to show correct error
            await setRecipientInitialValues({recipients: newValues});
            setRecipientInput('');
        }
        formikProps.handleSubmit();
    }

    return (
        <>
            <AddOrEditForm
                actionBtns={actionBtns}
                initialValues={formInitialValues}
                validationSchema={validationSchema}
            >
                <TemplateFormContent />
                <FormObserver onChange={setFormValue}/>
            </AddOrEditForm>
            <UiModal id="test-template-modal" title="Test template" open={open} onClose={() => setOpen(false)}>
                <div className="ui-field">
                    This is the header and main body of the test template.
                </div>
                <Formik
                    enableReinitialize
                    initialValues={recipientInitialValues}
                    onSubmit={handleSubmitTest}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validationSchema={testValidationSchema}
                    touched
                >
                    {(formikProps: FormikProps<RecipientFormValues>) => {
                        return (
                            <form onSubmit={formikProps.handleSubmit}>
                                <Field
                                    multiple={true}
                                    id="test-template-recipients"
                                    label="Additional recipients"
                                    name="recipients"
                                    validation={validateEmail}
                                    component={UiMultipleTagInput}
                                    onInputChange={handleInputChange}
                                    inputValue={recipientInput}
                                />
                                <div className="ui-modal__footer ui-modal__footer--buttons">
                                    <Button id="cancel-test-template" onClick={() => setOpen(false)} variant="contained" color="secondary">
                                        Cancel
                                    </Button>
                                    <Button
                                        id="send-test-template"
                                        onClick={() => handleSend(formikProps)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Send test template
                                    </Button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </UiModal>
        </>
    );
};

const TemplateFormContent = () => {
    const [lastFocus, setLastFocus] = useState<any>(null);
    const [haveFocus, setHaveFocus] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
    const { values, setValues } = useFormikContext<TemplateFormValue>();
    const [selection, setSelection] = useState<Range | null>(null);

    const handleFocus = (e: any) => {
        setTimeout(() => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            setLastFocus(e.target);
            setHaveFocus(true);

        }, 200);
    };
    const handleBlur = (e: any) => {
        timeoutRef.current = setTimeout(() => {
            setHaveFocus(false);
        }, 300);
    };
    const handleOpenModal = () => {
        if (lastFocus && lastFocus.focus) {
            lastFocus?.focus();
        }
        setModalOpen(true);
    };
    const insertPlaceholder = (placeholder: TemplatePlaceholder) => {
        const textToInsert = ' ' + placeholder.value + ' ';
        const start = selection?.index ?? lastFocus.selectionStart;
        const end = selection !== null ? (selection.index + selection.length) : lastFocus.selectionEnd;
        const text = values[lastFocus.name];
        const before = text.substring(0, start);
        const after = text.substring(end, text.length);
        const newVal = before + textToInsert + after;
        if (lastFocus.insertText) {
            lastFocus.insertText(start, textToInsert);
            setSelection({index: start + textToInsert.length, length: textToInsert.length});
        } else {
            setValues({...values, [lastFocus.name]: newVal});
            setSelection(null);
        }

        setModalOpen(false);
        setTimeout(() => {
            lastFocus.selectionStart = start;
            lastFocus.selectionEnd = start + textToInsert.length;
        });

    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSelection = (selection:Range) => {
        setSelection(selection);
    }

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    });

    return (
        <>
            <Field component={UiTextField} id="template-name" name="name" label="Name*"  />
            <Field component={UiTextField} id="template-subject" name="subject" label="Subject*" onFocus={handleFocus} onBlur={handleBlur} />
            <Field component={RichTextEditor} id="template-body" name="body" label="Body*" onSelection={handleSelection} onFocus={handleFocus}  onBlur={handleBlur}  />
            <div className="placeholder-actions">
                <Button id="template-placeholder" variant="contained" color="primary" disabled={!haveFocus} onClick={handleOpenModal}>
                    Insert placeholder
                </Button>
            </div>
            <UiModal id="insert-placeholder-modal" open={modalOpen} title="Insert placeholder" onClose={handleCloseModal}>
                <div className="placeholder-action__items">
                    {templatePlaceholders.map((placeholder, index) => (
                        <div className="placeholder-action__item" key={"placeholder-" + index}>
                            <Button id={`insert-placeholder-${index}`} onClick={() => insertPlaceholder(placeholder)} variant="contained" color="primary">
                                {placeholder.label}
                            </Button>
                        </div>
                    ))}
                </div>
            </UiModal>
        </>
    );
};
