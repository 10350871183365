import React, { ReactNode } from "react"
import Chip from '@material-ui/core/Chip';
import { StatusType } from "../../models/enums";


type IPropsUiStatus = {
    name? : StatusType
    label: ReactNode
}
export const UiStatus = (props: IPropsUiStatus) => {
    return(
        <div>
            <Chip {...props} className={"status ui-status--"+props.name }/>
        </div>
    )

}

export default UiStatus;