import { Check, Close, Notifications, NotificationsOff } from "@material-ui/icons";
import ErrorIcon from "@material-ui/icons/Error";
import React, { useCallback, useEffect, useState } from "react";
import { INewEvent } from "../../../event/interface";
import UiStatus from "../../../shared/components/ui/UiStatus";
import UiTags from "../../../shared/components/ui/UiTags";

import { CircularProgress, IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import { Field, Formik } from "formik";
import moment from "moment";
import { NextActions, SubmitData } from "../../../event/components/nextActions/nextActions";
import { numberScaleFormat } from "../../../shared/helpers/HelpersFunc";
import { StatusType, UserRoles } from "../../../shared/models/enums";
import { SnoozeService } from "../../../shared/services/snooze.service";
import { IPropsDashboardItem } from "../../interface";
import LocalStorageTokenService from "../../../shared/helpers/SessionStorageTokenService";

export const status = (props: IPropsDashboardItem) => {
    if(!props.status){
        return <></>;
    }
    const statusLowerCase = props.status.toLowerCase().replace(/ /g, '').replace('.', '') as StatusType;
    if(statusLowerCase) {
        return (<UiStatus label={props.status} name={statusLowerCase} />);
    }
    else {
        return null;
    }
}

export const exclamationPoint = (props: IPropsDashboardItem) => {
    if(props.showExclamationPoint) {
        return <ErrorIcon />
    }
    return null;
}

export const dateString = (props : IPropsDashboardItem) => {
    return  props.fromDate ? moment(props.fromDate).format('yyyy-MM-DD') : "-";
}

export const createdDate = (props : IPropsDashboardItem) => {
    return  props.createdDate ? moment(props.createdDate).format('yyyy-MM-DD') : "-";
}

export const amountFormat = (props : IPropsDashboardItem) => { 
    return "€ " + numberScaleFormat(Number(props.estimatedTotalValue))
}

export const countryFormat = (props : IPropsDashboardItem) => {
    return props.countriesContent ? props.countriesContent : "-"
}

export const nameOfEventFormat = (props : IPropsDashboardItem) => {
    return props.eventName ? props.eventName : "-"
}

export const riskLevelFormat = (props : IPropsDashboardItem) => {
    return props.riskLevelContent ? props.riskLevelContent : "-"
}

export const risks = (props: INewEvent) => {
    const tags = []
    if(props.riskTags.isHeritage){
        tags.push( <UiTags key="heritagetag" label="Heritage" />)
    }
    //Strategic ->Open displays
    if(props.riskTags.isStrategic){
        tags.push( <UiTags key="strategictag"  label="Open displays" />)
    } 
    return tags.map(tag=> tag )
}


export const C = (props: IPropsDashboardItem) => {
    if(props.hasConceptAccessDoc) {
        return <Check />
    }
    else {
        if (props.riskLevelContent) {
            if (Number(props.riskLevelContent) > 2) return <Close className={'ui-tablecell--c--cross'} />
            else return "-"

        }
    }
}
export const RA = (props: IPropsDashboardItem) => {
    if(props.hasReportAssessment) {
        return <Check />
    }
    else {
        return "-"
    }
}
const IconLoadingButton: React.FC<IconButtonProps & {loading:boolean}> = React.forwardRef( ({loading, children, ...props}, ref) =>  {
    return <IconButton ref={ref} disabled={loading} {...props}>
        {!loading ? children : <CircularProgress size={18} />}
    </IconButton>
});
export const Snooze = (props : IPropsDashboardItem) => {
    const {idSnoozeReminder,id,fetch} = props
    const [snoozed, setSnoozed] = useState(!!idSnoozeReminder);
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        setSnoozed(!!idSnoozeReminder);
    }, [idSnoozeReminder])

    const snooze = useCallback((e)=>{
        e.stopPropagation()
        if(!id) return;
        setLoading(true)
        SnoozeService.createSnooze(id).subscribe(r=>{
            setLoading(false)
            setSnoozed(true)
            if(fetch) fetch(false);
        }, e=>{
            setLoading(false)
        })
    }, [id]);
    
    const unsnooze = useCallback((e)=>{
        e.stopPropagation()
        if(!idSnoozeReminder) return;
        SnoozeService.deleteSnooze(idSnoozeReminder as number).subscribe(r=>{
            setLoading(false)
            setSnoozed(false)
            if(fetch) fetch(false);
        }, e=>{
            setLoading(false)
        })
    }, [idSnoozeReminder])

    if(snoozed){
        return <Tooltip title="unsnooze"><IconLoadingButton  loading={loading} onClick={unsnooze}><NotificationsOff /></IconLoadingButton></Tooltip>
    }
    return <Tooltip title="snooze"><IconLoadingButton loading={loading}  onClick={snooze}><Notifications /></IconLoadingButton></Tooltip>
}

export const Actions = (props: IPropsDashboardItem) => {
    const [isMounted, setIsMounted] = useState(true);
    const submit = (values: any) => {
        if(props.id && values.nextActionBehavior) {
            SubmitData('dashboard', props.id, values, null, null, null, true, isMounted);
         }
    }

    useEffect(
        () => {
            return () => setIsMounted(false);
        }
    )

    return (
        <Formik
            initialValues={{}}
            onSubmit={submit}>
            {({
                values,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => {
                return (
                    <Field
                        isSingleButton={true}
                        singleButtonValue={props}
                        handleSubmit={handleSubmit}
                        eventId={props.id}
                        enableEditButton={true}
                        component={NextActions}
                    />
                )
            }}
        </Formik>
    )
}

export const CloseEvent = (props: IPropsDashboardItem) => {
    const [isMounted, setIsMounted] = useState(true);
    const userRole = LocalStorageTokenService.getUserRoleToken() as UserRoles;
    const submit = (values: any) => {
        if(props.id && values.nextActionBehavior) {
            SubmitData('dashboard', props.id, values, null, null, null, true, isMounted);
        }
    }

    useEffect(
        () => {
            return () => setIsMounted(false);
        }
    )
    if (props.eventFlowToClose && props.eventFlowToClose.length > 0) {
        return (
            <Formik
                initialValues={{}}
                onSubmit={submit}>
                {({
                      values,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => {
                    return (
                        <Field
                            isSingleButton={true}
                            singleButtonValue={props.eventFlowToClose![0]}
                            handleSubmit={handleSubmit}
                            eventId={props.id}
                            enableEditButton={true}
                            closeFromDashboard={true}
                            component={NextActions}
                        />
                    )
                }}
            </Formik>
        )
    } else {
        return (<></>)
    }
}
