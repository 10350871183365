import { Badge, Button } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { Field, Formik, FormikProps } from "formik";
import React, { FC, useCallback, useMemo, useState } from "react";
import { ObjectSchema } from "yup";
import { UiAutocompleteField, UiSwitchField } from "../../../shared/components/fields";
import { UiModal } from "../../../shared/components/ui/UiModal/UiModal";

type AdministrationFilterType = "select" | "multiselect" | "boolean"

export type AdministrationFiltersItem<T = any> = {
    label: string;
    type: AdministrationFilterType;
    name: string;
    accessor?: string,
    filter?: {(item: T, filterValue: any): boolean}
    options?: {itemContent?: string, label?: string, value: any}[];
};

export type AdministrationFiltersProps<T=any> =  {items: AdministrationFiltersItem<T>[], validationSchema?:ObjectSchema, onApply?: {(values: any) : void}}


const componentByType : { [key in AdministrationFilterType] : {component: FC<any>, props?: Object} }= {
    "select": {component: UiAutocompleteField},
    "multiselect": {component: UiAutocompleteField, props: {multiple: true}},
    "boolean": {component: UiSwitchField}
}

const AdministrationFilter : FC<AdministrationFiltersItem> = ({name, label, type, options}) => {
    const FieldComponent= componentByType[type].component
    const props = componentByType[type].props
    return <Field 
        id={`admin-filter-${name}`}
        name={name}
        label={label}
        component={FieldComponent}
        {...props}
        options={options}
    />
}
const defaultValueByType = {
    boolean: false,
    select: null,
    multiselect: [],
}
export const AdministrationFilters: FC<AdministrationFiltersProps> = ({items, validationSchema, onApply}) => {
    const [open, setOpen] = useState(false);
    
    const formInitialValues = useMemo(() => {
        return items.reduce(
            (acc, item)=> {
                acc[item.name] = defaultValueByType[item.type];
                return acc;
            },{}
        )
    }, [items])

    const [filterCount, setFilterCount] = useState(0)
    const onSubmitForm = useCallback((formValues: any) => {
        if(onApply){
            onApply(formValues)
        }
        const count = Object.values(formValues).reduce((acc:number, filter:any)=>{
            if (filter instanceof Array) {
                if(filter.length){
                    acc++
                }
            }else if(filter instanceof Object){
                acc++
            }
            return acc;
        }, 0)
        setFilterCount(count)
        setOpen(false);
    }, [onApply])
    
    return (
        <>
            <Badge className="admin-filters-badge" color="secondary" badgeContent={filterCount} overlap="rectangular">
                <Button id="admin-filters-button" color="secondary" variant="outlined" startIcon={<FilterList />} onClick={() => setOpen(true)}>
                    Filters
                </Button>
            </Badge>
            {true && <Formik
                initialValues={formInitialValues}
                onSubmit={onSubmitForm}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={true}
                touched
            >
            {({ handleSubmit, resetForm }: FormikProps<any>) => {
                const handleClearFilter = () => {
                    resetForm()
                    handleSubmit()
                }
                return (
                    <UiModal open={open} title="Filters" onClose={()=>setOpen(false)}>
                        {items?.map((filter, key)=><AdministrationFilter key={`admin-filter-${key}`} {...filter} />)}
                    <div className="ui-modal__footer ui-modal__footer--buttons">
                        <Button  onClick={handleClearFilter} variant="contained" color="secondary" id="admin-clear-filters">Clear</Button>
                        <Button  onClick={()=>handleSubmit()} variant="contained" color="primary" id="admin-apply-filters">Apply</Button>
                    </div>
                </UiModal>
                );
            }}
        </Formik>}
        </>
    );
};
