import {  AuthResponse, Configuration } from 'msal'
import { SessionStorageToken } from '../../helpers/SessionStorageTokenService';

export const msalConfig: Configuration = {
   auth: {
      clientId: String(process.env.REACT_APP_CLIENT_ID),
      authority: String(process.env.REACT_APP_AUTHORITY),
      validateAuthority: false,
      // by default redirectUri is the current application Uri. 
      //but it cause some problem because redirectUri must be registered in Azure AD when we try to login()
      //and we can't register all Uri where we call methode login() 
      //so we must specifie one Uri where MSAL will redirect when we ask for login
      redirectUri: String(process.env.REACT_APP_REDIRECT_URI),
      postLogoutRedirectUri: String(process.env.REACT_APP_REDIRECT_URI)
   },
   cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
   }
   // ,system: {
   //    tokenRenewalOffsetSeconds: 36000
   // }
};

/** 
* Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
* visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
*/
export const loginRequestParam = {
   scopes: ["openid", "profile"]
};

export const tokenRequestParam = {
   scopes: [String(process.env.REACT_APP_CLIENT_ID)],
   forceRefresh: false
   
};


export interface AuthAppResponse {
   isError: boolean;
   errorMsg: string|null;
   response: AuthResponse|null;
   token: SessionStorageToken|null
}