import React, { useEffect, useState } from "react";
import { Field} from 'formik';
import { UiSwitchField, UiTextareaField, UiTextField } from '../../../../shared/components/fields';
import { ConditionnalField } from '../../../../shared/components/ConditionnalField';
import { FIELD_IDS } from '../../../../admin/components/FieldsMgmt/Fields.type';
import { useAuthContext } from "../../../../shared/contexts/AuthContext";
import "./MovementsAndStorage.scss";
import { UiSectionTitle } from "../../../../shared/components/ui/UiSectionTitle";


export const MovementsAndStorage = ({field, form}: any) => {

    const {fieldsVisibility} = useAuthContext();
    const [displayStorageOfPiecesAfterHours, setDisplayStorageOfPiecesAfterHours] = useState(false)
    const [displayStorageOfPiecesPreAndAfterEvent, setDisplayStorageOfPiecesPreAndAfterEvent] = useState(false)
    const [displayMovementOfPieces, setDisplayMovementOfPieces] = useState(false)
    useEffect(() => {
        const fieldsToIgnoreMovementOfPieces = ["tempExternalStorageBeforeEvent", "msLocationPlaceBeforeEvent", "msTempExternalStorageAfterEvent", "msLocationPlaceAfterEvent", "msDedicatedSafeRoom", "msExternalSecuredStorage", "msNearbyBoutique", "msPiecesRemainInShowcases", "msOther", "msOtherDescription"]
        const fieldsToIgnoreStorageOfPiecesPreAndAfterEvent = ["msMovementOfValuables", "msMovementDescription", "msMovementOfValuablesOutside", "msValOutsideLocationPlace", "msMovementOfValuablesOutsideDescription", "msPiecesRemainInShowcases", "msOther", "msOtherDescription", "msDedicatedSafeRoom", "msExternalSecuredStorage", "msNearbyBoutique"]
        const fieldsToIgnoreStorageOfPiecesAfterHours = ["msMovementOfValuables", "msMovementDescription", "msMovementOfValuablesOutside", "msValOutsideLocationPlace", "msMovementOfValuablesOutsideDescription", "msTempExternalStorageBeforeEvent", "msLocationPlaceBeforeEvent", "msTempExternalStorageAfterEvent", "msLocationPlaceAfterEvent"]
        Object.entries(FIELD_IDS.movementAndStorage).forEach(([, value]) => {
            if (!fieldsToIgnoreStorageOfPiecesAfterHours.includes(value) && fieldsVisibility[value] === true) {
                setDisplayStorageOfPiecesAfterHours(true)
           }
        })
        Object.entries(FIELD_IDS.movementAndStorage).forEach(([, value]) => {
            if (!fieldsToIgnoreStorageOfPiecesPreAndAfterEvent.includes(value) && fieldsVisibility[value] === true) {
                setDisplayStorageOfPiecesPreAndAfterEvent(true)
            }
        })
       Object.entries(FIELD_IDS.movementAndStorage).forEach(([, value]) => {
            if (!fieldsToIgnoreMovementOfPieces.includes(value) && fieldsVisibility[value] === true) {
                setDisplayMovementOfPieces(true)
           }
       })

    }, []);

    return (
        <React.Fragment>
            {displayMovementOfPieces &&
            <section>
                <UiSectionTitle>
                    <h4>Movements of pieces</h4>
                </UiSectionTitle>
                <ConditionnalField
                    fieldId={FIELD_IDS.movementAndStorage.movementOfValuables}
                    name="declaration.movementsAndStorages.movementOfValuables"
                    label="Movement of pieces inside the event venue (ex: Private viewings of pieces to clients)"
                    uncheckWarning={true}
                    fieldToChange={'declaration.movementsAndStorages.movementDescription'}
                    inline={true}
                    disabled={form.values.isReadOnly}
                    component={UiSwitchField}
                    style={{width: '500px'}}
                />

                {form.values.declaration.movementsAndStorages && form.values.declaration.movementsAndStorages.movementOfValuables && (

                    <ConditionnalField
                        fieldId={FIELD_IDS.movementAndStorage.movementDescription}
                        name="declaration.movementsAndStorages.movementDescription"
                        label="Movement description"
                        component={UiTextareaField}
                        disabled={form.values.isReadOnly}
                    />
                )}
                <ConditionnalField
                    fieldId={FIELD_IDS.movementAndStorage.movementOfValuablesOutside}
                    name='declaration.movementsAndStorages.isMovValuableOutsideEventVenue'
                    label='Movement of pieces outside of the event venue (ex: Gala  dinner in a different venue, displays in 2 distinct venues)'
                    component={UiSwitchField}
                    disabled={form.values.isReadOnly}
                    inline={true}
                />
                {form.values.declaration.movementsAndStorages && form.values.declaration.movementsAndStorages.isMovValuableOutsideEventVenue && (

                    <ConditionnalField
                        fieldId={FIELD_IDS.movementAndStorage.movValOutsideLocationPlace}
                        label="Location place"
                        name="declaration.movementsAndStorages.movValOutsideLocationPlace"
                        disabled={form.values.isReadOnly}
                        component={UiTextField}
                    />
                )}
                {form.values.declaration.movementsAndStorages && form.values.declaration.movementsAndStorages.isMovValuableOutsideEventVenue && (

                    <ConditionnalField
                        fieldId={FIELD_IDS.movementAndStorage.movementOfValuablesOutsideDescription}
                        name='declaration.movementsAndStorages.movementValuableDescription'
                        label="Movement description"
                        component={UiTextareaField}
                        disabled={form.values.isReadOnly}
                        inline={true}
                    />
                )}
            </section>
            }
            {displayStorageOfPiecesPreAndAfterEvent &&
            <section>
                <UiSectionTitle>
                    <h4 style={{marginTop: '20px'}}>Storage of pieces pre-event and after-event</h4>
                </UiSectionTitle>
                <ConditionnalField
                    fieldId={FIELD_IDS.movementAndStorage.tempExternalStorageBeforeEvent}
                    name="declaration.movementsAndStorages.tempExternalStorageBeforeEvent"
                    label="Temporary external storage before event"
                    uncheckWarning={true}
                    fieldToChange={'declaration.movementsAndStorages.locationPlaceBeforeEvent'}
                    disabled={form.values.isReadOnly}
                    component={UiSwitchField}
                    inline={true}

                />
                {form.values.declaration.movementsAndStorages && form.values.declaration.movementsAndStorages.tempExternalStorageBeforeEvent && (
                    <ConditionnalField
                        fieldId={FIELD_IDS.movementAndStorage.locationPlaceBeforeEvent}
                        label="Location place"
                        name="declaration.movementsAndStorages.locationPlaceBeforeEvent"
                        disabled={form.values.isReadOnly}
                        component={UiTextField}
                    />


                )}
                <ConditionnalField
                    fieldId={FIELD_IDS.movementAndStorage.tempExternalStorageAfterEvent}
                    name="declaration.movementsAndStorages.tempExternalStorageAfterEvent"
                    label="Temporary external storage after event"
                    uncheckWarning={true}
                    fieldToChange={'declaration.movementsAndStorages.locationPlaceAfterEvent'}
                    disabled={form.values.isReadOnly}
                    component={UiSwitchField}
                    inline={true}

                />
                {form.values.declaration.movementsAndStorages && form.values.declaration.movementsAndStorages.tempExternalStorageAfterEvent && (
                    <ConditionnalField
                        fieldId={FIELD_IDS.movementAndStorage.locationPlaceAfterEvent}
                        label="Location place"
                        name="declaration.movementsAndStorages.locationPlaceAfterEvent"
                        disabled={form.values.isReadOnly}
                        component={UiTextField}
                    />


                )}
            </section>
            }
            {displayStorageOfPiecesAfterHours && <section>
                <UiSectionTitle>
                    <h4 style={{marginTop: '20px'}}>Storage of pieces after-hours</h4>
                </UiSectionTitle>
                {displayStorageOfPiecesAfterHours && <p className="ui-field">How are pieces stored during after-hours </p>}
                <ConditionnalField
                    fieldId={FIELD_IDS.movementAndStorage.dedicatedSafeRoom}
                    name='declaration.movementsAndStorages.isDedicatedSafeRoom'
                    label='- Dedicated safe room in the event venue'
                    component={UiSwitchField}
                    disabled={form.values.isReadOnly}
                    inline={true}
                />
                <ConditionnalField
                    fieldId={FIELD_IDS.movementAndStorage.externalSecuredStorage}
                    name='declaration.movementsAndStorages.isExternalSecuredStorage'
                    label='- External secured storage outside of the events venue'
                    component={UiSwitchField}
                    disabled={form.values.isReadOnly}
                    inline={true}
                />
                <ConditionnalField
                    fieldId={FIELD_IDS.movementAndStorage.nearbyBoutique}
                    name='declaration.movementsAndStorages.isNearbyBoutique'
                    label='- Nearby boutique'
                    component={UiSwitchField}
                    disabled={form.values.isReadOnly}
                    inline={true}
                />
                <ConditionnalField
                    fieldId={FIELD_IDS.movementAndStorage.piecesRemainInShowcases}
                    name='declaration.movementsAndStorages.isPiecesRemainInShowcases'
                    label='- Pieces remain in showcases (exhibition events)'
                    component={UiSwitchField}
                    disabled={form.values.isReadOnly}
                    inline={true}
                />
                <ConditionnalField
                    fieldId={FIELD_IDS.movementAndStorage.other}
                    name='declaration.movementsAndStorages.isOther'
                    label='- Other (please specify)'
                    component={UiSwitchField}
                    disabled={form.values.isReadOnly}
                    inline={true}
                />
                {form.values.declaration.movementsAndStorages && form.values.declaration.movementsAndStorages.isOther && (

                    <ConditionnalField
                        fieldId={FIELD_IDS.movementAndStorage.otherDescription}
                        name='declaration.movementsAndStorages.otherDescription'
                        component={UiTextField}
                        disabled={form.values.isReadOnly}
                    />
                )}
            </section>
            }
        </React.Fragment>
    )
}