import React from 'react';
import DeclarationNewEdit from './declarationNewEdit/DeclarationNewEdit';
import DeclarationRead from './declarationRead/DeclarationRead';
import NewEventService from '../shared/services/NewEvent.service';
import {INewEvent} from '../event/interface';
import {usersRoleAllowToEnterInEditMode} from '../shared/models/records';
import {LoggingServie} from '../shared/services/LoggingService';
import {Flow, StatusType, UserRoles} from '../shared/models/enums';
import SessionStorageTokenService from '../shared/helpers/SessionStorageTokenService';
import LocalStorageTokenService from "../shared/helpers/SessionStorageTokenService";
import {GetEventStatusLabel} from "../shared/helpers/HelpersBusiness";
import {EventStatus} from "../dashboard/interface";
import { DeclarationInterface } from "./declarationNewEdit/interfaces";

interface Props {
    isCancelled: boolean
    event: any
    declaration: DeclarationInterface
}

interface StateProps {
    event: INewEvent,
    isReadOnly?: boolean
}

class Declaration extends React.Component<Props, StateProps> {
    constructor(props: Props) {
        super(props);
        this.state = {
            event: this.props.event,
            isReadOnly: undefined
        }
    }

    canEdit = (role: UserRoles): boolean => {
        return usersRoleAllowToEnterInEditMode.includes(role) || this.canMaisonSecEdit(role);
    }

    canMaisonSecEdit = (role: UserRoles): boolean => {

        if (role !== UserRoles.maisonsec) {
            return false;
        }
        const eventCreatedBy = this.state.event.createdBy!;
        const label = GetEventStatusLabel(this.state.event.eventsStatus!) as EventStatus;

        return (
            label === EventStatus.pendingMaison ||
            (eventCreatedBy != null && eventCreatedBy === LocalStorageTokenService.getUserNameToken())
        );
    }

    isEventCancelled = () => {
        const eventStstusLen = this.state.event.eventsStatus?.length;
        if(this.state.event.eventsStatus && eventStstusLen && eventStstusLen > -1) return this.state.event.eventsStatus[eventStstusLen-1].status?.flow.id === Flow.Cancelled;
        return false;
    }
    
    componentDidMount() {
        if(this.state.event.eventsStatus) {

            this.setState({
                isReadOnly: !this.canEdit(SessionStorageTokenService.getUserRoleToken() as UserRoles)
            })
        }
        if(!this.state.event.eventsStatus) {
            const eventId = Number(this.state.event.id);
            NewEventService.getById(eventId).subscribe(res => {
                this.setState({
                    event: res
                })
        }, (error) => { LoggingServie.LogError(error, ''); })
        }
    }

    render() {
        return (
            <div>
                {this.state.isReadOnly !== undefined && (
                    <div>
                        {this.props.event && this.state.isReadOnly ? (
                            <React.Fragment>
                                {this.state.event && (
                                    <DeclarationRead  {...this.props} />
                                )}
                            </React.Fragment>
                        ) : (
                            <DeclarationNewEdit isCancelled={this.isEventCancelled()} props={this.props}/>
                        )}
                    </div>
                )}
        </div>

        )
    }
}

export default Declaration;