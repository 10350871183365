import ClearIcon from '@material-ui/icons/Clear';
import React, { useCallback } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { formatBytes } from '../../../helpers/HelpersFunc';
import { DocAccessType, UserRoles } from '../../../models/enums';
import { FileTypesAccepted } from '../../../models/interfaces';
import { UiSelectField } from '../../fields/UiSelectField';
import { CustomFile } from '../interfaces';
import { PdfViewer } from '../../../../shared/components/pdfViewer/PdfViewer'
import './DocumentsListing.scss';

interface Props {
    files: CustomFile[];
    fileTypesAccepted: FileTypesAccepted;
    download: (file: CustomFile) => void;
    updateAccessType: (event: any, file: CustomFile) => void;
    deleteFile: (file: CustomFile) => void;
    renderProgress: (file: CustomFile) => void;
    disabled? : boolean;
    canDelete? : boolean;
}

export const DocumentsListing = (props: Props) => {    
    const {role} = useAuthContext()
    const isAllowToEdit = useCallback( (file: CustomFile) => {
        if(role && [UserRoles.underwriter, UserRoles.broker, UserRoles.riskassessor1].includes(role)){
            if(Number(file.docAccessType) === DocAccessType['Security concept']){
                return false;
            }
        }
        return true;
    }, [role])

    const isAllowToDelete = (file: CustomFile): boolean => {

        if (props.canDelete === undefined) {
            return file.canBeDeleted === undefined || file.canBeDeleted;
        }

        return props.canDelete;
    }

    return (
        <React.Fragment>
            { props.files?.map(file => {
                return (
                    <div key={file.name + file.id} className="row">
                        <PdfViewer file={file}>
                            <div className="upload__icon">
                                {
                                    Object.values(props.fileTypesAccepted.document).indexOf(file.name.split('.').pop()!) > -1 ?
                                    <i className="fa icon fa-file-text-o fa-2x" aria-hidden="true" onClick={() => props.download(file)}></i> :
                                    Object.values(props.fileTypesAccepted.image).indexOf(file.name.split('.').pop()!) > -1 ?
                                    <i className="fa icon fa-file-image-o fa-2x" aria-hidden="true" onClick={() => props.download(file)}></i> :
                                    <i className="fa icon fa-file-video-o fa-2x" aria-hidden="true" onClick={() => props.download(file)}></i>
                                }
                                {
                                    file.encryptionDone && (
                                        <i className="fa fa-lock icon Encrypting Lock" aria-hidden="true"></i>
                                    )
                                }
                            </div>
                        </PdfViewer>

                        <div className="upload__infos">
                            <span className="Filename">{file.name.substring(0, file.name.lastIndexOf('.'))}</span>
                            <span className="UpperCase">{file.name.split('.').pop()}</span>
                            <span className="Filesize">{formatBytes(file.size)}</span>
                        </div>

                        <div className="upload__select">
                            {
                                !file.encrypting && !file.uploading ? (
                                    <>
                                       {isAllowToEdit(file) && <> <UiSelectField
                                            disabled={props.disabled}
                                            //if docAccesType is undefined SelectField component raises an error.
                                            //we must provide a default value ('') not undefined
                                            name="selectDocumentType"
                                            value={file.docAccessType || ''} 
                                            label="Document Type"
                                            options={file.docRights ?? []}
                                            onChange={(event:any) => props.updateAccessType(event, file)}
                                            enableValue_None={false}/>
                                            {
                                                (isAllowToDelete(file)) ? (
                                                    <button className="btn" type="button" onClick={() => props.deleteFile(file)}><ClearIcon /></button>
                                                ) : ''
                                            }
                                        </>}
                                        {!isAllowToEdit(file) && <div className="document-listing__access-label">{DocAccessType[file.docAccessType]}</div>}
                                    </>
                                ) : file.uploading ? (
                                    <>
                                        {props.renderProgress(file)}
                                    </>
                                ) : (
                                    <div className="Encrypting">
                                        <i className="fa fa-circle" aria-hidden="true"></i>
                                        <label>Encrypting file </label>
                                        <img style={{height: "15px"}} src="/assets/oxygen-loader.gif" alt='encryting'/>
                                    </div>
                                )
                            }
                        </div>

                    </div>
                );
            })}
        </React.Fragment>
    )
}