import React, { ReactNode } from 'react';
import UiTooltip from './UITooltip';
type IPropsUiTooltipLabel = {
    label: string | ReactNode;
    tooltip: any;
}

const UiTooltipLabel = (props: IPropsUiTooltipLabel) => {
    if (!props.label) return null;
    return (
        <span className="ui-tooltip-label">
            <span className="ui-tooltip-label__label">{props.label} </span>
            <span className="ui-tooltip-label__icon"><UiTooltip title={props.tooltip} /></span>
        </span>
    )
}
export default UiTooltipLabel;