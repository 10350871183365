import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Field, Formik } from "formik";
import React, { Fragment, useRef, useState } from "react";
import { Subscription } from "rxjs";
import { useLoadingContext } from "../../../routes/LoadingContext";
import { UiSelectField, UiSwitchField } from "../../../shared/components/fields";
import { VisibleRolesToEdit } from "../../../shared/config/administrationConfig";
import { useAuthContext } from "../../../shared/contexts/AuthContext";
import { useConfirmation } from "../../../shared/contexts/ConfirmationContext";
import { UserRolesToDisplay } from "../../../shared/models/enums";
import { MyField, SubSection } from "../../../shared/models/interfaces";
import { getMappingRoles } from "../../../shared/models/records";
import { AdministrationService } from "../../../shared/services/administration.service";
import { FormObserver } from "../CommonMgmt/FormObserver";
import { defaultFieldFormValues, FieldFormValues, FieldsVisibilityFormStructure } from "./Fields.type";

const tableHeader = ['Section', 'Sub-section', 'Field', 'Depends-on', 'Mandatory', 'Visible'];
const rolesToEdit = VisibleRolesToEdit;

type FieldTableRowProps = {
    className?: string,
    row: MyField,
    sectionIndex: number,
    subsectionIndex?: number,
    fieldIndex: number,
}


const FieldTableRow = ({className, row, sectionIndex, subsectionIndex, fieldIndex}: FieldTableRowProps) => {
    return (
        <TableRow className={className}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.dependsOn}</TableCell>
            <TableCell align="center">
                {row.mandatory ? 'Yes' : 'No'}
            </TableCell>
            <TableCell align="center">
                {
                    subsectionIndex === undefined || subsectionIndex === null
                    ? <Field
                        id={`section-${sectionIndex}-field-${fieldIndex}-switch`}
                        name={row.id}
                        component={UiSwitchField}
                    />
                    : <Field
                        id={`section-${sectionIndex}-subSection-${sectionIndex}-field-${fieldIndex}-switch`}
                        name={row.id}
                        component={UiSwitchField}
                    />
                }
            </TableCell>
        </TableRow>
    )
}

const SubSectionTableRow = ({className, row, subSectionIndex, index}: {className?: string, row: SubSection, subSectionIndex: number, index: number}) => {

    return (
        <>
            <TableRow className={className}>
                <TableCell className={`sticky-cell sticky-cell--${index}`}>{row.name}</TableCell>
                <TableCell colSpan={4}></TableCell>
            </TableRow>
            {
                row.fields && (
                    <>
                        <TableRow className={className}>
                            <TableCell rowSpan={row.fields.length+1}></TableCell>
                        </TableRow>
                        {
                            row.fields.map((field, pos) => (
                                <Fragment key={`field-${index}-${pos}`}>
                                    <FieldTableRow className={className} row={field} subsectionIndex={subSectionIndex} sectionIndex={index} fieldIndex={pos} />
                                </Fragment>
                            ))
                        }
                    </>
                )
            }
        </>
    )
}

export const FieldsMgmt = () => {
    const [selectedRole, setSelectedRole] = useState<number | string>('');
    const [disabled, setDisabled] = useState(true);
    const [initchange, setInitchange] = useState(true);
    const rolesList = rolesToEdit.map((role, index) => ({
        id: getMappingRoles(role), 
        itemContent: UserRolesToDisplay[role],
        role
    }));
    const [initialValues, setInitialValues] = useState(defaultFieldFormValues);
    const [backupInitialValues, setBackupInitialValues] = useState(defaultFieldFormValues);
    const fieldSubscription = useRef<Subscription>()
    const loadingCtx = useLoadingContext()
    const confirm = useConfirmation()
    const {roleId, setFieldsVisibility} = useAuthContext()
    const submitForm = (formValues: FieldFormValues) => {
        loadingCtx.startLoading()
        if(fieldSubscription.current) fieldSubscription.current.unsubscribe()
        fieldSubscription.current = AdministrationService.updateFields(Number(selectedRole), formValues).subscribe((_)=>{
            loadingCtx.stopLoading()
            setDisabled(true)
            setBackupInitialValues(formValues);
            if(roleId === Number(selectedRole)){
                setFieldsVisibility(formValues)
            }

        }, ()=>{
            loadingCtx.stopLoading()
            handleClear()
        })
        //TODO: call service to save in the server
    };
    const handleRoleChange = async (ev: React.ChangeEvent<HTMLSelectElement>) => {
        if(!disabled){
            const ok  = await confirm({
                title: "Unsaved changes", 
                description: "You have made changes on this page, but they have not been saved. Are you sure you want to leave the page?",
                confirmText: "Leave"
            })
            if(!ok) return;
        }
        setSelectedRole(Number(ev.target.value));
        setInitchange(true);
        setDisabled(true)
        setInitialValues({})
        if(fieldSubscription.current) fieldSubscription.current.unsubscribe()
        fieldSubscription.current = AdministrationService.getFields(Number(ev.target.value)).subscribe((fields)=>{
            setInitialValues(fields)
            setBackupInitialValues(fields)
        })
    }

    const getTotalCount = (subSections: SubSection[]): number => {
        let count = 0;
        subSections.forEach(subsection => {
            count ++;
            count = count+subsection.fields.length+1;
        });
        return count;
    }

    const handleFormChange = (values: FieldFormValues) => {
        // If there are no values, it means that the form has just been reset. (The user group has just changed)
        if(values){
             if(initchange){
                setInitchange(false)
            }else{
                // CHECK IF VALUE IS DIFFRENT THAN SAVED VALUE
                if(JSON.stringify(backupInitialValues) !== JSON.stringify(values)){
                    setDisabled(false)
                }else{
                    setDisabled(true)
                }
            }
        }
    }

    const handleClear = async () => {
        if(backupInitialValues){
            setInitialValues(backupInitialValues);
        }
        setTimeout(() => {
            setDisabled(true);
        })
    }
    return (
        <div className="fields-management">
            <div className="role-select-div">
                <UiSelectField
                    name="role-select"
                    label="Select role"
                    value={selectedRole}
                    onChange={handleRoleChange}
                    enableValue_None={false}
                    options={rolesList}
                />
            </div>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={submitForm}
                validateOnChange={false}
                validateOnBlur={true}
                touched
            >
                {({handleSubmit, values}) => {
                    return (
                        <>
                            {
                                selectedRole !== undefined && typeof selectedRole === 'number' && Object.keys(values).length &&(
                                    <>
                                        <div className="tableFixHead">
                                            <Table id="fields-table">
                                                <TableHead>
                                                    <TableRow>
                                                        {tableHeader.map(header => (
                                                            <TableCell key={`header-${header}`}>{header}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                    {
                                                        FieldsVisibilityFormStructure.map((row, index) => (
                                                            <TableBody key={`section-${index}`} className={`section-${index}`}>
                                                                <TableRow>
                                                                    <TableCell className="sticky-cell">{row.section}</TableCell>
                                                                    <TableCell colSpan={5}></TableCell>
                                                                </TableRow>
                                                                {
                                                                    row.fields && (
                                                                        <>
                                                                            <TableRow>
                                                                                <TableCell rowSpan={row.fields.length+1} colSpan={2}></TableCell>
                                                                            </TableRow>
                                                                            {
                                                                                row.fields?.map((field, index2) => (
                                                                                    <Fragment key={`section-field-${index}-${index2}`}>
                                                                                        <FieldTableRow
                                                                                            row={field}
                                                                                            sectionIndex={index}
                                                                                            fieldIndex={index2}
                                                                                        />
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    row.subSections && (
                                                                        <>
                                                                            <TableRow>
                                                                                <TableCell rowSpan={getTotalCount(row.subSections)+1}></TableCell>
                                                                            </TableRow>
                                                                            {
                                                                                row.subSections.map((subsection, pos) => (
                                                                                    <SubSectionTableRow
                                                                                        key={`section-subsection-${index}-${pos}`}
                                                                                        index={index}
                                                                                        subSectionIndex={pos}
                                                                                        row={subsection}
                                                                                    />
                                                                                ))
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </TableBody>
                                                        ))
                                                    }
                                            </Table>
                                        </div>
                                        <div className="actions-sections">
                                            <Button
                                                id="fields-clear"
                                                name="clear"
                                                variant="outlined"
                                                color="primary"
                                                onClick={handleClear}
                                            >
                                                Clear changes
                                            </Button>
                                            <Button
                                                id="fields-save"
                                                name="save"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleSubmit()}
                                                disabled={disabled}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </>
                                )
                            }
                            <FormObserver onChange={handleFormChange}/>
                        </>
                    )
                }}
            </Formik>
        </div>
    );
};
