import React, { createContext, FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Subscription } from "rxjs";
import { IUiMultiselectOption } from "../shared/components/fields/UiAutocompleteField";
import {
    CountryDto,
    MaisonDto,
    NotificationDto,
    ReminderDto,
    TemplateDto,
    UserDto,
    LoadableItems,
    LoadableItemsWithOptions,
    PagedResponse, LoadablePagedItems
} from "../shared/models/interfaces";
import { AdministrationService } from "../shared/services/administration.service";
import { FlowRefService } from "../shared/services/flowRefService";
import { Reference, ReferencesService, Role } from "../shared/services/referencesService";

export interface IAdministrationContext {
    role: LoadableItemsWithOptions<Role>
    task: LoadableItemsWithOptions<Reference>
    template: LoadableItemsWithOptions<TemplateDto>
    notification: LoadableItems<NotificationDto>
    reminder: LoadableItems<ReminderDto>
    maison: LoadableItems<MaisonDto>,
    country: LoadableItems<CountryDto>
    riskLevels: LoadableItemsWithOptions<Reference>
    isLoading: boolean;
}

export const AdministrationContext = createContext<IAdministrationContext>({} as IAdministrationContext);

export const AdministrationContextProvider: FC = ({ children }) => {

    const [roles, setRoles] = useState<Role[]>([]);
    const [roleOptions, setRolesOptions] = useState<IUiMultiselectOption[]>([]);
    const rolesSubscription = useRef<Subscription>();
    const [roleLoading, setRoleLoading] = useState(true);
    
    const [templateOptions, setTemplateOptions] = useState<IUiMultiselectOption[]>([]);
    const [templates, setTemplates] = useState<TemplateDto[]>([]);
    const templateSubscription = useRef<Subscription>();
    const [templateLoading, setTemplateLoading] = useState(true);
  
    const [tasks, setTasks] = useState<Reference[]>([]);
    const [tasksOptions, setTasksOptions] = useState<IUiMultiselectOption[]>([]);
    const taskSubscription = useRef<Subscription>();
    const [taskLoading, setTaskLoading] = useState(true);

    const [notifications, setNotifications] = useState<NotificationDto[]>([]);
    const notificationSubscription = useRef<Subscription>();
    const [notificationLoading, setNotificationLoading] = useState(true);

    const [reminders, setReminders] = useState<ReminderDto[]>([]);
    const reminderSubscription = useRef<Subscription>();
    const [reminderLoading, setReminderLoading] = useState(true);

    const [maisons, setMaisons] = useState<MaisonDto[]>([]);
    const maisonSubscription = useRef<Subscription>();
    const [maisonLoading, setMaisonLoading] = useState(true);

    const [countries, setCountries] = useState<CountryDto[]>([]);
    const countriesSubscription = useRef<Subscription>();
    const [countriesLoading, setCountriesLoading] = useState(true);

    const [riskLevels, setRiskLevels] = useState<Reference[]>([]);
    const [riskLevelsOptions, setRiskLevelsOptions] = useState<IUiMultiselectOption[]>([]);
    const riskLevelSubscription = useRef<Subscription>();
    const [riskLevelsLoading, setRiskLevelsLoading] = useState(true);



    const loadRoles = () => {
        setRoleLoading(true);
        rolesSubscription.current?.unsubscribe();
        rolesSubscription.current = ReferencesService.getAllRoles().subscribe((result) => {
            setRoles(result);
            setRolesOptions(result.map((r) => ({ label: r.applicationRole, value: r.appId })));
            setRoleLoading(false);
        });
    };

    const loadTemplates = () => {
        setTemplateLoading(true);
        templateSubscription.current?.unsubscribe();
        templateSubscription.current = AdministrationService.getTemplates().subscribe((result) => {
            setTemplates(result);
            setTemplateOptions(result.map((r) => ({ label: r.name, value: r.id })));
            setTemplateLoading(false);
        });
    };
    const loadTasks = () => {
        setTaskLoading(true);
        taskSubscription.current?.unsubscribe();
        taskSubscription.current = FlowRefService.getAllTasks().subscribe((result) => {
            setTasks(result);
            setTasksOptions(result.map((r) => ({ label: r.itemContent, value: r.id })));
            setTaskLoading(false);
        });
    };

    const loadRiskLevels = () => {
        setRiskLevelsLoading(true)
        riskLevelSubscription.current?.unsubscribe()
        riskLevelSubscription.current = ReferencesService.GetAllRiskLevel().subscribe(riskLevels=>{
            setRiskLevels(riskLevels)
            setRiskLevelsOptions(riskLevels.map((r) => ({label: r.itemContent, value: r.id})))
            setRiskLevelsLoading(false)
        })
    }

    const loadNotifications = () => {
        setNotificationLoading(true);
        notificationSubscription.current?.unsubscribe();
        notificationSubscription.current = AdministrationService.getNotifications().subscribe((result) => {
            setNotifications(result);
            setNotificationLoading(false);
        });
    };
    const loadReminders = () => {
        setReminderLoading(true);
        reminderSubscription.current?.unsubscribe();
        AdministrationService.getReminders().subscribe()
        reminderSubscription.current = AdministrationService.getReminders().subscribe((result) => {
            setReminders(result);
            setReminderLoading(false);
        });
    };

    const loadMaisons = () => {
        setMaisonLoading(true);
        maisonSubscription.current?.unsubscribe();
        maisonSubscription.current = AdministrationService.getAllMaisons().subscribe((result) => {
            setMaisons(result);
            setMaisonLoading(false)
        });
    }

    const loadCountries = () => {
        setCountriesLoading(true);
        countriesSubscription.current?.unsubscribe();
        countriesSubscription.current = AdministrationService.getAllCountries().subscribe((result) => {
            setCountries(result);
            setCountriesLoading(false)
        });
    }

    const isLoading = useMemo(() => {
        return roleLoading || templateLoading || taskLoading || notificationLoading || reminderLoading;
    }, [notificationLoading, reminderLoading, roleLoading, taskLoading, templateLoading]);


    // Cleanup function
    useEffect(() => {
        return () => {
            [
                rolesSubscription,
                templateSubscription,
                taskSubscription,
                notificationSubscription,
                reminderSubscription,
                riskLevelSubscription,
            ].forEach((subscription) => {
                if (subscription.current) {
                    subscription.current.unsubscribe();
                }
            });
        };
    }, []);
    useEffect(() => {
        loadRoles();
        loadTasks();
        loadTemplates();
        loadNotifications();
        loadReminders();
        loadMaisons();
        loadCountries()
        loadRiskLevels()
        return () => {
            [rolesSubscription, taskSubscription, templateSubscription, notificationSubscription, reminderSubscription,
            maisonSubscription, countriesSubscription, riskLevelSubscription].forEach(sub=>{
                if(sub.current){
                    sub.current.unsubscribe()
                }
            })
        };
    }, []);

    return (
        <AdministrationContext.Provider
            value={{
                template: {
                    items: templates,
                    options: templateOptions,
                    isLoading: templateLoading,
                    load: loadTemplates
                },
                role: {
                    items: roles,
                    options: roleOptions,
                    isLoading: roleLoading,
                    load: loadRoles
                },
                task: {
                    items: tasks,
                    options: tasksOptions,
                    isLoading: taskLoading,
                    load: loadTasks,
                },
                notification: {
                    items: notifications,
                    isLoading: notificationLoading,
                    load: loadNotifications,
                },
                reminder: {
                    items: reminders,
                    isLoading: reminderLoading,
                    load: loadReminders,
                },
                maison: {
                    items: maisons,
                    isLoading: maisonLoading,
                    load: loadMaisons
                },
                country: {
                    items: countries,
                    isLoading: countriesLoading,
                    load: loadCountries
                },
                riskLevels: {
                    items: riskLevels,
                    isLoading: riskLevelsLoading,
                    load: loadRiskLevels,
                    options: riskLevelsOptions
                },
                isLoading
            }}
        >
            {children}
        </AdministrationContext.Provider>
    );
};

export const useAdministrationContext = () => useContext(AdministrationContext);
