import React from 'react';
import { TableCell } from "@material-ui/core";
import { IUiTableHead } from './types';
import { accessValue } from './UiTableHelper';
export type UiTableCellProps<T = any> = {
    index: number,
    head: IUiTableHead   
} & T;
export const UiTableCell = (props:UiTableCellProps<any>) => {
    const cell = props.head.cell ? props.head.cell : false
    const value = props.head.accessor ? accessValue(props, props.head.accessor) : false
    return (
    <TableCell className={'ui-tablecell ui-tablecell--'+props.head.name}>
        {cell && cell(props)}
        {!cell && value }
    </TableCell>
    )
}
export default UiTableCell;
