import React, { ReactNode, FC } from "react"
import { Box, Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

type IPropsUiBox = {
    id?: string,
    name? : string,
    title? : string | ReactNode,
    actions? : ReactNode,
    altStyle?: boolean
    collapsed?: boolean
    filterActivated?: string
    deleteKpiFilter?(): void
}
export const UiBox :FC<IPropsUiBox> = (props) => {
    const cssClass = ['box']
    if(props.name){
        cssClass.push(props.name)
    }
    if(props.altStyle){
        cssClass.push('box--alt')
    }
    if(props.collapsed){
        cssClass.push('collapsed')
    }
    return(
        <Box id={props.id} className={cssClass.join(' ')}>
            <div className="box-header">
                {
                    props.title &&
                    <h2 className="box-header__title">{props.title}</h2>
                }
                {
                    props.filterActivated &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Delete/>}
                        onClick={props.deleteKpiFilter}
                        style={{padding: "8px 18px"}}
                    >
                        KPI Filter
                    </Button>
                }
                {props.actions && (
                    <div className="box-header__actions">
                        {props.actions}
                    </div>
                )}
            </div>
            <div className="box-content">
                {props.children}
            </div>
        </Box>
    )

}

export default UiBox;