import { Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { UiAutocompleteField, UiNumberField, UiSwitchField } from '../../../shared/components/fields';
import { IUiMultiselectOption } from '../../../shared/components/fields/UiAutocompleteField';
import { Errors } from '../../../shared/constants';
import { UserGroup } from '../../../shared/models/enums';
import { ReminderDto } from '../../../shared/models/interfaces';
import { useAdministrationContext } from '../../AdministrationContext';
import { IAddOrEditForm } from '../../types';
import { AddOrEditForm } from '../CommonMgmt/AddOrEditForm';
import { recipientItemsToOption, relationItemToOption } from '../CommonMgmt/MgmtHelpers';




export type ReminderFormValue = {
    template:  IUiMultiselectOption | null
    nextAction: IUiMultiselectOption | null,
    recipients: IUiMultiselectOption[] | null
    active?: boolean,
    frequency?: number,
}


const validationSchema = Yup.object().shape<ReminderFormValue>({
    template: Yup.object<IUiMultiselectOption>().nullable(true).required(Errors.mandatory),
    nextAction: Yup.object<IUiMultiselectOption>().nullable().required(Errors.mandatory),
    recipients: Yup.array<IUiMultiselectOption>().nullable(true).required(Errors.mandatory),
    active: Yup.boolean(),
    frequency: Yup.number().min(1).max(99)
});

const currentValueFromItem = (item: ReminderDto): ReminderFormValue => {
    return {
        template: relationItemToOption(item.template),
        recipients: recipientItemsToOption(item.recipients),
        nextAction: { label: item.nextAction.itemContent, value: item.nextAction.id},
        active: item.active,
        frequency: item.frequency,
    }
}


export const ReminderForm = (props: IAddOrEditForm<ReminderDto>) => {

    const adminCtx = useAdministrationContext();
    const formInitialValues: ReminderFormValue = props.item ? currentValueFromItem(props.item) : {
        template: null,
        recipients: [],
        nextAction: null,
        active: true,
        frequency: 1
    };

    let filteredRoles = adminCtx.role.options
        .filter((role: any) => role.label !== null && role.value !== UserGroup.director);
    
    return (
        <AddOrEditForm initialValues={formInitialValues} validationSchema={validationSchema}>
            <Field id="reminder-template" label="Template name*" name="template" options={adminCtx.template.options} component={UiAutocompleteField} />
            <Field id="reminder-next-action" label="Next action*" name="nextAction" options={adminCtx.task.options} component={UiAutocompleteField} />
            <Field id="reminder-roles" label="Role recipient*" name="recipients" options={filteredRoles} multiple component={UiAutocompleteField} />
            <div className="row--flex valign--center">
                <Field id="reminder-active" label="Active" name="active" component={UiSwitchField} />
                <Field id="reminder-frequency" label="Frequency*" name="frequency" min={1} max={99} integer={true} component={UiNumberField} suffix="days"/>
            </div>
        </AddOrEditForm>
    )
}
