import React from 'react';
import { DeclarationInterface, EventPhaseItem, SecuritySystemDevicesItem } from '../../declarationNewEdit/interfaces';
import { UiSectionTitle } from '../../../shared/components/ui/UiSectionTitle';
import { Chip } from '@material-ui/core';
import { Reference } from '../../../shared/services/referencesService';
import ConditionalChildren from '../../../shared/components/ConditionalReadOnlyField';
import { FIELD_IDS } from '../../../admin/components/FieldsMgmt/Fields.type';
import {UiTextareaField} from "../../../shared/components/fields";
import {MAX_TEXTFIELD_CHARS} from "../../../shared/constants";

type Insurance = {
    isFromInsuranceTeam: boolean
}

export const SecurityAndSurveillance = (props: DeclarationInterface & Insurance) => (
    <React.Fragment>
        <section className="section">
            <table>
                <thead>
                    <tr>
                        <ConditionalChildren fieldId={FIELD_IDS.securityConcept.author}>
                            <th>Author(s)</th>
                        </ConditionalChildren >
                        <ConditionalChildren fieldId={FIELD_IDS.securityConcept.validator}>
                            <th>Validator(s)</th>
                        </ConditionalChildren >
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <ConditionalChildren fieldId={FIELD_IDS.securityConcept.author}>
                            <td>
                                {props.securityConcept?.authors.map((author: Reference) => (
                                    <Chip key={author.id} label={ author.itemContent } ></Chip>
                                ))}
                            </td>
                        </ConditionalChildren >
                        <ConditionalChildren fieldId={FIELD_IDS.securityConcept.validator}>
                            <td>
                                {props.securityConcept?.validators.map((validator: Reference) => (
                                    <Chip key={validator.id} label={ validator.itemContent }></Chip>
                                ))}
                            </td>
                        </ConditionalChildren >
                    </tr>
                </tbody>
            </table>
        </section>
        <section className="section">
            <UiSectionTitle>Security Staff deployment</UiSectionTitle>
            {!props.isFromInsuranceTeam &&
                <table>
                    <thead>
                    <ConditionalChildren fieldId={FIELD_IDS.securityStaff.eventPhaseList}>
                        <tr>
                            <th>Event phases</th>
                            <th>Number of security agents</th>
                        </tr>
                    </ConditionalChildren>
                    </thead>
                    <tbody>
                    <ConditionalChildren fieldId={FIELD_IDS.securityStaff.eventPhaseList}>
                        {props.securityStaffDeployment?.eventPhases?.eventPhasesList?.map((eventPhase: EventPhaseItem) => (
                            <React.Fragment key={eventPhase.eventPhase}>
                                <tr key={eventPhase.id}>
                                    <td>{eventPhase.eventPhase}</td>
                                    <td>{eventPhase.numberOfSecurityAgents}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                        <tr className="total">
                            <td><strong>Total/Estimated Amount</strong></td>
                            <td><strong>{props.securityStaffDeployment?.eventPhases?.total}/{props.securityStaffDeployment?.eventPhases?.totalGuardingCostEstimated}</strong></td>
                        </tr>
                    </ConditionalChildren>
                    <ConditionalChildren fieldId={FIELD_IDS.securityStaff.hasSecurityService}>
                        <tr>
                            <td><strong>Security service on site</strong></td>
                            <td className={(props.securityStaffDeployment?.hasSecurityService ? 'yes' : 'no')}>{props.securityStaffDeployment?.hasSecurityService ? 'yes' : 'no'}</td>
                        </tr>
                    </ConditionalChildren>
                    </tbody>
                </table>
            }
            <ConditionalChildren fieldId={FIELD_IDS.securityStaff.securityServiceDesc}>
                <p>{props.securityStaffDeployment?.securityServiceDesc}</p>
            </ConditionalChildren>
            <ConditionalChildren fieldId={FIELD_IDS.securityStaff.securityStaffDeployment}>
                <UiTextareaField
                    value={props.securityStaffDeployment?.securityStaffDeployment || ''}
                    label="Security Staff deployment for broker"
                    disabled={true}
                    placeholder="Security Staff deployment to send to broker…"
                    inputProps={{maxLength: MAX_TEXTFIELD_CHARS}}
                />
            </ConditionalChildren>
        </section>
        <UiSectionTitle>Technical Surveillance System</UiSectionTitle>
        {!props.isFromInsuranceTeam &&
            <table>
                <thead>
                <ConditionalChildren fieldId={FIELD_IDS.securitySurveillance.securitySystemDevicesList}>
                    <tr>
                        <th>Service system device</th>
                        <th>Installation company</th>
                        <th>Quantity</th>
                    </tr>
                </ConditionalChildren>
                </thead>
                <tbody>
                <ConditionalChildren fieldId={FIELD_IDS.securitySurveillance.securitySystemDevicesList}>
                    {props.technicalSurveillanceSystem?.securitySystemDevicesList?.map((systemDevice: SecuritySystemDevicesItem) => (
                        <React.Fragment key={systemDevice.securitySystemDevice}>
                            <tr>
                                <td>{systemDevice.securitySystemDevice}</td>
                                <td>{systemDevice.installationCompany}</td>
                                <td>{systemDevice.quantity}</td>
                            </tr>
                        </React.Fragment>
                    ))}
                </ConditionalChildren>
                <ConditionalChildren fieldId={FIELD_IDS.securitySurveillance.hasSecurity}>
                    <tr>
                        <td><strong>Security service on site</strong></td>
                        <td className={(props.technicalSurveillanceSystem?.hasSecurityService ? 'yes' : 'no')}>{props.technicalSurveillanceSystem?.hasSecurityService ? 'yes' : 'no'}</td>
                        <td></td>
                    </tr>
                </ConditionalChildren>
                </tbody>
            </table>
        }
        <ConditionalChildren fieldId={FIELD_IDS.securitySurveillance.securityServiceDesc}>
            <p>{props.technicalSurveillanceSystem?.securityServiceDesc}</p>
        </ConditionalChildren >
        <ConditionalChildren fieldId={FIELD_IDS.securitySurveillance.technicalSurveillanceSystemInformation}>
            <UiTextareaField
                value={props.technicalSurveillanceSystem?.technicalSurveillanceSystemInformation || ''}
                label="Technical Surveillance System information for broker"
                disabled={true}
                placeholder="Technical Surveillance System information to send to broker…"
                inputProps={{maxLength: MAX_TEXTFIELD_CHARS}}
            />
        </ConditionalChildren>
    </React.Fragment>
)