import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import UiFieldWrapper from './UiFieldWrapper';
import {UiBaseField} from './UiBaseField';
import { Chip } from '@material-ui/core';
import { getFieldData } from './UiFieldHelpers';
import { Reference } from '../../services/referencesService';
export type IUiMultiselectOption = {
    value: any,
    label: string,
    id?: any
    itemContent?: string
}

type IMultiSelectOptionItem = IUiMultiselectOption | string

export type IPropsUiAutocompleteField = UiBaseField &  {
    options: Array<IMultiSelectOptionItem>,
    multiple?: any,
    placeholder?: any,
    inputValue?: string,
    getOptionLabel?: (option: any) =>  string,
    onCustomSelect?: (value: Array<IUiMultiselectOption> | IUiMultiselectOption | Array<Reference> | Reference, eventId: number) => {},
    onInputChange?: (value: string) => void
    loading?: boolean
} 
export const UiAutocompleteField = (props: IPropsUiAutocompleteField) => {
    const {error, name, value} = getFieldData(props);
    
    const handleChange = (event: any, option: any) => {
        if(props.form){
            props.form.setFieldValue(name, option)
        }
        if(props.onChange){
            props.onChange(event,option)
        }
        if(props.onCustomSelect && props.form) {
            props.onCustomSelect(option, props.form.values.id);
        }
    }

    const getValue = (valuesList:any) => {
        if(props.multiple){
            return props.options.filter((option: string | IUiMultiselectOption)=>{
                if(typeof option !== "string"){
                    return valuesList?.filter((valueSelected:any) => (valueSelected?.id && valueSelected.id === option.id) || (valueSelected?.value && valueSelected.value === option.value))[0];
                }else{
                    return valuesList?.filter((valueSelected:any) => (valueSelected && valueSelected === option) || (valueSelected && valueSelected === option))[0];
                }
            })
        }
        return valuesList;
    }

    const getOptionLabel = (props.getOptionLabel) ? props.getOptionLabel : (option:any) : string => {
        if(typeof option === "string"){
            return option
        }else{
            return option.itemContent ?? option.label
        }
    }
    
    const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        if(event && props.onInputChange) {
            props.onInputChange(value);
        }
    }
    const getOptionSelected = useCallback( (option:IUiMultiselectOption | string, value:IUiMultiselectOption | string) => {
        if(typeof option !== "object" || typeof value !=="object"){
            return option === value;
        }else if(option.value){
            return option.value === value.value;
        }else{
            return option.id === value.id
        }
    }, [])
    return (
        <UiFieldWrapper name="ui-autocomplete" {...props}>
            <SearchIcon className="ui-autocomplete__icon" />
            <Autocomplete
                fullWidth
                id={props.id}
                disabled={props.disabled}
                multiple={props.multiple}
                className="ui-search-multiselect"
                value={getValue(value)}
                options={props.options}
                getOptionSelected={getOptionSelected}
                getOptionLabel={(option) => getOptionLabel(option)}
                onInputChange={handleInputChange}
                inputValue={props.inputValue}
                onChange={handleChange}
                loading={props.loading}
                renderTags={(value: IMultiSelectOptionItem[], getTagProps) =>
                    value.map((option: IMultiSelectOptionItem, index: number) => (
                      <Chip label={getOptionLabel(option)} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        error={error.hasError}
                        // if errorMessage is an object Error will be raised.
                        // this can occur when yup has object.shape in object.shape with several field.
                        // In this case errorMessage is an object with several error messages inside.
                        helperText={error.hasError ? typeof(error.errorMessage) === 'string' ?  error.errorMessage : null : null}
                        variant="outlined"
                        placeholder={props.placeholder}

                    />
                )}
            />
        </UiFieldWrapper>);
}
export default UiAutocompleteField