import React, { useState } from "react";
import { Button, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Add } from '@material-ui/icons';
import {  AdministrationItemContextProvider } from "./AdministrationItemContext";
import { FormikHelpers } from "formik";
import { Accessor } from "../../../shared/components/ui/UiTable/types";

export type AddEditBtnProps<Values=any, T=any> = {
    index?: number,
    title: string,
    item?: T,
    itemLabelProp: Accessor<Values>,
    data?:any,
    form: {
        component: any,
        onCreate: (values: Values, formikHelpers: FormikHelpers<Values>) => void | Promise<any>;
        onDelete?(data:Values): Promise<void | false>
        onUpdate: (values: Values, item: T, formikHelpers: FormikHelpers<Values>) => void | Promise<any>;
    }
}

export const AddEditBtn = ({index, form, title, item, itemLabelProp, data}: AddEditBtnProps) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const ItemForm = form.component

    const handleCreate = async (values: any, helpers: FormikHelpers<any>) => {
        setLoading(true)
        try{
            const ok = await form.onCreate(values, helpers);
            if(ok!==false){
                setOpen(false);
            }
        }finally{
            setLoading(false);
        }
    }
    const handleDelete = async (item: any) => {
        if(!form.onDelete){
            return;
        }

        setLoading(true)
        try{
            const ok = await form.onDelete(item);
            if(ok!==false){
                setOpen(false)
            }
        }finally{
            setLoading(false)
        }
    }
    const handleUpdate=  async (values: any, helpers: FormikHelpers<any>) => {
        setLoading(true);
        try{
            const ok = await form.onUpdate(values, item, helpers)
            if(ok!==false){
                setOpen(false)
            }
        }finally{
            setLoading(false)
        }
    }
    const handleConfirm = (values: any, helpers: FormikHelpers<any>) => {
        if(item){
            values.id = item.id;
            handleUpdate(values, helpers)
        }else{
            handleCreate(values, helpers)
        }
    }

    const deleteOrNull = form.onDelete ? handleDelete : null;

    return (
        <>
            {!item && (
                <Button id={`add-${title.toLocaleLowerCase()}`} color="secondary" variant="outlined" startIcon={<Add />} onClick={() => setOpen(true)}>
                    ADD {title}
                </Button>
            )}
            {!!item && (
                <IconButton id={`edit-${title.toLocaleLowerCase()}-${index}`} aria-label="edit" color="primary" onClick={() => setOpen(true)}>
                    <Edit />
                </IconButton>
            )}
            {open && <AdministrationItemContextProvider value={{ onConfirm: handleConfirm, onDelete: deleteOrNull, loading, data, item, open, title, itemLabelProp, onCancel: () => setOpen(false) }}>
                <ItemForm open={open} onCancel={() => setOpen(false)} item={item} />
            </AdministrationItemContextProvider>}
        </>
    );
}
