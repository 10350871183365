import React, { FC, createContext, useState, useContext, useMemo, useEffect, useCallback } from "react";
import { UserRoles } from "../models/enums";
import { getMappingRoles } from "../models/records";
import CustomAxios from "../helpers/AxiosHelper";
import { AuthAppResponse } from "../services/authentification/authentificationConfig";
import auth from "../services/authentification/authentificationService";
import { CircularProgress, Grid } from "@material-ui/core";
import SessionStorageTokenService from '../helpers/SessionStorageTokenService';
import { AdministrationService } from "../services/administration.service";

export interface IAuthContext {
    role?: UserRoles;
    roleId?: number;
    setRole(role: UserRoles): void;
    isLoading: boolean;
    setIsLoading(val: boolean): void;
    username?: string;
    setUsername(val: string): void;
    isAuth: boolean;
    setIsAuth(isAuth: boolean): void;
    isError: boolean;
    setIsError(isAuth: boolean): void;
    accessToken: string;
    setAccessToken(val: string): void;
    refreshToken: string;
    setRefreshToken(val: string): void;
    fieldsVisibility: any,
    setFieldsVisibility(val: any): void;
    
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider: FC = React.memo(({ children }) => {
    const [role, setRole] = useState<UserRoles>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [username, setUsername] = useState<string>();
    const [isAuth, setIsAuth] = useState(false);
    const [isError, setIsError] = useState(false);
    const [refreshToken, setRefreshToken] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [fieldsVisibility, setFieldsVisibility] = useState<any>(null)
    const defineRefreshToken = (token: string) => {
        setRefreshToken(token);
    };
    const defineAccessToken = (token: string) => {
        setAccessToken(token);
        CustomAxios.setAccessToken(token);
    };

    const roleId = useMemo(() => {
        return role ? getMappingRoles(role) : undefined;
    }, [role]);

    const updateContextFromResponse = useCallback(
        (response: AuthAppResponse) => {
            if (response === undefined || response === null) {
                setIsAuth(false);
                setIsError(true);
            } else {
                defineAccessToken(response.token!.AccessToken);
                setIsAuth(!response.isError);
                setIsError(response.isError);
                setRole(response.token!.UserRoles[0] as UserRoles);
                setUsername(response.token!.UserFullName as string);
            }
            setIsLoading(false);
        },
        [setIsAuth, setIsError, setIsLoading, setRole, setUsername]
    );
    useEffect(()=>{
        if(roleId){
            const idGroupUser =roleId || 0;
            AdministrationService.getFields(idGroupUser).subscribe((val)=>{
                setFieldsVisibility(val);
            })
        }
    }, [roleId])
    useEffect(() => {
        auth.refreshToken().then((response: AuthAppResponse) => {
            const authTest = sessionStorage.getItem("auth.test");
            response = authTest ? JSON.parse(authTest) : response;
            if (response.isError) {
                auth.signIn();
            } else {
                SessionStorageTokenService.setToken(response.token!);
                updateContextFromResponse(response);
            }
        });
    }, [updateContextFromResponse]);

    const value: IAuthContext = {
        role,
        setRole,
        isLoading,
        setIsLoading,
        username,
        setUsername,
        isAuth,
        setIsAuth,
        isError,
        setIsError,
        roleId,
        setAccessToken: defineAccessToken,
        setRefreshToken: defineRefreshToken,
        refreshToken,
        accessToken,
        fieldsVisibility,
        setFieldsVisibility
    };

    return (
        <AuthContext.Provider value={value}>
            {isLoading ? (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: "100vh" }}
                >
                    <Grid item xs={3}>
                        <CircularProgress />{" "}
                    </Grid>
                </Grid>
            ) : (
                children
            )}
        </AuthContext.Provider>
    );
});

export const useAuthContext = () => useContext(AuthContext);
