import React, { ReactNode } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

type IPropsUiTooltip = {
    title?: string | ReactNode;
}
export const UiTooltip: React.SFC<IPropsUiTooltip> = (props) => {
    if (!props.title) return null;
    return (
        <Tooltip title={props.title}>
            <HelpOutlineIcon fontSize="small" />
        </Tooltip>

    )
}
export default UiTooltip;