import { Container, Button } from "@material-ui/core";
import { Assignment, ExitToApp as Logout } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppBreadcrumbs } from "../shared/components/MyBreadCrumb";
import { useAuthContext } from "../shared/contexts/AuthContext";
import UiLogo from "../ui/UiLogo";
import auth from "../shared/services/authentification/authentificationService";
import { AllowedAdministrationRoles } from "../shared/config/administrationConfig";
import { Link } from 'react-router-dom';

export const Header = () => {
    const [showDropdownMenu, setShowDropdownMenu] = React.useState(false);
    const history = useHistory();
    const authCtx = useAuthContext();
    useEffect(() => {
        const callback = (event: any) => {
            if (!event.target.classList.contains("header-login")) {
                setShowDropdownMenu(false);
            }
        };

        document.addEventListener("click", callback);
        return () => document.removeEventListener("click", callback);
    }, []);

    const toggleDropdownMenu = () => {
        setShowDropdownMenu(!showDropdownMenu);
    };

    const goToAdminScreen = () => {
        history.push("/admin");
    };
    return (
        <header className="header">
            <Container maxWidth="xl">
                <div className="header-content">
                    <div className="header-logo">
                        <Link id="header-home-link" to="/">
                            <UiLogo />
                        </Link>
                    </div>
                    <div className="header-breadcrumbs">
                        <AppBreadcrumbs />
                    </div>
                    <div className="dropdown">
                        <button className="header-login" type="button" onClick={toggleDropdownMenu}>
                            {authCtx.username}
                        </button>
                        {showDropdownMenu && (
                            <div className="dropdown-menu" style={{ padding: 0 }}>
                                {authCtx.role && AllowedAdministrationRoles.includes(authCtx.role) && (
                                    <Button
                                        id="administration-access-btn"
                                        className="dropdown-item"
                                        startIcon={<Assignment />}
                                        style={{ padding: 10, justifyContent: "flex-start" }}
                                        size="small"
                                        onClick={goToAdminScreen}
                                    >
                                        Administration
                                    </Button>
                                )}
                                <Button
                                    className="dropdown-item"
                                    startIcon={<Logout />}
                                    style={{ padding: 10, justifyContent: "flex-start" }}
                                    size="small"
                                    onClick={() => auth.logOut()}
                                >
                                    Logout
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </header>
    );
};
