import React from 'react';
import { FIELD_IDS } from '../../admin/components/FieldsMgmt/Fields.type';
import { ConditionnalField } from '../../shared/components/ConditionnalField';
import { UiNumberField, UiSwitchField, UiTextareaField } from '../../shared/components/fields';
import { DeclarationProps } from './interfaces';

const EventDescription : React.FC<DeclarationProps>  = (props) => {
        return (
            <React.Fragment>
                <div className="form-group">
                    <ConditionnalField 
                        fieldId={FIELD_IDS.eventDescription.globalDescription}
                        name="declaration.globalDescription" 
                        label="Global description" 
                        placeholder="Placeholder text..." 
                        component={UiTextareaField}
                    />
                </div>
                    <ConditionnalField
                        fieldId={FIELD_IDS.eventDescription.recurrentEvent}
                        name="declaration.recurrentEvent"
                        label="Recurrent Event"
                        component={UiSwitchField}
                        disabled={props.values.isReadOnly}
                        inline={true}
                    />
           
                <ConditionnalField
                    fieldId={FIELD_IDS.eventDescription.exhibitionSurface}
                    name="declaration.exhibitionSurface"
                    label="Exhibition surface area"
                    component={UiNumberField}
                    disabled={props.values.isReadOnly}
                    min={0}
                    suffix="m2"
                />
                <ConditionnalField
                    fieldId={FIELD_IDS.eventDescription.specificEvents}
                    name="declaration.specificEvents"
                    label="Are there specific events or shows during the show ?"
                    component={UiTextareaField}
                    disabled={props.values.isReadOnly}
                />
                <ConditionnalField
                    fieldId={FIELD_IDS.eventDescription.specificShows}
                    name="declaration.specificShows"
                    label="Are there specific shows related to all brands ?"
                    component={UiTextareaField}
                    disabled={props.values.isReadOnly}
                />

            </React.Fragment>
        )
}

export default EventDescription;