import React, {ReactNode} from 'react';
import { FormikProps, Field, FieldAttributes } from 'formik';
import { Table, TableHead, TableBody, TableRow, TableCell, TableFooter, IconButton } from '@material-ui/core';
import { UiNumberField, UiTextField, UiAmountField} from '../../../../shared/components/fields';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Box from '@material-ui/core/Box';

interface CustomInterface{
    list: any[];
    listname: string;
    otherfieldexists: boolean;
    showlastrow: boolean;
    showlastrowsecondfield?: boolean;
    lastrowlabel?: string;
    lastrowname?: string;
    lastrownamesecondfield?: string;
    types: string[];
    originallistsize: number;
    isreadonly: boolean;
    footer: Partial<ReactNode>;
    columnnumbertocalc?: number;
    columnnumbertocalc2?: number;
    columnstoignore: string[];

      // FORMIK CONTEXT
      field?: FieldAttributes<any>
      form?: FormikProps<any>
}
const getComponent = (type:string) => {
    if(type==='number'){
        return UiNumberField;
    }else if(type==='amount'){
        return UiAmountField;
    }else{
        return UiTextField
    }
}

const regex = new RegExp(/(?=[A-Z])/);
export const RichemontTable = (props: CustomInterface) => {
    const propertiesList = props.list && props.list.length ? Object.keys(props.list[0]) : [];
    const colspan = props.showlastrowsecondfield
        ? propertiesList.length-props.columnstoignore.length-2
        : propertiesList.length-props.columnstoignore.length-1

    const setTotalFieldValue = (event: any, index: number, listprop: string, totalpropname: string) => {
        const newArr = Object.values(props.list.map(c => Number(c[listprop])));
        let sum = newArr.reduce((p, c) => p+c ) - Number(newArr[index]) + Number(event.target.value);
        props.form?.setFieldValue(`${props.field.name}.${totalpropname}`, sum)
    }
    const updateTotal = (event: any, index: number, listprop: string, min?: number, max?: number) => {
        if ((min != null && event.target.value < min) || isNaN(event.target.value) ) {
            event.target.value = min;
        } else if (max != null && event.target.value > max) {
            event.target.value = max;
        }
        const value = event.target.value;
        props.form?.setFieldValue(`${props.listname}.${index}.${listprop}`, value);
        
        if(propertiesList.indexOf(listprop) === props.columnnumbertocalc && !isNaN(value) && props.lastrowname ) {
            setTotalFieldValue(event, index, listprop, props.lastrowname);
        } else if (propertiesList.indexOf(listprop) === props.columnnumbertocalc2 && !isNaN(value) && props.lastrownamesecondfield) {
            setTotalFieldValue(event, index, listprop, props.lastrownamesecondfield);
        }
    }

    const removeLine = (rowNumber: number) => {
        props.list.splice(rowNumber, 1);
        props.form?.setFieldValue(props.listname, props.list);
    }

    return (
        <div className="ui-section-content">
            <Table size="small" className="richemont-table">
                <TableHead>
                    <TableRow>
                        {propertiesList.map(p => {
                            if(props.columnstoignore.indexOf(p) < 0)
                                return <TableCell key={p}>{p.charAt(0).toUpperCase() + p.slice(1).split(regex).join(' ')}</TableCell>
                            else return null
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.list ? props.list.map((keyvalue: any, index: number) => (
                            <TableRow key={index}>
                                {
                                    propertiesList.map((listprop: any, propsListIndex: number) => {
                                        if(props.columnstoignore.indexOf(listprop) < 0) {
                                            return (
                                                <TableCell key={propsListIndex}>
                                                    {
                                                        props.types[propsListIndex] === 'view' ?
                                                            index >= props.originallistsize-1 && props.otherfieldexists ?
                                                            (
                                                                <Box alignItems="center" display="flex">
                                                                    <Field
                                                                        size="small"
                                                                        label="other ..."
                                                                        name={`${props.listname}.${index}.${listprop}`}
                                                                        onChange={props.field.onChange}
                                                                        disabled={props.isreadonly}
                                                                        component={UiTextField}
                                                                    />
                                                                    {
                                                                        Object.keys(props.list).length - (props.originallistsize) >= 1 ?
                                                                        
                                                                        <IconButton size="small" onClick={() => removeLine(index)}>
                                                                            <RemoveCircleOutlineIcon />
                                                                        </IconButton>
                                                                        :
                                                                        null
                                                                    }
                                                                </Box>
                                                            ) :
                                                            (
                                                                <label>{keyvalue[listprop]}</label>
                                                            ) :
                                                            <Field name={`${props.listname}.${index}.${listprop}`}
                                                            onChange={(e:any) => updateTotal(e, index, listprop, 0)}
                                                            disabled={props.isreadonly}
                                                            onBlur={props.field.onBlur}
                                                            component={getComponent(props.types[propsListIndex])}
                                                            size="small"
                                                            min={0}
                                                            integer={false}
                                                            />
                                                    }
                                                </TableCell>
                                            )
                                        }
                                        else return null
                                    })
                                }
                            </TableRow>
                        )): 'something went wrong'
                    }
                    </TableBody>

                    <TableFooter>
                    {props.showlastrow && 
                        <TableRow>
                            <TableCell colSpan={colspan} align="right">
                                <label className="float-right">{props.lastrowlabel}</label>
                            </TableCell>
                            <TableCell align="left">
                                <Field 
                                    name={`${props.field.name}.${props.lastrowname}`}
                                    onChange={props.field.onChange}
                                    onBlur={props.field.onBlur}
                                    disabled={props.isreadonly}
                                    min={0}
                                    size="small"
                                    component={getComponent(props.types[props.types.length-1])}
                                />
                            </TableCell>
                            {props.showlastrowsecondfield &&
                                <TableCell align="left">
                                    <Field 
                                        name={`${props.field.name}.${props.lastrownamesecondfield}`}
                                        onChange={props.field.onChange}
                                        onBlur={props.field.onBlur}
                                        disabled={props.isreadonly}
                                        min={0}
                                        size="small"
                                        component={getComponent(props.types[props.types.length-2])}
                                    />
                                </TableCell>
                            }
                        </TableRow>
                    }
                    {props.footer && (
                        <TableRow>
                            <TableCell colSpan={propertiesList.length} align="right">
                                {props.footer}
                            </TableCell>
                        </TableRow>
                    )}
                    </TableFooter>
            </Table>
        </div>
    );
}