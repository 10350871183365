import { UserRoles, UserRolesToDisplay } from "../models/enums";

export type TemplatePlaceholder = {
    label: string;
    value: string;
};
export const templatePlaceholders: TemplatePlaceholder[] = [
    {
        label: "Creator of the event",
        value: "%%EVENT_CREATOR%%",
    },
    {
        label: "Name of the event",
        value: "%%EVENT_NAME%%",
    },
    {
        label: "Link of the event",
        value: "%%EVENT_LINK%%",
    },
    {
        label: "Last date of change",
        value: "%%LAST_CHANGE%%",
    },
    {
        label: "Last person to have modified the event",
        value: "%%LAST_EDITOR%%",
    },
];

export const VisibleRolesToEdit: string[] = [ ...Object.keys(UserRolesToDisplay)];

export const AllowedAdministrationRoles = [UserRoles.admin, UserRoles.hqsecurity, UserRoles.director];
