import React from 'react'
import moment from "moment";
import { Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import './CalendarToolbar.scss'
// TODO : Find right type for toolbarProps, Type ToolbarProps seems wrong
export const CalendarToolbar = (toolbar : any) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev', toolbar.date);
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next', toolbar.date);
    };
    const goToNextYear = () => {
        toolbar.date.setMonth(toolbar.date.getMonth());
        toolbar.date.setYear(toolbar.date.getFullYear() + 1);
        toolbar.onNavigate("next", toolbar.date);
    };
    const goToBackYear = () => {
        toolbar.date.setMonth(toolbar.date.getMonth());
        toolbar.date.setYear(toolbar.date.getFullYear() - 1);
        toolbar.onNavigate("prev", toolbar.date);
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current', toolbar.date);
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        date.format('MMMM')+', '+date.format('YYYY')
      );
    };

    return (
      <div className="calendar-toolbar">
        <div>
            <Button
                onClick={goToCurrent}
                variant="outlined"
                color="primary"
                size="small"
                className="calendar-toolbar__today"
            >today</Button>
        </div>
        <div className="calendar-toolbar__navigation">
          <button className="calendar-toolbar__backyear" onClick={goToBackYear}><NavigateBeforeIcon /><NavigateBeforeIcon /></button>
          <button className="calendar-toolbar__back" onClick={goToBack}><NavigateBeforeIcon /></button>
            <span  className="calendar-toolbar__date">{label()}</span>
          <button className="calendar-toolbar__next" onClick={goToNext}><NavigateNextIcon /></button>
          <button className="calendar-toolbar__nextyear" onClick={goToNextYear}><NavigateNextIcon /><NavigateNextIcon /></button>
        </div>
        <div></div>
      </div >
    );
  };