import React from 'react';
import { EventDescription, ValuablesManagement, SecurityAndSurveillance} from './components'
import {UiCollapsable} from '../../shared/components/ui/UiCollapsable'
import { Grid, Container } from '@material-ui/core';
import {UiBox} from '../../shared/components/ui/UiBox';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {NextActions, SubmitData } from '../../event/components/nextActions/nextActions';
import Upload from '../../shared/components/upload/Upload';
import { fileTypesAccepted, MAX_FILE_SIZE } from '../../shared/config/uploadConfig';
import './DeclarationRead.scss'
import { partialUrlUpload, partialUrlProgress, partialUrlDownload } from '../../shared/config/GlobalAppConfig';
import { DeclarationService } from '../../shared/services/declaration/declarationService';
import { DeclarationInterface } from '../declarationNewEdit/interfaces';
import { Option } from '../../shared/components/SelectField';
import { Field, Formik } from 'formik';
import LoadingContext from '../../routes/LoadingContext';
import { UploadService } from '../../shared/services/uploadService';
import { getMappingRoles } from '../../shared/models/records';
import LocalStorageTokenService from '../../shared/helpers/SessionStorageTokenService';
import {insuranceRoles} from "../../shared/config/securityConcept";
import {UserRoles} from "../../shared/models/enums";
import { GetEventStatusLabel } from "../../shared/helpers/HelpersBusiness";
import { EventStatus } from "../../dashboard/interface";

const users: Option[] = [
    { value: 1, itemContent: 'Stéphane' },
    { value: 2, itemContent: 'Douglas' }
]

class DeclarationRead extends React.Component<any, any> {
    static contextType = LoadingContext;
    constructor(props: any) {
        super(props);

        this.state = {
            event: {},
            initialValues: {
                nextActionBehavior: {
                    actionDesc: '',
                    comment: '',
                    idEventFlow: -1,
                    idTask: 0,
                    companyName: undefined
                 },
            },
            declaration: {},
            declarations: [],
            currentDeclarationPos: -1,
            files: [],
            isMounted: false,
        }
    }

    submit = (values: any, actions: any) => {
        if(this.state.event.id && values.nextActionBehavior) {
            SubmitData('event', this.state.event.id, values, actions, null, this.props.history, true, this.state.isMounted);
         }
    };

    componentDidMount() {
        if (this.props && this.props.declaration/*this.props.hasOwnProperty('match') && Object.keys(this.props.match.params).length*/) {
            this.getDeclaration(Number(this.props.declaration.id));
        }

        if(this.props.event) {
            this.setState({
                event: this.props.event,
                isMounted: true,
            })
        }

        this.loadDocuments()
    }
    loadDocuments(){
        UploadService.getAllDocuments(
            Number(getMappingRoles(LocalStorageTokenService.getUserRoleToken())),
            Number(this.props.declaration.idEvent),
            this.props.event.eventDeclarations.length > 1 ? Number(this.props.declaration.id) : null
          ).subscribe(
            (res) => {
              this.setState({
                  files: res
              })
            }
          );
    }
    componentWillUnmount() {
        this.setState({
            isMounted: false
        })
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if(!prevProps.event) {
            this.setState({
                event: this.props.event
            })
        }
    }

    declarationExists = (declarationId: number): boolean => {
        return this.state.declarations.some((d: DeclarationInterface) => d.id === declarationId);
    }

    getDeclaration = (id: number):void => {
        this.context.startLoading();
        const declarations = this.state.declarations;
        DeclarationService.getById(id, [], [], [], users).subscribe((declaration: DeclarationInterface) => {
            declarations.push(declaration);
            this.setState({
                declaration: declaration,
                declarations: declarations
            })
            
            this.props.event?.eventDeclarations.forEach((d: any, position: number) => {
                if(d.id === this.state.declaration.id) this.setState({currentDeclarationPos: position})
            })
            
            this.context.stopLoading();
        });
    }

    goLeft = () => {
        const pos = this.state.currentDeclarationPos-1;
        this.changeDeclarationToDisplay(pos);
    }

    goRight = () => {
        const pos = this.state.currentDeclarationPos+1;
        this.changeDeclarationToDisplay(pos);
    }

    changeDeclarationToDisplay = (pos: number) => {
        const declarationId = this.state.event.eventDeclarations[pos].id;
        if(!this.declarationExists(declarationId)) {
            this.getDeclaration(declarationId);
        }
        else {
            this.setState({
                declaration: this.state.declarations.filter((d: DeclarationInterface) => d.id === declarationId)[0]
            })
        }
        
        this.setState({
            currentDeclarationPos: pos
        })
    }

    isFromInsuranceTeam = () => {
        const role = LocalStorageTokenService.getUserRoleToken() as UserRoles;
        return insuranceRoles.includes(role);
    }

    isMaison = (): boolean => {
        const role = LocalStorageTokenService.getUserRoleToken();

        return role === UserRoles.maisonsec;
    }

    maisonOnlyEditEventHeCreatedOrPendingMaison = ():boolean | undefined => {
        const username = LocalStorageTokenService.getUserNameToken();
        let label = null;
        if (this.state.event.eventsStatus) {
            label = GetEventStatusLabel(this.state.event.eventsStatus) as EventStatus;
        }
        return this.state.event.createdBy === username || (label !== null && label === EventStatus.pendingMaison);
    }

    isAbleToDeleteFile = ():boolean | undefined => {

        if(this.isMaison()){
            return this.maisonOnlyEditEventHeCreatedOrPendingMaison();
        }

        return true;
    }

    isAbleToAddNewFile = ():boolean | undefined => {

        if(this.isMaison()){
            return this.maisonOnlyEditEventHeCreatedOrPendingMaison();
        }

        return true;
    }


    render() {
        return (
            <Formik
            onSubmit={this.submit}
            initialValues={this.state.initialValues}
            validateOnChange={false}
            validateOnBlur={false}>
            {({values, handleSubmit, setSubmitting}) => {
                return (
                    <div className="declarationRead readonly">
                        {this.state.event && (
                            <Container maxWidth="xl" className="content">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={8} className="readonly__left">
                                        <div className="declarationRead__arrows">
                                            {this.state.event.eventDeclarations && this.state.event.eventDeclarations.length > 0 && (
                                                <button
                                                    className="btn"
                                                    type="button"
                                                    disabled={!this.state.currentDeclarationPos}
                                                    onClick={this.goLeft}>
                                                    <ArrowBackIcon/>
                                                </button>
                                            )}
                                            <h2>{this.state.declaration.brandsName} - declaration</h2>
                                            {this.state.event.eventDeclarations && this.state.event.eventDeclarations.length > 0 && (
                                                <button
                                                    className="btn"
                                                    type="button"
                                                    disabled={this.state.currentDeclarationPos === this.state.event.eventDeclarations.length-1}
                                                    onClick={this.goRight}>
                                                    <ArrowForwardIcon/>
                                                </button>
                                            )}
                                        </div>
                                        <UiCollapsable title="Event Description" number={1} name="event-description">
                                            <EventDescription {...this.state.declaration}/>
                                        </UiCollapsable>
                                        <UiCollapsable title="Valuables Management" number={2} >
                                            <ValuablesManagement isFromInsuranceTeam={this.isFromInsuranceTeam()} {...this.state.declaration}/>
                                        </UiCollapsable>
                                        <UiCollapsable title="Security & Surveillance System" number={3} >
                                            <SecurityAndSurveillance isFromInsuranceTeam={this.isFromInsuranceTeam()} {...this.state.declaration}/>
                                        </UiCollapsable>
                                    </Grid>
                                    <Grid item xs={12} md={4} className="declaration-right readonly__right">
                                        <UiBox title="Next actions" altStyle={true} >
                                        <Field
                                            isSingleButton={false}
                                            handleSubmit={handleSubmit}
                                            enableEditButton={false}
                                            enableSaveAndCloseButton={false}
                                            handleSubmitWithoutFormik={() => this.submit(values,{setSubmitting : setSubmitting})}
                                            component={NextActions}
                                        />
                                        </UiBox>
                                        <Upload
                                            title="Manage documents"
                                            fileTypesAccepted={fileTypesAccepted}
                                            maxFileSize={MAX_FILE_SIZE()}
                                            urlUpload={partialUrlUpload}
                                            urlProgress={partialUrlProgress}
                                            urlDownload={partialUrlDownload}
                                            eventId={this.state.event?.id}
                                            declarationId={
                                                (this.state.initialValues?.declaration?.id) ? this.state.initialValues?.declaration?.id : this.props.declaration.id
                                            }
                                            files={this.state.files}
                                            progressActivated={true}
                                            reloadFiles={this.loadDocuments}
                                            disabled={!this.isAbleToAddNewFile()}
                                            canDelete={this.isAbleToDeleteFile()}
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                        )}
                    </div>
                )
            }}
        </Formik>   
        )
    }
}

export default DeclarationRead;