import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Field, Formik } from 'formik';
import React, { Component, useEffect } from 'react';
import { ReplaySubject, combineLatest, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Yup from 'yup';
import { DeclarationInterface } from '../declaration/declarationNewEdit/interfaces';
import { EventsActivityType, INewEvent } from "../event/interface";
import LoadingContext from '../routes/LoadingContext';
import { UiBox } from '../shared/components/ui/UiBox';
import { baseCreateNotification, createNotification } from "../shared/helpers/HelpersFunc";
import { Action, ActivityType, Maison, NotificationType, Task, TaskMappingToAction } from "../shared/models/enums";
import NewEventService from '../shared/services/NewEvent.service';
import { Reference, ReferencesService } from '../shared/services/referencesService';
import './NewEvent.scss';
import EventInformations, { eventInformationsInitialValues, eventInformationsSchema } from './components/eventInformations/EventInformations';
import EventRisksLevel, { eventRisksLevelInitialValues, eventRisksLevelSchema, eventRisksLevelSchemaAreaField } from './components/eventRisksLevel/EventRisksLevel';
import LevelOfMyEvent from './components/levelOfMyEvent/LevelOfMyEvent';
import { NewEventReadOnly } from './components/newEventReadOnly/newEventReadOnly';
import { NextActions } from './components/nextActions/nextActions';
import PreRequisites, { preRequisitesConcernedMaisonSchema, preRequisitesInitialValues, preRequisitesSchema } from './components/preRequisites/PreRequisites';
import { CanEditClosedEvent } from "../shared/helpers/HelpersBusiness";
import { useAuthContext } from "../shared/contexts/AuthContext";
import LocalStorageTokenService from "../shared/helpers/SessionStorageTokenService";

interface IStateDashboard {
   newEvent: INewEvent;
   allComponentsValidationSchema: any;
   brandsMaisonList: any;
   brandsMaisonListOriginal: any;
   brandsMaisonListWithUserFilter: any;
   brandsMaisonListOriginalWithUserFilter: any;
   countriesList: any;
   eventTypeList: [];
   locationTypeList: [];
   countryLevelRisk: [];
   riskLevel: [];
   riskLevelBackup: [];
   numberOfParticipants: [];
   eventStatus?: any;
   eventsActivityType: EventsActivityType[]
}

const myInitialValues = {
   id: undefined,
   createdBy: '',
   modifiedBy: '',
   deleted: false,
   ...preRequisitesInitialValues,
   ...eventInformationsInitialValues,
   ...eventRisksLevelInitialValues,
   selectedNewBrandsMaison: Array<any>(),
   selectedConcernedMaison: Array<any>(),
   selectedLeadBrandMaison: Array<any>(),
   selectedCountry: null,
   nextActionBehavior: {
      actionDesc: '',
      comment: '',
      idEventFlow: -1,
      idTask: 0,
      create: false,
      companyName: '',
      mustSendEmail: false,
      emailData: undefined
   }
}

// Concatenation of individual component validation schema into single one in order to give it to Formik
const allComponentsValidationSchemaSendtoX = preRequisitesSchema.concat(eventInformationsSchema).concat(eventRisksLevelSchema);

//const allComponentsValidationSchemaSaveForLater = preRequisitesSchema.concat(eventInformationsSchemaSaveForLater);

export class NewEvent extends Component<any, IStateDashboard> {
   static contextType = LoadingContext;
   loadReadonly: boolean | null;

   constructor(props: any) {
      super(props)
      this.state = {
         newEvent: myInitialValues,
         allComponentsValidationSchema: allComponentsValidationSchemaSendtoX,
         brandsMaisonList: [],
         brandsMaisonListOriginal: [],
         brandsMaisonListWithUserFilter: [],
         brandsMaisonListOriginalWithUserFilter: [],
         countriesList: [],
         eventTypeList: [],
         locationTypeList: [],
         countryLevelRisk: [],
         riskLevel: [],
         riskLevelBackup: [],
         numberOfParticipants: [],
         eventsActivityType: []
      }
      this.loadReadonly = null;
   }

   componentDidMount() {
      const requestMaison$: ReplaySubject<any> = new ReplaySubject<any>();
      const requestCountries$: ReplaySubject<any> = new ReplaySubject<any>();

      const getAllReference = forkJoin([
         ReferencesService.getAllMaisons().pipe(catchError(error => of(error))),
         ReferencesService.getAllMaisonsWithUserFilter().pipe(catchError(error => of(error))),
         ReferencesService.getAllCountries().pipe(catchError(error => of(error))),
         ReferencesService.GetAllEventType().pipe(catchError(error => of(error))),
         ReferencesService.GetAllLocationType().pipe(catchError(error => of(error))),
         ReferencesService.GetAllCountryLevelRisk().pipe(catchError(error => of(error))),
         ReferencesService.GetAllRiskLevel().pipe(catchError(error => of(error))),
         ReferencesService.GetAllNumberOfParticipants().pipe(catchError(error => of(error))),
      ]);

      getAllReference.subscribe(([allMaisons, allMaisonsWithUserFilter, allCountries, allEventType, allLocationType, allCountryLevelRisk, allRiskLevel, allNumberOfParticipants]) => {
         if (!(allMaisons instanceof Error)) this.setListStateMaison(allMaisons)
         if (!(allMaisonsWithUserFilter instanceof Error)) this.setListStateMaisonWithUserFilter(allMaisonsWithUserFilter)
         if (!(allCountries instanceof Error)) this.setListStateCountry(allCountries)
         requestMaison$.next(allMaisons);
         requestCountries$.next(allCountries);
         if (!(allEventType instanceof Error)) this.setState({ eventTypeList: allEventType })
         if (!(allLocationType instanceof Error)) this.setState({ locationTypeList: allLocationType })
         if (!(allCountryLevelRisk instanceof Error)) this.setState({ countryLevelRisk: allCountryLevelRisk })
         if (!(allRiskLevel instanceof Error)) this.setState({
            riskLevel: allRiskLevel,
            riskLevelBackup: allRiskLevel.slice(),
         })
         if (!(allNumberOfParticipants instanceof Error)) this.setState({ numberOfParticipants: allNumberOfParticipants })
         this.context.stopLoading();
      });

      if (this.props.match.params.mode === "readonly" || this.props.match.params.mode === "edit") {
         combineLatest([requestMaison$, requestCountries$]).subscribe(() => {
            this.getNewEventForm();
         })
      }
   }

   handleBrandsList = (newList: any) => {
      this.setState({
         ...this.state,
         brandsMaisonList: newList
      })
   }

   componentDidUpdate(prevProps: any, prevState: any) {
      if (this.props.match.params.mode === "readonly" || this.props.match.params.mode === "edit") {
         if (prevProps.match.params.mode !== this.props.match.params.mode) {
            this.getNewEventForm()
         }
      } else {
         if (prevProps.match.params.mode !== this.props.match.params.mode) {
            this.setState({ newEvent: myInitialValues })
         }
      }
   }

   getNewEventForm = () => {
      this.setState({
         brandsMaisonList: this.state.brandsMaisonListOriginal
      })

      NewEventService.getById(Number(this.props.match.params.id)).subscribe(
         {
            next: (resp: any) => {
               this.setState({
                  eventStatus: resp.eventsStatus
               })
               this.setState({eventsActivityType: resp.eventsActivityType});

               const brandMaisonsRes = this.state.brandsMaisonList;
               const countriesRes = this.state.countriesList;
               resp.selectedNewBrandsMaison = [];
               resp.selectedConcernedMaison = [];
               const newobj = resp;

               resp.eventDeclarations.forEach((eventDeclaration: any) => {
                  const selectedNewBrand = brandMaisonsRes.filter((t: any) => t.id === eventDeclaration.maisonBrands.id)[0];
                  if (selectedNewBrand) {
                     newobj.selectedNewBrandsMaison.push(selectedNewBrand);
                  }
               })

               const selectedNewBrand = brandMaisonsRes.filter((t: any) => t.itemContent === resp.leadbrandName)[0];
               newobj.selectedLeadBrandName = Object.assign({}, selectedNewBrand)

               const selectedConcernedMaison = brandMaisonsRes.filter((t: any) => t.itemContent !== resp.leadbrandName && resp.eventMaisonBrands?.some((e: any) => e.idMaisonBrands === t.id));
               if (selectedConcernedMaison) {
                  newobj.selectedConcernedMaison = selectedConcernedMaison.slice();
               }

               const countryId = typeof resp.country === 'number' ? Number(resp.country) : null;
               const selectedCountry = countriesRes.filter((t: any) => t.id === countryId)[0];
               if (selectedCountry) {
                  newobj.selectedCountry = selectedCountry;
               }

               if (newobj.eventDeclarations)
                  newobj.eventDeclarations = this.reorderDeclarations(newobj.eventDeclarations, newobj.leadbrandName);

               this.setState({ newEvent: newobj })

               //Update breadcrumb with the event name
               NewEventService.eventData.next(resp);
               this.context.stopLoading();
            },
            error: () => this.context.stopLoading()
         }
      );
   }

   /**
    * Sort Declarations with Global at the first place if exists
    * then lead maison (second place) and the rest of maisons
    * @param declarations 
    * @param leadMaisonName 
    */
   reorderDeclarations = (declarations: DeclarationInterface[], leadMaisonName: string): DeclarationInterface[] => {
      const reorderedDeclarations: DeclarationInterface[] = [];
      if (declarations.length > 1) {
         declarations.forEach((declaration: DeclarationInterface) => {
            if (declaration.idMaisonBrands === Maison.global) {
               reorderedDeclarations.splice(0, 0, declaration);
            }
            else if (declaration.brandsName === leadMaisonName) {
               reorderedDeclarations.splice(1, 0, declaration);
            }
            else {
               reorderedDeclarations.push(declaration)
            }
         })
      } else if (declarations.length === 1) {
         return declarations
      }
      return reorderedDeclarations;
   }

   setListStateMaison = (brandsList: Reference[]) => {
      this.setState({
         brandsMaisonList: brandsList.map((brand: Reference) => {
            brand.label = brand.itemContent;
            return brand;
         }),
         brandsMaisonListOriginal: brandsList.slice()
      })
   }

   setListStateMaisonWithUserFilter = (brandsListWithUserFilter: Reference[]) => {
      if (brandsListWithUserFilter) {
         this.setState({
            brandsMaisonListWithUserFilter: brandsListWithUserFilter.map((brand: Reference) => {
               brand.label = brand.itemContent;
               return brand;
            }),
            brandsMaisonListOriginalWithUserFilter: brandsListWithUserFilter.slice()
         })
      }
   }

   setListStateCountry = (countriesList: Reference[]) => {
      this.setState({
         countriesList: countriesList.map((country: Reference) => {
            country.label = country.itemContent;
            return country;
         })
      })
   }

   checkAndGetNewLevelValidationSchema = (riskLevel: number, multiBrandEvent: boolean, values: any): Yup.ObjectSchema => {
      let schema: Yup.ObjectSchema;
      schema = preRequisitesSchema.concat(eventInformationsSchema).concat(eventRisksLevelSchema);

      if (riskLevel > 1) {
         schema = preRequisitesSchema.concat(eventInformationsSchema).concat(eventRisksLevelSchemaAreaField);
      }

      if (multiBrandEvent) {
         schema = schema.concat(preRequisitesConcernedMaisonSchema);
      }

      if (!schema.isValidSync(values)) {
         baseCreateNotification(NotificationType.error, 'Form not complete', 'Some required fields are empty');
      }

      return schema;
   }

   toggleRiskLevelZeroValueFromRiskLevelOptions = (areaRiskLevel: string) => {
      if (this.state.riskLevel.length && areaRiskLevel) {
         return this.state.riskLevel.slice(1, this.state.riskLevel.length);
      }
      return this.state.riskLevelBackup.slice();
   }

   submit = (values: INewEvent, actions: any, goToReadOnlyView: boolean = false) => {
      // check if must be redirect to readonly view after submit
      this.context.startLoading();
      if (this.loadReadonly !== null) {
         goToReadOnlyView = this.loadReadonly.valueOf();
         this.loadReadonly = null;
      }

      const actionClicked = values.nextActionBehavior.actionDesc
         ? TaskMappingToAction[values.nextActionBehavior.actionDesc]
         : values.nextActionBehavior.idTask === Task.Save_for_later
            ? Task.Save_for_later
            : null;
      values.eventsStatus = [];
      if (values.nextActionBehavior.nextAction) {
         values.eventsStatus.push({ idStatus: values.nextActionBehavior.nextAction, comment: values.nextActionBehavior.comment });
      }
      this.setState({
         brandsMaisonList: this.state.brandsMaisonListOriginal
      })

      values.createdDate = this.state.newEvent.createdDate;
      if (this.state.eventsActivityType) {
         values.eventsActivityType = this.state.eventsActivityType
      }
      if (!values.isInvitationOnly) values.isInvitationOnly = false;
      if (!values.isPress) values.isPress = false;
      if (!values.isPublic) values.isPublic = false;
      if (!values.isSemiPublic) values.isSemiPublic = false;
      if (!values.isVIP) values.isVIP = false;
      if (!values.isOther) values.isOther = false;
      if (!values.otherDescription) values.otherDescription = '';

      if (this.props.match.params.mode === "edit") {
         values.eventsStatus = this.state.newEvent.eventsStatus;
         NewEventService.updateEvent(values).subscribe((event: INewEvent) => {
            if (actionClicked === Action.Save_Action) {
               this.props.history.push({
                  pathname: '/dashboard/event/readonly/' + event.id
               });
            }
            else this.props.history.push('/dashboard');
            createNotification(NotificationType.success, actionClicked === Action.Save_Action ? actionClicked : values.nextActionBehavior.idTask);
         },
            () => {
               createNotification(NotificationType.error, values.nextActionBehavior.idTask);
               this.context.stopLoading();
            }
         );
      }
      else {
         NewEventService.addEvent(values).subscribe((idEvent: number) => {
            if (goToReadOnlyView) this.props.history.push('/dashboard/event/readonly/' + idEvent);
            else this.props.history.push('/dashboard');
            createNotification(NotificationType.success, values.nextActionBehavior.idTask);
         },
            () => {
               createNotification(NotificationType.error, values.nextActionBehavior.idTask);
               this.context.stopLoading();
            }
         );
      }
      actions.setSubmitting(false);
   }

   updateActivityType = (event: any, activityType: ActivityType, activityTypeOtherDescription?: string) => {
      let eventActivityArray = this.state.eventsActivityType;
      const username = LocalStorageTokenService.getUserNameToken()!;
      switch (activityType) {
         case ActivityType.Pieces_presentations_on_displays:
            if (event) {
               if (!eventActivityArray.find(activity => activity.idActivityType === ActivityType.Pieces_presentations_on_displays)) {
                     eventActivityArray.push({createdBy: username, modifiedBy: username, idEvent: this.props.match.params.id ? Number(this.props.match.params.id) : 0, idActivityType: ActivityType.Pieces_presentations_on_displays});
               }
            } else {
               eventActivityArray = eventActivityArray.filter(activity => activity.idActivityType !== ActivityType.Pieces_presentations_on_displays);
            }
            break;
         case ActivityType.Boutique_activation:
            if (event) {
               eventActivityArray.push({createdBy: username, modifiedBy: username, idEvent: this.props.match.params.id ? Number(this.props.match.params.id) : 0, idActivityType: ActivityType.Boutique_activation});
            } else {
               eventActivityArray = eventActivityArray.filter(activity => activity.idActivityType !== ActivityType.Boutique_activation);
            }
            break;
         case ActivityType.Exhibition:
            if (event) {
               eventActivityArray.push({createdBy: username, modifiedBy: username, idEvent: this.props.match.params.id ? Number(this.props.match.params.id) : 0, idActivityType: ActivityType.Exhibition});
            } else {
               eventActivityArray = eventActivityArray.filter(activity => activity.idActivityType !== ActivityType.Exhibition);
            }
            break;
         case ActivityType.Private_viewings:
            if (event) {
               eventActivityArray.push({createdBy: username, modifiedBy: username, idEvent: this.props.match.params.id ? Number(this.props.match.params.id) : 0, idActivityType: ActivityType.Private_viewings});
            } else {
               eventActivityArray = eventActivityArray.filter(activity => activity.idActivityType !== ActivityType.Private_viewings);
            }
            break;
         case ActivityType.Gala_dinner:
            if (event) {
               eventActivityArray.push({createdBy: username, modifiedBy: username, idEvent: this.props.match.params.id ? Number(this.props.match.params.id) : 0, idActivityType: ActivityType.Gala_dinner});
            } else {
               eventActivityArray = eventActivityArray.filter(activity => activity.idActivityType !== ActivityType.Gala_dinner);
            }
            break;
         case ActivityType.Model_show:
            if (event) {
               eventActivityArray.push({createdBy: username, modifiedBy: username, idEvent: this.props.match.params.id ? Number(this.props.match.params.id) : 0, idActivityType: ActivityType.Model_show});
            } else {
               eventActivityArray = eventActivityArray.filter(activity => activity.idActivityType !== ActivityType.Model_show);
            }
            break;
         case ActivityType.Other:
            if (event) {
               eventActivityArray.push({createdBy: username, modifiedBy: username, idEvent: this.props.match.params.id ? Number(this.props.match.params.id) : 0, idActivityType: ActivityType.Other, activityTypeOtherDescription: activityTypeOtherDescription});
            } else {
               eventActivityArray = eventActivityArray.filter(activity => activity.idActivityType !== ActivityType.Other);
            }
            break;
         default:
            eventActivityArray.find(activity => activity.idActivityType == ActivityType.Other)!.activityTypeOtherDescription = activityTypeOtherDescription;
            break;
      }
      this.setState({eventsActivityType: eventActivityArray});
   }

   render() {
      // be care typeof(match.params.id) is string but we need typeof(id) number in rest of application
      const id = Number(this.props.match.params.id);
      const mode = this.props.match.params.mode;
      const event = this.state.newEvent;

      return (
         <React.Fragment>
            {
               (mode === "readonly" && id) ?
                  <NewEventReadOnly {...this.state.newEvent} />
                  :
                  ((mode === "edit" && id) || !mode) ?
                     <Formik
                        enableReinitialize={false}
                        initialValues={myInitialValues}
                        onSubmit={this.submit}
                        validationSchema={this.state.allComponentsValidationSchema}
                        validateOnChange={false}
                        validateOnBlur={true}
                        touched
                     >
                        {({
                           handleChange,
                           handleBlur,
                           handleSubmit,
                           values,
                           isSubmitting,
                           errors,
                           setFieldValue,
                           initialValues,
                           setSubmitting
                        }) => {
                           useEffect(() => {
                              //TODO try find better way for "as any" in setFieldValue
                              Object.keys(initialValues).forEach(key => {
                                 setFieldValue(key, (event as any)[key], false)
                              })
                              // eslint-disable-next-line react-hooks/exhaustive-deps
                           }, [initialValues, setFieldValue, event])

                           return (
                              <Container maxWidth="xl" className="content new-event">
                                 {
                                    (mode === "edit" && id) ?

                                       <h1>{this.state.newEvent.eventName ? this.state.newEvent.eventName : "-"}</h1>
                                       :
                                       <h1>New Event</h1>
                                 }

                                 <form onSubmit={handleSubmit}>
                                    <Grid spacing={3} container>
                                       <Grid item xs={12} >
                                          All mandatory fields have a trailing (*)
                                       </Grid>
                                       <Grid item xs={12} >
                                          <UiBox title="Pre-requisites" name="prerequisite">
                                             <Field
                                                name="PreRequisites"
                                                countriesList={this.state.countriesList}
                                                brandsList={this.state.brandsMaisonList}
                                                brandsListWithUserFilter={this.state.brandsMaisonListWithUserFilter}
                                                onBrandsListChange={(newList: any) => this.handleBrandsList(newList)}
                                                component={PreRequisites} />
                                          </UiBox>
                                       </Grid>
                                       <Grid item xs={12} md={6}>
                                          <UiBox title="Summary information">
                                             <Field
                                                name="EventInformations"
                                                eventTypeList={this.state.eventTypeList}
                                                locationTypeList={this.state.locationTypeList}
                                                numberOfParticipants={this.state.numberOfParticipants}
                                                updateActivityType={this.updateActivityType}
                                                eventsActivityType={this.state.newEvent.eventsActivityType}
                                                component={EventInformations} />
                                          </UiBox>
                                          <UiBox title="Event risk level">
                                             <Field
                                                name="EventRisksLevel"
                                                component={EventRisksLevel}
                                                countryLevelRisk={this.state.countryLevelRisk}
                                                riskLevel={this.state.riskLevel}
                                                riskLevelBackup={this.state.riskLevelBackup}
                                                updateActivityType={this.updateActivityType}
                                                eventsActivityType={this.state.newEvent.eventsActivityType}
                                             />
                                          </UiBox>
                                          <UiBox title="Next actions" altStyle={true} >
                                             <Field
                                                isSingleButton={false}
                                                enableEditButton={false}
                                                enableSaveAndCloseButton={true}
                                                canEditClosedEvent={CanEditClosedEvent(useAuthContext(),this.state.eventStatus)}
                                                editEventForm={true}
                                                handleSubmit={() => {
                                                   this.loadReadonly = null;
                                                   const schema = this.checkAndGetNewLevelValidationSchema(Number(values.riskLevel), values.multiBrandEvent, values);
                                                   this.setState({
                                                      allComponentsValidationSchema: schema
                                                   },
                                                      handleSubmit)
                                                }
                                                }
                                                component={NextActions}
                                                handleSubmitWithoutFormik={(moveToReadonly: boolean) => {
                                                   this.loadReadonly = moveToReadonly;
                                                   this.setState({
                                                      allComponentsValidationSchema: allComponentsValidationSchemaSendtoX
                                                   },
                                                      handleSubmit)
                                                }
                                                }
                                             />
                                          </UiBox>
                                       </Grid>
                                       <Grid item xs={12} md={6}>
                                          <UiBox title="What is the risk level of this Event?" name="level-event">
                                             <LevelOfMyEvent
                                                arrayValues={[
                                                   ['Level', '1', '2', '3'],
                                                   ['Total Value < 3M €', true, false, false],
                                                   ['Total Value 3M € - 20M €', false, true, false],
                                                   ['Total Value > 20M €', false, false, true],
                                                   ['Fashion show', false, false, true],
                                                   ['Heritage pieces', false, false, true]
                                                ]}
                                             />
                                          </UiBox>
                                       </Grid>
                                    </Grid>
                                 </form>
                              </Container>
                           )
                        }
                        }
                     </Formik>
                     :
                     <p>erro mode parameter</p>
            }


         </React.Fragment>
      );
   }
}

