import { AppBar, Button, Container, Grid, Tab, Tabs } from "@material-ui/core";
import Collapse from '@material-ui/core/Collapse';
import { Field, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from "react";
import { NotificationContainer } from 'react-notifications';
import { useHistory } from "react-router-dom";
import { NextActionBehavior } from '../../../declaration/declarationNewEdit/DeclarationNewEdit';
import { DeclarationInterface } from '../../../declaration/declarationNewEdit/interfaces';
import LoadingContext from '../../../routes/LoadingContext';
import UiBox from '../../../shared/components/ui/UiBox';
import UiEventRecap from '../../../shared/components/ui/UiEventRecap';
import UiStatus from '../../../shared/components/ui/UiStatus';
import { CustomFile } from '../../../shared/components/upload/interfaces';
import Upload from '../../../shared/components/upload/Upload';
import {
   notificationMessage,
   partialUrlDownload,
   partialUrlProgress,
   partialUrlUpload
} from '../../../shared/config/GlobalAppConfig';
import { fileTypesAccepted, MAX_FILE_SIZE } from '../../../shared/config/uploadConfig';
import {
   CanEditClosedEvent,
   GetEventStatusLabel,
   GetIsEditFromEventStatus
} from "../../../shared/helpers/HelpersBusiness";
import {
    baseCreateNotification,
    getDateDifferenceInDays,
    numberScaleFormat
} from "../../../shared/helpers/HelpersFunc";
import LocalStorageTokenService from '../../../shared/helpers/SessionStorageTokenService';
import { useEventContext } from '../../../shared/hooks/eventHook';
import { Flow, Maison, NotificationType, StatusType, UserRoles } from "../../../shared/models/enums";
import { Email, ISecurityConcept } from '../../../shared/models/interfaces';
import { getMappingRoles } from '../../../shared/models/records';
import { LoggingServie } from '../../../shared/services/LoggingService';
import { SecurityConceptService } from '../../../shared/services/mock.securityConcept.service';
import NewEventService, { EventHistory } from '../../../shared/services/NewEvent.service';
import { UploadService } from '../../../shared/services/uploadService';
import { INewEvent, MyEvent } from '../../interface';
import { eventInformationsSchemaWithoutActivities } from "../eventInformations/EventInformations";
import { eventRisksLevelSchema, eventRisksLevelSchemaAreaField } from '../eventRisksLevel/EventRisksLevel';
import {NextActions, SubmitData} from '../nextActions/nextActions';
import { SecurityConceptBox } from '../securityConcept/SecurityConceptBox';
import './newEventReadOnly.scss';
import {insuranceRoles} from "../../../shared/config/securityConcept";
import Declaration from "../../../declaration/Declaration";
import { useAuthContext } from "../../../shared/contexts/AuthContext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Reference, ReferencesService } from "../../../shared/services/referencesService";
import { EventStatus } from "../../../dashboard/interface";
import NumberFormat from "react-number-format";

interface InitialValues {
   nextActionBehavior: NextActionBehavior;
   email: Email;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

export const NewEventReadOnly = (event: INewEvent) => {
   const eventContext = useEventContext();
   const authContext = useAuthContext();
   const [eventHistory, setEventHistory] = React.useState<EventHistory[]>([]);
   const [files, setFiles] = React.useState([]);
   const history: any = useHistory();
   const ctx = React.useContext(LoadingContext);
   const [isEventFormValid, setIsEventFormValid] = React.useState(false);
   const [isMounted, setIsMounted] = React.useState(true);
   const [preUpload, setPreUpload] = React.useState(false);

   const [loading, setLoading] = useState(false);
   const [securityConcepts, setSecurityConcepts] = useState<ISecurityConcept[]>([]);
   const [tabValue, setTabValue] = React.useState(0);
   const [selectedDeclaration, setSelectedDeclaration] = React.useState<DeclarationInterface>();
   const [locationType, setLocationType] = useState<Reference[]>([]);
   const [numberOfParticipants, setNumberOfParticipants] = useState<any[]>([]);
   const [participantsType, setParticipantsType] = useState<string[]>([]);
   const [activitiesType, setActivitiesType] = useState<Reference[]>([]);

    useEffect(() => {
        ReferencesService.GetAllLocationType().subscribe({
            next: (resp: any) => {
                setLocationType(resp);
            },
            error: err => console.error(err),
        });
        ReferencesService.GetAllNumberOfParticipants().subscribe({
            next: (resp: any) => {
                setNumberOfParticipants(resp);
            },
            error: err => console.error(err),
        });
        ReferencesService.GetAllActivityType().subscribe({
            next: (resp: any) => {
                setActivitiesType(resp)
            },
            error: err => console.error(err),
        });
    }, []);
   const initialValues: InitialValues = {
      nextActionBehavior: {
         actionDesc: '',
         comment: '',
         idEventFlow: -1,
         idTask: 0,
         companyName: undefined
      },
      email: {
         mustSendEmail: false,
         emailData: undefined
      }
   };

   const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
       ctx.startLoading();
       setTabValue(newValue);
   }

   const getMaisonName = useCallback((fileList: any) => {
      fileList.forEach((file: CustomFile) => {
         if(file.idDeclaration) {
            if(file.idDeclaration && file.idMaison === Maison.global) {
               file.maisonName = 'Global - Declaration'
            }
            else {
               var item = event.selectedNewBrandsMaison.filter((t: any) => t.id === file.idMaison)[0];
               if(item) {
                  file.maisonName = event.selectedNewBrandsMaison.filter((t: any) => t.id === file.idMaison)[0].itemContent + ' - Declaration';
               }
            }
         }
      });
      setFiles(fileList);
   }, [ctx, event.selectedNewBrandsMaison])

    useEffect(() => {
        if (tabValue == 0 || (tabValue == 1 && event && event.eventDeclarations && event.eventDeclarations.length > 1 && !selectedDeclaration) )
            ctx.stopLoading();
    }, [files]);

   const loadFiles = React.useCallback((eventId:number) => {
         const idrole = getMappingRoles(LocalStorageTokenService.getUserRoleToken());
         let res = UploadService.getAllDocuments(idrole,eventId).subscribe((filesResp:any) => {
            getMaisonName(filesResp);
         }, (error: any) => LoggingServie.LogError(error, ''));
         return res;
   }, [getMaisonName])

   const reloadFiles = React.useCallback(()=>{
      if(event.id){
         loadFiles(event.id)
      }
   }, [loadFiles, event.id])
   React.useEffect(() => {
      if(event.id) {
         let res = loadFiles(event.id);
         let res2 = NewEventService.GetStatusHistoryFromAnEvent(event.id).subscribe((eventHistoryList: EventHistory[]) => {
            setEventHistory(eventHistoryList.slice().reverse());
         });
         return () => {
            res.unsubscribe();
            res2.unsubscribe();
        }
      }
   }, [event.id, event.eventMaisonBrands, loadFiles]);

   React.useEffect(() => {
      if(event.id){
         isEventValid(event);
      }

      if(event.eventsStatus) {
         eventContext.handleStatusChange(GetEventStatusLabel(event.eventsStatus) ?? '')
      }

      let participantsArray = [];
       if (event.isPress) {
           participantsArray.push("Press")
       }
       if (event.isVIP) {
           participantsArray.push("VIPs")
       }
       if (event.isInvitationOnly) {
           participantsArray.push("Invitation-only event")
       }
       if (event.isSemiPublic) {
           participantsArray.push("Semi-public")
       }
       if (event.isPublic) {
           participantsArray.push("Public")
       }
       if (event.isOther) {
           participantsArray.push("Other")
       }
       setParticipantsType(participantsArray)

      return () => {
         setIsMounted(false);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [(event as MyEvent)]);

   const mustDisplayEditButton = () :Boolean  => {
      if(event.eventsStatus && event.eventsStatus.length) {
         return GetIsEditFromEventStatus(event.eventsStatus);
      }
      return true;
   }

   const mustDisplayEditButtonForClosedEvent = () :Boolean  => {
      if(event.eventsStatus && event.eventsStatus.length) {
         return CanEditClosedEvent(authContext, event.eventsStatus);
      }
      return false;
   }

   const isEventValid = (entity: INewEvent) => {
      const allComponentsValidationSchemaSendtoX = Number(entity.riskLevel) < 2
                                                   ? eventInformationsSchemaWithoutActivities.concat(eventRisksLevelSchema)
                                                   : eventInformationsSchemaWithoutActivities.concat(eventRisksLevelSchema).concat(eventRisksLevelSchemaAreaField);
      const atLeastOneActivity = !!(entity.eventsActivityType && entity.eventsActivityType.length > 0)
      const isvalid = allComponentsValidationSchemaSendtoX.isValidSync(entity) && atLeastOneActivity;
      setIsEventFormValid(isvalid);
   }

   const redirectToDeclarationForm = (declaration: DeclarationInterface) => {
      ctx.startLoading();
      setSelectedDeclaration(declaration);
   }

   const submit = (values: any, actions: any) => {
      if(event.id && values.nextActionBehavior) {
         SubmitData('event', event.id, values, actions, ctx, history, isEventFormValid, isMounted);
      }
   }
   const getDeclarationMaisonName = useCallback( (declaration: DeclarationInterface) => {
      if(declaration.idMaisonBrands === Maison.global){
         return 'Global'
      }else{
         const maisons = event.selectedNewBrandsMaison.filter((t: any) => t.id === declaration.idMaisonBrands)
         if(maisons.length){
           return maisons[0].itemContent
         }else{
            // THIS SHOULD NEVER HAPPENDS
            return ""
         }
      }
   }, [event])

   const loadConcepts = useCallback(() => {
      if (event.id && !insuranceRoles.includes(LocalStorageTokenService.getUserRoleToken() as UserRoles)) {
          setLoading(true);
          SecurityConceptService.getSecurityConcepts(event.id).subscribe(
              (concepts) => {
                  setLoading(false);
                  setSecurityConcepts(concepts);
              },
              () => {
                  setLoading(false);
              }
          );
      }
  }, [event]);

   const Row = (props: EventHistory) => {
      const [open, setOpen] = React.useState(false);
      return (
        <React.Fragment>
            <tr onClick={() => setOpen(!open)}>
               
               <td>{moment(props.modifiedDate).format('yyyy.MM.DD')} {moment(props.modifiedDate).format('HH:mm')}</td>
               <td>
                  <UiStatus
                     label={props.flow}
                     name={props.flow?.toLowerCase().replace(/ /g, '').replace('.', '') as StatusType} />
               </td>
               <td>{props.modifiedBy}</td>
               <td>{props.groupUsers ? props.groupUsers : ' - '}</td>
               <td>{props.tasks ? props.tasks : ' - '}</td>
            </tr>
            <tr>
               <td style={{ paddingBottom: 0, paddingTop: 0, color:'#000' }} colSpan={5}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                     <table>
                        <tbody>
                           <tr>
                              <td>Comment: </td>
                              <td>{props.comment}</td>
                           </tr>
                        </tbody>
                     </table>
                  </Collapse>
               </td>
            </tr>
        </React.Fragment>
      );
    }

    const onPreUpload = (preUpload: boolean) => {
      setPreUpload(preUpload);
    }

   const onReject = ():void =>{
      baseCreateNotification(NotificationType.success, notificationMessage.Reject_security_concept.title, notificationMessage.Reject_security_concept.message);
      ctx.startLoading();
      history.push('/dashboard');
   }

   const isMaison = (): boolean => {
      const role = LocalStorageTokenService.getUserRoleToken();

      return role === UserRoles.maisonsec;
   }

   const maisonOnlyEditEventHeCreatedOrPendingMaison = ():boolean | undefined => {
      const username = LocalStorageTokenService.getUserNameToken();
      let label = null;
      if (event.eventsStatus) {
         label = GetEventStatusLabel(event.eventsStatus) as EventStatus;
      }
      return event.createdBy === username || (label !== null && label === EventStatus.pendingMaison);
   }

   const isAbleToDeleteFile = ():boolean | undefined => {

      if(isMaison()){
         return maisonOnlyEditEventHeCreatedOrPendingMaison();
      }

      return true;
   }

   const isAbleToAddNewFile = ():boolean | undefined => {

      if(isMaison()){
         return maisonOnlyEditEventHeCreatedOrPendingMaison();
      }

      return true;
   }

   const isEventCancelled = () => {
      const eventStstusLen = event.eventsStatus?.length;
      if(event.eventsStatus && eventStstusLen && eventStstusLen > -1) return event.eventsStatus[eventStstusLen-1].status?.flow.id === Flow.Cancelled;
      return false;
   }

   return (
      <Formik
         onSubmit={submit}
         initialValues={initialValues}
         validateOnChange={false}
         validateOnBlur={false}>

         {({ values,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            setSubmitting,
            errors }) => {
            return (
               <React.Fragment>
                  <UiEventRecap event={event}></UiEventRecap>
                   <AppBar position="static">
                       <Tabs value={tabValue} onChange={handleTabChange}>
                           <Tab label="Event overview" />
                           <Tab label="Security Declaration" />
                       </Tabs>
                   </AppBar>
                   <TabPanel value={tabValue} index={0}>
                       <div className="readonly">
                           <Container maxWidth="xl" className="content eventRead">
                               <Grid container spacing={3}>
                                   <Grid item xs={12} md={8} className="readonly__left">
                                       <UiBox title="Event Key Details" name="event-key-details">
                                           <p><strong>- Location Type: </strong> {locationType.filter(location => location.id == Number(event.locationType))[0]?.itemContent} </p>
                                           <p><strong>- Location Name: </strong> {event.locationName}</p>
                                           <p><strong>- Location Address: </strong> {event.eventAddress}, {event.selectedCountry?.label}</p>
                                           <p><strong>- Duration (days): </strong> {event.toDate && event.toDate != '' ? getDateDifferenceInDays(new Date(event.fromDate), new Date(event.toDate)) : 'No end date specified'}</p>
                                           <p><strong>- Number of Participants: </strong> {numberOfParticipants.filter(participants => participants.value == Number(event.numberParticipants))[0]?.label}</p>
                                           <div>
                                               <p><strong>- Type of participants: </strong></p>
                                               {event && participantsType && participantsType.map(participant => {
                                                   return <p style={{marginLeft: "1.5rem"}} key={participant}>- {participant}</p>
                                               })}
                                           </div>
                                           <div>
                                               <p><strong>- Type of activities: </strong></p>
                                               {event && event.eventsActivityType && event.eventsActivityType.map(activity => {
                                                   const activityToDisplay = activitiesType.filter(activityType => activityType.id == activity.idActivityType)[0]?.itemContent;
                                                   return <p style={{marginLeft: "1.5rem"}} key={activity.idActivityType}>- {activityToDisplay == "Other (please specify)" ? "Other" : activityToDisplay}</p>
                                               })}
                                           </div>
                                           <p><strong>- Number of pieces: </strong> {event.numberOfPieces}</p>
                                           <p><strong>- Cost value of pieces: </strong> <NumberFormat value={event.estimatedTotalValue} displayType={'text'} thousandSeparator="'"  prefix={'€'} /></p>
                                       </UiBox>
                                       <SecurityConceptBox
                                           event={event}
                                           reloadFiles={reloadFiles}
                                           securityConcepts={securityConcepts}
                                           loadConcepts={loadConcepts}
                                           securityConceptsLoading={loading}
                                           onReject={onReject}
                                       />
                                       <UiBox title="Event history" name="event-history">
                                           <table>
                                               <thead>
                                               <tr>
                                                   <th>Date</th>
                                                   <th>Status</th>
                                                   <th>Updated by</th>
                                                   <th>Role</th>
                                                   <th>Action</th>
                                               </tr>
                                               </thead>
                                               <tbody>
                                               {
                                                   eventHistory && eventHistory.length > 0 ?
                                                       eventHistory.map((elemOfEventHistory: EventHistory) => {
                                                           elemOfEventHistory.comment = elemOfEventHistory.comment && elemOfEventHistory.comment.length ? elemOfEventHistory.comment : '-';
                                                           return <Row key={elemOfEventHistory.id} {...elemOfEventHistory} />
                                                       })
                                                       : <tr><td colSpan={5} style={{textAlign: "center"}}>No status found</td></tr>
                                               }
                                               </tbody>
                                           </table>
                                       </UiBox>
                                   </Grid>
                                   <Grid item xs={12} md={4} className="declaration-right readonly__right">
                                       <UiBox title="Next actions" altStyle={true} >
                                           <Field
                                               isSingleButton={false}
                                               handleSubmit={handleSubmit}
                                               eventId={event.id}
                                               eventStatus={event.eventsStatus}
                                               canEditClosedEvent={mustDisplayEditButtonForClosedEvent()}
                                               enableEditButton={mustDisplayEditButton()}
                                               enableSaveAndCloseButton={false}
                                               isSubmitting={isSubmitting}
                                               component={NextActions}
                                               handleSubmitWithoutFormik={() => submit(values,{setSubmitting : setSubmitting})}
                                               disabled={preUpload}
                                           />
                                       </UiBox>

                                       <Upload
                                           title="Manage documents"
                                           fileTypesAccepted={fileTypesAccepted}
                                           maxFileSize={MAX_FILE_SIZE()}
                                           urlUpload={partialUrlUpload}
                                           urlProgress={partialUrlProgress}
                                           urlDownload={partialUrlDownload}
                                           eventId={event.id || 0}
                                           files={files}
                                           maisonList={event?.selectedNewBrandsMaison}
                                           isEvent={true}
                                           progressActivated={true}
                                           reloadFiles={reloadFiles}
                                           loadConcepts={loadConcepts}
                                           onPreUpload={onPreUpload}
                                           disabled={!isAbleToAddNewFile()}
                                           canDelete={isAbleToDeleteFile()}
                                       />
                                   </Grid>
                               </Grid>
                           </Container>
                       </div>
                   </TabPanel>
                   <TabPanel value={tabValue} index={1}>
                       {event && event.id && event.eventDeclarations && event.eventDeclarations.length == 1 ?
                           <Declaration isCancelled={isEventCancelled()} event={event} declaration={event.eventDeclarations[0]} /> :
                           event && event.id && event.eventDeclarations && event.eventDeclarations.length > 1 && selectedDeclaration ?
                               <Container maxWidth="xl" className="content eventRead">
                                   <Button
                                       size="medium"
                                       color="primary"
                                       startIcon={<ArrowBackIcon />}
                                       onClick={()=> {
                                           setSelectedDeclaration(undefined)
                                       }}
                                   >
                                       Go back
                                   </Button>
                                   <Declaration isCancelled={isEventCancelled()} event={event} declaration={selectedDeclaration} />
                               </Container> :
                               event && event.id && event.eventDeclarations && event.eventDeclarations.length > 1 ?
                                   <Container maxWidth="xl" className="content eventRead">
                                       <Grid container spacing={3}>
                                           <Grid item xs={12} md={8} className="readonly__left">
                                               <UiBox title="Declaration forms">
                                                   <table id="declarationTable">
                                                       <thead>
                                                       <tr>
                                                           <th>Maison - declaration</th>
                                                           <th>Author</th>
                                                           <th>Amount</th>
                                                           <th>Last modification</th>
                                                       </tr>
                                                       </thead>
                                                       <tbody>
                                                       {
                                                           event.eventDeclarations.map((declaration: DeclarationInterface) => (
                                                               <tr key={declaration.id} onClick={() => redirectToDeclarationForm(declaration)}>
                                                                   <td>
                                                                       {
                                                                           getDeclarationMaisonName(declaration)
                                                                       } - {event.fromDate && moment(event.fromDate).format('YYYY.MM.DD')}
                                                                   </td>
                                                                   <td>{declaration.createdBy}</td>
                                                                   <td>{declaration.valuableAssets?.totalEstimated ? "€ " + numberScaleFormat(declaration.valuableAssets.totalEstimated) : '-'}</td>
                                                                   <td>{moment(declaration.modifiedDate).format('yyyy.MM.DD')} {moment(declaration.modifiedDate).format('HH:mm')}</td>
                                                               </tr>
                                                           ))

                                                       }
                                                       </tbody>
                                                   </table>
                                               </UiBox>
                                           </Grid>
                                       </Grid>
                                   </Container>
                                    : <>No declaration</>}
                   </TabPanel>
                  <NotificationContainer></NotificationContainer>
               </React.Fragment>
            )
         }}
      </Formik>
   )
}

