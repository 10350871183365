import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import { withFormik, FormikProps, FormikErrors, Form, Field, ErrorMessage, Formik } from 'formik';
import { withFormik, FormikProps } from 'formik';

import { MergeSchemas, ObjectUtils } from '../helpers/HelpersFunc';

import { mockServie } from '../services/mock.Service';

import FormPreRequisites from './PreRequisites/NewEvent-PreRequisites';
import { /*SchemaPreRequisites,*/ InitPreRequisites, IPreRequisites } from './PreRequisites/PreRequisitesSchema';

import FormEventInformation from './EventInformation/NewEvent-EventInformation'
import { SchemaEventInformation, InitEventInformation } from './EventInformation/EventInformationSchema';


const setFormInitValues = (setFieldValueObject: any, initialObject: any, dataTObject: IPreRequisites, field: string) => {
  let auxVar = ObjectUtils.getInterfacePropertyValue(dataTObject, field);
  setFieldValueObject(field, auxVar, true);
  initialObject[field] = auxVar;
}

const useFormState = (setFieldValue: any, setFormikState: any, initialValues: any) => {  
  let id:number  = useParams();
  let isCreateMode: boolean = !Object.keys(id).length;

  useEffect(() => {
    let propsName=  ObjectUtils.getInterfaceProperties(InitPreRequisites);
    if (!isCreateMode) {
        let entityData: IPreRequisites = mockServie.getById(id);
        propsName.forEach(field => {
          setFormInitValues(setFieldValue, initialValues, entityData, field);
        });
        console.log(entityData);
    }
    else{
      propsName.forEach(field => {
        setFormInitValues(setFieldValue, initialValues, InitPreRequisites, field);
      });
    }
  }, [id, isCreateMode, setFieldValue, initialValues]);
  //throw new Error('I crashed!');
}

const MyForm = (props: FormikProps<any>) => {
  // const { touched, errors, isSubmitting, title , handleSubmit, handleReset} = props;
  const { touched, errors, isSubmitting, handleReset, setFieldValue, setFormikState, initialValues } = props;

  let formProps = [touched, errors];
  let titleForm1: string = "Pre-requisites";
  let titleForm2: string = "Event Information";
 
  useFormState(setFieldValue, setFormikState, initialValues);

  return(
    <form onSubmit={props.handleSubmit}>
      <FormPreRequisites title={titleForm1} formProp={formProps} />
      <FormEventInformation title={titleForm2} formProp={formProps} />
      <br />
      <button type="button" onClick={handleReset}>
            Reset
      </button>   
      <button type="submit" disabled={isSubmitting}>
            Submit
      </button>    
    </form>
  );
}


// configuring withFormik props
const MyEnhancedForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => {  //props: Props<any>
    return {
      ...InitPreRequisites,
      ...InitEventInformation,
    }
  },
  validate: values => {
  },
  // validationSchema: MergeSchemas(SchemaPreRequisites, SchemaEventInformation),
  validationSchema: MergeSchemas(SchemaEventInformation),
  // handleSubmit: (values, actions) => {
  handleSubmit(values, {resetForm}){
    console.log(values);
    // actions.setSubmitting(false)
    //resetForm();
  },
})(MyForm);

export default MyEnhancedForm;