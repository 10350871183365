import React, {useCallback, useState} from 'react';
import Button from '@material-ui/core/Button';
import UiSplitButton from '../../../shared/components/ui/UiSplitButton';
import { useHistory } from "react-router-dom";
import UiModalBrocker from '../../../shared/components/ui/UiModal/UiModalBrocker';
import {EventFlow, FlowRefService} from '../../../shared/services/flowRefService';
import {
   Action,
   Flow,
   LocationNamingRsult,
   NotificationType,
   StatusType,
   Task,
   TaskMappingToAction,
   UserGroup,
   UserRoles
} from '../../../shared/models/enums';
import {
   baseCreateNotification,
   createNotification,
   getEnumKeyByEnumValue,
   getEnumValueByEnumKey,
   getLocationSite,
   ScrollToTopHelper
} from '../../../shared/helpers/HelpersFunc';
import {UiModal} from '../../../shared/components/ui/UiModal/UiModal';
import {Field} from 'formik';
import {UiTextareaField} from '../../../shared/components/fields';
import {IPropsDashboardBase} from '../../../dashboard/interface';
import LoadingContext from '../../../routes/LoadingContext';
import {ValidatorNextActionComments} from '../../../shared/validators/validatorsNextActionComment';
import LocalStorageTokenService from '../../../shared/helpers/SessionStorageTokenService';
import {
   flowsWhereCanEditEventAndDeclaration,
   getMappingRoles,
   getMappingRolesFromValue,
   usersAllowedToEditEventAndDeclaration
} from '../../../shared/models/records';
import UiModalRiskAssessorOrUnderwritter from '../../../shared/components/ui/UiModal/UiModalRiskAssessor';
import { ReferencesService, Role } from '../../../shared/services/referencesService';
import EmailService from '../../../shared/services/emailService';
import { EmailData, EmailEventHistory, ILoadingContext } from '../../../shared/models/interfaces';
import { notificationMessage } from '../../../shared/config/GlobalAppConfig';
import NewEventService from '../../../shared/services/NewEvent.service';
import { eventInformationsSchemaWithoutActivities } from "../eventInformations/EventInformations";
import { LoggingServie } from '../../../shared/services/LoggingService';
import { preRequisitesSchema } from '../preRequisites/PreRequisites';
import { eventRisksLevelSchema } from '../eventRisksLevel/EventRisksLevel';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { StatusItemServer } from '../../interface';
import { GetEventStatusLabel } from '../../../shared/helpers/HelpersBusiness';
import {rolesAdmin} from "../../../shared/config/securityConcept";

type PropsUiNextActions = {
   isSingleButton: boolean
   singleButtonValue?: IPropsDashboardBase
   enableEditButton: boolean
   canEditClosedEvent?: boolean
   editEventForm?: boolean
   enableSaveAndCloseButton: boolean
   handleSubmit?: () => boolean
   eventId?: number
   handleSubmitWithoutFormik?: (redirectToReadOnly: boolean | null) => void
   isDeclaration?: boolean
   actions?: NextActionOption[]
   form?: any
   isSubmitting?: boolean
   isCancelled?: boolean
   eventStatus?: StatusItemServer[] | undefined
   disabled?: boolean
   closeFromDashboard?: boolean
   eventFlowToClose?: EventFlow[]
}

type Source = 'dashboard' | 'event';

const saveEmailEventHistoryForBroker = (values: any) => {
   if (values.email && values.email.emailHistory) {
      EmailService.saveEmailEventHistory(values.email.emailHistory).pipe(take(1)).subscribe(
          (response) => LoggingServie.LogInformation(response),
          (error) => LoggingServie.LogError(error, '')
      );
   }
}

const continueEventUpdate = (from: Source, idEvent: number, values: any, actions: any, ctx: ILoadingContext | null, history: any, isComponentMounted: boolean): void => {
   if(from === 'event') {
      FlowRefService.changeEventStatus(idEvent, values.nextActionBehavior).pipe(take(1)).subscribe(() => {
         if (ctx) ctx.stopLoading();
         if(history) history.push('/dashboard');

         if(isComponentMounted) {
            actions.setSubmitting(false);
         }

         createNotification(NotificationType.success, values.nextActionBehavior.idTask);
         values.nextActionBehavior = {
            actionDesc: '',
            comment: '',
            idEventFlow: -1,
            idTask: -1,
            mustSendEmail: false
         }

         saveEmailEventHistoryForBroker(values);

         if(values.email && values.email.emailData) {
            values.email.emailData.idEvent = idEvent
            EmailService.sendEmailInformation(values.email.emailData).pipe(take(1)).subscribe(
               (response) => console.log(response),
               (error) => LoggingServie.LogError(error, '')
            )
         }
      }, (error) => {
         if (ctx) ctx.stopLoading();
         actions.setSubmitting(false)
         createNotification(NotificationType.error, values.nextActionBehavior.idTask,notificationMessage.ErrorGenericMessage.title,error.data.errorMessage)
      })
   }
   else {
      FlowRefService.changeEventStatus(idEvent, values.nextActionBehavior).pipe(take(1)).subscribe(() => {
         createNotification(NotificationType.success, values.nextActionBehavior.idTask);

         saveEmailEventHistoryForBroker(values);

         if(values.email && values.email.emailData) {
            values.email.emailData.idEvent = idEvent;
            EmailService.sendEmailInformation(values.email.emailData).pipe(take(1)).subscribe(
               (response) => LoggingServie.LogInformation(response),
               (error) => LoggingServie.LogError(error, '')
            )
         }
         setTimeout(() => {
            window.location.reload();
          }, 3000);
         
      }, (error) => {
         createNotification(NotificationType.error, values.nextActionBehavior.idTask,notificationMessage.ErrorGenericMessage.title,error.data.errorMessage)
      })
   }
 }

export const SubmitData = (from: Source, idEvent: number, values: any, actions: any, ctx: ILoadingContext | null, history: any, isEventFormValid: boolean | null, isMounted: boolean) => {
   if(values.nextActionBehavior.idTask === Task.Send_to_Region){
      if(isEventFormValid === false){
         baseCreateNotification(NotificationType.warning, notificationMessage.Send_to_region_invalid.title, notificationMessage.Send_to_region_invalid.message);
         return;
      }
      else if(isEventFormValid === null){
         NewEventService.getById(idEvent).pipe(take(1)).subscribe(entity => {
            const allComponentsValidationSchemaSendtoX = preRequisitesSchema.concat(eventInformationsSchemaWithoutActivities).concat(eventRisksLevelSchema);
            const atLeastOneActivity = !!(entity.eventsActivityType && entity.eventsActivityType.length > 0)
            let res = allComponentsValidationSchemaSendtoX.isValidSync(entity) && atLeastOneActivity;
            if(res){
               continueEventUpdate(from, idEvent, values, actions, ctx, history, isMounted);
            }
            else{
               baseCreateNotification(NotificationType.warning, notificationMessage.Send_to_region_invalid.title, notificationMessage.Send_to_region_invalid.message);
            }
         });
      }
      else{
         continueEventUpdate(from, idEvent, values, actions, ctx, history, isMounted);
      }
   }
   else{
      continueEventUpdate(from, idEvent, values, actions, ctx, history, isMounted);
   }
}

export interface NextActionOption {
   name: any;
   label: string;
}

export const NextActions = (props: PropsUiNextActions) => {
   const [openModal,setOpenModal] = useState<boolean>(false);
   const [openRAModal,setOpenRAModal] = useState<boolean>(false);
   const [openUnderwriterModal,setOpenUnderwriterModal] = useState<boolean>(false);
   const [openMessageModal,setOpenMessageModal] = useState<boolean>(false);
   const [listButton, setListButton] = React.useState<NextActionOption[]>([]);
   const [optionToSend, setOptionToSend] = React.useState<EventFlow>();
   const [secondaryOption, setSecondaryOption] = React.useState<boolean>(false);
   const [isToAskRiskAssessment, setIsToAskRiskAssessment] = React.useState<boolean>(false);
   const { setFieldValue, values } = props.form;

   const ctx = React.useContext(LoadingContext);
   const history = useHistory();

   const [loadDataPopUpBroker, setLoadDataPopUpBroker] = React.useState<boolean>(false);
   const [loadDataPopUpRisk, setLoadDataPopUpRisk] = React.useState<boolean>(false);
   const [loadDataPopUpUnderw, setLoadDataPopUpUnderw] = React.useState<boolean>(false);

   // this method is used to handle specific buttons actions cases
   const handleActionsButtons = useCallback(
      (efl: EventFlow[], actions: NextActionOption[], userid: string | null) => {
         let loc = getLocationSite();
         let sendToHQText = getEnumKeyByEnumValue(Task, Task.Send_to_HQ)?.replaceAll('_', ' ');
         let sendToRegionText = getEnumKeyByEnumValue(Task, Task.Send_to_Region)?.replaceAll('_', ' ').toLowerCase();
         let sendToSecurity = getEnumKeyByEnumValue(Task, Task.Send_to_security)?.replaceAll('_', ' ').toLowerCase();
         const roleId=  efl[0].idGroupUsers;
         const idFlow =  efl[0].idFlow;
         efl.slice(1, efl.length).forEach((ef: EventFlow) => {
            actions.push({name: ef, label: ef.taskLabel})
         });

         if(efl.length === 1 && efl[0].idFlow !== Flow.Cancelled) {
            actions.push({name: efl[0], label: efl[0].taskLabel})
            if (props.editEventForm && props.canEditClosedEvent) {
               actions.push({ name: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Cancel"]), label: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Cancel"]) });
            }
         }

         const valExists = actions.some(
            (val: NextActionOption) => typeof val.label === "string"
                                       && (val.label === sendToHQText 
                                          || val.label.toLowerCase() === sendToRegionText 
                                          || val.label.toLowerCase() === sendToSecurity)
            );
         const canModifySummary =efl.length === 1  
                                 && usersAllowedToEditEventAndDeclaration.includes(roleId)
                                 && flowsWhereCanEditEventAndDeclaration.includes(idFlow)
                                 && idFlow !== Flow.Cancelled;
  
         if((loc === LocationNamingRsult.none || loc === LocationNamingRsult.event) && (valExists || canModifySummary))
         {
            keepOnlySaveForLaterAndCancelEventActions(actions);
         }

         if ((props.enableEditButton && usersAllowedToEditEventAndDeclaration.includes(roleId))) {
            const editSummaryOption = { name: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Edit summary"]), label: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Edit summary"]) };
            let cancelIndex = actions.findIndex(exp => exp.label.toLowerCase() === "cancel event");
            if(cancelIndex >= 0){
               // ADD EDIT SUMMARY BEFORE CANCEL EVENT ACTION
               actions.splice(cancelIndex, 0, editSummaryOption);
            }
            else if(canModifySummary) {
               actions.push(editSummaryOption)
            }
         }
         //If saveAndCloseButton is not enable we remove this action from actionsList
         if(!props.enableSaveAndCloseButton && (Number(userid) < UserGroup.broker || Number(userid) === UserGroup.director || Number(userid) === UserGroup.region_security_manager || Number(userid) === UserGroup.maison_security_manager)){
            const positionSaveForLaterAction = actions.findIndex((action) => action.name.idTask === Task.Save_for_later)
            if(positionSaveForLaterAction > -1) {
               actions.splice(positionSaveForLaterAction,1)
            }
         }
      },
      [props.enableEditButton, props.enableSaveAndCloseButton, props.canEditClosedEvent]
   );

   const isPendingBrokerAndAdmin = (): boolean => {
      const userRole = LocalStorageTokenService.getUserRoleToken() as UserRoles;
      let statusLabel: StatusType = (props.eventStatus ? GetEventStatusLabel(props.eventStatus) : "") as StatusType;
      if (statusLabel) {
         statusLabel = statusLabel.toLowerCase().replace(/ /g, '').replace('.', '') as StatusType;
      }

      return (
          userRole &&
          rolesAdmin.includes(userRole) &&
          statusLabel === "pendingbroker"
      );
   }

   const getActionsList = useCallback((): Subscription | null => {
      const userid: string | null = getMappingRoles(LocalStorageTokenService.getUserRoleToken()).toString();
      const ids = props.eventId ? [props.eventId] : history.location.pathname.match(/\d+/g);

      if(props.isDeclaration && !props.isCancelled) {
         const actions: NextActionOption[] = [];
         actions.push({ name: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Save"]), label: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Save"]) });
         setListButton(actions);
      } else if (isPendingBrokerAndAdmin()) {
         const actions: NextActionOption[] = [];
         actions.push({
            name: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Recall Event"]),
            label: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Recall Event"])
         });
         setListButton(actions);
      } else if(userid) {
         const idEvent = ids  && ids.length ? Number(ids[0]) : 0;
         return FlowRefService.getFlowsByIdGroupUser(userid, idEvent).subscribe((efl: EventFlow[]) => {
            if(efl.length === 1 && efl[0].taskLabel === 'Save for later' && !props.editEventForm)
            {
               if (props.canEditClosedEvent) {
                  const actions: NextActionOption[] = [];
                  const editSummaryOption = { name: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Edit summary"]), label: getEnumValueByEnumKey(TaskMappingToAction, TaskMappingToAction["Edit summary"]) };
                  actions.push(editSummaryOption);
                  setListButton(actions);
               }
               return;
            } else if (efl.length < 1) {
               return;
            }

            const actions: NextActionOption[] = [];
            handleActionsButtons(efl, actions, userid);
           
            setListButton(actions);
         }, (error) => {
            LoggingServie.LogError(error, '');
         })
      }
      return null;
   }, [handleActionsButtons,history.location.pathname,props.eventId, props.isDeclaration, props.isCancelled])

   React.useEffect(() => {
      let subscription: Subscription | null;
      if(!props.isSingleButton) {
         subscription = getActionsList();
      }

      // Check if popup must be loaded
      let cTaskLabel:string = "";
      if(props.singleButtonValue && props.singleButtonValue.taskLabel){
         cTaskLabel = props.singleButtonValue.taskLabel
      }
      else if(optionToSend && optionToSend.taskLabel){
         cTaskLabel = optionToSend.taskLabel;
      }
      if(cTaskLabel){
         if(TaskMappingToAction[cTaskLabel] === Action.Send_to_Broker){
            setLoadDataPopUpBroker(true);
         }
         if(TaskMappingToAction[cTaskLabel] === Action.Ask_for_Risk_Assessment){
            setLoadDataPopUpRisk(true);
         }
         if(TaskMappingToAction[cTaskLabel] === Action.Ask_for_Underwritter){
            setLoadDataPopUpUnderw(true);
         }
      }
      return () => {
         if(subscription) {
            subscription.unsubscribe();
         }
      }
   }, [props.isSubmitting, props.singleButtonValue, props.enableEditButton, optionToSend, getActionsList, props.isSingleButton])

   const keepOnlySaveForLaterAndCancelEventActions = (actions: NextActionOption[]) => {
      const saveForLater = getEnumKeyByEnumValue(Task, Task.Save_for_later)?.replaceAll('_', ' ').toLowerCase();
      const cancelEvent = getEnumKeyByEnumValue(Task, Task.Cancel_event)?.replaceAll('_', ' ').toLowerCase();
      const actionsToRemove: number[] = [];
      actions.forEach((action, index) => {
         if(action.label.toLowerCase() !== saveForLater && action.label.toLowerCase() !== cancelEvent) {
            actionsToRemove.push(index);
         }
      });
      for(let i = actionsToRemove.length-1; i >= 0; i--) {
         actions.splice(actionsToRemove[i], 1);
      }
   }

   const setFieldValuesAndOpenMessageModal = (option: EventFlow) => {
      setFieldValue('nextActionBehavior.idEventFlow', (option as IPropsDashboardBase).idEventFlow ?? option.id );
      setFieldValue('nextActionBehavior.idTask',option.idTask);
      setFieldValue('nextActionBehavior.nextAction', option.idNextTask);

      setOpenMessageModal(true);
      setSecondaryOption(true);
   }

   const handleClick = (ev: any, option: EventFlow) => {
      props.form.setFieldValue('nextActionBehavior.actionDesc', option.taskLabel);
      const currentOption = option.taskLabel ? TaskMappingToAction[option.taskLabel] : TaskMappingToAction['' + option];
      ctx.startLoading();
      switch (currentOption) {
         case Action.Main_Action:
            if (props.handleSubmit && !props.isDeclaration) {
               if(props.isSingleButton) {
                  setFieldValue('nextActionBehavior.idEventFlow', (option as IPropsDashboardBase).idEventFlow); 
                  setFieldValue('nextActionBehavior.idTask',(option as IPropsDashboardBase).idTask);
               } 
               else {
                  setFieldValue('nextActionBehavior.idEventFlow', option.id);
                  setFieldValue('nextActionBehavior.idTask',option.idTask)
               }
               
               setFieldValue('nextActionBehavior.nextAction', option.idNextTask);
               props.handleSubmit();
            }
            break;
         case Action.Save_Action:
            if(!values.nextActionBehavior) values.nextActionBehavior = {}
            if(option.id) {
               setFieldValue('nextActionBehavior.idEventFlow', option.id);
               setFieldValue('nextActionBehavior.idTask',option.idTask)
               setFieldValue('nextActionBehavior.nextAction', option.idNextTask);
               if (props.handleSubmit && option.id) {
                  props.handleSubmit();
               }
            }
            
            break;
         case Action.Secondary_action:
            //If there is no id, the event is not created yet so cancel button just go back to dashboard.
            //if there is a props.eventID, we are readonly so event is created we need to cancel it in backend
            //if there is values.id we are in edit so event is created we need to cancel it in backend
            if(values && !values.id && !props.eventId && option.idTask === Task.Cancel_event){
               history.push('/dashboard');
            }else{
               if(option.idTask === Task.Ask_for_Underwritter) {
                  ReferencesService.getAllRoles(UserGroup.underwriter).pipe(take(1)).subscribe((roles: Role[]) => {
                     if(roles.length && props.eventId) {
                        let eData:EmailData = {
                           targetEmail: roles[0].targetEmail,
                           emailContent: '',
                           idEvent: props.eventId,
                           targetEmailRole: getMappingRolesFromValue(UserGroup.underwriter)
                        }
                        setFieldValue('email.mustSendEmail', true);
                        setFieldValue('email.emailData', eData);
                     }
                 });
               }
               setFieldValuesAndOpenMessageModal(option);
            }
            
            break;
         case Action.Ask_for_Risk_Assessment:
            setFieldValuesAndOpenMessageModal(option);
            // TODO: To check
            setOptionToSend(option);
            setIsToAskRiskAssessment(true);
            break;
         case Action.Ask_for_Underwritter:
            setFieldValuesAndOpenMessageModal(option);
            setOpenUnderwriterModal(true);
            break;
         case Action.Send_to_Broker:
            if(props.isSingleButton){
               setFieldValue('nextActionBehavior.idEventFlow', (option as IPropsDashboardBase).idEventFlow);
                setFieldValue('nextActionBehavior.idTask',(option as IPropsDashboardBase).idTask) 
            }else {
               setFieldValue('nextActionBehavior.idEventFlow', option.id);
               setFieldValue('nextActionBehavior.idTask',option.idTask)
            }
           
            setFieldValue('nextActionBehavior.nextAction', option.idNextTask);
            setOpenModal(true);
            break;
         case Action.Edit_Event:
            if (props.eventId) {
               history.push('/dashboard/event/edit/' + props.eventId);
            }
            break;
         case Action.Save_and_close:
            if(props.isDeclaration && props.handleSubmitWithoutFormik) {
               props.handleSubmitWithoutFormik(null);
            }
            break;
         case Action.Recall_Event:
            if (props.eventId) {
               recallEventAction(props.eventId);
            }

            break;
         case Action.Cancel:
            history.push('/dashboard/event/readonly/' + history.location.pathname.match(/\d+/g))
            break;
         default:
            break;
      }
      ScrollToTopHelper();
      ctx.stopLoading();
   }

   const recallEventAction = (eventId: number) => {

      NewEventService.recallEvent(eventId).pipe(take(1)).subscribe({
         next: () => {
            if (history) history.push('/dashboard');
            createNotification(NotificationType.success, Task.Recall_event);
         },
         error: (err) => {
            LoggingServie.LogError(err, '');
         }
      })

   }

   const mainAction = (option: any) => {
      if(!props.isDeclaration && option.name && Number(TaskMappingToAction[option.name]) === Action.Edit_Event) {
         history.push('/dashboard/event/edit/' + props.eventId);
      }
      if(!props.isDeclaration && (!props.editEventForm || !props.canEditClosedEvent)) {
         if(option.name && option.name.idTask === Task.Save_for_later) {
            setFieldValue('nextActionBehavior.idEventFlow', option.name.id);
            setFieldValue('nextActionBehavior.idTask',option.name.idTask);
            setFieldValue('nextActionBehavior.nextAction', option.name.idNextTask);
            if(props.handleSubmit) {
               props.handleSubmit();
            }
         } else if (isPendingBrokerAndAdmin()) {
            option.taskLabel = option.label;
            handleClick(null, option);
         } else {
            setOpenMessageModal(true);
            if(props.isSingleButton && !props.closeFromDashboard) {
               setOptionToSend(option);
            } else if (props.closeFromDashboard) {
               setFieldValue('nextActionBehavior.idEventFlow', (option as IPropsDashboardBase).idEventFlow ?? option.id );
               setFieldValue('nextActionBehavior.idTask',option.idTask);
               setFieldValue('nextActionBehavior.nextAction', option.idNextTask);
            }
            else{
               setOptionToSend(option.name);
            }
         }
      } else if (props.canEditClosedEvent && props.editEventForm) {
         props.form.setFieldValue('nextActionBehavior.actionDesc', option.name.taskLabel);
         setFieldValue('nextActionBehavior.idEventFlow', option.name.id);
         setFieldValue('nextActionBehavior.idTask',option.name.idTask);
         setFieldValue('nextActionBehavior.nextAction', option.name.idNextTask);
         if(props.handleSubmit) {
            props.handleSubmit();
         }
      } else {
         option.taskLabel = option.label;
         handleClick(null, option);
      }
   }

   const cancel = () => {
      props.form.setFieldValue('nextActionBehavior.comment', '');
      props.form.setFieldValue('nextActionBehavior.create', false);
      setOpenMessageModal(false);
      ctx.stopLoading();
   }

   const send = () => {
      if(optionToSend && !secondaryOption) {
         handleClick(null, optionToSend);
      }
      else if (props.handleSubmitWithoutFormik) {
         // handle specific content for nextActionBehavior.comment
         // related to mandatory field
         props.form.setFieldValue("nextActionBehavior.comment", props.form.values.nextActionBehavior.comment, true);
         props.form.setFieldTouched("nextActionBehavior.comment", true, false);

         if(!validateMessage(props)){
            return;
         }

         if(isToAskRiskAssessment) {
            setOpenMessageModal(false);
            setOpenRAModal(true);
            setIsToAskRiskAssessment(false);
            return;
         }

         props.handleSubmitWithoutFormik(null);
      } else if (props.closeFromDashboard) {
         props.handleSubmit!();
      }
      setSecondaryOption(false);
      setOpenMessageModal(false);
   }

   const sendToBroker = (values : any) => {
      let propsEventId : number = 0;
      if(props.eventId !== null && props.eventId !== undefined){
         propsEventId = props.eventId;
      }
      // Prepare emailData
      let eData: EmailData = {
         emailContent: values.emailContent,
         idEvent: propsEventId,
         targetEmailRole: getMappingRolesFromValue(UserGroup.broker),
         targetEmail: [],
         idDeclaration: undefined
      };

      const eventHistory: EmailEventHistory[] = [];

      // Fill selected e-mail and the eventHistory
      if(values.targetEmails?.some((item: { hasOwnProperty: (arg0: string) => any; }) => item.hasOwnProperty('value'))){
         values.targetEmails?.forEach((item: any) => {
            eData.targetEmail.push(item.value);

            eventHistory.push({
                  id:0,
                  idEvent:propsEventId,
                  groupRoleDisplayName: getMappingRolesFromValue(UserGroup.broker),
                  groupRoleId: UserGroup.broker,
                  userEmail: item.value,
                  isDeleted: false
                })
          })
      }
      //Set new object to the main one
      props.form.values.email = {
         mustSendEmail: true,
         emailData: eData,
         emailHistory: eventHistory
      }

      if(props.handleSubmit) props.handleSubmit();
      setOpenModal(false);
   }

   const askForRiskAssessor = (value: string) => {
      props.form.values.nextActionBehavior.companyName = value;
      let propsEventId : number = 0;
      if(props.eventId !== null && props.eventId !== undefined){
         propsEventId = props.eventId;
      }
      // Prepare emailData
      let eData: EmailData = {
         emailContent: '',
         idEvent: propsEventId,
         targetEmailRole: value,
         targetEmail: [],
         idDeclaration: undefined
      };
      //Set new object to the main one
      props.form.values.email = {
         mustSendEmail: true,
         emailData: eData
      }
      
      if(props.handleSubmitWithoutFormik) {
         props.handleSubmitWithoutFormik(null);
      }

      setOpenRAModal(false);
   }

   const askForUnderwriter = (email: string) => {
      let propsEventId : number = 0;
      if(props.eventId !== null && props.eventId !== undefined){
         propsEventId = props.eventId;
      }
      // Prepare emailData
      let eData: EmailData = {
         emailContent: '',
         idEvent: propsEventId,
         targetEmailRole: getMappingRolesFromValue(UserGroup.underwriter),
         targetEmail: [email],
         idDeclaration: undefined
      };

      //Set new object to the main one
      props.form.values.email = {
         mustSendEmail: true,
         emailData: eData
      }
      
      if(props.handleSubmit) {
         props.handleSubmit()
      }

      setOpenUnderwriterModal(false);
   }

   const singleAction = (e: any) => {
      e.stopPropagation();
      mainAction(props.singleButtonValue);
   }

   const validateMessage = (props: PropsUiNextActions) => {
      let errorMessage : string = 'Comment is mandatory';
      let idEventFlow = props.form.values.nextActionBehavior.idEventFlow;
      let idTask = props.form.values.nextActionBehavior.idTask;
      let nextAction = props.form.values.nextActionBehavior.nextAction;
      let res = ValidatorNextActionComments.NextActionCommentMustMandatory(idEventFlow, idTask, nextAction)

      if(res){

         if(props.form.values.nextActionBehavior.comment.length > 0)
         {
            return true;
         }

         setTimeout(() => {
            props.form.setErrors({"nextActionBehavior.comment": errorMessage});
          }, 0);
      }

      return !res;
    };

   return (
      <React.Fragment>
         {
            !props.isSingleButton ?
               listButton.length > 0 ? (
                  <div className="box-content">
                     <Button disabled={props.disabled} style={{marginLeft : '10px'}}  className="btn btn-white--outline" variant="outlined" color="secondary" onClick={() => mainAction(listButton[0])}>{props.editEventForm && props.canEditClosedEvent && listButton[0].label === 'Save for later' ? 'Save' : listButton[0].label}</Button>
                     {
                        listButton.length > 1 && (
                           <React.Fragment>
                              <p>or</p>
                              <UiSplitButton
                                  disabled={props.disabled}
                                  actions={listButton.slice(1, listButton.length)}
                                  default={0}
                                  onClick={(event: any, actionName: any) => handleClick(event, actionName)}
                              />
                           </React.Fragment>
                        )
                     }
                  </div>
               ) :
               'No available actions'
            :
               <Button variant="contained" color="primary" onClick={(e) => singleAction(e)} disabled={props.disabled || !props.singleButtonValue || !props.singleButtonValue.taskLabel}>
                  { props.singleButtonValue && props.singleButtonValue.taskLabel ? props.singleButtonValue.taskLabel : 'No Action'}
               </Button>
               
         }
         <UiModal title="Message" open={openMessageModal} onClose={cancel}>
            <div>
               <div className="row">
                  <Field
                     name="nextActionBehavior.comment"
                     label="Comment"
                     component={UiTextareaField}
                  />
               </div>
               <div className="ui-modal__footer ui-modal__footer--buttons">
                  <Button onClick={cancel} variant="outlined" color="primary" key="cancel">Cancel</Button>
                  <Button onClick={send} variant="contained" color="primary" key="apply">Confirm</Button>
               </div>
            </div>
         </UiModal>
         {
            loadDataPopUpBroker ? (
               <UiModalBrocker event={undefined} onClose={() => setOpenModal(false)} open={openModal} send={(value: any) => sendToBroker(value)} />
            ) : ''
         }
         {
            loadDataPopUpRisk ? (
               <UiModalRiskAssessorOrUnderwritter onClose={() => setOpenRAModal(false)} open={openRAModal} send={(value: string) => askForRiskAssessor(value)} role={UserGroup.risk_assessor} />
            ) : ''
         }
         {
            loadDataPopUpUnderw ? (
               <UiModalRiskAssessorOrUnderwritter onClose={() => setOpenUnderwriterModal(false)} open={openUnderwriterModal} send={(value: string) => askForUnderwriter(value)} role={UserGroup.underwriter} />
            ) : ''
         }
      </React.Fragment>
   )
}

