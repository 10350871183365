import { IUiMultiselectOption } from "../components/fields/UiAutocompleteField";
import { UserGroup } from "./enums";
import {IStateOrder} from "../components/ui/UiTable/types";

export interface CommonProperties {
    id?: number;
    createdBy?: string;
    createdDate?: string | null;
    modifiedBy?: string;
    modifiedDate?: string | null;
    isDeleted?: boolean;
}

export interface RiskTagsProps {
    isStrategic: boolean;
    isHeritage: boolean;
    isMulti?: boolean;
}

export interface KeyValuePair {
    [key: string]: string;
}

export interface KeyValuePairAsBool {
    [key: string]: boolean;
}

export interface FileTypesAccepted {
    document: KeyValuePair;
    image: KeyValuePair;
    video: KeyValuePair;
}

export interface User {
    value: number;
    username: string;
    role: string;
}

export interface Download {
    headers: {
        "content-type": string;
    };
    data: string;
}

export interface ApiResponse<T> {
    data: {
        model: T;
    };
}

export interface SelectItem {
    value: string;
    label: string;
}

export interface EmailData {
    targetEmail: string[];
    targetEmailRole: string;
    emailContent: string;
    idEvent: number;
    idDeclaration?: number;
}

export interface Email {
    mustSendEmail: boolean;
    emailData?: EmailData;
}

export interface EmailEventHistory {
    isDeleted: false;
    id: 0;
    groupRoleId: number;
    userEmail: string;
    idEvent: number;
    groupRoleDisplayName: string;
}

export interface ILoadingContext {
    startLoading: () => void;
    stopLoading: () => void;
}

export type IitemsLoader = () => void;
export type LoadableItems<T = any> = {
    items: T[];
    isLoading: boolean;
    load: IitemsLoader;
    set?: (value: T[]) => void;
};
export type LoadablePagedItems<T = any> = {
    pageResponse: PagedResponse<T>,
    isLoading: boolean,
    load: IitemsLoader
}

export type LoadableItemsWithOptions<T> = LoadableItems<T> & {
    options: IUiMultiselectOption[];
};

export type LoadableValue<T> = {
    value: T,
    isLoading: boolean,
    load: IitemsLoader
    set: (value: T) => void
}

export type BaseAttributeDto = {
    id: number
    code: string,
    name: string,
    isActive: boolean,
}
export type ICreateAttribute = Omit<BaseAttributeDto, "id">

export type MaisonDto = BaseAttributeDto & CommonProperties & {
    alternateName: string,

}

export type CountryDto = BaseAttributeDto & CommonProperties & {displayOrder: number};

export type IThreshold = {
    id: number,
    thresholdValue: number,
    modifiedBy: string,
}
export interface TemplateDto {
    id: number,
    name: string,
    subject: string,
    body: string,
}
export type ICreateTemplate = Omit<TemplateDto, "id">
export type ICreateNotification = Omit<NotificationDto, "id">
export type ICreateReminder = Omit<ReminderDto, "id">


interface INotificationBase {
    id: number,
    name?: string,
    template: Partial<TemplateDto>,
    recipients: RoleDto[],
    active:boolean,
    idRiskLevel?:number,
    nextAction: {
        id: number,
        itemContent: string,
    },
    isShownDeleteButton?: boolean
}


export interface NotificationDto extends INotificationBase{
    threshold?: number,
    thresholdRecipients: RoleDto[], 
}

export interface NotificationDtoBack extends BaseEntity {
    templateId: number,
    nextActionId: number,
    threshold: number,
    isActive: boolean,
    idRiskLevel: number,
    notificationRoleRecipients: Array<{
        idNotification: number
        idNotificationRoleGroupUser: number
    } & BaseEntity>,
    thresholdAlertRecipients: Array<{
        idNotification: number
        idThresholdRoleGroupUser: number
    } & BaseEntity>,
}

export interface ReminderDto extends INotificationBase {
    frequency?: number,
}

export interface ReminderDtoBack extends BaseEntity {
    templateId: number
    nextActionId: number
    reminderRoleRecipients: Array<{
        idReminder : number
        idReminderRoleGroupUser: number
    } & BaseEntity>
    frequency: number
    isActive: boolean
}

export interface IThresholdValue {
    threshold: number
}

export interface RoleDto {
    id: number,
    name: string,
    idGroupUsers?: UserGroup[]
    isDeleted? : boolean
    idReminderRoleGroupUser? : number

}

export interface UserDto {
    name: string, 
    email: string,
    role: string,
    maisons: MaisonDto[],
    countries: CountryDto[],
    isActive: boolean;
}

export interface IFieldListItem {
    role: string,
    declarationFields: IDeclarationField[]
}

export interface IDeclarationField {
    section: string,
    subSections?: SubSection[]
    fields?: MyField[]
}


export type SubSection = {
    name: string,
    fields: MyField[]
}

export type MyField = {
    name: string,
    dependsOn?: string,
    mandatory: boolean,
    id: string
};

export enum SecurityConceptStatus {
    Draft = "Draft",
    Validated = "Validated",
    Reviewed = "Reviewed",
    Rejected = "Rejected",
    Accepted = "Accepted" // for compatibility, considered as Validated
}
export interface ISecurityConcept extends CommonProperties {
    id: number;
    filename: string;
    extension: string;
    maison: {
        id: number;
        maison: string;
    };
    idDeclaration: number;
    idEvent: number;
    version: number;
    status: SecurityConceptStatus;
    master: boolean; // Should be deleted
    comments?: string;
    author: {
        id: number;
        name: string;
    };
    validator?: {
        id: number;
        name: string;
    };
    reviewer?: {
        id: number;
        name: string;
    };
}

export interface BaseEntity extends Entity {
    id : number
}

export interface Entity {
    isDeleted : boolean
}

export interface UserDtoBack {
    name : string | undefined, 
    email: string | undefined, 
    role: string, 
    maisons: any[], 
    countries: any[],
    id : number,
    isActive: boolean;
}

export interface PagedResponse<T> {
    model: T[],
    pageCount: number,
    pageSize: number,
    pageNumber: number,
    itemsCount: number
}

export interface PagedRequest {
    pageSize: number,
    pageNumber: number,
    orderColumn?: string,
    sortOrder?: IStateOrder,
    searchValue?: string;
    refreshCache?: boolean;
}

export interface FilteredAdminPagedRequest extends PagedRequest{
    maisons?: string [],
    countries?: string [];
}