import {Button, Icon, Tooltip} from '@material-ui/core';
import { AxiosRequestConfig } from 'axios';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { notificationMessage } from '../../config/GlobalAppConfig';
import { fileTypesAccepted, securityConceptFileTypesAccepted } from '../../config/uploadConfig';
import { ConfirmationContext, IConfirmationContext } from '../../contexts/ConfirmationContext';
import CustomAxios, { ApiCallingMethods } from "../../helpers/AxiosHelper";
import { ArrayUtils, baseCreateNotification, downloadFile, getLocationForUpload } from '../../helpers/HelpersFunc';
import LocalStorageTokenService from '../../helpers/SessionStorageTokenService';
import { DocAccessType, DocumentActions, Maison, NotificationType, UserGroup, UserRoles } from '../../models/enums';
import { getMappingRoles } from '../../models/records';
import { LoggingServie } from '../../services/LoggingService';
import { SecurityConceptService } from '../../services/mock.securityConcept.service';
import { UploadService } from '../../services/uploadService';
import Dropzone from '../dropzone/Dropzone';
import ProgressBar from '../progressBar/ProgressBar';
import { UiModal } from '../ui/UiModal/UiModal';
import { DocumentsListing } from './components/DocumentsListing';
import { CustomFile, DocumentRights, Props, StateProps } from './interfaces';
import './Upload.css';
import './Upload.scss';
import {HelpOutline, Warning} from "@material-ui/icons";
import LoadingContext from "../../../routes/LoadingContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import {TOOLTIP_SECURITY_CONCEPT_REGION} from "../../constants";


type Resolve = (value: boolean) => void

export type ConfirmResolve = {
    resolve: Resolve;
}


class Upload extends Component<Props, StateProps> {
    fileExtensionsList = [];
    extension = null;
    requestProgress: boolean;
    displayUploadSection: boolean = true;
    static contextType = ConfirmationContext;
    // @ts-ignore
    context: IConfirmationContext

    constructor(props: any) {
        super(props);

        this.state = {
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false,
            files: this.props.files,
            isModalOpen: false,
            documentRights: [],
            isLoading: false,
            confirmResolver: { resolve: () => {} },
            preUpload: false,
            deleteInProgress: false,
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.renderProgress = this.renderProgress.bind(this);

        Object.values(this.props.fileTypesAccepted).forEach((value: any) => {
            this.fileExtensionsList = this.fileExtensionsList.concat(Object.values(value));
        })

        this.requestProgress = false;
    }

    updatePreUploadState = (preUpload: boolean) => {
        if (this.props.onPreUpload) {
            this.props.onPreUpload(preUpload);
            this.setState({preUpload: preUpload});
        }
    }

    setDeleteInProgress(value: boolean){
        this.setState({deleteInProgress: value});
    }

    documentRightsforSelectField = (documentRights: DocumentRights[]): DocumentRights[] => ArrayUtils.getUniqueBySingleKey(
        documentRights.map((documentRights: DocumentRights) => ({
            itemContent: documentRights.documentTypes.itemContent,
            id: documentRights.documentTypes.id
        })
    ), 'id');

    documentRightsforSelectFieldUploadOnly = (documentRights: DocumentRights[]): DocumentRights[] => ArrayUtils.getUniqueBySingleKey(
        documentRights.filter((documentRights: DocumentRights) => documentRights.documentActions.itemContent.toLowerCase() === DocumentActions.Upload)
            .map((documentRights: DocumentRights) => ({
                itemContent: documentRights.documentTypes.itemContent,
                id: documentRights.documentTypes.id
            }))
    , 'id');

    isUploadAllowedForDocumentType = (docAccessType: DocAccessType, documentRights: DocumentRights[]) => {
        //if no docType it's new file so upload is allowed
        if (docAccessType === DocAccessType["No Type"]) {
            return true
        } else {
            return documentRights.find((documentRight) => documentRight.documentTypes.id === docAccessType && (documentRight.documentActions.itemContent.toLowerCase() === DocumentActions.Upload))
                ? true : false
        }
    }

    isUploadAllowedForUser = (idUserRole: UserGroup) => {
        switch (idUserRole) {
            case UserGroup.local_event_manager:
            case UserGroup.local_security_manager:
            case UserGroup.group_security_manager:
            case UserGroup.risk_assessor:
            case UserGroup.underwriter:
            case UserGroup.maison_security_manager:
                return true;
            case UserGroup.broker:
                return false;
        }
    }

    isUploadAllowedForUserIdWithDocumentType = (idUserRole: UserGroup, docAccessType: DocAccessType, documentRights: DocumentRights[]) => {
        return this.isUploadAllowedForUser(idUserRole) && this.isUploadAllowedForDocumentType(docAccessType, documentRights);
    }

    addDocumentRightsToFile = (file: CustomFile, documentrights?: DocumentRights[]) => {
        const docAccessType = Number(file.docAccessType) ? Number(file.docAccessType) : DocAccessType["No Type"];
        const rights = documentrights ? documentrights : this.state.documentRights
        file.docRights = this.isUploadAllowedForDocumentType(docAccessType, rights)
            ? this.documentRightsforSelectFieldUploadOnly(rights)
            : this.documentRightsforSelectField(rights);
    }

    onFilesAdded(files: CustomFile[]) {
        let filesToBeAdded: CustomFile[] = [];
        let filesToBeRemoved: CustomFile[] = [];
        let filesFoundToBeReplace: CustomFile[] = [];
        let filesToBeReplace: CustomFile[] = [];

        this.updatePreUploadState(true);

        if (this.state.files.length) {
            files.forEach((file: CustomFile) => {
                this.addDocumentRightsToFile(file);
                const filesFound = this.props.isEvent ?
                    this.state.files.filter(f => f.name === file.name && !f.idDeclaration) :
                    this.state.files.filter(f => f.name === file.name)

                if (filesFound.length && Number(filesFound[0].docAccessType) !== DocAccessType['Security concept']) {
                    // Add inside an array a list of files found that will be replaced
                    filesFoundToBeReplace.push(filesFound[0]);
                    // Add inside an array a list of new files to be replaced
                    filesToBeReplace.push(file);
                    this.setState({
                        isModalOpen: true,
                        fileToReplace: filesToBeReplace,
                        filesFound: filesFoundToBeReplace,
                        filesToBeAdded: filesToBeAdded,
                        filesToBeRemoved: filesToBeRemoved
                    })
                }
                else {
                    file.docAccessType = ''; //FIX is necessary to add this line ?
                    filesToBeAdded.push(file);
                    file.idMaison = this.props.isEvent ? 0 : this.state.files[0].idMaison 
                    setTimeout(() => {
                        this.setState(prevState => ({
                            files: prevState.files.concat(filesToBeAdded.filter(value => !prevState.files.includes(value)))
                        }));
                    }, 100)
                }
            })
        }
        else {
            files.forEach((file: CustomFile) => {
                this.addDocumentRightsToFile(file);
            })
            this.setState(prevState => ({
                files: prevState.files.concat(files)
            }));
        }
    }

    renderProgress(file: CustomFile) {
        const uploadProgress = this.state?.uploadProgress[file.name];
        if (file.uploading && (this.state?.uploading || this.state?.successfullUploaded)) {
            return (
                <ProgressBar progress={uploadProgress ? uploadProgress.percentage : 0} />
            );
        }
    }

    async uploadFile(file: CustomFile, autoValidation: boolean = false) {
        return new Promise((resolve, reject) => {
            //Set full url to upload file
            let fullurl = `${this.props.urlProgress}?idEvent=${this.props.eventId}`
            if (this.props.declarationId) {
                fullurl += `&idDeclaration=${this.props.declarationId}`
            }

            this.setState({ uploadProgress: {}, uploading: true });
            const filename = file.name.replace(/\s/g, '%20');
            if (!file.id || (file.isToReplace && file.id)) {
                const formData = new FormData();
                formData.append("idEvent", this.props.eventId.toString());
                if (this.props.declarationId) {
                    formData.append("idDeclaration", this.props.declarationId.toString());
                }
                const pos = Number(file.docAccessType);
                formData.append("docType", DocAccessType[pos]);
                formData.append("files", file, file.name);
                if(autoValidation){
                    formData.append("IsAutoApproveProcess", true.toString())
                }
                let method: ApiCallingMethods = ApiCallingMethods.post;
                let uploadUrl = this.props.urlUpload;
                if (file.id) {
                    uploadUrl += "/update/" + file.id;
                    method = ApiCallingMethods.put;
                }

                const options: AxiosRequestConfig = {
                    method: method,
                    url: uploadUrl,
                    data: formData,
                    responseType: 'json',
                    onUploadProgress: (event) => this.onUploadProgress(event, file, fullurl, filename)
                };
                CustomAxios.request(options).subscribe((response) => {
                    if (response.data && typeof response.data.id === 'number') {
                        file.id = response.data.id;
                        file.filenameInBe = response.data.filenameConverted;
                        if(this.props.loadConcepts) {
                            this.props.loadConcepts();
                        }
                      
                        resolve(file);
                    }else{
                        reject()
                    }
                }, (error) => {
                    reject(error);
                    LoggingServie.LogError(error, '');
                    baseCreateNotification(NotificationType.error, notificationMessage.Error_Upload_Files.title, notificationMessage.Error_Upload_Files.message);
                });
            }
            else {
                UploadService.update(this.props.urlUpload, file).subscribe(() => {
                    NotificationManager.success('File updated');
                    this.setState({ uploading: false });
                    resolve(file)
                }, (error)=>{
                    reject(error);
                })
            }
        })

    }

    onUploadProgress = (progressEvent: any, file: CustomFile, fullurl: string, filename: string) => {
        if (this.props.progressActivated) {
            let interval: any;
            const copy = { ...this.state.uploadProgress };
            let state = 'pending';
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

            if (percentCompleted === 100) {
                state = 'done';
                interval = setInterval(() => {
                    if (!this.requestProgress) {
                        this.followProgress(interval, file, fullurl, filename);
                    }
                }, 1000);
            }

            copy[file.name] = { state: state, percentage: percentCompleted };
            this.setState({
                uploadProgress: copy
            })
        }
    }

    followProgress = (interval: any, file: CustomFile, fullurl: string, filename: string) => {
        const options: AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: `${fullurl}&fileName=${filename}`,
            data: null
        };
        this.requestProgress = true;
        CustomAxios.request(options).subscribe(res => {
            if (String(res.data).includes("Upload - Encrypt - Init") && !file.encrypting) {
                file.uploading = false;
                file.encrypting = true;
                file.encryptionDone = false;
                file.isToReplace = false;
            }
            else if (String(res.data).includes("Upload - Encrypt - Done")) {
                file.uploading = false;
                file.encrypting = true;
                file.encryptionDone = false;
                file.isToReplace = false;

                this.setState({ uploading: true });
            }
            else if (String(res.data).includes("Upload - Init") || String(res.data).includes("Upload - Save")) {
                file.uploading = true;
                file.encrypting = false;
                file.encryptionDone = false;
                file.isToReplace = false;

                this.setState({ uploading: true });
            }
            else {
                file.uploading = false;
                file.encrypting = false;
                file.encryptionDone = true;
                file.isToReplace = false;
                this.setState({ uploading: false });
                clearInterval(interval);
            }
            this.requestProgress = false;
            this.setState({
                files: this.state.files
            });
        });
    }

     onInvalidFile = async (extension: string)=>{
        await this.context.confirm({
            title: "Format error",
            confirmText: "Ok",
            hideCancel: true,
            description: (
                <div>
                    The extension ".{extension}" is not supported. 
                    Please choose a file with one of the following extensions:
                    <ul style={{ padding: "10px 25px" }}>
                        <li>Document: {Object.values(fileTypesAccepted.document).map(f=>`.${f}`).join(", ")}</li>
                        <li>Image: {Object.values(fileTypesAccepted.image).map(f=>`.${f}`).join(", ")}</li>
                    </ul>
                </div>
            ),
        });
        return ;
    }
    updateAccessType = async(event: React.ChangeEvent<HTMLSelectElement>, file: CustomFile) => {
        const fileExtension = file.name.split('.').pop();
        const allowedExtensions = Object.values(securityConceptFileTypesAccepted);
        let acceptUpdate = true;
        if(!fileExtension) {
            return ;
        }
        if(Number(event.target.value) === DocAccessType['Security concept']) {
            if(!allowedExtensions.includes(fileExtension)) {
                await this.context.confirm({
                    title: "Format error",
                    confirmText: "Ok",
                    hideCancel: true,
                    description: (
                        <div>
                            The selected file is not supported for security concept. Please choose a file with one of
                            the following extensions:
                            <ul style={{ padding: "10px 25px" }}>
                                <li>Word: .doc, .docx, .rtf</li>
                                <li>PowerPoint: .ppt, .pptx, .pps, .ppsx</li>
                            </ul>
                        </div>
                    ),
                });
                return ;
            }else {
                const filesFound = this.props.isEvent ?
                    this.state.files.filter(f => Number(f.docAccessType) === DocAccessType['Security concept'] && !f.idDeclaration && f.idMaison === file.idMaison) :
                    this.state.files.filter(f => Number(f.docAccessType) === DocAccessType['Security concept'])
                if(filesFound.length) {
                    const ok = await this.context.confirm({
                        title: "Security concept already exist",
                        description: <> 
                            We have detected a security concept document. By clicking on confirm button, it will create a new version.
                        </>,
                        
                    })
                    if(!ok){
                        acceptUpdate = false;
                    }
                }
            }
        }
  
        if(acceptUpdate){
            const role = LocalStorageTokenService.getUserRoleToken();
            if(Number(event.target.value) === DocAccessType['Security concept'] && (role === UserRoles.areasec || role === UserRoles.hqsecurity || role === UserRoles.director)){
                const doAutoValidation = await this.context.confirm({
                    title: "Security Concept Validation",
                    description: <>Do you want to validate the selected document <strong>{file.name}</strong> ? </>,
                    confirmText: "Validate",
                    confirmColor: "secondary",
                    actionButtons: [{
                        label: "Keep as draft",
                        id: "draft"
                    }]
                }) as boolean | "draft";
                if(doAutoValidation === "draft"){
                    // TODO: UPLOAD SECURITY CONCEPT AS DRAFT OR VALIDATED
                    await this.startUploadFile(file, event.target.value, false)
                }else{
                    await this.startUploadFile(file, event.target.value, true)
                }
                SecurityConceptService.securityConceptBus.next(this.props.eventId)
            }else{
                await this.startUploadFile(file, event.target.value)
            }
            
        }
    }
    async startUploadFile(file:CustomFile, accessType: string, autoValidation: boolean = false){
        if (!file.encryptionDone) {
            file.uploading = true;
        }
        file.docAccessType = accessType;
        try{
            await this.uploadFile(file, autoValidation);
        }finally{
            if(this.props.reloadFiles){
                this.props.reloadFiles();
            }
            this.updatePreUploadState(false);
        }
    }
    
    deleteFile = (file: CustomFile, fromPopup: boolean = false) => {
        let pos = -1;

        if (file.id && !fromPopup) {
            this.updatePreUploadState(true);
            this.setDeleteInProgress(true)
            UploadService.delete(`${this.props.urlUpload}`, file.id).subscribe(() => {
                NotificationManager.success('File deleted successfully');
                SecurityConceptService.securityConceptBus.next(this.props.eventId)
                if(this.props.reloadFiles){
                    this.props.reloadFiles();
                }
            }, () => {
                NotificationManager.error('File deletion went wrong');
            }, () => {
                this.updatePreUploadState(false);
                this.setDeleteInProgress(false);
            });
        }
        if (!file.id && !fromPopup) {

            this.updatePreUploadState(false);

        }

        const fileToDel = this.state.files.filter((f, index) => {
            if (f.name === file.name && f.idDeclaration === file.idDeclaration) {
                pos = index;
                return true;
            }
            return false;
        })[0];

        if (fileToDel) {
            this.state.files.splice(pos, 1);
            this.setState({
                files: this.state.files
            })
        }
    }

    handleModalClose = () => {
        this.setState({
            isModalOpen: false,
        })
    }

    cancel = () => {
        this.handleModalClose();
    }

    onDeleteCrossClick = async (file: CustomFile) => {
        const ok = await this.context.confirm({
            title: "Warning - Delete",
            description: `Are you sure you want to delete ${file.name}?`
        })
        if(ok){
            this.deleteFile(file);
        }
    }


    onDownload = (file: CustomFile) => {
        UploadService.download(this.props.urlDownload, file.filenameInBe).subscribe(
            (res) => {
                downloadFile(res, file.name);
            },
            (error) => {
                if(error.status === 404) {
                    baseCreateNotification(NotificationType.error, notificationMessage.Error_Download_FileNotFound.title, notificationMessage.Error_Download_FileNotFound.message);
                }
                else {
                    baseCreateNotification(NotificationType.error, notificationMessage.Error_Download_Files.title, notificationMessage.Error_Download_Files.message);
                }
            }
        )
    }

    handleReplace = () => {
        if (this.state.filesFound && this.state.fileToReplace) {
            const filesToBeAdded = this.state.filesToBeAdded;
            const filesToBeRemoved = this.state.filesToBeRemoved;

            this.state.fileToReplace.forEach((item: CustomFile) => {
                item.docAccessType = '';
                item.id = this.state.filesFound?.find(exp => exp.name === item.name)?.id
                filesToBeAdded?.push(item);
                filesToBeRemoved?.push(item);
                item.isToReplace = true;
            });

            if (filesToBeRemoved && filesToBeRemoved.length) {
                filesToBeRemoved.forEach((customFile: CustomFile) => {
                    const fileToRemove = this.state.files.filter(f => f.name === customFile.name)[0]
                    this.deleteFile(fileToRemove, true);
                })
            }

            if (filesToBeAdded && filesToBeAdded.length) {
                setTimeout(() => {
                    this.setState(prevState => ({
                        files: prevState.files.concat(filesToBeAdded.filter(value => !prevState.files.includes(value)))
                    }));
                }, 100)
            }
            this.updatePreUploadState(true);
            this.handleModalClose();
        }
    }

    updateAndGetFileProps = (documentRights: DocumentRights[]) => {
        return this.props.files?.map(
            file => {
                const docAccessType = Number(file.docAccessType) ? Number(file.docAccessType) : DocAccessType["No Type"];
                this.addDocumentRightsToFile(file, documentRights);
                const idUserRole = getMappingRoles(LocalStorageTokenService.getUserRoleToken());
                file.disable = !this.isUploadAllowedForUserIdWithDocumentType(idUserRole, docAccessType, documentRights)
                return file;
            }
        )
    }

    componentDidMount() {
        const idrole = getMappingRoles(LocalStorageTokenService.getUserRoleToken());
        this.setState({
            ...this.state,
            isLoading: true
        });

        const uploadSubscription = UploadService.getDocumentRightsByRole(idrole).subscribe(
            (response) => {
                this.displayUploadSection = response.some((documentRights: DocumentRights) => documentRights.documentActions.itemContent.toLowerCase() === DocumentActions.Upload)
                const newFileList = this.updateAndGetFileProps(response);
                this.setState({
                    ...this.state,
                    documentRights: response,
                    files: newFileList,
                    isLoading: false
                });
            },
            (error) => {
                this.setState({
                    ...this.state,
                    isLoading: false
                });
                LoggingServie.LogError(error, '')
            }
        );

        this.setState({
            subscription: uploadSubscription
        })
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.files !== this.props.files && this.state.documentRights.length) {
            this.setState({
                ...this.state,
                isLoading: true
            });

            this.setState({
                ...this.state,
                files: this.updateAndGetFileProps(this.state.documentRights),
                isLoading: false
            })
        }
    }

    componentWillUnmount() {
        this.state.subscription?.unsubscribe();
    }

    isRegion(){

        const role = LocalStorageTokenService.getUserRoleToken();

        return role === UserRoles.areasec;
    }

    render() {

        return (
            <div className="upload">

                <div className={!this.state.preUpload || this.state.deleteInProgress ? "hidden" : "warning-container"}>
                    <Warning/>
                    <p className="warning-message">
                        Please select the document type for all attachments before proceeding to the next action
                    </p>
                </div>

                <div className={!this.state.deleteInProgress ? "hidden" : "warning-container"}>
                    <Warning/>
                    <p className="warning-message">
                        Please wait the document deletion before proceeding to the next action
                    </p>
                    <CircularProgress className="loading-delete" />
                </div>

                <div className="upload__title">
                    <h3>
                        {this.props.title}
                        <div hidden={!this.isRegion()}>
                            <Tooltip className="upload__tooltip" title={TOOLTIP_SECURITY_CONCEPT_REGION}>
                                <HelpOutline/>
                            </Tooltip>
                        </div>
                    </h3>

                    {this.state.files?.length === 1 ? (
                        <p className="upload__number"><span>{this.state.files.length}</span>Document</p>
                    ) : this.state.files?.length > 1 && (
                        <p className="upload__number"><span>{this.state.files.length}</span> Documents</p>
                    )}
                </div>
                <div>
                    {this.props.isEvent && this.state.files.length > 0 && !this.state.isLoading ? (
                        <React.Fragment>
                            {/* Declaration listing linked only to the Event */}
                            {
                                <div className="files">
                                    <div className="row">
                                        Events
                                    </div>
                                    <DocumentsListing
                                        files={this.state.files.filter(t => !t.idDeclaration)}
                                        fileTypesAccepted={this.props.fileTypesAccepted}
                                        updateAccessType={this.updateAccessType}
                                        deleteFile={this.onDeleteCrossClick}
                                        renderProgress={this.renderProgress}
                                        download={this.onDownload}
                                        disabled={this.props.disabled}
                                        canDelete={this.props.canDelete}
                                    />
                                </div>
                            }
                            {/* Add Global Declaration to the list if created*/}
                            {this.state.files.filter(f => f.idDeclaration && f.idMaison === Maison.global).length > 0 && (
                                <React.Fragment>
                                    <div className="files" key={Maison.global}>
                                        <div className="row">
                                            {this.state.files.filter(f => f.idDeclaration && f.idMaison === Maison.global)[0].maisonName}
                                        </div>
                                    </div>
                                    <DocumentsListing
                                        files={this.state.files.filter(t => t.idMaison === Maison.global)}
                                        fileTypesAccepted={this.props.fileTypesAccepted}
                                        updateAccessType={this.updateAccessType}
                                        deleteFile={this.onDeleteCrossClick}
                                        renderProgress={this.renderProgress}
                                        download={this.onDownload}
                                        disabled={this.props.disabled}
                                        canDelete={this.props.canDelete}
                                    />
                                </React.Fragment>
                            )}
                            {/* Declaration Listing of the correspondent maison created*/}
                            {
                                this.props.maisonList.map((t: any) => {
                                    if (this.state.files.filter(i => i.idDeclaration && i.idMaison === t.id).length > 0)
                                        return (
                                            <div className="files" key={t.id}>
                                                <div className="row">
                                                    {t.itemContent}
                                                </div>
                                                <DocumentsListing
                                                    files={this.state.files.filter(z => z.idMaison === t.id)}
                                                    fileTypesAccepted={this.props.fileTypesAccepted}
                                                    updateAccessType={this.updateAccessType}
                                                    deleteFile={this.onDeleteCrossClick}
                                                    renderProgress={this.renderProgress}
                                                    download={this.onDownload}
                                                    disabled={this.props.disabled}
                                                    canDelete={this.props.canDelete}
                                                />
                                            </div>
                                        )
                                    else return null;
                                })
                            }
                        </React.Fragment>
                    ) :
                        <DocumentsListing
                            files={this.state.files}
                            fileTypesAccepted={this.props.fileTypesAccepted}
                            updateAccessType={this.updateAccessType}
                            deleteFile={this.onDeleteCrossClick}
                            renderProgress={this.renderProgress}
                            download={this.onDownload}
                            disabled={this.props.disabled}
                            canDelete={this.props.canDelete}
                        />
                    }
                    <div>
                        {
                            this.displayUploadSection ? (
                                <Dropzone
                                    onInvalidFile={this.onInvalidFile}
                                    maxFileSize={this.props.maxFileSize}
                                    supportedExtensions={this.fileExtensionsList}
                                    onFilesAdded={this.onFilesAdded}
                                    disabled={this.props.disabled || this.state.uploading}
                                    locationForUpload={getLocationForUpload(this.props.declarationId)} />
                            ) : ''
                        }
                    </div>
                    <NotificationContainer />
                </div>
                <UiModal id={'replacePopup'} title={'Warning'} onClose={this.handleModalClose} open={this.state.isModalOpen}>
                    <div>
                        <div className="row">
                            <ul>
                                <li>
                                    We have detected {this.state.filesFound?.length} identitical files. Are you sure you want to replace them :
                                    <ul>
                                        {this.state.filesFound?.map((value: CustomFile, index: number) => {
                                            return <li key={index}>{value.name}</li>
                                        })}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="ui-modal__footer ui-modal__footer--buttons">
                            <Button onClick={this.cancel} variant="outlined" color="primary" key="cancel">Cancel</Button>
                            <Button onClick={() => { this.handleReplace() }} variant="contained" color="primary" key="apply">Confirm</Button>
                        </div>
                    </div>
                </UiModal>

            </div>
        )
    }
}

export default Upload;