import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Loading.scss";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      marginTop: 5,
    },
    placeholder: {
      height: 40,
    },
  })
);

interface Props {
  cancel?: () => void;
}

export const Loading = (props: Props, ref: any) => {
  const classes = useStyles();
  const [showCancel, setShowCancel] = React.useState(false);

  const handleClickLoading = () => {
    if (props.cancel) {
      props.cancel();
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowCancel(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="loading-container">
      <div className="loading-box">
        <div className={classes.placeholder}>
          <CircularProgress />
        </div>
        {showCancel && (
          <Button onClick={handleClickLoading} className={classes.button}>
            Stop loading
          </Button>
        )}
      </div>
    </div>
  );
};
