import React, { createContext, FC, useContext } from "react";
import { Accessor } from "../../../shared/components/ui/UiTable/types";
type IAdministrationItemContext<T = any, IData = any> = {
    onDelete?: any;
    onCancel: any;
    onConfirm: any;
    loading: boolean;
    open: boolean;
    item?: T;
    data?: IData;
    title: string;
    itemLabelProp: Accessor<T>;
};
const defaultContext = {
    onDelete: async (data: any) => {},
    onCancel: () => {},
    onConfirm: async (data: any) => {},
    loading: false,
    open: false,
    title: "",
    itemLabelProp: "",
};

export const AdministrationItemContext = createContext<IAdministrationItemContext>(defaultContext);
export const AdministrationItemContextProvider: FC<{ value: IAdministrationItemContext }> = ({ value, children }) => {
    return <AdministrationItemContext.Provider value={{...value}}>{children}</AdministrationItemContext.Provider>;
};

export type IUseAdministationContext<T = any, IData = any> = () => IAdministrationItemContext<T, IData>;
export function useAdministrationItemContext<ItemType = any, IData = any>() {
    return useContext<IAdministrationItemContext<ItemType, IData>>(AdministrationItemContext);
}
