import React, { ReactChildren, ReactChild } from "react"
import { Divider } from "@material-ui/core"
import './UiSectionTitle.scss'
type IPropsUiSectiontitle = {
    children: ReactChildren | ReactChild
}
export const UiSectionTitle  = (props: IPropsUiSectiontitle) => {
    return(
        <div className="ui-section-title">
            <div className="ui-section-title__title">
                {props.children}
            </div>
            <Divider />
        </div>
    )
}