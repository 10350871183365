import { Field } from "formik";
import React from "react";
import * as Yup from 'yup';
import { UiAmountField, UiAutocompleteField, UiSwitchField } from "../../../shared/components/fields";
import { IUiMultiselectOption } from "../../../shared/components/fields/UiAutocompleteField";
import { Errors } from "../../../shared/constants";
import { UserGroup } from "../../../shared/models/enums";
import { NotificationDto } from "../../../shared/models/interfaces";
import { useAdministrationContext } from "../../AdministrationContext";
import { IAddOrEditForm } from "../../types";
import { AddOrEditForm } from "../CommonMgmt/AddOrEditForm";
import { relationItemsToOption, relationItemToOption } from "../CommonMgmt/MgmtHelpers";

export type NotificationFormValue = {
    template:  IUiMultiselectOption | null
    nextAction: IUiMultiselectOption | null,
    roles: IUiMultiselectOption[]
    threshold?: number | null,
    active?: boolean,
    thresholdRecipients: IUiMultiselectOption[],
    riskLevels?: IUiMultiselectOption | null,
    isShownDeleteButton?: boolean
}

const validationSchema = Yup.object().shape<NotificationFormValue>({
    template: Yup.object<IUiMultiselectOption>().nullable(true).required(Errors.mandatory),
    nextAction: Yup.object<IUiMultiselectOption>().nullable().required(Errors.mandatory),
    roles: Yup.array<IUiMultiselectOption>().required(Errors.mandatory),
    thresholdRecipients: Yup.array<IUiMultiselectOption>(),
    threshold: Yup.number()/*.required(Errors.mandatory)*/.min(0, "The threshold must be positive").when('riskLevels', {
        is: (riskLevels) => !riskLevels,
        then: Yup.number().required(Errors.notification),
        otherwise: Yup.number()
    }),
    active: Yup.boolean(),
    riskLevels: Yup.object<IUiMultiselectOption>().nullable(true).when('threshold', {
        is: (threshold) => !threshold,
        then: Yup.object().required(Errors.notification),
        otherwise: Yup.object<IUiMultiselectOption>()
    })
}, [['threshold', 'riskLevels']]);

const currentValueFromItem = (item: NotificationDto, riskLevelsOptions: IUiMultiselectOption[]): NotificationFormValue => {
    return {
        template: relationItemToOption(item.template),
        roles: relationItemsToOption(item.recipients),
        thresholdRecipients: relationItemsToOption(item.thresholdRecipients),
        nextAction: { label: item.nextAction.itemContent, value: item.nextAction.id},
        active: item.active,
        threshold: item.threshold,
        riskLevels: riskLevelsOptions.filter((riskLevel) => riskLevel.value === item.idRiskLevel)[0] ?? null,
        isShownDeleteButton: item.isShownDeleteButton
    }
}


export const NotificationForm = (props: IAddOrEditForm<NotificationDto>) => {
    const adminCtx = useAdministrationContext();
    const formInitialValues: NotificationFormValue = props.item ? currentValueFromItem(props.item, adminCtx.riskLevels.options) : {
        template: null,
        roles: [],
        thresholdRecipients: [],
        threshold: undefined,
        nextAction:null,
        active: true,
        riskLevels: null
    };
    let filteredRoles = adminCtx.role.options
        .filter((role: any) => role.label !== null && role.value !== UserGroup.director);
    return (
        <AddOrEditForm initialValues={formInitialValues} validationSchema={validationSchema} >
            <Field id="notification-template" component={UiAutocompleteField} name="template" label="Template*" options={adminCtx.template.options} />
            <Field id="notification-next-action" component={UiAutocompleteField} name="nextAction" label="Notification trigger action*" options={adminCtx.task.options} />
            <Field id="notification-roles" component={UiAutocompleteField} name="roles" label="Alert recipient*" options={filteredRoles} multiple />
            <Field id="notification-threshold" component={UiAmountField} name="threshold" label="Threshold*" />
            <Field id="notification-riskLevels" component={UiAutocompleteField} name="riskLevels" label="Risk Level*" options={adminCtx.riskLevels.options}/>
            <Field id="notification-active" component={UiSwitchField} name="active" label="Active" />
        </AddOrEditForm>
    );
};