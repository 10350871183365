import {UserRoles} from "../models/enums";

export const roleAllowedToSeeTable = [
    UserRoles.hqsecurity,
    UserRoles.localsec,
    UserRoles.areasec,
    UserRoles.maisonsec,
    UserRoles.director,
]

export const roleAllowedToAutoApprovation = [
    UserRoles.hqsecurity,
    UserRoles.areasec,
    UserRoles.maisonsec,
    UserRoles.director,
]

export const rolesAllowedToPreviewPdf = [
    UserRoles.localsec,
    UserRoles.underwriter,
    UserRoles.broker,
    UserRoles.riskassessor1,
    UserRoles.riskassessor2,
    UserRoles.riskassessor3,
    UserRoles.riskassessor4,
    UserRoles.riskassessor5,
    UserRoles.hqsecurity,
    UserRoles.maisonsec,
    UserRoles.director,
];

export const rolesAdmin = [
    UserRoles.hqsecurity,
    UserRoles.director,
];

export const roleNotAllowedToSeeCHeader = [
    UserRoles.riskassessor1,
    UserRoles.riskassessor2,
    UserRoles.riskassessor3,
    UserRoles.riskassessor4,
    UserRoles.riskassessor5,
    UserRoles.broker,
    UserRoles.underwriter,
]

export const insuranceRoles = [
    UserRoles.riskassessor1,
    UserRoles.riskassessor2,
    UserRoles.riskassessor3,
    UserRoles.riskassessor4,
    UserRoles.riskassessor5,
    UserRoles.broker,
    UserRoles.underwriter,
    UserRoles.centralinsurance
]