import React, { Component } from 'react'
import Style from './ProgressBar.module.css'

class ProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div className={Style.ProgressBar}>
        <div
          className={Style.Progress}
          style={{ width: this.props.progress + '%' }}
        />
      </div>
    )
  }
}

export default ProgressBar