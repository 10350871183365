import React, { useEffect } from "react"
import {getComparator} from './UiTableHelper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableSortLabel } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {UiTableRowSkeleton} from './UiTableRowSkeleton';
import {IPropsUiTable, IStateOrderBy, IStateOrder} from './types'
import {UiTableCell} from './UiTableCell'
import {UiPagination} from './UiTablePagination'
import { PAGE_NUMBER_KEY } from "../../../constants";

export const UiTable = (props: IPropsUiTable) => {
    const [order, setOrder] = React.useState<IStateOrder>();
    const [orderBy, setOrderBy] = React.useState<IStateOrderBy>();
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(props.rowPerPages);
    const [page, setPage] = React.useState<number>(1);

    const {items, feedLists, tableHeads, onRowSelect} = props;
    
    useEffect(() => {
        setRowsPerPage(props.rowPerPages);
        if (sessionStorage.getItem(PAGE_NUMBER_KEY) !== null) {
            setPage(parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!))
        } else {
            setPage(1)
        }
    }, [props.rowPerPages] );

    const getItems = () => {
        let orderedItems = items
        if(orderBy !== undefined && orderBy !== null){
            const head = tableHeads[orderBy]
            if(head.sortable && (head.accessor || head.customSort)){
                orderedItems = orderedItems.sort((a:any,b:any) =>{
                    if(head.customSort){
                        return head.customSort(a,b,order);
                    }
                    return getComparator(head.accessor,  a, b, order!=='desc');
                })
            }
        }
        const currentPage = page - 1;
        return orderedItems.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
    }

    const currentItems = getItems();

    const handleChangePage = (event: unknown, newPage: number) => {
        sessionStorage.setItem(PAGE_NUMBER_KEY, newPage.toString());
        setPage(newPage);
    };


    const toggleOrder = () => {
        const newOrder = order === 'asc' ? 'desc' : 'asc';
        setOrder(newOrder);

        return newOrder;
    }

    const sort = (headIndex: number) => {
        let newOrder = 'asc';
        if (headIndex === orderBy) {
            setOrderBy(headIndex)
            newOrder = toggleOrder();
        } else {
            setOrder('asc')
            setOrderBy(headIndex)
        }

        if (props.sortColumnHandler) {
            props.sortColumnHandler({headIndex: headIndex, order: newOrder});
        }
    }

    const createSortHandler = (headIndex:number) => (event: React.MouseEvent<unknown>) => {
        sort(headIndex);
    }

    useEffect(() => {
        if(props.sorted && props.sortColumn !== undefined) {
            sort(props.sortColumn)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    useEffect(() => {
        if (props.sort) {
            setOrder(props.sort.order);
            setOrderBy(props.sort.headIndex);
        }

    }, [props.sort]);

    /**
     * Watch props change
     */
    // When items changes
    useEffect(() => {
        if (sessionStorage.getItem(PAGE_NUMBER_KEY) !== null) {
            setPage(parseInt(sessionStorage.getItem(PAGE_NUMBER_KEY)!))
        } else {
            setPage(1)
        }
    }, [props.items, props.keepPagination]);

    const loadingClass = props.loading ? 'ui-table--loading' : 'ui-table--loaded';
    return(
        <>
        <Table size="small" className={`ui-table fixed ${props.className} ${loadingClass}`}>
            <TableHead>
                <TableRow>
                    {tableHeads.map((head, index)=>(
                        <TableCell
                            style={head.cssProperties}
                            key={'headDashboard-'+index}
                            sortDirection={orderBy === index ? order : false}
                            className={'ui-tableheadcell ui-tableheadcell--'+head.name}
                        >
                            {head.sortable ?
                                <TableSortLabel
                                    onClick={createSortHandler(index)}
                                    active={orderBy === index}
                                    direction={orderBy === index ? order : 'asc'}
                                    IconComponent={ArrowDropDownIcon}
                                >
                                    {head.label}
                                </TableSortLabel>
                                :
                                <>{head.label}</>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {!props.loading ?
                    currentItems.length ? 
                        currentItems.map((item:any, i:number) => 
                            <TableRow className={`ui-tablerow ${props.rowClasses ? props.rowClasses(item) : ""}`} key={'tablerow-'+i} onClick={() => onRowSelect ? onRowSelect(item) : null}>
                                {tableHeads.map((head, j:number) => <UiTableCell name="" key={'tablecell'+i+'-'+j} index={i} {...item} {...feedLists} onChange={props.onItemChange} head={head}></UiTableCell> )}
                            </TableRow>
                        )
                    :
                    <TableRow>
                        <TableCell colSpan={tableHeads.length} align="center">No item found</TableCell>
                    </TableRow>

                : <UiTableRowSkeleton nbRow={rowsPerPage} nbCell={tableHeads.length} />}

            </TableBody>
        </Table>
        <div className="ui-table__footer">
           <div>Found {items.length} events<div></div></div>
            <div>
                <div className="ui-table__action">
                {props.action}
                </div>
            </div>
            <div>
                <div>
                    {Math.ceil(items.length / props.rowPerPages) > 1 && (
                        <UiPagination
                            page={page}
                            onChange={handleChangePage}
                            count={!props.loading ? Math.ceil(items.length / props.rowPerPages) : 1}
                        />
                    )}
                </div>
            </div>

         </div>

    </>
    )
}