import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { UiSwitchField, UiTextField } from '../../../shared/components/fields';
import { Errors } from '../../../shared/constants';
import { AddOrEdit } from '../../types.d';
import { AddOrEditForm } from '../CommonMgmt/AddOrEditForm';

interface AddOrEditAttributeProps extends AddOrEdit { }

export type AttributeFormValue = {
    name: string,
    code: string,
    isActive: boolean;
}
const itemInit: AttributeFormValue = {
    name: '',
    code: '',
    isActive: false,
}

const validationSchema = Yup.object().shape<AttributeFormValue>({
    name: Yup.string().required(Errors.mandatory),
    code: Yup.string().required(Errors.mandatory),
    isActive: Yup.boolean()
})

export const AddOrEditAttribute = (props: AddOrEditAttributeProps) => {
    const [initialValues, setInitialValues] = useState(itemInit);

    useEffect(() => {
        if(props.item?.name) {
            setInitialValues({
                isActive: props.item.isActive ?? false,
                name: props.item.name ?? '',
                code: props.item.code ?? ''
            });
        }
    },[props.item?.name]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <AddOrEditForm initialValues={initialValues} validationSchema={validationSchema} modalSize='sm'>
            <Field id="attribute-name" name='name' label='Name*' component={UiTextField} />
            <Field id="attribute-code" name='code' label='Code*' component={UiTextField} />
            <Field id="attribute-active" name='isActive' label='Active' component={UiSwitchField} />
        </AddOrEditForm>
    )
}