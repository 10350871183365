import React, { Fragment } from 'react';
//import { withFormik, FormikProps, FormikErrors, Form, Formik, Field, ErrorMessage } from 'formik';
import { Field, ErrorMessage } from 'formik';

// import { SchemaPreRequisites, InitPreRequisites } from './PreRequisitesSchema';
import { SchemaPreRequisites } from './PreRequisitesSchema';

  // Use <MyForm /> wherevs
  const PreRequisites = (props: {title: string, formProp: Array<any>}) => (
    <div>
      <h1>{props.title}</h1>
      <InnerForm formProp={props.formProp} />
    </div>
  );


  const InnerForm = (props: {formProp: Array<any>}) => {
    let touched = props.formProp[0];
    let errors = props.formProp[1]

    return (
      <Fragment>
        <div>
          <label>Lead Brand Name</label>
          <Field type="number" name="leadBrandName" placeholder="text" validation={SchemaPreRequisites.fields.leadBrandName} autoFocus onFocus={(e: any) => {e.currentTarget.select()}} />
          {touched.leadBrandName && errors.leadBrandName && <p>{errors.leadBrandName}</p>}
        </div>
        <div>
          <label>Country</label>
          <Field as="select" name="country" validation={SchemaPreRequisites.fields.country}>
              <option value="c1">c1</option>
              <option value="c2">c2</option>
              <option value="c3">c3</option>
          </Field>
          <ErrorMessage name="country" />
        </div>
        <div>
          <label>Multi Brand Event</label>
          <Field type="checkbox" name="multiBrandEvent" validation={SchemaPreRequisites.fields.multiBrandEvent} />
          <ErrorMessage name="multiBrandEvent" />
        </div>
        {/* <div>
          <label>Brands Concerned</label>
          <Field type="text" name="brandsConcerned" placeholder="text" validation={SchemaPreRequisites.fields.brandsConcerned} />
          {touched.brandsConcerned && errors.brandsConcerned && <p>{errors.brandsConcerned}</p>}
        </div> */}
      </Fragment>
    )
  };

export default PreRequisites;