import { Check, Close } from '@material-ui/icons';
import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { IUiMultiselectOption } from '../../../shared/components/fields/UiAutocompleteField';
import {NotificationDto, NotificationDtoBack} from '../../../shared/models/interfaces';
import { AdministrationService } from '../../../shared/services/administration.service';
import { useAdministrationContext } from '../../AdministrationContext';
import { AdministrationFiltersProps } from '../CommonMgmt/AdministrationFilters';
import { AdministrationTable, IAdministrationTableHead } from '../CommonMgmt/AdministrationTable';
import { NotificationForm, NotificationFormValue } from './NotificationForm';
import {UserGroup} from "../../../shared/models/enums";

const riskLevelsEnum = [{label: 'Risk Level 1', value: 2}, {label: 'Risk Level 2', value: 3}, {label: 'Risk Level 3', value: 4}];
const tableHeads: IAdministrationTableHead<NotificationDto>[] = [
    { name: "template", label: "Template", sortable: true, searchable: true, accessor: "template.name" },
    { name: "nextAction", label: "Next actions", sortable: true, accessor: "nextAction.itemContent", searchable: true },
    {
        name: "role",
        label: "Threshold alert recipients",
        sortable: true,
        accessor: (i) => i.recipients.map((r: any) => r.name).join(","),
        searchable: true,
    },
    {
        name: "threshold",
        label: "Threshold",
        sortable: true,
        accessor: "threshold",
        cell: (props) => (
            <NumberFormat value={props.threshold} displayType={"text"} thousandSeparator={true} prefix={"€"} />
        ),
        searchable: true,
    },
    {
        name: "riskLevels",
        label: "Risk level",
        sortable: true,
        accessor: "idRiskLevel",
        cell: (props) => (
            <p>{riskLevelsEnum.filter(riskLevel => (riskLevel.value == props.idRiskLevel))[0]?.label ?? <></>}</p>
        ),
        searchable: true,
    },
    {
        name: "active",
        label: "Active",
        sortable: true,
        accessor: "active",
        cell: (props) => <>{props.active ? <Check /> : <Close /> }</>,
        searchable: false,
    },
];



export type NotificationTableProps = {};
export const NotificationTable = (props: NotificationTableProps) => {
    const adminCtx = useAdministrationContext();

    const handleCreate = async (data: any) => {
        AdministrationService.addNotification(data).subscribe(()=>{
            adminCtx.notification.load()
        })
    };
    const handleDelete = async (data: NotificationDto) => {
        AdministrationService.deleteNotification(data).subscribe(()=>{
            adminCtx.notification.load()
        })
    };

    const updateDeletedStatus = (dataFromForm: NotificationFormValue, baseNotification: NotificationDto): void => {
        baseNotification.recipients.map(item => {
            const foundItem = dataFromForm.roles?.find(x => x.value === item.id);
            item.isDeleted = !foundItem;
        });

        baseNotification.thresholdRecipients.map(item => {
            const foundItem = dataFromForm.thresholdRecipients?.find(x => x.value === item.id);
            item.isDeleted = !foundItem;
        });
    }

    const createUpdateRequest = (dataFromForm: NotificationFormValue, baseNotification: NotificationDto): NotificationDtoBack => {

        updateDeletedStatus(dataFromForm, baseNotification);

        dataFromForm.roles?.filter(data => {
            const foundItem = baseNotification.recipients.find(x => x.id === data.value);
            if (!foundItem) {
                baseNotification.recipients.push({
                    id: data.value,
                    name: data.label,
                    idReminderRoleGroupUser: data.value,
                    isDeleted: false
                });
            }
        });

        dataFromForm.thresholdRecipients?.filter(data => {
            const foundItem = baseNotification.thresholdRecipients.find(x => x.id === data.value);
            if (!foundItem) {
                baseNotification.thresholdRecipients.push({
                    id: data.value,
                    name: data.label,
                    idReminderRoleGroupUser: data.value,
                    isDeleted: false
                });
            }
        });

        return {
            id: baseNotification.id,
            templateId: dataFromForm.template?.value || 0,
            nextActionId: dataFromForm.nextAction?.value,
            notificationRoleRecipients: baseNotification.recipients.map((recipient) => ({
                id: recipient.id,
                idNotification: baseNotification.id,
                idNotificationRoleGroupUser: recipient.id,
                isDeleted: recipient.isDeleted ?? false
            })),
            thresholdAlertRecipients: baseNotification.thresholdRecipients.map((recipient) => ({
                id: recipient.id,
                idNotification: baseNotification.id,
                idThresholdRoleGroupUser: recipient.id,
                isDeleted: recipient.isDeleted ?? false
            })),
            threshold: dataFromForm.threshold || 0,
            isDeleted: false,
            isActive: dataFromForm.active ?? true,
            idRiskLevel: dataFromForm.riskLevels?.value
        }
    }

    const handleUpdate = async (dataFromForm: NotificationFormValue, baseNotification: NotificationDto) => {

        const request = createUpdateRequest(dataFromForm, baseNotification);

        AdministrationService.updateNotification(request).subscribe(()=>{
            adminCtx.notification.load()
        })
    };


    const filters: AdministrationFiltersProps<NotificationDto> | undefined = useMemo(() => {
        if(!adminCtx.role.options.length) return undefined;
        return {
            items: [
                {
                    name: "template",
                    label: "Template names",
                    type: "multiselect",
                    filter: (item, filterValue) => !filterValue || !filterValue.length || filterValue.some((filterTemplate: IUiMultiselectOption) => filterTemplate.value === item.template.id),
                    options: adminCtx.template.options,
                },
                {
                    name: "nextAction",
                    label: "Notification trigger actions",
                    type: "multiselect",
                    filter: (item, filterValue) => {
                       return !filterValue || !filterValue.length || filterValue.some((filterAction: IUiMultiselectOption) => filterAction.value === item.nextAction.id)
                    },
                    options: adminCtx.task.options
                },
                {
                    name: "roles",
                    label: "Threshhold alert recipients",
                    type: "multiselect",
                    filter: (item, filterValue) => item.recipients.some(role=>!filterValue.length || filterValue.some((filterRole:IUiMultiselectOption)=>filterRole.value===role.id)),
                    options: adminCtx.role.options.filter((role: any) => role.label !== null && role.value !== UserGroup.director),
                },
                {
                    name: "active",
                    label: "Active",
                    type: "select",
                    filter: (item, filterValue) =>{
                        return !filterValue || item.active === filterValue.value
                    },
                    options: [
                        {label: "Yes", value: true},
                        {label: "No", value: false},
                    ],
                },
            ],
        };
    }, [adminCtx.role.options, adminCtx.task.options, adminCtx.template.options]);


    return (
        <AdministrationTable
            id="notification-table"
            filters={filters}
            title="notification"
            loading={adminCtx.notification.isLoading}
            items={adminCtx.notification.items}
            itemLabelProp="template.name"
            form={NotificationForm}
            tableHeads={tableHeads}
            onCreate={handleCreate}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
        />
    );
};
