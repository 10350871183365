import { map } from 'rxjs/operators';

import {
    urlPartialGetTasks,
    urlPartialChangeEventStatus,
    urlPartialGetEventStatus
} from '../config/GlobalAppConfig';
import { Observable } from 'rxjs';
import { CommonProperties } from '../models/interfaces';
import { NextActionBehavior } from '../../declaration/declarationNewEdit/DeclarationNewEdit';
import { Reference } from './referencesService'
import { AxiosRequestConfig } from 'axios';
import CustomAxios, { ApiCallingMethods } from '../helpers/AxiosHelper';

export interface EventFlow extends CommonProperties{
    idGroupUsers: number;
	idFlow: number;
	idTask: number;
	taskLabel: string;
	idNextTask?: number;
	displayOrder: number;
    isVisible: boolean;
}

interface EventFlowToSend {
    idEvent: number;
    idTask: number;
    nextIdFlow?: number; 
    message: string;
    companyName?: string;
}

export class FlowRefService {

    static getAllTasks(): Observable<Reference[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetTasks,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static getFlowsByIdGroupUser(idGroupUser: string, idEvent: number): Observable<EventFlow[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetTasks + '/' + idGroupUser + '/' + idEvent,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static changeEventStatus(idEvent: number, nextActionBehavior: NextActionBehavior): Observable<EventFlow[]> {
        const objectToSend: EventFlowToSend = {
            'idEvent': idEvent,
            'idTask': nextActionBehavior.idEventFlow,
            'nextIdFlow': nextActionBehavior.nextAction,
            'message': nextActionBehavior.comment,
            'companyName' : nextActionBehavior.companyName,
        }
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.put,
            url: urlPartialChangeEventStatus,
            data: objectToSend,
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }

    static getAllEventStatus() : Observable<Reference[]> {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.get,
            url: urlPartialGetEventStatus,
            data: null
        };
        return CustomAxios.request(options).pipe(map(res => res.data.model));
    }
}