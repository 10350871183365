import { Field } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Subscription } from 'rxjs';
import * as Yup from 'yup';
import LoadingContext from '../../../routes/LoadingContext';
import UiAutocompleteField, { IUiMultiselectOption } from '../../../shared/components/fields/UiAutocompleteField';
import { minLettersToTriggerSearch, onTypeSearchWaitTime } from '../../../shared/config/GlobalAppConfig';
import { Errors } from '../../../shared/constants';
import { KeyValuePair } from '../../../shared/models/interfaces';
import { Reference, ReferencesService } from '../../../shared/services/referencesService';
import { useAdministrationContext } from '../../AdministrationContext';
import { AddOrEdit } from '../../types';
import { AddOrEditForm } from '../CommonMgmt/AddOrEditForm';
import { FormObserver } from '../CommonMgmt/FormObserver';
import {UiSwitchField} from "../../../shared/components/fields";

export interface User {
    username: IUiMultiselectOption | null,
    countries: any[],
    maisons: any[],
    name: string,
    id : number,
    role: string,
    email: string,
    isActive: boolean;
}

interface AddOrEditUserProps extends AddOrEdit<User> {
    countryList?: Reference[],
    maisonList?: Reference[]
}

const userInit: User = {
    username: null,
    countries: [],
    maisons: [],
    name : '',
    id : 0,
    role: '',
    email : '',
    isActive: false
}

const validationSchema = Yup.object().shape<User>({
    username: Yup.object<IUiMultiselectOption>().nullable(true).required(Errors.mandatory),
    countries: Yup.array(),
    maisons: Yup.array(),
    name: Yup.string(),
    id : Yup.number(),
    role: Yup.string(),
    email: Yup.string(),
    isActive: Yup.boolean()
});

export const AddOrEditUser = (props: AddOrEditUserProps) => {
    const [ formInitialValues, setFormInitialValues ] = useState(userInit);
    const {country, maison} = useAdministrationContext();
    const countryList =  useMemo(()=>country.items.map(item=>({label: item.name, value: item.code})), [country.items]) 
    const maisonList =  useMemo(()=>maison.items.map(item=>({label: item.name, value: item.code})), [maison.items]) 
    const [userRole, setUserRole] = useState<string>();
    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [userAdList, setUserAdList] = useState<IUiMultiselectOption[]>([]);
    const [inputCountrySearch, setInputCountrySearch] = useState<string>('');
    const [inputMaisonSearch, setInputMaisonSearch] = useState<string>('');
    const [roleSubscription, setRoleSubscription] = useState<Subscription>();
    const [listUserSubscription, setListUserSubscription] = useState<Subscription>();
    const [hide, setHide] = useState(true)
    const ctx = useContext(LoadingContext);

    const getUserRole = (userId: string) => {
        const subscription = ReferencesService.getListUserRole(userId).subscribe((role: string) => {
            setUserRole(role);
        }, () => {
            setUserRole('Not found');
        });
        setRoleSubscription(subscription);
    }

    const handleUserSearchChange = (value: string) => {
        if(timer) clearTimeout(timer);
        const tempTimer = setTimeout(() => {
            if(value.length >= minLettersToTriggerSearch) {
                ctx.startLoading();
                const subscription = ReferencesService.getListUsersWithIds(value).subscribe((list: KeyValuePair[]) => {
                    if(list) {
                        const options = list.map(pair => ({ value: pair.key, label: pair.value}));
                        setUserAdList(options);
                    }
                    ctx.stopLoading();
                });
                setListUserSubscription(subscription);
            }
        }, onTypeSearchWaitTime);
        setTimer(tempTimer);
    }

    const handleOnChange = (values: User) => {

        if(!props.item && values.username && values.username.label.length) {
            getUserRole(values.username.value);
            setHide(false);
        } else if(props.item){
            setHide(false);
        }
        else {
            setHide(true);
        }
    }

    useEffect(() => {
        if(props.item) {
            const options:IUiMultiselectOption[] = [{value: props.item.email as string, label: props.item.name ?? props.item.email as string}];

            setUserAdList(options);
            setUserRole(props.item.role);

            const maisonsOfUser = props.item?.maisons
                .filter(m => m.code && m.name)
                .map((_m) => maisonList?.filter((ml: KeyValuePair) => _m.code === ml.value)[0]) || [];

            const countriesOfUser = props.item?.countries
                .filter(c => c.code && c.name)
                .map((_c) => countryList?.filter((cl: KeyValuePair) => _c.code === cl.value)[0]) || [];

            const editUser: any = {
                            name: options[0].label,
                            username: options[0],
                            maisons: maisonsOfUser,
                            countries: countriesOfUser,
                            role: props.item.role ?? '',
                            isActive: props.item.isActive
                        }
            setFormInitialValues(editUser);
        }
    },[props.item?.username]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            roleSubscription?.unsubscribe();
            listUserSubscription?.unsubscribe();
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AddOrEditForm disableSubmit={!props.item && (!userRole || userRole === 'Not found')} initialValues={formInitialValues} validationSchema={validationSchema}>
            <Field
                id='username'
                name='username'
                label='Username*'
                multiple={false}
                onInputChange={handleUserSearchChange}
                disabled={!!props.item}
                options={userAdList}
                component={UiAutocompleteField}
            />
            {userRole &&
                <div id="rolename" className='role-section'>
                    Role: {userRole}
                </div>
            }
            {props.item &&
                <Field id="isActive" name='isActive' label='Active' component={UiSwitchField} />
            }

            {
                ((props.item || !props.item) && !hide) && !(!userRole || userRole === 'Not found') && (
                    <>
                        <Field
                            id='countries'
                            name='countries'
                            label='Countries'
                            multiple={true}
                            inputValue={inputCountrySearch}
                            onInputChange={setInputCountrySearch}
                            options={countryList}
                            component={UiAutocompleteField}
                        />
                        <Field
                            id='maisons'
                            name='maisons'
                            label='Maisons'
                            multiple={true}
                            inputValue={inputMaisonSearch}
                            onInputChange={setInputMaisonSearch}
                            options={maisonList}
                            component={UiAutocompleteField}
                        />
                    </>
                )
            }
            <FormObserver onChange={handleOnChange}/>
        </AddOrEditForm>
    )
}