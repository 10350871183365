import { Formik, FormikProps } from "formik";
import React, { FC } from "react";
import { ModalSize } from "../../../shared/components/ui/UiModal/UiModal";
import { IFieldListItem } from "../../../shared/models/interfaces";
import { ActionBtnItem, AddOrEditModal } from "./AddOrEditModal";
import { useAdministrationItemContext } from "./AdministrationItemContext";

export type AddOrEditFormProps<T=object> = {
    initialValues: any;
    validationSchema?: any;
    onChange?(values: T): void;
    actionBtns?: ActionBtnItem[],
    modalSize?: ModalSize,
    disableSubmit?: boolean
};
export const AddOrEditForm: FC<AddOrEditFormProps> = ({actionBtns=[], ...props}) => {
    const ctx = useAdministrationItemContext();
    const formInitialValues: IFieldListItem = props.initialValues;

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={ctx.onConfirm}
            validationSchema={props.validationSchema}
            validateOnChange={false}
            validateOnBlur={true}
            touched
        >
            {(formikProps: FormikProps<IFieldListItem>) => {
                return (
                    <AddOrEditModal
                        title={ctx.title}
                        itemLabelProp={ctx.itemLabelProp}
                        loading={ctx.loading}
                        open={ctx.open}
                        item={ctx.item}
                        onConfirm={formikProps.handleSubmit}
                        onValidateForm={formikProps.validateForm}
                        onCancel={ctx.onCancel}
                        onDelete={ctx.onDelete}
                        actionsBtns={actionBtns}
                        size={props.modalSize}
                        disableSubmit={props.disableSubmit}
                    >
                        <form onSubmit={formikProps.handleSubmit}>{props.children}</form>
                    </AddOrEditModal>
                );
            }}
        </Formik>
    );
};
