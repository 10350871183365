import React, { useCallback, useMemo } from "react";
import { getFieldData } from "./UiFieldHelpers";
import UiTextField, { IPropsUiTextField } from "./UiTextField";
export type IPropsUiNumberField = Omit<IPropsUiTextField, "type"> & {
    min?: number;
    max?: number;
    integer?: boolean;
};
export const UiNumberField = ({ min, max, integer, ...props }: IPropsUiNumberField) => {
    const { onChange } = useMemo(
        () => getFieldData({ field: props.field, form: props.form, onChange: props.onChange }),
        [props.field, props.form, props.onChange]
    );
    const handleChange = useCallback(
        (e) => {
            if (onChange) {
                if (!e.target.value) {
                    onChange(e);
                    return;
                }
                let value = Number(e.target.value);
                if (integer) {
                    value = Math.trunc(value);
                }
                if (min != null && value < min) {
                    value = min;
                } else if (max && value > max) {
                    value = max;
                }
                e.target.value = `${value}`;
                onChange(e);
            }
        },
        [onChange, integer, min, max]
    );
    return <UiTextField type="number" {...props} onChange={handleChange} />;
};
export default UiNumberField;
