import {Button, CircularProgress, Tooltip} from "@material-ui/core";
import {Check, Close, Comment} from "@material-ui/icons";
import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Subscription} from "rxjs";
import {EventStatus} from "../../../dashboard/interface";
import {UiTextareaField} from "../../../shared/components/fields";
import {UiBoldNoShift} from "../../../shared/components/ui/UiBoldNoShift";
import {UiLoadingRows} from "../../../shared/components/ui/UiLoadingTable/UiLoadingRows";
import {UiModal} from "../../../shared/components/ui/UiModal/UiModal";
import {useAuthContext} from "../../../shared/contexts/AuthContext";
import {baseCreateNotification} from "../../../shared/helpers/HelpersFunc";
import {useEventContext} from "../../../shared/hooks/eventHook";
import {NotificationType, UserRoles} from "../../../shared/models/enums";
import {ISecurityConcept, SecurityConceptStatus} from "../../../shared/models/interfaces";
import {SecurityConceptService} from "../../../shared/services/mock.securityConcept.service";

export type SecurityConceptModalProps = {
    onClose(): void;
    onUpdate(): void;
    reloadFiles: () => void;
    items: ISecurityConcept[];
    loading?: boolean;
    eventCreatedBy: string;
    onReject: () => void;
};
export const SecurityConceptModal: FC<SecurityConceptModalProps> = ({ onClose, onUpdate, items, loading = false, reloadFiles,eventCreatedBy, onReject }) => {
    const eventContext = useEventContext();
    const authContext = useAuthContext();
    const [comments, setComments] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const validateSubscription = useRef<Subscription>();
    const rejectSubscription = useRef<Subscription>();
    const downloadSubscription = useRef<Subscription>();
    const currentSubscription = useRef<Subscription>();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [currentLoading, setCurrentLoading] = useState(false);
    const [validateLoading, setValidateLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const conceptsSubscription = useRef<Subscription>();

    const open = !!items && !!items.length;

    const securityConcepts = useMemo(() => {
        return items.sort((a, b) => a.version - b.version);
    }, [items]);
    const masterItem = useMemo(() => {
        const masters = securityConcepts.find((i) => i.master);
        return masters ? masters : securityConcepts[securityConcepts.length - 1];
    }, [securityConcepts]);

    const canRegionApprove =
        authContext.role === UserRoles.areasec
        && (eventContext.status === EventStatus.pendingregion || eventContext.status === EventStatus.draft)
        && masterItem && masterItem.status === SecurityConceptStatus.Draft;
    const canHqApprove =
        (authContext.role === UserRoles.hqsecurity || authContext.role === UserRoles.director)
        && (eventContext.status === EventStatus.pendinghq || eventContext.status === EventStatus.draft)
        && masterItem && masterItem.status !== SecurityConceptStatus.Reviewed;
    const canMaisonSecurityManagerApprove  = 
        authContext.role === UserRoles.maisonsec && 
        eventContext.status === EventStatus.pendingMaison && 
        eventCreatedBy && eventCreatedBy === authContext?.username && 
        masterItem && masterItem.status === SecurityConceptStatus.Draft;
    useEffect(() => {
        return () => {
            [validateSubscription, rejectSubscription, downloadSubscription, currentSubscription, conceptsSubscription].forEach((subscription) => {
                if (subscription.current) {
                    subscription.current.unsubscribe();
                }
            });
        };
    }, []);
    useEffect(() => {
        setComments("");
        setErrorMessage("");
    }, [open]);

    const handleCommentsChange = useCallback((e: any) => {
        setComments(e.target.value);
        setErrorMessage("");
    }, []);

    const validate = useCallback(() => {
        setValidateLoading(true);
        validateSubscription.current = SecurityConceptService.validate(masterItem.id, comments).subscribe(
            () => {
                setValidateLoading(false);
                onClose();
                onUpdate();
                reloadFiles();
            },
            () => {
                setValidateLoading(false);
            }
        );
    }, [comments, masterItem, onClose, onUpdate]);

    const accept = useCallback(() => {
        setValidateLoading(true);
        validateSubscription.current = SecurityConceptService.accept(masterItem.id, comments).subscribe(
            () => {
                setValidateLoading(false);
                onClose();
                onUpdate();
                reloadFiles();
            },
            () => {
                setValidateLoading(false);
            }
        );
    }, [comments, masterItem, onClose, onUpdate]);

    const reject = useCallback(() => {
        if (!comments.length) {
            setErrorMessage("Comments is mandatory to reject");
            return;
        }
        setRejectLoading(true);
        rejectSubscription.current = SecurityConceptService.reject(masterItem.id, comments, masterItem.idEvent).subscribe(
            () => {
                setRejectLoading(false);
                onClose();
                onUpdate();
                onReject();
            },
            () => {
                setRejectLoading(false);
            }
        );
    }, [comments, masterItem, onClose, onUpdate]);

    const setAsCurrent = useCallback(
        (item: ISecurityConcept) => {
            setCurrentLoading(true);
            currentSubscription.current = SecurityConceptService.setAsCurrent(item).subscribe(
                () => {
                    setCurrentLoading(false);
                    onUpdate();
                },
                (res) => {
                    setCurrentLoading(false);
                    baseCreateNotification(NotificationType.error, "ERROR", (res && res.data && res.data.message) || "Something went wrong, please contact technical support");
                }
            );
        },
        [onUpdate]
    );

    const download = useCallback((item: ISecurityConcept) => {
        setDownloadLoading(true);
        downloadSubscription.current = SecurityConceptService.download(item).subscribe(
            () => {
                setDownloadLoading(false);
            },
            (response) => {
                const handle = async (response: any) => {
                    const isJsonBlob = (data: any) => data instanceof Blob && data.type === "application/json";
                    const responseData = isJsonBlob(response?.data) ? await response?.data?.text() : response?.data || {};
                    const responseJson = typeof responseData === "string" ? JSON.parse(responseData) : responseData;
                    baseCreateNotification(NotificationType.error, "ERROR", (responseJson && responseJson.message) || "Something went wrong during download");
                };
                handle(response);

                setDownloadLoading(false);
            }
        );
    }, []);

    const footerVisible = () => {
        return (
            (canRegionApprove || canHqApprove || canMaisonSecurityManagerApprove)
            && (!masterItem.reviewer?.id || !masterItem.validator?.id)
        );
    }

    const review = useCallback( () => {
        setValidateLoading(true);
        validateSubscription.current = SecurityConceptService.review(masterItem.id, comments).subscribe(
            () => {
                setValidateLoading(false);
                onClose();
                onUpdate();
                reloadFiles();
            },
            () => {
                setValidateLoading(false);
            }
        );
    },[comments, masterItem, onClose, onUpdate])

    const isNeedToBeReviewed = () => {
        return masterItem?.status === SecurityConceptStatus.Validated
            || masterItem?.status === SecurityConceptStatus.Accepted;
    }

    const title = useMemo(() => {
        if (!items.length) {
            return "Security Concept";
        }
        return items[0].maison.maison + " Security Concept";
    }, [items]);
    if (!items.length) return <></>;
    return (
        <UiModal size="lg" open={open} onClose={onClose} title={title}>
            <div className="security-concept-modal">
                <table className="security-concept-table">
                    <thead>
                        <tr>
                            <th>Author</th>
                            <th>Validator</th>
                            <th>Reviewer</th>
                            <th>Version</th>
                            <th className="security-concept-modal__comments"></th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <UiLoadingRows rowCount={3} columnCount={5} />}
                        {!loading &&
                            items.map((item, key) => (
                                <tr key={`security-concept-${key}-${item.id}-${item.version}`}>
                                    <td>
                                        <UiBoldNoShift>
                                            {item.author.name}
                                        </UiBoldNoShift>
                                    </td>
                                    <td>
                                        <UiBoldNoShift>
                                            {item.validator?.name}
                                        </UiBoldNoShift>
                                    </td>
                                    <td>
                                        <UiBoldNoShift>
                                            {item.reviewer?.name}
                                        </UiBoldNoShift>
                                    </td>
                                    <td>
                                        <UiBoldNoShift>
                                            <div className="version">
                                                {item.version}
                                                {item.version === masterItem.version && <Check />}
                                            </div>
                                        </UiBoldNoShift>
                                    </td>
                                    <td>
                                        {item.comments && (
                                            <Tooltip title={item.comments}>
                                                <Comment />
                                            </Tooltip>
                                        )}
                                    </td>
                                    <td className="security-concept-modal__comments">
                                        <Button onClick={() => download(item)} disabled={downloadLoading}>
                                            Download
                                        </Button>
                                        {item.version !== masterItem.version && (
                                            <Button onClick={() => setAsCurrent(item)} disabled={currentLoading}>
                                                Set as current
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>

                {(footerVisible()) && (
                    <>
                        <UiTextareaField id="security-concept-modal-comment-field" label="Comments" value={comments} error={errorMessage} onChange={handleCommentsChange}></UiTextareaField>
                        <div className="ui-modal__footer ui-modal__footer--buttons">
                            <Button
                                onClick={reject}
                                variant="contained"
                                color="secondary"
                                size="large"
                                disabled={rejectLoading}
                                startIcon={rejectLoading ? <CircularProgress size={14} /> : <Close />}
                                id="security-concept-reject"
                            >
                                Reject
                            </Button>
                            <Button
                                onClick={isNeedToBeReviewed() ? review : validate}
                                variant="contained"
                                color="primary"
                                size="large"
                                id="security-concept-accept"
                                disabled={validateLoading}
                                startIcon={validateLoading ? <CircularProgress size={14} /> : <Check />}
                            >
                                {isNeedToBeReviewed() ? "Review" : "Validate"}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </UiModal>
    );
};
