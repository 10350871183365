import { ValuableAssetItem, EventPhaseItem, SecuritySystemDevicesItem } from '../../../declaration/declarationNewEdit/interfaces'
import { ValuableAsset, SecurityDevice } from '../../models/enums';
import { sortArrayObject } from '../../helpers/HelpersFunc';
import { DeclarationInterface } from '../../../declaration/declarationNewEdit/interfaces';
import { DeclarationFromServer, DeclarationEventPhase, DeclarationValuableAsset, DeclarationSecuritySystemDevice, DeclarationAuthors, DeclarationValidators } from './interfaces';
import { Option } from '../../components/SelectField';

export const mappingDeclarationFromServerToLocal = (d: DeclarationFromServer, valuableAssetsList: ValuableAssetItem[], eventPhasesList: EventPhaseItem[], technicalSurveillanceList: SecuritySystemDevicesItem[], users: Option[]):DeclarationInterface => {
    const declaration: DeclarationInterface = {
        brandsName: d.maisonBrands?.itemContent,
        idMaisonBrands: d.idMaisonBrands,
        exhibitionSurface: d.exhibitionsArea ?? '',
        globalConcept: d.globalConceptDeclaration,
        globalDescription: d.eventDesctiption || '',
        recurrentEvent: d.recurrentEvent,
        specificEvents: d.specificEventsShow || '',
        specificShows: d.specificShowAllBrands || '',
        createdBy: d.createdBy,
        createdDate: d.createdDate,
        id: d.id,
        modifiedBy: d.modifiedBy,
        modifiedDate: d.modifiedDate,
        movementsAndStorages: {
            locationPlaceBeforeEvent: d.locationPlaceBeforeEvent || '',
            locationPlaceAfterEvent: d.locationPlaceAfterEvent || '',
            movValOutsideLocationPlace: d.movValOutsideLocationPlace || '',
            movementDescription: d.movementDescription || '',
            movementOfValuables: d.isMovValuableInsideEventVenue,
            tempExternalStorageBeforeEvent: d.tempExternalStorageBeforeEvent,
            tempExternalStorageAfterEvent: d.tempExternalStorageAfterEvent,
            isDedicatedSafeRoom: d.isDedicatedSafeRoom,
            isExternalSecuredStorage: d.isExternalSecuredStorage,
            isNearbyBoutique: d.isNearbyBoutique,
            isPiecesRemainInShowcases: d.isPiecesRemainInShowcases,
            isOther: d.isOther,
            isMovValuableOutsideEventVenue: d.isMovValuableOutsideEventVenue,
            movementValuableDescription: d.movementValuableDescription,
            otherDescription: d.otherDescription
        },
        routingAndTransfer: {
            complyWithDirectives: d.complyDirectives,
            from: d.routingTransferFrom || '',
            routingAndDeparture: d.routingTransferDoneBy || '',
            to: d.routingTransferTo || '',
            transportOrganizer: d.routingTransportOrganizer || '',
            routingAndTransferInformation: d.routingAndTransferInformation || '',
        },
        securityConcept: {
            authors: d.declarationsAuthors?.map(a => {
                const userFound = a;
                userFound.id = a.id;
                userFound.itemContent = a.idAuthor;
                userFound.value = a.idAuthor;
                userFound.createdDate = a.createdDate;
                return Object.assign({}, userFound);
            }),
            validators: d.declarationsValidators?.map(a => {
                const userFound = a;
                userFound.id = a.id;
                userFound.itemContent = a.idValidator;
                userFound.value = a.idValidator;
                userFound.createdDate = a.createdDate;
                return Object.assign({}, userFound);
            }),
        },
        securityStaffDeployment: {
            eventPhases: {
                eventPhasesList: valuableAssetsList.length ?
                    getTreatedEventPhasesList(d.declarationsEventPhase, eventPhasesList) :
                    d.declarationsEventPhase?.map(ep => {
                        ep.eventPhase = ep.eventPhase.itemContent;
                        return ep;
                    }),
                total: d.securityTotalAmount,
                totalGuardingCostEstimated: d.securityTotalAmountGuardingCost
            },
            hasSecurityService: d.securityService,
            security: d.securityDoneBy || '',
            securityServiceDesc: d.securityServiceDescription || '',
            securityStaffDeployment: d.securityStaffDeployment || '',
        },
        valuableAssets: {
            totalEstimated: d.valuableAssetsTotalEstimated || 0,
            valuableAssetsList: valuableAssetsList.length ?
                getTreatedValuableAssetsList(d.declarationsListValuableAssetss, valuableAssetsList) :
                d.declarationsListValuableAssetss?.map(val => {
                    if(val.valuableAsset.id === ValuableAsset.other) {
                        val.type = val.typeDescription;
                    }
                    else {
                        val.type = val.valuableAsset.itemContent;
                    }
                    return val;
                }),
                valuableAssetsInformation: d.valuableAssetsInformation || ''
        },
        technicalSurveillanceSystem: {
            hasSecurityService: d.surveillanceSecurityService,
            securityServiceDesc: d.surveillanceSecurityServiceDescription,
            securitySystemDevicesList: technicalSurveillanceList.length ?
                getTreatedSecuritySystemDevicesList(d.declarationsSecuritySystemDevice, technicalSurveillanceList):
                d.declarationsSecuritySystemDevice?.map(ssd => {
                    if(ssd.securitySystemDevice.id === SecurityDevice.other) {
                        ssd.securitySystemDevice = ssd.typeDescription;
                    }
                    else {
                        ssd.securitySystemDevice = ssd.securitySystemDevice.itemContent;
                    }
                    return ssd;
                }),
                technicalSurveillanceSystemInformation: d.technicalSurveillanceSystem || '',
        }
    }

    return declaration;
}

export const mappingDeclarationFromlocalToServer = (d: DeclarationInterface, eventId: number):DeclarationFromServer => {
    const declaration: DeclarationFromServer = {
        id: d.id,
        idEvent: eventId,
        globalConceptDeclaration: d.globalConcept,
        idMaisonBrands: d.idMaisonBrands,
        eventDesctiption: d.globalDescription,
        recurrentEvent: d.recurrentEvent,
        exhibitionsArea: typeof d.exhibitionSurface === 'number' ? d.exhibitionSurface : null,
        specificEventsShow: d.specificEvents,
        specificShowAllBrands: d.specificShows,
        routingTransferDoneBy: d.routingAndTransfer?.routingAndDeparture,
        routingTransferFrom: d.routingAndTransfer?.from,
        routingTransferTo: d.routingAndTransfer?.to,
        routingTransportOrganizer: d.routingAndTransfer?.transportOrganizer,
        routingAndTransferInformation: d.routingAndTransfer?.routingAndTransferInformation,
        complyDirectives: d.routingAndTransfer?.complyWithDirectives,
        isMovValuableInsideEventVenue: d.movementsAndStorages?.movementOfValuables,
        movementDescription: d.movementsAndStorages?.movementDescription,
        tempExternalStorageBeforeEvent: d.movementsAndStorages?.tempExternalStorageBeforeEvent,
        tempExternalStorageAfterEvent: d.movementsAndStorages?.tempExternalStorageAfterEvent,
        locationPlaceBeforeEvent: d.movementsAndStorages?.locationPlaceBeforeEvent,
        movValOutsideLocationPlace: d.movementsAndStorages?.movValOutsideLocationPlace,
        locationPlaceAfterEvent: d.movementsAndStorages?.locationPlaceAfterEvent,
        securityDoneBy: d.securityStaffDeployment?.security,
        securityService: d.securityStaffDeployment?.hasSecurityService,
        securityServiceDescription: d.securityStaffDeployment?.securityServiceDesc,
        securityStaffDeployment:d.securityStaffDeployment?.securityStaffDeployment,
        securityTotalAmount: d.securityStaffDeployment?.eventPhases?.total ? Number(d.securityStaffDeployment?.eventPhases?.total): 0,
        securityTotalAmountGuardingCost: d.securityStaffDeployment?.eventPhases?.totalGuardingCostEstimated ? Number(d.securityStaffDeployment?.eventPhases?.totalGuardingCostEstimated): 0,
        surveillanceSecurityService: d.technicalSurveillanceSystem?.hasSecurityService,
        surveillanceSecurityServiceDescription: d.technicalSurveillanceSystem?.securityServiceDesc,
        technicalSurveillanceSystem: d.technicalSurveillanceSystem?.technicalSurveillanceSystemInformation,
        valuableAssetsTotalEstimated: d.valuableAssets?.totalEstimated ? Number(d.valuableAssets?.totalEstimated) : 0,
        valuableAssetsInformation:d.valuableAssets?.valuableAssetsInformation,
        isDedicatedSafeRoom: d.movementsAndStorages?.isDedicatedSafeRoom,
        isExternalSecuredStorage: d.movementsAndStorages?.isExternalSecuredStorage,
        isNearbyBoutique: d.movementsAndStorages?.isNearbyBoutique,
        isPiecesRemainInShowcases: d.movementsAndStorages?.isPiecesRemainInShowcases,
        isOther: d.movementsAndStorages?.isOther,
        isMovValuableOutsideEventVenue: d.movementsAndStorages?.isMovValuableOutsideEventVenue,
        movementValuableDescription: d.movementsAndStorages?.movementValuableDescription,
        otherDescription: d.movementsAndStorages?.otherDescription,
        declarationsEventPhase: d.securityStaffDeployment?.eventPhases?.eventPhasesList?.filter(
            (eventPhase: EventPhaseItem) => {
                if(!eventPhase.numberOfSecurityAgents && eventPhase.idEventPhase) {
                    eventPhase.isDeleted = true;
                    return eventPhase;
                }
                else if(eventPhase.numberOfSecurityAgents != null) {
                    return eventPhase;
                }
                return null;
            }
        ).map(
            (eventPhase:EventPhaseItem) => {
                const ref: DeclarationEventPhase = {
                    id: eventPhase.idEventPhase,
                    idDeclaration: d.id,
                    idEventPhase: eventPhase.id,
                    numberOfSecurityAgents: eventPhase.numberOfSecurityAgents ? Number(eventPhase.numberOfSecurityAgents) : 0,
                    guardingCost: eventPhase.guardingCost ? Number(eventPhase.guardingCost) : 0
                }
                return ref;
        }),
        declarationsListValuableAssetss: d.valuableAssets?.valuableAssetsList?.filter(
            (valuableAsset: ValuableAssetItem) => {
                if(!valuableAsset.quantity && !valuableAsset.amount && valuableAsset.idType) {
                    valuableAsset.isDeleted = true;
                    return valuableAsset;
                }
                else if(valuableAsset.quantity != null && valuableAsset.amount != null ) {
                    return valuableAsset;
                }
                return null;
            }
        ).map(
            (valuableAsset: ValuableAssetItem) => {
                const ref: DeclarationValuableAsset = {
                    id: valuableAsset.idType,
                    idDeclaration: d.id,
                    amount: valuableAsset.amount ? Number(valuableAsset.amount) : 0,
                    idListValuableAssets: valuableAsset.id,
                    quantity: valuableAsset.quantity ? Number(valuableAsset.quantity) : 0
                }

                if(valuableAsset.id === ValuableAsset.other) {
                    ref.typeDescription = valuableAsset.type;
                }
                return ref;
        }),
        declarationsSecuritySystemDevice: d.technicalSurveillanceSystem?.securitySystemDevicesList?.filter(
            (securitySystemDevice: SecuritySystemDevicesItem) => {
                if(!securitySystemDevice.quantity && securitySystemDevice.idSecuritySystemDevice) {
                    securitySystemDevice.isDeleted = true;
                    return securitySystemDevice;
                }
                else if(securitySystemDevice.quantity != null && securitySystemDevice.securitySystemDevice !== '') {
                    return securitySystemDevice;
                }
                else if (securitySystemDevice.cost || securitySystemDevice.quantity){
                    return securitySystemDevice;
                }
                return null;
            }
        ).map(
            (securitySystemDevice: SecuritySystemDevicesItem) => {
                if(!securitySystemDevice.id) {
                    securitySystemDevice.id = SecurityDevice.other;
                }

                const ref: DeclarationSecuritySystemDevice = {
                    id: securitySystemDevice.idSecuritySystemDevice,
                    idDeclaration: d.id,
                    quantity: securitySystemDevice.quantity ? Number(securitySystemDevice.quantity) : 0,
                    installationCompany: securitySystemDevice.installationCompany,
                    cost: securitySystemDevice.cost ? Number(securitySystemDevice.cost) : 0,
                    idSecuritySystemDevice: securitySystemDevice.id
                }

                if(securitySystemDevice.id === SecurityDevice.other) {
                    ref.typeDescription = securitySystemDevice.securitySystemDevice;
                }
                return ref;
        }),
        declarationsAuthors: d.securityConcept?.authors.map((a: Option):DeclarationAuthors => {
            return {
                id: a.id ? a.id : undefined,
                idDeclaration: d.id,
                idAuthor: a.value
            }
        }),
        declarationsValidators: d.securityConcept?.validators.map((a: Option):DeclarationValidators => {
            return {
                id: a.id ? a.id : undefined,
                idDeclaration: d.id,
                idValidator: a.value
            }
        }),
        securityConceptAuthors: '',
        securityConceptValidators: '',
        createdBy: d.createdBy,
        createdDate: d.createdDate
    }
    return declaration;
}

export const getTreatedValuableAssetsList = (declarationsListValuableAssets: any, valuableAssetsList: ValuableAssetItem[]) => {
    // Valuable Assets List - add omitted values
    if (declarationsListValuableAssets) {
        const tempArr = valuableAssetsList.map(el => { el.idType = 0; return el }).slice();
        declarationsListValuableAssets.forEach((v: any) => {
            let el = tempArr.filter(e => e.id === v.idListValuableAssets || e.type === '')[0];

            if (el) {
                if (el.type === '' || el.id === ValuableAsset.other) {
                    el.type = v.typeDescription;
                }
                el.quantity = v.quantity;
                el.amount = v.amount;
                el.id = v.idListValuableAssets;
                el.idType = v.id;
            }
        });

        return tempArr.slice();
    }

    return [];
}

export const getTreatedSecuritySystemDevicesList = (declarationsSecuritySystemDevice: any, securitySystemDeviceList: SecuritySystemDevicesItem[]) => {
    // Security System Devices List - add omitted values
    if (declarationsSecuritySystemDevice) {
        let tempArr = securitySystemDeviceList.slice();
        const otherTempArr: any[] = [];
        declarationsSecuritySystemDevice.forEach((v: any) => {
            let el = tempArr.filter(e => e.id === v.idSecuritySystemDevice || e.securitySystemDevice === '')[0];
            if (el) {
                if (el.securitySystemDevice === '' || el.id === SecurityDevice.other) {
                    const tempOther = Object.assign({}, el);
                    tempOther.securitySystemDevice = v.typeDescription;
                    tempOther.quantity = v.quantity;
                    tempOther.installationCompany = v.installationCompany;
                    tempOther.cost = v.cost;
                    tempOther.id = v.idSecuritySystemDevice;
                    tempOther.idSecuritySystemDevice = v.id;
                    otherTempArr.push(tempOther);
                }
                else {
                    el.quantity = v.quantity;
                    el.installationCompany = v.installationCompany;
                    el.cost = v.cost;
                    el.id = v.idSecuritySystemDevice;
                    el.idSecuritySystemDevice = v.id;
                }
            }
            else {
                tempArr.push(v);
            }
        });

        // Work around to sort correctly the 'other' fields filled by the user 
        sortArrayObject(otherTempArr, true, 'idSecuritySystemDevice');
        const lastElement = tempArr.splice(tempArr.length-1, 1);
        tempArr = tempArr.concat(otherTempArr).concat(lastElement);

        return tempArr.slice();
    }

    return [];
}

export const getTreatedEventPhasesList = (declarationsEventPhaseList: any, eventPhasesList: EventPhaseItem[]) => {
    // Event phases list from security staff deployment - add omitted values
    if (declarationsEventPhaseList) {
        const tempArr = eventPhasesList.slice();
        declarationsEventPhaseList.forEach((v: any) => {
            let el = eventPhasesList.filter(e => e.id === v.idEventPhase)[0];
            if (el) {
                el.numberOfSecurityAgents = v.numberOfSecurityAgents;
                el.guardingCost = v.guardingCost;
                el.id = v.idEventPhase;
                el.idEventPhase = v.id;
            }
        });

        return tempArr.slice();
    }

    return [];
}