import React from 'react';
import { NotificationTable } from './NotificationTable';
import { ReminderTable } from './ReminderTable';
import { TemplateTable } from './TemplateTable';

export const NotificationsMgmt = () => {
    return (
        <>
            <TemplateTable />
            <NotificationTable />
            <ReminderTable />
        </>
    )
}