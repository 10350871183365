import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Reference, ReferencesService } from "../services/referencesService";
import RefDataService, { IRefDataService } from "../contexts/RefDataContext";
import { thresholdRefreshIntervalMs } from "../config/GlobalAppConfig";
import { Subscription } from "rxjs";
import { AdministrationService } from "../services/administration.service";
import { IThreshold } from "../models/interfaces";
import {rolesAdmin} from "../config/securityConcept";
import LocalStorageTokenService from "../helpers/SessionStorageTokenService";
import {UserRoles} from "../models/enums";

export default React.memo(function RefDataController({ children }: { children: ReactNode }) {
    const [threshold, setThreshold] = useState<IThreshold>({id: 0, thresholdValue: Infinity, modifiedBy: "none"});
    const thresholdSubscription = useRef<Subscription>();
    const [thresholdLoading, setThresholdLoading] = useState(false);
    
    const [maisonsList, setMaisonsList] = useState<Reference[]>([]);
    const maisonSubscription = useRef<Subscription>();
    const [maisonLoading, setMaisonLoading] = useState(false);
    
    const [countriesList, setCountriesList] = useState<Reference[]>([]);
    const countrySubscription = useRef<Subscription>();
    const [countryLoading, setCountryLoading] = useState(false);
    
    const [riskLevels, setRiskLevels] = useState<Reference[]>([]);
    const [riskLevelsLoading, setRiskLevelsLoading] = useState(false);
    const riskLevelSubscription = useRef<Subscription>();

   

    const loadMaisons = () => {
        setMaisonLoading(true);
        maisonSubscription.current?.unsubscribe() 
        maisonSubscription.current = ReferencesService.getAllMaisons().subscribe(maisonList =>{ 
            setMaisonsList(maisonList)
            setMaisonLoading(false);
        });
    }

    const loadCountries = () => {
        setCountryLoading(true);
        countrySubscription.current?.unsubscribe() 
        countrySubscription.current = ReferencesService.getAllCountries().subscribe(countryList => {
            setCountriesList(countryList)
            setCountryLoading(false);
        });
    }
    const loadThreshold = () => {
        if (rolesAdmin.includes(LocalStorageTokenService.getUserRoleToken() as UserRoles)) {
            setThresholdLoading(true);
            thresholdSubscription.current = AdministrationService.getThreshold().subscribe(result => {
                setThreshold(result)
                setThresholdLoading(false)
            });
        }
    }

    const loadRiskLevels = () => {
        setRiskLevelsLoading(true)
        riskLevelSubscription.current?.unsubscribe() 
        riskLevelSubscription.current = ReferencesService.GetAllRiskLevel().subscribe(riskLevels=>{
            setRiskLevelsLoading(false)
            setRiskLevels(riskLevels)
        })

    }

    useEffect(() => {
        loadCountries();
        loadMaisons();    
        loadThreshold();
        loadRiskLevels();
        let thresholdInterval = setInterval(()=>{
            loadThreshold()
        }, thresholdRefreshIntervalMs)

        return () => {
            countrySubscription.current?.unsubscribe();
            maisonSubscription.current?.unsubscribe();
            thresholdSubscription.current?.unsubscribe();
            clearInterval(thresholdInterval)
        }
    },[]);

    const value: IRefDataService = {
        maison: {
            isLoading: maisonLoading,
            items: maisonsList,
            load: loadMaisons,
            set: setMaisonsList
        },
        country: {
            isLoading: countryLoading,
            items: countriesList,
            load: loadCountries,
            set: setCountriesList
        },
        threshold: {
            value: threshold,
            isLoading: thresholdLoading,
            load: loadThreshold,
            set: setThreshold
        },
        riskLevel: {
            isLoading: riskLevelsLoading,
            items: riskLevels,
            load: loadRiskLevels
        }

    }

    return (
        <RefDataService.Provider value={value}>
            {children}
        </RefDataService.Provider>
    )
})
