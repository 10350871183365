import { BehaviorSubject, Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { INewEvent, EventMaisonBrand, StatusItemServer, EventsActivityType } from "../../event/interface";
import { CommonProperties } from '../models/interfaces';
import {
   urlGetEvent,
   urlAddEvent,
   urlUpdateEvent,
   urlGetEventStatusList,
   urlGetEventHistoryStatus,
   urlRecallEvent
} from '../config/GlobalAppConfig';
import { NextActionBehavior } from '../../declaration/declarationNewEdit/DeclarationNewEdit';
import { AxiosRequestConfig } from 'axios';
import CustomAxios, { ApiCallingMethods } from '../helpers/AxiosHelper';

interface INewEventBackEnd {
   id: number | null,
   leadbrandName: string,
   country?: number | null,
   multiBrandEvent: boolean,
   eventName: string,
   eventType?: string | null,
   fromDate: Date | null,
   toDate: Date | null,
   locationName: string,
   locationType?: string | null,
   eventAddress: string,
   estimatedTotalValue: number,
   countryLevelRisk?: string | null,
   isStrategic: boolean,
   isHeritage: boolean,
   riskLevel?: string | null,
   createdBy?: string,
   modifiedBy?: string | null,
   eventMaisonBrands: EventMaisonBrand[],
   eventsStatus: StatusItemServer[],
   numberParticipants: string,
   nextActionBehavior: NextActionBehavior,
   accountableForEventLastName: string,
   accountableForEventFirstName: string,
   responsibleOfSecurityFirstName: string,
   responsibleOfSecurityLastName: string,
   createdDate: Date | string | null | undefined,
   isInvitationOnly: boolean,
   isVIP: boolean,
   isPress: boolean,
   isSemiPublic: boolean,
   isPublic: boolean,
   eventsActivityType?: EventsActivityType[],
   numberOfPieces: number,
   isOther: boolean,
   otherDescription: string
}

export interface EventHistory {
   comment: string;
   createdBy: string;
   createdDate: Date;
   flow: string;
   groupUsers: string;
   id: number;
   idEvent: number;
   idFlow: number;
   idGroupUsers: number;
   idStatus: number;
   idTask: number;
   isDeleted: boolean;
   modifiedBy: string;
   modifiedDate: Date;
   tasks: string;
} 

export interface Status extends CommonProperties {
   idEvent: number,
   idStatus: number
}

function getById(id: number) {
   const options : AxiosRequestConfig = {
      method: ApiCallingMethods.get,
      url: `${urlGetEvent}?idEvent=${id}`,
      data: null
  };
  return CustomAxios.request(options).pipe(
   map((r : any) => {
      r.data.model.eventDeclarations.forEach((declaration:any) => {
         declaration.valuableAssets = {
            totalEstimated: declaration.valuableAssetsTotalEstimated
         }
         declaration.eventPhases = {
            total: declaration.securityTotalAmount
         }
      })

      let event  = r.data.model;
      event.country = r.data.model.country ? r.data.model.country : ""
      event.eventType =  r.data.model.eventType ? r.data.model.eventType : "";
      event.locationType =  r.data.model.locationType ? r.data.model.locationType : "";
      event.countryLevelRisk =  r.data.model.countryLevelRisk ? r.data.model.countryLevelRisk : "";
      event.riskLevel = r.data.model.riskLevel ? r.data.model.riskLevel : "";
      event.riskTags = {
         isStrategic: r.data.model.isStrategic,
         isHeritage: r.data.model.isHeritage,
      }
      event.fromDate = r.data.model.fromDate ? r.data.model.fromDate.split("T")[0] : null;
      event.toDate = r.data.model.toDate ? r.data.model.toDate.split("T")[0] : null;

      delete event.isStrategic;
      delete event.isHeritage;
      delete event.valuableAssetsTotalEstimated;
      delete event.securityTotalAmount;

      return event
   })
  );
}
const eventData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

function updateEvent(event : INewEvent): Observable<INewEvent> {
   const options : AxiosRequestConfig = {
      method: ApiCallingMethods.put,
      url: urlUpdateEvent,
      data: DTONewEvent_to_NewEventBackEnd(event)
   };
   return CustomAxios.request(options).pipe(map(i => i.data.model));
}

function addEvent(event : INewEvent){
   const options : AxiosRequestConfig = {
      method: ApiCallingMethods.post,
      url: urlAddEvent,
      data: DTONewEvent_to_NewEventBackEnd(event)
   };
   return CustomAxios.request(options).pipe(map(i => i.data.model.id));
}

function GetStatusListFromAnEvent(idEvent: number){
   const options : AxiosRequestConfig = {
      method: ApiCallingMethods.get,
      url: `${urlGetEventStatusList}/${idEvent}`,
      data: null
  };
  return CustomAxios.request(options).pipe(
      map((r : any) => r.data.model)
   );
}

function GetStatusHistoryFromAnEvent(idEvent: number): Observable<EventHistory[]>{
   const options : AxiosRequestConfig = {
      method: ApiCallingMethods.get,
      url: `${urlGetEventHistoryStatus}/${idEvent}`,
      data: null
  };
  return CustomAxios.request(options).pipe(
      map((r : any) => r.data.model)
   );
}

function DTONewEvent_to_NewEventBackEnd(event : INewEvent) : INewEventBackEnd{
   return   {
      "id" : event.id ? event.id : 0,
      "leadbrandName": event.leadbrandName,
      "country": !event.country ? null : Number(event.country),
      "multiBrandEvent": event.multiBrandEvent,
      "eventName": event.eventName,
      "eventType": !event.eventType ? null : event.eventType,
      "fromDate": event.fromDate ? new Date(event.fromDate) : null,
      "toDate": event.toDate ? new Date(event.toDate) : null,
      "locationName": event.locationName,
      "locationType": !event.locationType ? null : event.locationType,
      "eventAddress": event.eventAddress,
      "estimatedTotalValue": Number(event.estimatedTotalValue),
      "countryLevelRisk": !event.countryLevelRisk ? null : event.countryLevelRisk,
      "isStrategic": event.riskTags.isStrategic,
      "isHeritage": event.riskTags.isHeritage,
      "riskLevel": !event.riskLevel ? null : event.riskLevel,
      "createdBy": event.createdBy,
      "modifiedBy": event.modifiedBy,
      "eventMaisonBrands": event.eventMaisonBrands,
      "numberParticipants": event.numberParticipants,
      "eventsStatus": event.eventsStatus && event.eventsStatus.length ? [event.eventsStatus[event.eventsStatus.length-1]] : [
         {
            idEvent: 0,
            idStatus: event.eventsStatus && event.eventsStatus.length ? event.eventsStatus[0].idStatus : 1,
            comment: event.eventsStatus && event.eventsStatus.length ? event.eventsStatus[0].comment : ''
         }
      ],
      "nextActionBehavior": event.nextActionBehavior,
      "accountableForEventLastName": event.accountableForEventLastName?.toUpperCase(),
      "accountableForEventFirstName": event.accountableForEventFirstName,
      "responsibleOfSecurityFirstName": event.responsibleOfSecurityFirstName,
      "responsibleOfSecurityLastName":event.responsibleOfSecurityLastName?.toUpperCase(),
      "createdDate": event.createdDate,
      "isInvitationOnly": event.isInvitationOnly,
      "isPress": event.isPress,
      "isPublic": event.isPublic,
      "isSemiPublic": event.isSemiPublic,
      "isVIP": event.isVIP,
      "eventsActivityType": event.eventsActivityType,
      "numberOfPieces": event.numberOfPieces,
      "isOther": event.isOther,
      "otherDescription": event.otherDescription
   };
}

function recallEvent(idEvent: number): Observable<EventHistory[]>{
   const options : AxiosRequestConfig = {
      method: ApiCallingMethods.post,
      url: `${urlRecallEvent}`,
      data: {id: idEvent}
   };
   return CustomAxios.request(options).pipe(
       map((r : any) => r.data.model)
   );
}

const NewEventService = {
   getById : getById,
   addEvent : addEvent,
   updateEvent : updateEvent,
   eventData: eventData,
   GetStatusListFromAnEvent: GetStatusListFromAnEvent,
   GetStatusHistoryFromAnEvent: GetStatusHistoryFromAnEvent,
   recallEvent: recallEvent
}

export default NewEventService
