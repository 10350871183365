import React, { useEffect, useState } from "react";
import { Field } from "formik";
import * as Yup from "yup";
import {
   UiDateField,
   UiRadioField,
   UiSelectField,
   UiSwitchField,
   UiTextField
} from "../../../shared/components/fields/";
import { EventsActivityType, IEventInformations } from "../../interface";
import "./EventInformations.scss";
import { ActivityType, LocationType } from "../../../shared/models/enums";
import { RISK_LEVEL_3_LABEL } from "../../../shared/constants";

export const eventInformationsInitialValues: IEventInformations = {
   eventName: '',
   eventType: '',
   fromDate: '',
   toDate: "",
   locationName: '',
   locationType: '',
   eventAddress: '',
   numberParticipants: '',
   isInvitationOnly: false,
   isVIP: false,
   isPress: false,
   isSemiPublic: false,
   isPublic: false,
   accountableForEventLastName: '',
   accountableForEventFirstName: '',
   responsibleOfSecurityFirstName:'',
   responsibleOfSecurityLastName:'',
   eventsActivityType: [],
   isOther: false,
   otherDescription: '',
}
export const eventInformationsSchemaWithoutActivities = Yup.object().shape({
   eventName: Yup.string().required('Event Name is required'),
   eventType: Yup.string().required('Event Type is required'),
   fromDate : Yup.date().required('From date is required'),
   toDate: Yup.date().nullable().min(Yup.ref('fromDate'), ({min}) => 'To Date need to be after ' + new Date(min).toLocaleDateString()),
   locationName : Yup.string().required('Location Name is required'),
   locationType: Yup.string().required('Location Type is required'),
   eventAddress : Yup.string().required('Event Address is required'),
   numberParticipants : Yup.string().required('Number of participants is required'),
   accountableForEventLastName: Yup.string().required('Last name of person accountable for the event is required'),
   accountableForEventFirstName: Yup.string().required('First name of person accountable for the event is required'),
   responsibleOfSecurityFirstName: Yup.string().required('First name of person in charge of event security is required'),
   responsibleOfSecurityLastName:Yup.string().required('Last name of person in charge of event security is required'),
   isInvitationOnly: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isSemiPublic || this.parent.isPress || this.parent.isPublic || this.parent.isOther || this.parent.isInvitationOnly)}),
   isVIP: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isInvitationOnly ||  this.parent.isSemiPublic || this.parent.isPress || this.parent.isPublic || this.parent.isOther || this.parent.isVIP)}),
   isPress: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isSemiPublic || this.parent.isInvitationOnly || this.parent.isPublic || this.parent.isOther || this.parent.isPress)}),
   isSemiPublic: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isInvitationOnly || this.parent.isPress || this.parent.isPublic || this.parent.isOther || this.parent.isSemiPublic)}),
   isPublic: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isSemiPublic || this.parent.isPress || this.parent.isInvitationOnly || this.parent.isOther || this.parent.isPublic)}),
   isOther: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isSemiPublic || this.parent.isPress || this.parent.isPublic || this.parent.isInvitationOnly || this.parent.isOther)}),
   otherDescription: Yup.string().nullable().test('descriptionRequired', 'Details required', function(item) {return (this.parent.isOther && item && item !== '') || (!this.parent.isOther)}),
})

export const eventInformationsSchema = Yup.object().shape({
   eventName: Yup.string().required('Event Name is required'),
   eventType: Yup.string().required('Event Type is required'),
   fromDate : Yup.date().required('From date is required'),
   toDate: Yup.date().nullable().min(Yup.ref('fromDate'), ({min}) => 'To Date need to be after ' + new Date(min).toLocaleDateString()),
   locationName : Yup.string().required('Location Name is required'),
   locationType: Yup.string().required('Location Type is required'),
   eventAddress : Yup.string().required('Event Address is required'),
   numberParticipants : Yup.string().required('Number of participants is required'),
   accountableForEventLastName: Yup.string().required('Last name of person accountable for the event is required'),
   accountableForEventFirstName: Yup.string().required('First name of person accountable for the event is required'),
   responsibleOfSecurityFirstName: Yup.string().required('First name of person in charge of event security is required'),
   responsibleOfSecurityLastName:Yup.string().required('Last name of person in charge of event security is required'),
   isInvitationOnly: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isSemiPublic || this.parent.isPress || this.parent.isPublic || this.parent.isOther || this.parent.isInvitationOnly)}),
   isVIP: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isInvitationOnly ||  this.parent.isSemiPublic || this.parent.isPress || this.parent.isPublic || this.parent.isOther || this.parent.isVIP)}),
   isPress: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isSemiPublic || this.parent.isInvitationOnly || this.parent.isPublic || this.parent.isOther || this.parent.isPress)}),
   isSemiPublic: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isInvitationOnly || this.parent.isPress || this.parent.isPublic || this.parent.isOther || this.parent.isSemiPublic)}),
   isPublic: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isSemiPublic || this.parent.isPress || this.parent.isInvitationOnly || this.parent.isOther || this.parent.isPublic)}),
   isOther: Yup.boolean().nullable().test('oneOfRequired', 'A type of participant must be chosen', function() {return (this.parent.isVIP ||  this.parent.isSemiPublic || this.parent.isPress || this.parent.isPublic || this.parent.isInvitationOnly || this.parent.isOther)}),
   otherDescription: Yup.string().nullable().test('descriptionRequired', 'Details required', function(item) {return (this.parent.isOther && item && item !== '') || (!this.parent.isOther)}),
   boutiqueActivation: Yup.boolean().nullable().test('oneOfRequired', 'A type of activity must be chosen', function() {return (this.parent.boutiqueActivation ||  this.parent.exhibition || this.parent.privateViewings || this.parent.galaDinner || this.parent.other || this.parent.modelShow || this.parent.piecesPresentations)}),
   exhibition: Yup.boolean().nullable().test('oneOfRequired', 'A type of activity must be chosen', function() {return (this.parent.boutiqueActivation ||  this.parent.exhibition || this.parent.privateViewings || this.parent.galaDinner || this.parent.other || this.parent.modelShow || this.parent.piecesPresentations)}),
   privateViewings: Yup.boolean().nullable().test('oneOfRequired', 'A type of activity must be chosen', function() {return (this.parent.boutiqueActivation ||  this.parent.exhibition || this.parent.privateViewings || this.parent.galaDinner || this.parent.other || this.parent.modelShow || this.parent.piecesPresentations)}),
   galaDinner: Yup.boolean().nullable().test('oneOfRequired', 'A type of activity must be chosen', function() {return (this.parent.boutiqueActivation ||  this.parent.exhibition || this.parent.privateViewings || this.parent.galaDinner || this.parent.other || this.parent.modelShow || this.parent.piecesPresentations)}),
   modelShow: Yup.boolean().nullable().test('oneOfRequired', 'A type of activity must be chosen', function() {return (this.parent.boutiqueActivation ||  this.parent.exhibition || this.parent.privateViewings || this.parent.galaDinner || this.parent.other || this.parent.modelShow || this.parent.piecesPresentations)}),
   piecesPresentations: Yup.boolean().nullable().test('oneOfRequired', 'A type of activity must be chosen', function() {return (this.parent.boutiqueActivation ||  this.parent.exhibition || this.parent.privateViewings || this.parent.galaDinner || this.parent.other || this.parent.modelShow || this.parent.piecesPresentations)}),
   other: Yup.boolean().nullable().test('oneOfRequired', 'A type of activity must be chosen', function() {return (this.parent.boutiqueActivation ||  this.parent.exhibition || this.parent.privateViewings || this.parent.galaDinner || this.parent.other || this.parent.modelShow || this.parent.piecesPresentations)}),
   activityOtherDescription: Yup.string().nullable().test('descriptionRequired', 'Details required', function(item) {return (this.parent.other && item && item !== '') || (!this.parent.other)}),
})

const EventInformations = ({ field, form, ...props }: any) => {

   const {eventTypeList,locationTypeList,numberOfParticipants, updateActivityType, eventsActivityType} = props
   // the date.toJSON() display the Date part and Time part separated by letter T
   // so we use split("T") in order to get only the date
   // if min and max attribute doesn't respect "yyyy-mm-dd" they will be ignored by <input>
   const [date] = new Date().toJSON().split("T")
   const maxDate = new Date().setFullYear(new Date().getFullYear()+5);

   const updateForm = (field: string, e: any, activityType: ActivityType) => {
      form.setFieldValue(field, e);
      updateActivityType(e, activityType, e);
   }

   const handleEventTypeChange = (e: any) => {
      // TODO: Due to the lack of informations, the following code is not clean and should be refactored see JIRA ticket (https://onejira.sqli.com/browse/SUDG0224-154)

      form.setFieldValue("eventType", e.target.value);
      if (e.target.value === 5) {
          form.setFieldValue("riskLevel", 4);
      }
   };


   useEffect(() => {
      eventsActivityType.forEach((activity: EventsActivityType) => {
         switch (activity.idActivityType) {
            case ActivityType.Pieces_presentations_on_displays:
               form.setFieldValue("piecesPresentations", true);
               break;
            case ActivityType.Boutique_activation:
               form.setFieldValue("boutiqueActivation", true);
               break;
            case ActivityType.Exhibition:
               form.setFieldValue("exhibition", true);
               break;
            case ActivityType.Private_viewings:
               form.setFieldValue("privateViewings", true);
               break;
            case ActivityType.Gala_dinner:
               form.setFieldValue("galaDinner", true);
               break;
            case ActivityType.Model_show:
               form.setFieldValue("modelShow", true);
               break;
            case ActivityType.Other:
               form.setFieldValue("other", true);
               if (activity.activityTypeOtherDescription) {
                  form.setFieldValue("activityOtherDescription", activity.activityTypeOtherDescription);
               }
               break;
         }
      });
   }, [eventsActivityType]);

   useEffect(() => {
      if (form.values.locationType == LocationType.Richemont_boutique) {
         updateForm('boutiqueActivation', true, ActivityType.Boutique_activation);
      }
   }, [form.values.locationType]);

   return (
      <div>
         <div>
            <Field id="inputEventName" name="eventName" label="Event Name (*)" component={UiTextField} />
            <Field
               id="selectEventType"
               name="eventType"
               label="Event Type (*)"
               component={UiSelectField}
               options={eventTypeList}
               onChange={handleEventTypeChange}
            />
            <Field id="datePickerFromDate" name="fromDate" column="half" label="From (*)  " component={UiDateField} min={date} max={maxDate} />
            <Field id="datePickerToDate" name="toDate" column="half" label={<span>To</span>} component={UiDateField} min={date} max={maxDate} />
            <Field id="inputLocationName" name="locationName" label="Location Name (*)" component={UiTextField} />
            <Field id="selectLocationType" name="locationType" component={UiSelectField} label="Location Type (*)" options={locationTypeList} />
            <Field id="inputEventAddress" name="eventAddress" label="Event Address (*)" component={UiTextField} />
            <Field
                    id="radioNumberParticipants"
                    name="numberParticipants"
                    component={UiRadioField}
                    inline={true}
                    options={numberOfParticipants}
                    label="Number of participants (*)"
                />

            <p className="ui-field">How are participants (guests) managed ? (*)</p>
            <Field id="invitationOnly" name='isInvitationOnly' label='- Invitation-only event' component={UiSwitchField} inline={true}/>
            <Field id="vips" name='isVIP' label='- VIPs' component={UiSwitchField} inline={true}/>
            <Field id="press" name='isPress' label='- Press' component={UiSwitchField} inline={true}/>
            <Field id="semiPublic" name='isSemiPublic' label='- Semi-public (pre-registration required)' component={UiSwitchField} inline={true}/>
            <Field id="public" name='isPublic' label='- Public (no pre-registration required)' component={UiSwitchField} inline={true}/>
            <Field id="other" name='isOther' label='- Other (please specify)' component={UiSwitchField} inline={true}/>
            {form.values.isOther && (

                <Field id="otherDescription" name='otherDescription' component={UiTextField}/>

            )}
            <p style={{ paddingTop: "10px" }} className="ui-field">Person accountable for the event (*) </p>
            <div className="inline-form">
               <Field id="inputAccountableFirstName" name="accountableForEventFirstName" label="First Name (*)" component={UiTextField} />
               <Field id="inputAccountableLastName" name="accountableForEventLastName" label="Last Name (*)" component={UiTextField} />
            </div>

            <p className="ui-field">Person in charge of event security (*) </p>
            <div className="inline-form">
               <Field id="inputResponsibleSecurityFirstName" name="responsibleOfSecurityFirstName" label="First Name (*)" component={UiTextField} />
               <Field id="inputResponsibleSecurityLastName" name="responsibleOfSecurityLastName" label="Last Name (*)" component={UiTextField} />
            </div>
            <p className="ui-field">Type of activities in the event (*)</p>
            <Field label='- Pieces presentations on displays' name="piecesPresentations" component={UiSwitchField} inline={true} onChange={(e:any) => updateForm("piecesPresentations", e.target.checked, ActivityType.Pieces_presentations_on_displays)}/>
            <Field id="boutiqueActivation" name="boutiqueActivation" label='- Boutique activation' component={UiSwitchField} inline={true} onChange={(e:any) => updateForm("boutiqueActivation", e.target.checked, ActivityType.Boutique_activation)}/>
            <Field id="exhibition" name="exhibition" label='- Exhibition' component={UiSwitchField} inline={true} onChange={(e:any) => updateForm("exhibition", e.target.checked, ActivityType.Exhibition)}/>
            <Field id="privateViewings" name="privateViewings" label='- Private viewings' component={UiSwitchField} inline={true} onChange={(e:any) => updateForm("privateViewings", e.target.checked, ActivityType.Private_viewings)}/>
            <Field id="galaDinner" name="galaDinner" label='- Gala dinner' component={UiSwitchField} inline={true} onChange={(e:any) => updateForm("galaDinner", e.target.checked, ActivityType.Gala_dinner)}/>
            <Field id="modelShow" name="modelShow" label='- Model show' component={UiSwitchField} inline={true} onChange={(e:any) => updateForm("modelShow", e.target.checked, ActivityType.Model_show)}/>
            <Field id="other" name="other" onChange={(e:any) => updateForm("other", e.target.checked, ActivityType.Other)} label='- Other (please specify)' component={UiSwitchField} inline={true}/>
            {form.values.other && <Field name="activityOtherDescription" component={UiTextField} onChange={(e:any) => updateForm("activityOtherDescription", e.target.value, ActivityType.OtherDescription)}/>}
         </div>
      </div>
   )
}

export default EventInformations;