import React, { useMemo } from 'react';
import { IUiMultiselectOption } from '../../../shared/components/fields/UiAutocompleteField';
import { ReminderDto, ReminderDtoBack, RoleDto } from '../../../shared/models/interfaces';
import { AdministrationService } from '../../../shared/services/administration.service';
import { useAdministrationContext } from '../../AdministrationContext';
import { ActiveCell } from '../CommonMgmt/ActiveCell';
import { AdministrationFiltersProps } from '../CommonMgmt/AdministrationFilters';
import { AdministrationTable, IAdministrationTableHead } from '../CommonMgmt/AdministrationTable';
import { ReminderForm, ReminderFormValue } from './ReminderForm';
import {cloneDeep} from 'lodash';

const tableHeads: IAdministrationTableHead<ReminderDto>[] = [
    { name: "template", label: "Template", sortable: true, searchable: true, accessor: "template.name" },
    { name: "nextAction", label: "Next actions", sortable: true, accessor: "nextAction.itemContent", searchable: true },
    { name: "roles", label: "Role recipient", sortable: true, accessor: (item => item.recipients.map(r=>r.name).join(', ')), searchable: true},
    {
        name: "frequency",
        label: "Frequency",
        sortable: true,
        accessor: "frequency",
        searchable: true,
        cell: (props) => `${props.frequency} ${props.frequency !== 1 ? 'days' : 'day'}`,
    },
    { name: "active", label: "Active", sortable: true, accessor: "active", cell: ActiveCell },
];

export const ReminderTable = () => {

    const adminCtx = useAdministrationContext()
    const handleCreate = async (data: ReminderFormValue) => {
        AdministrationService.addReminder(data).subscribe(()=>{
            adminCtx.reminder.load();
        })
    };

    const handleDelete = async (data: ReminderDto) => {
        AdministrationService.deleteReminder(data).subscribe(()=>{
            adminCtx.reminder.load();
        })
    };
    const handleUpdate = async (data: ReminderFormValue, item: ReminderDto) => {

        // this map is used for change properties isDeleted for each recipients that have been removed
        item.recipients.map( item =>{
            const foundItem = data.recipients?.find( x => x.value === item.idReminderRoleGroupUser)
            if(foundItem){
                item.isDeleted = false
            }else{
                item.isDeleted = true
            }
        })
        let newRecipients :RoleDto[] = [];
         // this filter is used to create a list with the new recipients that have been added
        data.recipients?.filter( data => {
            const foundItem = item.recipients.find( x => x.idReminderRoleGroupUser === data.value)
            if(!foundItem){
                newRecipients.push({id :0,name:data.label,idReminderRoleGroupUser: data.value,isDeleted: false})
            }
        })

        const updatedRecipients = cloneDeep(item.recipients)
        // first we destructure item, that is the old data of the form. Then we destructure data that contains the new values
        // entered in the form and finally, we override recipients with the updated, delete, and added values
        const updatedReminder = {...item,...data,recipients: [...updatedRecipients,...newRecipients]}

        //Finally we map all data in an object required by the backend
        const updatedReminderBackend : ReminderDtoBack = {
            id: updatedReminder.id,
            templateId: updatedReminder.template?.value,
            nextActionId: updatedReminder.nextAction?.value,
            reminderRoleRecipients: updatedReminder.recipients?.map((recipient) => ({
                id: recipient.id,
                idReminder: updatedReminder.id,
                idReminderRoleGroupUser: recipient.idReminderRoleGroupUser || 0,
                isDeleted : recipient.isDeleted || false
            })),
            frequency: updatedReminder.frequency || 0,
            isActive: updatedReminder.active,
            isDeleted : false
        }

        AdministrationService.updateReminder(updatedReminderBackend).subscribe(()=>{
            adminCtx.reminder.load()
        })
    };


    const filters: AdministrationFiltersProps<ReminderDto> | undefined = useMemo(() => {
        if(!adminCtx.role.options.length) return undefined;
        return {
            items: [
                {
                    name: "template",
                    label: "Template names",
                    type: "multiselect",
                    filter: (item, filterValue) => !filterValue || !filterValue.length || filterValue.some((filterTemplate: IUiMultiselectOption) => filterTemplate.value === item.template.id),
                    options: adminCtx.template.options,
                },
                {
                    name: "nextAction",
                    label: "Next actions",
                    type: "multiselect",
                    filter: (item, filterValue) => {
                       return !filterValue || !filterValue.length || filterValue.some((filterAction: IUiMultiselectOption) => filterAction.value === item.nextAction.id)
                    },
                    options: adminCtx.task.options,
                },
                {
                    name: "roles",
                    label: "Roles Recipient",
                    type: "multiselect",
                    filter: (item, filterValue) => item.recipients.some(role=>!filterValue.length || filterValue.some((filterRole:IUiMultiselectOption)=>filterRole.value===role.id)),
                    options: adminCtx.role.options,
                },
                {
                    name: "active",
                    label: "Active",
                    type: "select",
                    filter: (item, filterValue) =>{
                        return !filterValue || item.active === filterValue.value
                    },
                    options: [
                        {label: "Yes", value: true},
                        {label: "No", value: false},
                    ],
                },
            ],
        };
    }, [adminCtx.role.options, adminCtx.task.options, adminCtx.template.options]);

    return (
        <AdministrationTable
            id="reminder-table"
            title="Reminder"
            items={adminCtx.reminder.items}
            itemLabelProp={(item)=> `reminder of template "${item.template.name}"`}
            form={ReminderForm}
            loading={adminCtx.reminder.isLoading}
            tableHeads={tableHeads}
            onCreate={handleCreate}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            filters={filters}
        />
    );
};