import React from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import { MovementsAndStorage, RichemontTable, RoutingAndTransfer } from './components';
import { ValuableAssetItem } from '../interfaces';
import { UiSectionTitle } from '../../../shared/components/ui/UiSectionTitle';
import { FIELD_IDS } from '../../../admin/components/FieldsMgmt/Fields.type';
import { ConditionnalField } from '../../../shared/components/ConditionnalField';
import UiTextareaField from '../../../shared/components/fields/UiTextareaField';
import { MAX_TEXTFIELD_CHARS } from '../../../shared/constants';

interface CustomInterface extends FieldProps, FormikProps<any> {
    fieldVisibility: any;
}

const valuableAssetsList: ValuableAssetItem[] = require('./valuablesAssets.json');

interface State {
    displayListOfValuableAssets: boolean;
    displayRoutingAndTransfer: boolean;
    displayMovementAndStorage: boolean;
}

class ValuablesManagement extends React.Component<CustomInterface, State> {
    constructor(props: CustomInterface) {
        super(props);

        this.state = {
            displayListOfValuableAssets: false,
            displayRoutingAndTransfer: false,
            displayMovementAndStorage: false,
        };
    }

    componentDidMount() {
        const fieldsVisibility = this.props.fieldVisibility;
        Object.entries(FIELD_IDS.valuablesAssets).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                this.setState({ displayListOfValuableAssets: true })
            }
        })
        Object.entries(FIELD_IDS.routingAndTransfer).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                this.setState({ displayRoutingAndTransfer: true })
            }
        })
        Object.entries(FIELD_IDS.movementAndStorage).forEach(([, value]) => {
            if (fieldsVisibility[value] === true) {
                this.setState({ displayMovementAndStorage: true })
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.displayListOfValuableAssets && <section className="section">
                    <UiSectionTitle>List of valuable assets</UiSectionTitle>
                    <ConditionnalField
                        fieldId={FIELD_IDS.valuablesAssets.list}
                        name="declaration.valuableAssets"
                        list={this.props.values.declaration.valuableAssets.valuableAssetsList}
                        listname="declaration.valuableAssets.valuableAssetsList"
                        otherfieldexists={true}
                        showlastrow={true}
                        lastrowlabel="Total Estimated"
                        lastrowname="totalEstimated"
                        originallistsize={valuableAssetsList.length}
                        types={["number", "view", "amount", "amount"]}
                        isreadonly={this.props.values.isReadOnly}
                        columnnumbertocalc={2}
                        columnstoignore={["id", "idType"]}
                        footer={null}
                        component={RichemontTable} />

                    <ConditionnalField
                        fieldId={FIELD_IDS.valuablesAssets.valuableAssetsInformation}
                        name="declaration.valuableAssets.valuableAssetsInformation"
                        label="Valuables Assets Information"
                        component={UiTextareaField}
                        disabled={this.props.values.isReadOnly}
                        placeholder="Valuables Assets Information to send to broker…"
                        inputProps={{ maxLength: MAX_TEXTFIELD_CHARS }}
                    />
                </section>}

                {this.state.displayRoutingAndTransfer && <section className="section">
                    <UiSectionTitle>Routing and transfer</UiSectionTitle>
                    <Field name="declaration.routingAndTranfer" component={RoutingAndTransfer} />
                </section>}
                {this.state.displayMovementAndStorage && <div>
                    <UiSectionTitle>Movements and storages</UiSectionTitle>
                    <Field name="declaration.movementsAndStorage" component={MovementsAndStorage} />
                </div>}
            </React.Fragment>
        )
    }
}

export default ValuablesManagement;