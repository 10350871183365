import { AxiosRequestConfig } from 'axios';
import {urlToSaveEmailEventHistory, urlToSendEmail} from '../config/GlobalAppConfig';
import CustomAxios, { ApiCallingMethods } from '../helpers/AxiosHelper';
import {EmailData, EmailEventHistory} from '../models/interfaces';

function sendEmailInformation(emailData : EmailData){
    const options : AxiosRequestConfig = {
        method: ApiCallingMethods.post,
        url: urlToSendEmail,
        data: emailData
    };
    return CustomAxios.request(options);
 }

function saveEmailEventHistory(emailData: EmailEventHistory[]) {
    const options: AxiosRequestConfig = {
        method: ApiCallingMethods.post,
        url: urlToSaveEmailEventHistory,
        data: emailData
    };
    return CustomAxios.request(options);
}

export const EmailService = {
    sendEmailInformation : sendEmailInformation,
    saveEmailEventHistory : saveEmailEventHistory
}
 
 export default EmailService