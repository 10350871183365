import React from "react";
import { IThreshold, LoadableItems, LoadableValue } from "../models/interfaces";
import { Reference } from "../services/referencesService";

export interface IRefDataService {
    country: LoadableItems<Reference>
    maison: LoadableItems<Reference>,
    threshold: LoadableValue<IThreshold>,
    riskLevel: LoadableItems<Reference>,
}

export default React.createContext<IRefDataService>({} as IRefDataService);