import React from "react";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Zoom, Fab } from '@material-ui/core';
import './UiScrollToTopButton.scss';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: '50%',
      right: '50px',
    },
  }),
);

const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
};

function ScrollTop(props: Props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 800,
    });
  
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
        '#back-to-top-anchor',
      );
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      else {
        scrollToTop();
      }
    };
  
    return (
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation" className={classes.root}>
          {children}
        </div>
      </Zoom>
    );
  }

export const UiScrollToTopButton = (props: any) => {
    return(
        <ScrollTop {...props}>
            <Fab color="default" size="small" aria-label="scroll back to top" className="goToTopBtn--white">
                <ArrowUpwardIcon />
            </Fab>
        </ScrollTop>
    )
}