import { IconButton, Modal } from "@material-ui/core";
import { Close, ZoomIn } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { rolesAllowedToPreviewPdf } from "../../config/securityConcept";
import { useAuthContext } from "../../contexts/AuthContext";
import { DocAccessType, UserRoles } from "../../models/enums";
import { CustomFile } from "../upload/interfaces";
import "./PdfViewer.scss";
import { PdfViewerContent } from "./PdfViewerContent";
export type PdfViewerProps = {
    file: CustomFile;
};
export const PdfViewer: React.FC<PdfViewerProps> = ({ children, file }) => {
    const [modal, setModal] = useState(false);
    const {role} = useAuthContext();
    const allowPreview = useMemo(()=>{
        return rolesAllowedToPreviewPdf.includes(role as UserRoles);
    }, [role])
    if (DocAccessType["Security concept"] !== Number(file.docAccessType) || !allowPreview) return <>{children}</>;
    return (
        <>
            <div className="pdf-viewer-opener" onClick={() => setModal(true)}>
                {children}
                <div className="pdf-viewer-opener__icon">
                    <ZoomIn />
                </div>
            </div>
            <Modal className="pdf-viewer" open={modal} onClose={() => setModal(false)}>
                <>
                    <div  className="pdf-viewer__close">
                        <IconButton onClick={() => setModal(false)} aria-label="delete">
                            <Close />
                        </IconButton>
                    </div>
                    <div className="pdf-viewer__modal__content">
                        <PdfViewerContent file={file} />
                    </div>
                </>
            </Modal>
        </>
    );
};
