export interface SessionStorageToken {
    AccessToken: string;
    RefreshToken: string|null;
    UserName: string;
    UserFullName: string|null;
    UserRoles: string;
 }

const SessionStorageTokenService = (function () {
    var _service: any; function _getService(this: { getService: () => any; setToken: (tokenObj: SessionStorageToken) => void; getAccessToken: () => string | null; getRefreshToken: () => string | null; clearToken: () => void; }) {
        if (!_service) {
            _service = this;
            return _service
        } return _service
    }

    function _setToken(tokenObj: SessionStorageToken) {
        if(tokenObj === undefined || tokenObj === null){
            return;
        }
        if(tokenObj.AccessToken !== undefined || tokenObj.AccessToken !== null){
            sessionStorage.setItem('access_token', (tokenObj.AccessToken as string));
        }
        if(tokenObj.AccessToken !== undefined || tokenObj.AccessToken !== null){
            sessionStorage.setItem('refresh_token', (tokenObj.RefreshToken as string));
        }
        if(tokenObj.UserFullName !== undefined || tokenObj.UserFullName !== null){
            sessionStorage.setItem('user_full_name', (tokenObj.UserFullName as string));
        }
        if(tokenObj.UserRoles !== undefined || tokenObj.UserRoles !== null){
            sessionStorage.setItem('user_roles', (tokenObj.UserRoles as string));
        }
    }

    function _getAccessToken() {
        return sessionStorage.getItem('access_token');
    }
    function _getRefreshToken() {
        return sessionStorage.getItem('refresh_token');
    }
    function _getUserNameToken() {
        return sessionStorage.getItem('user_full_name');
    }
    function _getUserRoleToken() {
        return sessionStorage.getItem('user_roles');
    }
    function _clearToken() {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
        sessionStorage.removeItem('user_full_name');
        sessionStorage.removeItem('user_roles');
    }
    function _clearFilters() {
        sessionStorage.removeItem('modalFilter');
        sessionStorage.removeItem('pageNumber');
    }
    
    return {
        getService: _getService,
        setToken: _setToken,
        getAccessToken: _getAccessToken,
        getRefreshToken: _getRefreshToken,
        getUserNameToken: _getUserNameToken,
        getUserRoleToken: _getUserRoleToken,
        clearToken: _clearToken,
        clearFilters: _clearFilters,
    }
})();

export default SessionStorageTokenService;