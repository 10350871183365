import React, { ReactNode, useState } from "react";
import EventService from "../contexts/EventContext";

export default React.memo(function EventController({ children }: { children: ReactNode }) {
    const [status, setStatus] = useState<string>('');

    const handleStatusChange = (newStatus: string) => {
        setStatus(newStatus)
    }

    const value = {
        status,
        handleStatusChange
    }

    return (
        <EventService.Provider value={value}>
            {children}
        </EventService.Provider>
    )
})
