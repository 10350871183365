import {reactAppAdPrefix} from "../config/GlobalAppConfig";
import {getMapKeyByEnumValue} from "../helpers/HelpersFunc";
import {Flow, UserGroup, UserRoles} from "./enums";

const createMappingRoles = (): Map<string, number> => {
// ric-ww-gstech-users-eventd-areasec
// ric-ww-gstech-users-eventd-broker
// ric-ww-gstech-users-eventd-centralinsurance
// ric-ww-gstech-users-eventd-hqsecurity
// ric-ww-gstech-users-eventd-localsec
// ric-ww-gstech-users-eventd-riskassessor-1
// ric-ww-gstech-users-eventd-riskassessor-2
// ric-ww-gstech-users-eventd-riskassessor-3
// ric-ww-gstech-users-eventd-riskassessor-4
// ric-ww-gstech-users-eventd-riskassessor-5
// ric-ww-gstech-users-eventd-underwriter

    let mapRoles= new Map<string, number>();
    mapRoles.set("localeventmanager", UserGroup.local_event_manager);
    mapRoles.set("localsec", UserGroup.local_security_manager);
    mapRoles.set("areasec", UserGroup.region_security_manager);
    mapRoles.set("hqsecurity", UserGroup.group_security_manager);
    mapRoles.set("broker", UserGroup.broker);
    mapRoles.set("riskassessor", UserGroup.risk_assessor);
    mapRoles.set("centralinsurance", UserGroup.central_insurance);
    mapRoles.set("underwriter", UserGroup.underwriter);
    mapRoles.set("maisonsec", UserGroup.maison_security_manager);
    mapRoles.set("admin", UserGroup.administrator);
    mapRoles.set("director", UserGroup.director);
    return mapRoles;
}

/**
 * Return idGroupUser from user role key p.ex: localsec => 2 
 * @param roleKey p.ex: localsec 
 * @returns idGroupUser
 */
export const getMappingRoles = (propRoles: string | null): number => {
    if(propRoles === null && propRoles === undefined){
        return 0;
    }

    let azPrefix = reactAppAdPrefix? reactAppAdPrefix : '';
    let roleToTest : string[] | undefined = propRoles?.replace(azPrefix, '').split('-');
    if(roleToTest === undefined){
        return 0; 
    }

    let res = createMappingRoles().get(roleToTest[0]);
    return res ? res : 0;
}

export const getMappingRolesFromValue = (propRoles: UserGroup): string => {
    let res = getMapKeyByEnumValue(createMappingRoles(), propRoles);
    return res ? res : '';
}

export const usersAllowedToEditEventAndDeclaration: UserGroup[] =  [
    UserGroup.local_event_manager,
    UserGroup.local_security_manager,
    UserGroup.region_security_manager,
    UserGroup.maison_security_manager,
    UserGroup.group_security_manager
];

export const usersRoleAllowToEnterInEditMode: UserRoles[] = [
    UserRoles.localsec,
    UserRoles.areasec,
    UserRoles.director,
    UserRoles.hqsecurity,
    UserRoles.localeventmanager
];

export const flowsWhereCanEditEventAndDeclaration: Flow[] =  [
     Flow['Pending Region']
    ,Flow['Pending HQ']
    ,Flow['Pending Local Sec']
    ,Flow['Pending local event']
]