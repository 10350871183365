import React, { Dispatch, MouseEvent, SetStateAction } from "react";
import { Button } from "@material-ui/core";
import { EUiModalReason } from "../../../shared/components/ui/UiModal/UiModal";
import { EAlertStatus, TMassImportResponse } from "../../../shared/services/massImportLVL1/massImportService";
import { TProcessResponseData } from "./ImportResponseData.config";

export interface IFooterModalImportLVL1 {
    statusResponse: TMassImportResponse["status"] | null;
    onClose: (event: MouseEvent<HTMLElement> | KeyboardEvent | {}, reason: EUiModalReason) => void;
    setImportResponse: Dispatch<SetStateAction<TMassImportResponse | null>>;
    setResponseData: Dispatch<SetStateAction<TProcessResponseData | null>>;
}

const FooterModalImportLVL1: React.FC<IFooterModalImportLVL1> = ({
    statusResponse,
    onClose,
    setImportResponse,
    setResponseData,
}) => {
    const handleCloseClick = (event: MouseEvent<HTMLButtonElement>) => onClose(event, EUiModalReason.CloseIconClick);
    const handleCancelClick = (event: MouseEvent<HTMLButtonElement>) => onClose(event, EUiModalReason.CancelBtnClick);
    const handleImportClick = () => {
        setImportResponse(null);
        setResponseData(null);
    };

    switch (statusResponse) {
        case EAlertStatus.Success:
            return (
                <Button key="close" variant="contained" onClick={handleCloseClick} color="primary">
                    Close
                </Button>
            );

        case EAlertStatus.Error:
            return (
                <>
                    <Button key="try-again" variant="contained" onClick={handleImportClick} color="primary">
                        Try Again
                    </Button>
                    <Button key="cancel" onClick={handleCancelClick} variant="outlined" color="primary">
                        Cancel
                    </Button>
                </>
            );

        case EAlertStatus.Duplicate:
            return (
                <>
                    <Button key="cancel" onClick={handleCancelClick} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button key="import" onClick={handleImportClick} variant="contained" color="primary">
                        Import
                    </Button>
                </>
            );

        default:
            return (
                <>
                    <Button key="cancel" onClick={handleCancelClick} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button
                        key="import"
                        variant="contained"
                        color="primary"
                        disabled={false}
                        onClick={() => document.getElementById("file-upload-input")?.click()}
                    >
                        Import
                    </Button>
                </>
            );
    }
};

export default FooterModalImportLVL1;
