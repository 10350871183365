import React from 'react';
import { Calendar, momentLocalizer, Event } from 'react-big-calendar';
import moment from 'moment';
import { EventStatus, BigValendarItem } from '../../interface';
import Style from './RichemontCalendar.module.scss';
import {isDateInRange} from '../../../shared/helpers/HelpersFunc'
import '../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarView.scss';
import { Grid } from '@material-ui/core';
import { CalendarEventItem } from './CalendarEventItem';
import { CalendarEventDetail } from './CalendarEventDetail';
import { CalendarToolbar } from './CalendarToolbar'
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

interface Props {
    events: any;
    onClickBtn: () => void;
}

export class CalendarView extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedEvents: [],
            selectedDate: undefined
        }
    }

    componentDidUpdate(prevProps: Props) {
        if(this.props.events && this.props.events.length && this.props.events !== prevProps.events) {
            const newItems = this.state.selectedEvents.map((se: BigValendarItem) => {
                return this.props.events.filter((item: BigValendarItem) => se.resource && item.resource && se.resource.id === item.resource.id)[0];
            })

            this.setState({
                events: this.props.events,
                selectedEvents: newItems
            })
        }
    }

    render() {
        return (
            <div className="dashboard-calendar-content">
                <Grid container spacing={3} className="dashboard-calendar">
                    <Grid item xs={12} md={9} >
                        <Calendar
                            localizer={localizer}
                            events={this.props.events}
                            startAccessor="start"
                            endAccessor="end"
                            popup={true}
                            className={Style.calendar}
                            eventPropGetter={(event: Event) => {
                                if (event.resource.status === EventStatus.pendinghq) {
                                    return { className: Style.eventpending }
                                }
                                return {};
                            }}
                            dayPropGetter={(date :Date) => {
                                return this.state.selectedDate ? 
                                    isDateInRange(this.state.selectedDate,this.state.selectedDate,date) ? 
                                        {style : {backgroundColor: '#dcdcff'} } 
                                        :
                                        {} 
                                    : 
                                    {}
                            }}
                            views={['month']}
                            onSelectEvent={(ev: Event) => {
                                this.setState({
                                    selectedEvents: [ev]
                                })
                            }}
                            onSelectSlot={(evs: any) => {
                                evs.slots.map((date: Date) => 
                                    this.setState({
                                        selectedEvents: this.props.events.filter((ev: any) => isDateInRange(new Date(ev.start), new Date(ev.end), new Date(date))),
                                        selectedDate: date
                                    })
                                )
                            }}
                            selectable={true}
                            components={{ event: CalendarEventItem, toolbar: CalendarToolbar }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className="dashboard-event-details">
                        <h2 className="dashboard-event-details__title">Event Details</h2>
                        {
                            this.state.selectedEvents.length > 0 ? (
                                <div>
                                    {
                                        this.state.selectedEvents.map((ev: Event, index: number) => (
                                            <CalendarEventDetail key={`event-${index}`} event={ev} onClickBtn={this.props.onClickBtn}/>
                                        ))
                                    }
                                </div>
                            ):
                            <div>Sorry no event planned on this day</div>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
}