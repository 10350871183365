import * as Yup from 'yup';

export interface IPreRequisites {
  leadBrandName: string,
  country: string,
  multiBrandEvent: boolean,
  brandsConcerned: Array<string>,
}

export const InitPreRequisites: IPreRequisites = {
  leadBrandName: 'Toto1',
  country: '',
  multiBrandEvent: false,
  brandsConcerned: [],
};

// export const MapTo = {
//   leadBrandName : InitPreRequisites.leadBrandName || '',

// };

export const SchemaPreRequisites = Yup.object<IPreRequisites>().shape({
    leadBrandName: Yup.string().required("*"),
    country: Yup.string().required("*"),
    multiBrandEvent: Yup.bool().required("*"),
    brandsConcerned: Yup.array<string>(),
  });