import { FormikHelpers } from "formik";
import React from "react";
import { TemplateDto } from "../../../shared/models/interfaces";
import { AdministrationService } from "../../../shared/services/administration.service";
import { useAdministrationContext } from "../../AdministrationContext";
import { AdministrationTable, IAdministrationTableHead } from "../CommonMgmt/AdministrationTable";
import { TemplateForm, TemplateFormValue } from "./TemplateForm";

const tableHeads: IAdministrationTableHead<TemplateFormValue>[] = [
    { name: "name", label: "Name", sortable: true, searchable: true, accessor: "name" },
    { name: "subject", label: "Subject", sortable: true, accessor: "subject", searchable: false },
    {
        name: "body",
        label: "Body",
        sortable: true,
        accessor: ((template) => template.body?.replace(/<[^>]*>?/gm, '')),
        searchable: false
    },
];

export const TemplateTable = () => {
    const adminCtx = useAdministrationContext();

    const handleCreate = async (data: TemplateFormValue, formikHelpers: FormikHelpers<TemplateFormValue>) => {
        if(adminCtx.template.items.some(t=>t.name.toLowerCase()===data.name.toLowerCase())){
            formikHelpers.setFieldError("name", "This name already exists")
            return false;
        }
        data.body = data.body.replace("<p><br></p>", "");
        AdministrationService.addTemplate(data).subscribe(()=>{
            adminCtx.template.load();
        })    
    };

    const handleDelete = async (data: TemplateDto) => {
        AdministrationService.deleteTemplate(data).subscribe(()=>{
            adminCtx.template.load();
        })
    };
    const handleUpdate = async (data: TemplateFormValue, item: TemplateDto, formikHelpers:FormikHelpers<TemplateFormValue> ) => {
        if(adminCtx.template.items.some(t=>t.name.toLowerCase()===data.name.toLowerCase() && data.name.toLowerCase() !== item.name.toLowerCase())){
            formikHelpers.setFieldError("name", "This name already exists")
            return false;
        }
        data.body = data.body.replace("<p><br></p>", "");
        AdministrationService.updateTemplate({...item, ...data}).subscribe(()=>{
            adminCtx.template.load();
        })
    };

    return (
        <AdministrationTable
            className="columns-4"
            id="template-table"
            title="template"
            loading={adminCtx.template.isLoading}
            items={adminCtx.template.items}
            itemLabelProp="name"
            form={TemplateForm}
            tableHeads={tableHeads}
            onCreate={handleCreate}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
        />
    );
};
