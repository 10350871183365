import React from 'react';
import SiteRoutes from './routes/Routes';
import './App.css';

const App = () => {
  return (
      <div>
        {/* <div>{new Date().toISOString()}</div> */}
        <SiteRoutes />
      </div>
  );
}

export default App;