import React from 'react';
import { FieldProps, FormikProps } from 'formik';
import { UiTextField, UiSwitchField, UiTextareaField } from '../../../../shared/components/fields';
import { ConditionnalField } from '../../../../shared/components/ConditionnalField';
import { FIELD_IDS } from '../../../../admin/components/FieldsMgmt/Fields.type';
import { MAX_TEXTFIELD_CHARS } from '../../../../shared/constants';

interface CustomInterface extends FieldProps, FormikProps<any>{}

export const RoutingAndTransfer = (props: CustomInterface) => {
    return (
        <React.Fragment>
            <ConditionnalField 
                fieldId={FIELD_IDS.routingAndTransfer.routingAndDeparture}
                name="declaration.routingAndTransfer.routingAndDeparture"
                label="routing and departure done by"
                component={UiTextField}
                onChange={props.field.onChange}
                onBlur={props.field.onBlur}
                disabled={props.form.values.isReadOnly}
            />
            <ConditionnalField 
                fieldId={FIELD_IDS.routingAndTransfer.from}
                name="declaration.routingAndTransfer.from"
                label="from"
                component={UiTextField}
                onChange={props.field.onChange}
                onBlur={props.field.onBlur}
                disabled={props.form.values.isReadOnly}
                column="half"
            />

            <ConditionnalField 
                fieldId={FIELD_IDS.routingAndTransfer.to}
                name="declaration.routingAndTransfer.to"
                label="to"
                component={UiTextField}
                onChange={props.field.onChange}
                onBlur={props.field.onBlur}
                disabled={props.form.values.isReadOnly}
                column="half"
            />
           
            <ConditionnalField 
                fieldId={FIELD_IDS.routingAndTransfer.transportOrganizer}
                name="declaration.routingAndTransfer.transportOrganizer"
                label="transport organizer"
                component={UiTextField}
                onChange={props.field.onChange}
                onBlur={props.field.onBlur}
                disabled={props.form.values.isReadOnly}
                column="half"
            />
            <ConditionnalField 
                fieldId={FIELD_IDS.routingAndTransfer.complyWithDirectives}
                name="declaration.routingAndTransfer.complyWithDirectives"
                label="Comply with directives"
                inline={true}
                component={UiSwitchField}
                disabled={props.form.values.isReadOnly}
            />

            <ConditionnalField                                
                        fieldId={FIELD_IDS.routingAndTransfer.routingAndTransferInformation}
                        name="declaration.routingAndTransfer.routingAndTransferInformation"
                        label="Routing and transfer information for broker"
                        placeholder="Information about routing and transfer to send to broker..."
                        inline={true}
                        component={UiTextareaField}
                        disabled={props.form.values.isReadOnly}
                        inputProps={{maxLength:MAX_TEXTFIELD_CHARS}}
            /> 

        </React.Fragment>
    )
}