import React, { useContext } from "react";

const LoadingContext = React.createContext({
  startLoading: () => {},
  stopLoading: () => {},
});

export const useLoadingContext = () => useContext(LoadingContext)

export default LoadingContext;
