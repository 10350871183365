import Container from "@material-ui/core/Container";
import React, {useMemo} from "react";
import { NotificationContainer } from 'react-notifications';
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Administration } from "../admin/Administration";
import { ConfirmationContextProvider } from "../shared/contexts/ConfirmationContext";
import {Dashboard} from "../dashboard/Dashboard";
import Declaration from "../declaration/Declaration";
import ErrorPage from "../Error/ErrorPage";
import {NewEvent} from "../event/NewEvent";
import { Loading } from "../shared/components/loading/Loading";
import PrivateRoute from "../shared/components/PrivateRoute";
import { AuthContextProvider } from "../shared/contexts/AuthContext";
import AppErrorBoundary from "../shared/helpers/AppErrorBoundary";
import Page2 from "../shared/mock/FormExample";
import auth from "../shared/services/authentification/authentificationService";
import Ui from "../ui/Ui";
import RefDataController from "./../shared/controllers/RefDataController";
import { Header } from "./Header";
import LoadingContext from "./LoadingContext";
import EventController from "../shared/controllers/EventController";

const Routes = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  const loadingCtx = {
    startLoading: () => setShowLoading(true),
    stopLoading: () => setShowLoading(false),
  };

  const cancel = () => {
     setShowLoading(false);
  };

  const termsOfUsePath = () =>{
    return `${process.env.PUBLIC_URL}/assets/docs/2021-06_TermsOfUse-EventDeclaration.pdf`;
  }
 
  const year = useMemo(()=> new Date().getFullYear(), [])

  const version = useMemo( () => String(process.env.REACT_APP_VERSION), []);

  return (
    <>
      <LoadingContext.Provider value={loadingCtx}>
        <AppErrorBoundary>
          <ConfirmationContextProvider>
            <AuthContextProvider>
              <RefDataController>
                <EventController>
                  <Header />
                  <Switch>
                    {/* <PrivateRoute auth={auth} exact={true} path="/" component={Dashboard} /> */}
                    <PrivateRoute auth={auth} exact={true} path="/">
                        <Redirect to="/dashboard" />
                    </PrivateRoute>
                    <PrivateRoute auth={auth} exact path="/page2/index" component={Page2} />
                    <PrivateRoute auth={auth} exact path="/Page2/index/:id" component={Page2} />
                    <PrivateRoute auth={auth} exact path="/dashboard/event/new" component={NewEvent} />
                    <PrivateRoute auth={auth}  
                      exact
                      path="/dashboard/event/:mode/:id"
                      render={({match,history}:any) => <NewEvent match={match} history={history} />}
                    />
                    <PrivateRoute auth={auth} 
                      exact
                      path="/admin"
                      component={Administration}
                    />
                    <PrivateRoute auth={auth} 
                      exact
                      path="/dashboard/event/:mode/:id/:idd"
                      component={Declaration}
                    />
                    <PrivateRoute auth={auth} 
                      exact
                      path="/dashboard/event/:mode/:id/:idd/readonly"
                      component={Declaration}
                    />
                    <PrivateRoute auth={auth} exact path="/declaration/:id" component={Declaration} />
                    <PrivateRoute auth={auth} exact path="/declaration/new" component={Declaration} />
                    <PrivateRoute auth={auth} 
                      exact
                      path="/declaration/forms/:id"
                      component={Declaration}
                    />
                    <PrivateRoute auth={auth} exact path="/declaration/Read/:id" component={Declaration} />
                    <PrivateRoute auth={auth} 
                      exact
                      path="/dashboard"
                      name="Dashboard"
                      component={Dashboard}
                      
                    />
                    {/* TODO: For Dev UI only, need to be delete at the end*/}
                    <PrivateRoute auth={auth} exact path="/ui" name="UI" component={Ui} />

                    <PrivateRoute auth={auth} 
                      path="/dashboardProtected"
                      component={Dashboard}
                    />

                    {/* Error page */}
                    <Route exact path="/Error" component={ErrorPage} />

                    {/* Not Found */}
                    <PrivateRoute auth={auth} component={() => <Redirect to="/" />} />
                  </Switch>
                </EventController>
              </RefDataController>
              {false && (
                <nav>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/Page2/index">Page 2</Link>
                    </li>
                    <li>
                      <Link to="/Page2/index/2">Page 2 with id</Link>
                    </li>
                    <li>
                      <Link to="/declaration/new">Declaration New</Link>|{" "}
                      <Link to="/declaration/1">Edit</Link>|{" "}
                      <Link to="/declaration/forms">Forms</Link>|{" "}
                      <Link to="/declaration/read/33">Read</Link>
                    </li>
                    <li>
                      <Link to="/dashboard/event/new">New event</Link>
                      <Link to="/dashboard/event/edit/2"> New event edit</Link>
                      <Link to="/dashboard/event/readonly/2">
                        {" "}
                        New event readonly
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard">Dashboard</Link>
                    </li>

                    <li>
                      <Link to="/dashboardProtected">Dashboard Protected</Link>
                    </li>
                    <li onClick={() => auth.logOut()}>
                      <Link to="/">logout</Link>
                    </li>
                    <li onClick={() => auth.signIn()}>
                      <Link to="/">signIn</Link>
                    </li>
                  </ul>
                </nav>
              )}
              {showLoading && <Loading cancel={cancel}/>}
              <footer className="footer">
                <Container maxWidth="xl">
                  <div className="footer-content">
                    <span id="footer-copyright">© Richemont {year} - {version}</span>
                    <div>
                      <a className="footer-link" href={termsOfUsePath()} target="_blank" rel="noreferrer">
                        Term of use
                      </a>
                      <a className="footer-link" href={termsOfUsePath()} target="_blank" rel="noreferrer">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </Container>
              </footer>
              <NotificationContainer />
            </AuthContextProvider>
          </ConfirmationContextProvider>
        </AppErrorBoundary>
      </LoadingContext.Provider>
    </>
  );
};


export default Routes;
