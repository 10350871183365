import React, { ReactNode, useEffect, useState } from 'react'
import { UiModal, IPropsUiModalBase } from './UiModal'
import { UiAutocompleteField, UiTextareaField } from '../../fields';
import { Formik, Field, FormikProps} from 'formik';
import { Button } from '@material-ui/core';
import { ReferencesService, Role } from '../../../services/referencesService';
import { UserGroup } from '../../../models/enums';
import { IUiMultiselectOption } from '../../fields/UiAutocompleteField';
import { EmailData } from '../../../models/interfaces';

type IPropsUiModalBrocker = IPropsUiModalBase & {
    event: any // TODO: Use right type
    send?: (values : any) => void
    onClose?: () => void
}

const InitialValue: EmailData = {
    targetEmail: [],
    emailContent : '',
    idEvent: 0,
    targetEmailRole: ''
}

export const UiModalBrocker = (props: IPropsUiModalBrocker) => {
    const [brokers, setBrokers] = useState<Array<IUiMultiselectOption>>([]);

    const onClose = (func: () => void) => {
        if(props.onClose) {
            func();
            props.onClose();
        }
    }

    const send = (values : any) => {
        if(props.send) {
            props.send(values);
        }
    }

    const footerButtons: ReactNode[] = []

    useEffect(() => {
        // if brokers contains no values load data 
        if(brokers.length === 0){
            var res = ReferencesService.getAllRoles(UserGroup.broker).subscribe((roles: Role[]) => {
                if(roles.length) {
                    const list = roles[0].targetEmail.map((email: string): IUiMultiselectOption => {
                        return {value: email, label: email, itemContent: email} as IUiMultiselectOption;
                    });
                    setBrokers(list);
                }
            });
            return () => {
                res.unsubscribe();
            }
        }
    }, [brokers.length]);

    return (
        <UiModal
            title="Fill the informations to send to broker"
            open={props.open}
            onClose={props.onClose}
            footerButtons={footerButtons}
        >
            <Formik
                initialValues={InitialValue}
                onSubmit={() => {}}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    handleReset,
                }: FormikProps<any>) => {

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <Field id="brokersAutocomplte" component={UiAutocompleteField} options={brokers ? brokers: []} name="targetEmails" label="brokers" multiple={true} />
                                <UiTextareaField label="Text send by email" value={values.emailContent} name="emailContent" onChange={handleChange}/>
                            </div>
                            <div className="ui-modal__footer ui-modal__footer--buttons">
                                <Button onClick={() => onClose(handleReset)} variant="outlined" color="primary">Cancel</Button>
                                <Button onClick={() => send(values)} variant="contained" color="primary">Send to Broker</Button>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </UiModal>
    )
}
export default UiModalBrocker;