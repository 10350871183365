import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

interface Props {
    onChange?(values:any): void
}

export const FormObserver: React.FC<Props> = (props) => {
    const { values } = useFormikContext();
    useEffect(() => {
      if(props.onChange) {
          props.onChange(values);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
    return null;
  };