import { FormHelperText } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useEffect } from "react";
import UiBaseField from "./UiBaseField";
import { getFieldData } from "./UiFieldHelpers";
import UiFieldWrapper from "./UiFieldWrapper";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25)
  }
}));

export type TagsInputProps = UiBaseField & {
  placeholder: string,
  validation?(value: any): boolean,
  onInputChange?(value: any): void,
  inputValue?: string
}

export default function TagsInput(props: TagsInputProps) {
  const classes = useStyles();
  const { label } = props;
  const { error, name, value } = getFieldData(props);
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = (event: any, option: any) => {
    if(props.form){
        props.form.setFieldValue(name, option)
    }
    if(props.onChange){
        props.onChange(event,option)
    }
  }

  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      event.preventDefault();
      
      const newSelectedItem = [...value];

      if (!event.target.value.replace(/\s/g, "").length) return;

      newSelectedItem.push(event.target.value.trim());
      handleChange(event, newSelectedItem);
      setInputValue('');
    }
    if (
      value.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      handleChange(event, value.slice(0, value.length - 1));
    }
  }

  const handleDelete = (item: string) => () => {
    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });

    const newSelectedItem = [...value];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    handleChange(event, newSelectedItem);
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.value.includes(",") && !event.target.value.includes(";")) {
      setInputValue(event.target.value);

      if(props.onInputChange) {
        props.onInputChange(event.target.value);
      }
    } else {
      const inputValue = event.target.value.slice(0, -1);
      const newValue = value && value.length ? [...value, inputValue] : [inputValue];
      handleChange(event, newValue);
      setInputValue('');
    }
  }

  useEffect(() => {
    if(props.inputValue !== null && props.inputValue !== undefined) {
      setInputValue(props.inputValue)
    }
  }, [props.inputValue])
  return (
    <React.Fragment>
       <UiFieldWrapper name="ui-text-field" {...props}>
        {props.inline && (
            <div className="ui-field__label">{props.label}</div>
        )}
        <div>
          <TextField
            InputProps={{
              startAdornment: value?.map((item: string) => (
                <Chip
                  key={item}
                  tabIndex={-1}
                  label={item}
                  className={classes.chip}
                  onDelete={handleDelete(item)}
                />
              )),
              onChange: (event: any) => {
                handleInputChange(event);
              },
            }}
            variant="outlined"
            label={label}
            name={name}
            id={props.id}
            value={inputValue}
            onKeyDown={handleKeyDown}
            placeholder={props.placeholder}
            disabled={props.disabled}
            error={error.hasError}
          />
          {error.hasError && (
            <FormHelperText error={error.hasError}>{ error.errorMessage }</FormHelperText>
          )}
        </div>
      </UiFieldWrapper>
    </React.Fragment>
  );
}
