import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCell, TableRow } from '@material-ui/core';
type IPropsUiTableRowSkeleton ={
    nbCell: number,
    nbRow: number
}

export const UiTableRowSkeleton = (props: IPropsUiTableRowSkeleton ) => {
    return <>
    {[...Array(props.nbRow)].map((row, i) =>
        <TableRow key={i}>
             {[...Array(props.nbCell)].map((cell, j) =>
                <TableCell key={j}>
                    <Skeleton animation="wave" variant="text"  />
                </TableCell>
            )}
        </TableRow>
    )}

    </>
}