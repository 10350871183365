import React from 'react';
import { FIELD_IDS } from '../../../admin/components/FieldsMgmt/Fields.type';
import ConditionalChildren from '../../../shared/components/ConditionalReadOnlyField';
import { DeclarationInterface } from '../../declarationNewEdit/interfaces';

export const EventDescription = (props: DeclarationInterface) => {

return (
    <React.Fragment>
        <ConditionalChildren fieldId={FIELD_IDS.eventDescription.globalDescription}>
            <p>{props.globalDescription}</p>
        </ConditionalChildren>
        <table>
            <tbody>
                <ConditionalChildren fieldId={FIELD_IDS.eventDescription.recurrentEvent}>
                    <tr>
                        <td>Recurrent Event</td>
                        <td
                        className={ (props.recurrentEvent ? 'yes' : 'no') }
                        >{props.recurrentEvent ? 'yes' : 'no'}</td>
                    </tr>
                </ConditionalChildren>
                <ConditionalChildren fieldId={FIELD_IDS.eventDescription.exhibitionSurface}>
                    <tr>
                        <td>Exhibition Surface Area</td>
                        <td>{props.exhibitionSurface} m2</td>
                    </tr>
                </ConditionalChildren>
                <ConditionalChildren fieldId={FIELD_IDS.eventDescription.specificEvents}>
                    <tr>
                        <td>Specific shows during the event</td>
                        <td
                        className={ (props.specificEvents ? 'yes' : 'no') }
                        >{props.specificEvents ? 'yes' : 'no'}</td>
                    </tr>
                </ConditionalChildren>
                <ConditionalChildren fieldId={FIELD_IDS.eventDescription.specificShows}>
                <tr>
                    <td>Specific shows related to all brands</td>
                    <td
                    className={ (props.specificShows ? 'yes' : 'no') }
                    >{props.specificShows ? 'yes' : 'no'}</td>
                </tr>
                </ConditionalChildren>
            </tbody>
        </table>
    </React.Fragment>
)

} 