import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import UiBaseField from './UiBaseField';
import { getFieldData } from './UiFieldHelpers';
import UiTooltip from './UITooltip';

type IPropsUiSelectField= UiBaseField &  {
    options: Array<any>,
    enableValue_None : boolean,
    onCustomSelect?: (value: IPropsUiSelectField) => {}
}

export const UiSelectField = (props: IPropsUiSelectField) => {
    let {error, name, value} = getFieldData(props);
    

    const renderOption = (option:any) => {
        if(typeof option === 'string' ){
            return option
        }else{
            return option.itemContent
        }
    }
    const handleChange = (event: any, option: any) => {
        if(props.form){
            props.form.setFieldValue(name, event.target.value)
        }
        if(props.onChange){
            props.onChange(event,option)
        }
        if(props.onCustomSelect) {
            props.onCustomSelect(option);
        }
    }
    return (
        <React.Fragment>
            <FormControl fullWidth variant="outlined" className="ui-field">
                <div className="ui-field__field">
                    <InputLabel error={error.hasError} id={name + "-label"}>{props.label}</InputLabel>
                    <Select
                        disabled={props.disabled ? props.disabled : false}
                        fullWidth
                        labelId={name + "-label"}
                        id={name}
                        label={props.label}
                        value={value}
                        name={name}
                        onChange={handleChange}
                        error={error.hasError}
                    >
                        {props.enableValue_None && 
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                        }
                        {props.options.map((option: any, index: number) => {
                            return <MenuItem key={index} value={option.id}>{renderOption(option)}</MenuItem>
                        })}
                    </Select>
                    {error.hasError && (
                        <FormHelperText error={error.hasError}>{error.errorMessage}</FormHelperText>
                    )}
                </div>
                {props.tooltip && <span className="ui-field__tooltip"> <UiTooltip title={props.tooltip} /></span>}
            </FormControl>
        </React.Fragment>
    )
}
export default UiSelectField