import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    BaseAttributeDto,
    CountryDto, FilteredAdminPagedRequest,
    MaisonDto,
    PagedRequest, PagedResponse,
    UserDto,
    UserDtoBack
} from '../../../shared/models/interfaces';
import { AdministrationService } from '../../../shared/services/administration.service';
import { useAdministrationContext } from '../../AdministrationContext';
import { AdministrationFiltersProps } from '../CommonMgmt/AdministrationFilters';
import { AdministrationTable, IAdministrationTableHead } from '../CommonMgmt/AdministrationTable';
import { AddOrEditUser, User } from './AddOrEditUser';
import {DELAY_LOADING, ROWS_PER_PAGE} from "../../../shared/constants";
import {Subscription} from "rxjs";
import {NotificationManager} from "react-notifications";

const hasOneEmptyElement = (key: string, i: UserDto) => {
    const elements = i[key];

    if (elements.length && elements.some((el: any) => !el.name || !el.code)) {
        return true;
    }

    return false;
}

const isActive = (item: UserDto) => {
    return item.isActive ? 'Yes' : 'No';
}

const collectionAccessor = (key: string) =>  (i:UserDto) =>{
    if(!i[key] || hasOneEmptyElement(key, i) || !i[key].length ){
        return "All"
    }
    return i[key].sort((a:BaseAttributeDto, b:BaseAttributeDto) => a.name.localeCompare(b.name)).map((m:BaseAttributeDto)=>m.name).join(", ")
}

const tableHeads : IAdministrationTableHead<UserDto>[] = [
    {
        name: 'name',
        label: 'Name',
        sortable: true,
        accessor: 'name',
        search: (item, search) => item.name?.toLowerCase().includes(search.toLowerCase()),
    },
    { name: 'email', label: 'Email', accessor: 'email', sortable: true},
    { name: 'role', label: 'Role', accessor : 'role', sortable: true},
    { name: 'maisons', label: 'Maison', accessor : collectionAccessor('maisons'),  sortable: true},
    {name: 'countries', label: 'Country', accessor: collectionAccessor('countries'), sortable: true},
    {name: 'isActive', label: 'Active', accessor: 'isActive', cell: isActive, sortable: true}
]

const filterCountries = (item: any, filterValue: any): boolean => {
    return (
        !filterValue.length ||
        filterValue.some((f: any) => {
            return (
                item.countries.some((country: CountryDto) => country.code === f.value) ||
                !item.countries ||
                !item.countries.length
            );
        }));
}

const filterMaisons = (item: any, filterValue: any): boolean => {
    return (
        !filterValue.length ||
        filterValue.some((f: any) => {
            return (
                item.maisons.some((maison: MaisonDto) => maison.code === f.value) ||
                !item.maisons ||
                !item.maisons.length
            );

        }));
}

const nameAdjustement = (items: UserDto[]) => {
    items.forEach(item => {
        const emailSplitted = item.email.split('@')[0];
        const firstAndLastName = emailSplitted.split('.');

        const firstName = firstAndLastName[0].charAt(0).toUpperCase() + firstAndLastName[0].slice(1);
        const lastName = firstAndLastName[1]?.charAt(0).toUpperCase() + firstAndLastName[1]?.slice(1);

        item.name = item.name ?? firstName + ' ' + lastName;
    });
}

export const UsersMgmt = function() {
    const {maison, country} = useAdministrationContext();
    const [pageResponse, setPageResponse] = useState<PagedResponse<UserDto>>({
        pageSize: ROWS_PER_PAGE,
        model: [],
        pageCount: 1,
        itemsCount: 0,
        pageNumber: 1
    });
    const baseRequest: PagedRequest = {
        sortOrder: undefined,
        pageNumber: 1,
        pageSize: ROWS_PER_PAGE
    }
    const [pageRequest, setPageRequest] = useState<FilteredAdminPagedRequest>(baseRequest);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [update, setUpdate] = useState<boolean>(false);
    const userSubcription = useRef<Subscription>();
    const editSubcription = useRef<Subscription>();

    const handleCreate = async (data: User) => {
        const _cnt = data.countries.map( (c : any) => ({code : c.value, name : c.label}))
        const _maison = data.maisons.map( m => ({code : m.value, name : m.label}))
        const payload = {name : data.username?.label, email: data.username?.value, role: '' , countries: _cnt, maisons:_maison, id: 0, isActive: true}
        AdministrationService.addUser(payload).subscribe(()=> { })
    };

    const handleDelete = async (data: any) => {
        AdministrationService.deleteUser(data.id).subscribe(()=>  {} )
    };

    const handleUpdate = async (data: User) => {
        const _cnt = data.countries.map( (c : any) => ({code : c.value, name : c.label}))
        const _maison = data.maisons.map( m => ({code : m.value, name : m.label}))
        const payload : UserDtoBack = {
            id : data.id,
            name : data.name,
            email : data.username?.value,
            role : '',
            maisons: _maison,
            countries: _cnt,
            isActive: data.isActive
        }
        editSubcription.current?.unsubscribe();
        editSubcription.current = AdministrationService.updateUser(payload).subscribe(resp =>
            {
                setUpdate(!update);
            })
    };

    const handlePagination = (request: FilteredAdminPagedRequest) => {
        setPageRequest(request);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(true);
        }, DELAY_LOADING);

        userSubcription.current = AdministrationService.getPagedUsers(pageRequest).subscribe((res: PagedResponse<UserDto>) => {
                setPageResponse(res);
            },
            (err) => {
                NotificationManager.error(err.message ?? err.errorMessage ?? "Unable to fetch users");
            },
            () => {
                clearTimeout(timeout);
                setIsLoading(false);
            }
        )
        return () => {
            userSubcription.current?.unsubscribe();
            clearTimeout(timeout);
            setIsLoading(false);
        };

    }, [pageRequest?.pageNumber, pageRequest?.countries, pageRequest?.maisons,
        pageRequest?.sortOrder, pageRequest?.searchValue, pageRequest?.orderColumn,
        pageRequest?.refreshCache, update]);

    useEffect(() => {
        return () => {
            [userSubcription.current, editSubcription.current].forEach(sub => sub?.unsubscribe())
        }
    }, []);

    const filters: AdministrationFiltersProps | undefined = useMemo(() => {
        if(!maison.items?.length || !country.items?.length) return undefined;
        return {
            items: [
                {
                    name: "countries",
                    label: "Countries",
                    type: "multiselect",
                    accessor: "countries",
                    options: country.items.map((r) => ({ itemContent: r.name, value: r.code })),
                    filter: filterCountries,

                },
                {
                    name: "maisons",
                    label: "Maisons",
                    type: "multiselect",
                    accessor: "maisons",
                    //BRule: if user has empty string on countries/maisons, it means he has all countries/maisons
                    filter: filterMaisons,
                    options: maison.items.map((r) => ({ itemContent: r.name, value: r.code })),
                },
            ],
        };
    }, [maison.items, country.items]);

    return (
        <AdministrationTable
            id="user-table"
            title="User"
            itemLabelProp="name"
            items={[]}
            tableHeads={tableHeads}
            form={AddOrEditUser}
            onCreate={handleCreate}
            onDelete={null}
            onUpdate={handleUpdate}
            onPage={handlePagination}
            pageResponse={pageResponse}
            backendPagination={true}
            filters={filters}
            loading={isLoading || country.isLoading || maison.isLoading}
        />
    );
}