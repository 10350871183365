import * as Yup from 'yup';

export const declarationSchema = Yup.object().shape({
    gcdeclaration: Yup.bool().oneOf([true], 'This field must be checked'),
    brands: Yup.array().min(1, 'You must choose one brand').of(Yup.object().shape({
        value: Yup.string(),
        label: Yup.string()
    }))
});

export const eventDescriptionSchema = Yup.object().shape({
    globalDescription: Yup.string(),
    recurrentEvent: Yup.bool().oneOf([true], 'Please check Recurrent Event'),
    numberOfParticipants: Yup.number().min(1, 'Number of participants required'),
    specificEvents: Yup.string().max(100, 'Max of characters is 100'),
    specificShows: Yup.string().max(100, 'Max of characters is 100')
});

export const mergeProps = (...schemas) => {
    let finalSchema = schemas[0];
    
    for(let schema of schemas.slice(1, schemas.length)) {
        finalSchema = finalSchema.concat(schema);
    }
    
    return finalSchema;
}