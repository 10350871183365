import React from "react"
import Chip from '@material-ui/core/Chip';

type IPropsUiTags = {
    name? : string
    label : string
    uiStyle? : string
}

export const UiTags = (props: IPropsUiTags) => {
    const className = props.uiStyle ? props.uiStyle : 'tags';
    return(
            <Chip label={props.label} className={className}/>
    )

}

export default UiTags;